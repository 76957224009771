import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailNotificationsData } from '../../../services/BPM/workflowtasksDetails';
import { WorkflowTasksManagementService } from '../../../services/BPM/workflowtasksmanagement.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-emailnotifications-view',
  templateUrl: './emailnotifications-view.component.html',
  styleUrls: ['./emailnotifications-view.component.css']
})
export class EmailnotificationsViewComponent implements OnInit {

  isDraggable: boolean = true;
  ModuleID: number;
  CompanyID: number = 0;
  EmailNotificationsID: number;
  showEmailNotificationsFields: boolean = false;;
  ResponseEmailNotificationsData: EmailNotificationsData[] = [];
  showEmailTemplate:boolean=false;
  emailTemplate;
  showemailDataEditTempWindow: boolean = false;
  value;
  WFTemplatesTypes: any;
  constructor(private http: HttpClient, private formbuilder: FormBuilder,private av_router: ActivatedRoute, private router: Router, private dataservice: WorkflowTasksManagementService) {
  }

  ngOnInit() {
    this.CompanyID= +localStorage.getItem("usercompanyID");

    this.loadUsersDashBoard(1, this.CompanyID, 0);
  }


  getWFTemplates() {
    this.WFTemplatesTypes = [{ id: 1, name: 'Page Level' },
    { id: 2, name: 'Form Level' },
    { id: 3, name: 'Display Control Level(inline)' },
    { id: 4, name: 'Display Template Level' },
    { id: 5, name: 'Task Level' },
    ];
  }


  UserDyForm = this.formbuilder.group({
    //userFormMessage: ['', [Validators.required]],
    //userFormMessageType: ['', [Validators.required]],
  });

  loadUsersDashBoard(ModuleID: number, CompanyID: number, EmailNotificationsID: number) {
    // showLoader :true
    debugger;
    this.dataservice.GetAllEmailNotificationsData(ModuleID, CompanyID, EmailNotificationsID).subscribe(data => {
      this.ResponseEmailNotificationsData = data;
    });
  }

  public ManageEmailNotificationsData(ModuleID: number, CompanyID: number, EmailNotificationsID: number, templatePath: string) {
    debugger;
    this.dataservice.GetAllEmailNotificationsData(ModuleID, CompanyID, EmailNotificationsID).subscribe(data => {
      this.showEmailNotificationsFields = true;
      this.showemailDataEditTempWindow = false;

      //console.log("data", data);
      this.ResponseEmailNotificationsData = data;
      this.openEmailTemplate(templatePath);
      this.emailTemplate = "";
    });
  }

  public AddEmailContent() {
    this.showemailDataEditTempWindow = true;
    this.showEmailTemplate = false;
  }

  public dataFieldsClose() {
    this.loadUsersDashBoard(1, this.CompanyID, 0);
    this.showEmailNotificationsFields = false;

  }
  public openEmailTemplate(templatePath: string) {
    this.dataservice.GetHtmlTemplate(templatePath).subscribe(data=>{
      //console.log(data);
      this.showEmailTemplate=true;
      this.emailTemplate = data;
      this.value = "";
      this.value = data;
    })
  }

  public saveOrUpdateEmailContent() {

  }
  
  rolesDialogClose() {
    this.showEmailTemplate = false;
  }

  rolesDialogClose1() {
    this.showemailDataEditTempWindow = false;
  }
}
