import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Input, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { DynamicFieldControlService } from '../ArtworkManagement/dynamic-fields-service/dynamic-field-control.service';
import { DynamicFieldService } from '../ArtworkManagement/dynamic-fields-service/dynamic-field.service';
import { DynamicFormGroupBase } from '../ArtworkManagement/dynamic-fields/dynamic-field-base';
import { ModuleService } from '../services/module.service';
import { NotificationAlertsService } from '../services/notification.service';
import { getBaseUrl } from '../../main';
import { FileInfo, FileRestrictions, SelectEvent, UploadEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { AssignUsersModal, backTemplate, DynamicTemplateData, TaskOwnerAssignUsermodal } from '../ArtworkManagement/dynamic-fields/dynamic-field-list/dynamic-field-list.component';
import { breadCrumbs, cascadingField, templateData } from '../interfaces/ItemplateFields';
import { GlobalVeriables } from '../Global/GlobalVaribales';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { AdminUserManagementService, DateConvertions, GetAuditAllFieldName, NotificationEmailTriggerEvent } from '../services/adminusermanagement.service';
import { CommonFeaturesService } from '../services/commonfeatures.service';
import { Subscription } from 'rxjs';
import { LanguageService } from '../services/language.service';
import { PDfExpAuditSave } from '../services/AMFormsDataDetails';
import { environment } from '../../environments/environment'
import * as e from 'cors';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';




//import { truncate } from 'fs';
@Component({
  selector: 'app-dynamic-form-view',
  templateUrl: './dynamic-form-view.component.html',
  styleUrls: ['./dynamic-form-view.component.css'],
  providers: [DynamicFieldControlService, DynamicFieldService, NotificationAlertsService]
})
export class DynamicFormViewComponent implements OnInit, AfterViewChecked {
  displayViewID: any;
  windowState = 'default';
  /* Isdraggable = true;*/
  resizeWindow = false;
  showArtWorkWindow = false;
  showPopUp: boolean = false;
  defaultModule: any;
  display = 'none';
  setProjectOwnerdisplay = 'none';
  setProjectOwnerdisplaydialog: boolean = false;
  setAssignProjectOwnerdisplay = 'none';
  FieldStatusobj = new FieldStatusDTO();
  actionTypeTemplateID: any;
  templateID: any = null;
  inputFormFields: string = "";
  disableFields: string = "";
  backTemplates: Array<backTemplate> = [];
  controltemplatesforMenuTab: any;

  controltemplatesForTabs: any;
  controlActionNotForMenuTabs: any;
  controlActionForMenuItemsTabs: any;
  selectedTemplateID: any;
  RecordID: any;
  displayTemplateID: any;
  fileUploadDynamicField: any;
  formClass: string = "col-md-6";
  //templates: any;
  myFiles: Array<FileInfo> = [];
  cascadingFormFields: Array<cascadingField> = [];
  toogleActtions: any;

  customUploadType: number = 0;//only local---only Dal---both dal and local

  allTemplates: any;
  controltemplates: any;
  targetModuleID: any;
  targetTemplateID: any;
  controltemplatesforMenu: any;
  controlActionNotForMenuItems: any;
  controltemplatesforMenuCount: number = 4;  //For showing number of action outside of menu
  displaytemplates: any;
  CreateinputFormFields: any;



  breadcrumbs: Array<breadCrumbs>;
  displaytemplatesControlIDs: any;
  displayFiltertemplatesControlIDs: any;
  IsdisplaytemplatesControlID: boolean = false;
  selectedGroupDisplayControlID: number = 0;
  template: any;
  subTemplates: any;
  templateParentID: number = 0;
  IsDisplayControls: boolean = false;
  IsDisplayView: boolean = false;
  IsDisplaySubFormView: boolean = false;
  IsDisplayNestedSubFormView: boolean = false;
  uploadedFiles = [];
  selectedItems = [];
  invalidSubmitAttempt: boolean = false;
  DynamicFields: DynamicFormGroupBase<any>;
  dynamicForm: FormGroup;
  dynamicGroupForm: FormGroup;
  DynamicFormFieldsActions: any = [];
  SelectedRecord: number = 0;
  tempSelectedRecord: number = 0;
  viewRecords: any;
  viewRelProjects: any;
  documentsPath: string;
  IsfromProject: boolean = false;
  DeleteRelProjectConfrim: boolean = false;
  bodyText: string;
  templateData = new DynamicTemplateData();
  projectid: number = 0
  relatedProjectId: number = 0;
  submitted = false;
  offset: string = "+05:30";
  public proids = [];
  public listItems: any = [];
  public value = 0;
  public running: any;
  actionEvent: string;
  dynHeadingName: string;
  ActionEventID: number;
  workflowTasksActionID: number;
  CompanyID: number = 0;
  @Input() PassedmoduleID: number = 0;
  @Input() moduleID: number = 0;
  setSubProcessdisplay: boolean = true;
  tempDocumentsPath: string = "";
  deleteProjectId: number;
  deleteRelatedProjectId: number;
  chatNotificatioData: any;
  isChatNotificatioData: boolean = false;
  MainDataSource: any;
  BindGroupsUsers: any[] = [];
  public fileInfo: Array<FileInfo> = [];
  public FileType: number = 0;
  count: number = 0;
  ProjectStatus: number = 0;
  uids = [];
  uidsText: string = "";
  taskOwnermodal = new TaskOwnerAssignUsermodal();
  userData = new AssignUsersModal();
  userDataList: Array<AssignUsersModal> = [];
  userDataListCheck: Array<AssignUsersModal> = [];
  userGroupDataListCheck: any[] = [];
  openReassignwindow: boolean = false;
  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();

  dialogForm: FormGroup;
  dateFormats: FormatSettings = {
    displayFormat: 'dd-MMM-yyyy',
    inputFormat: 'dd-MMM-yyyy'
  };
  showLoader: boolean = false;
  dynTemplateHeader: string = null;
  dynTemplateHeaderKey: string = null;
  //uploadSaveUrl = getBaseUrl() + 'api/dynamic/uploadDocument';
  uploadSaveUrl = getBaseUrl() + 'api/dynamic/DynACU';
  /*  uploadRemoveUrl = getBaseUrl() + 'api/dynamic/RemoveDocument';*/
  uploadRemoveUrl = getBaseUrl() + 'api/dynamic/DynACUR?loginUserId=' + this.GV.UserId;
  dynPopUpHeading: string = null;
  dynPopUpHeadingKey: string = null;
  IpAddress: string;
  includeAttributeValues = true;
  processTemplate: string = null;
  //bypassUnbypassOpen = 'none';
  dynChatHeadingName: string;
  chatpopup: string = "none";
  dependencyTemplateSubscription: Subscription = new Subscription();
  CheckIsParentTemplateSubscription: Subscription = new Subscription();
  CheckHasDependentsSubscription: Subscription = new Subscription();
  templateRecordSubscription: Subscription = new Subscription();
  templateRecordRemoveSubscription: Subscription = new Subscription();

  tempDataUnSub: Subscription;
  //bypassUnbypassOpen = 'none';
  AuditHistory = 'none';
  ACMIDetails = 'none';
  id: string = '';
  myRestrictions: FileRestrictions = {
    allowedExtensions: [".pdf",],
    maxFileSize: 2147483648,
  };
  ShowChatWindow: boolean = false;
  fromReportsData: any;
  description: string = null;
  projectName: string = null;
  reassignpopup: string = "none";
  showProductPopUp: boolean = false;
  openAssignuserswindow: boolean = false;
  ShowReAssignWindow: boolean = false;
  viewChatPopUp: boolean = false;
  public languageDataDefault: any;
  public dynHeadingNameKey = '';
  public SendEmailToFRAConfirm = false;
  outofOfficeStatus: boolean = false;
  dstaskOwnerGroupList: any;
  dstaskOwnerList: any;
  taskOwnerGroupSelectedValue: any;
  selectedTaskItems: any = [];
  selectedProcessIDItems: any = [];
  selectedGroupID: any = [];
  frmsubmitted: boolean = false;
  sessionUserRole: string;
  sessionUserRoleTrim: string;
  setPriority: string;
  setForCStartDate: string;
  setFirstReviewtDate: string;
  setCompletetDate: string;
  setWorkflowID: number;

  showBypassUnbypassPopUp: boolean = false;
  showgriderror: boolean = false;
  showradioerror: boolean = false;
  BypassunpassAduit: boolean = false;
  byPassForm: FormGroup;
  BypassedItems: any = [];
  BypassItems: any = [];
  byPassItemsSelected: any = [];
  showBypassedList: boolean = false;
  showBypassTasks: boolean = false;
  public data: Array<{ text: string; value: number }>;
  public source: Array<{ text: string; value: number }> = [
    { text: "Project level bypass", value: 1 },
    { text: "Sub process level bypass", value: 2 },
  ];
  submitCount: number = 0;
  TaskBypassConfrimopened: boolean = false;
  TaskUnbypassBypassConfrimopened: boolean = false;
  multiTaskBypassConfrimopened: boolean = false;
  multiTaskUnBypassConfrimopened: boolean = false;
  divHisdata: boolean = false;
  showDialogPopUp: boolean = false;
  displaypopup: string = "none";
  AuditexcelBtn: boolean = false;
  AuditLogId: boolean = false;
  IsAuditExportExcel: boolean = false;
  htmlToAdd: any;
  ObjAuditExpPdf = new PDfExpAuditSave();
  TZoffset = this.DateTimeService.setUTCTimeZone();
  FileName: string;
  progress: number;
  message: string;
  gridData: any;
  IsProjectAccess: boolean = false;
  GroupDyForm = this.formbuilder.group({
  });
  myValue: any;
  IsSSOEnable: boolean = false;
  filteredRecords: any;
  filter: CompositeFilterDescriptor;
  projectLists: any = [];
  groupId: string;
  userId: string;
  AutomationTaskStatus: any;

  constructor(private moduleService: ModuleService, private http: HttpClient, public service: DynamicFieldService,
    private qcs: DynamicFieldControlService,
    private DFS: DynamicFieldService,
    private _router: Router
    , private notifyService: NotificationAlertsService,
    private amService: AdminUserManagementService,
    private cdr: ChangeDetectorRef, private fb: FormBuilder, private GV: GlobalVeriables,
    private dialogService: DialogService, private commonfeaturesservice: CommonFeaturesService,
    private languageService: LanguageService, private router: ActivatedRoute,
    private formbuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private DisplayAllFields: GetAuditAllFieldName,
    private DateTimeService: DateConvertions,


  ) {
    this.myValue = this.service.getValue();
  }

  ngOnInit() {
    this.showProductPopUp = false;
    this.IsSSOEnable = JSON.parse(localStorage.getItem("ssoLogin")) != null ? JSON.parse(localStorage.getItem("ssoLogin")) : false; //this.moduleService.enableSSO;

    if (this.IsSSOEnable) {
      this.moduleService.userSSOLogin.subscribe(response => {
        if (response) {
          this.onPageLoad();
        }
      })
    }
    else {
      this.onPageLoad();
    }
    if (this.myValue === "StartActionCompleted") {
      this.getControlTemplatesForTabs();
    }
  }

  onPageLoad() {
    try {
      this.showDialogPopUp = false;
      this.isChatNotificatioData = true;
      this.userData = null;
      this.userDataList = [];
      this.BindGroupsUsers = [];
      if (this.router.snapshot.paramMap.get('ID') == localStorage.getItem("cryptoProjectID")) {
        this.id = this.router.snapshot.paramMap.get('ID') != null ? JSON.parse(localStorage.getItem("reportsProjectID")) : this.router.snapshot.paramMap.get('ID');
      }
      else { this.id = this.router.snapshot.paramMap.get('ID') != null ? '0101' : this.router.snapshot.paramMap.get('ID') }
      this.GV.SetFileType(1);//1 from Upload
      this.GV.UserName = localStorage.getItem("userName");
      this.GV.UserRoleID = parseInt(localStorage.getItem("userRoleID"));
      this.sessionUserRole = localStorage.getItem("userRoleName");
      this.CompanyID = +localStorage.getItem("usercompanyID");
      this.outofOfficeStatus = localStorage.getItem("OutofOfficeStatus") == 'true' ? true : false;
      this.displayViewID = 0;
      this.templateParentID = 0;
      this.GV.UserId = parseInt(localStorage.getItem("userId"));
      this.IpAddress = this.GV.UserIpAddress;

      //searchFilters

      this.fromReportsData = localStorage.getItem("reportsSelectedData");
      localStorage.removeItem('reportsSelectedData');

      //check for search filters applied
      this.moduleService.searchFilters.subscribe(searchData => {

        if (searchData.templateId == 0) {
          /**/
        } else {

          var tempDisplayTemplates = this.displaytemplates;
          this.displaytemplates = null;
          this.IsDisplayControls = false;

          setTimeout(() => {
            if (tempDisplayTemplates !== null && tempDisplayTemplates !== "" && tempDisplayTemplates.length > 0) {
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchFilters = searchData.searchFields;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].defaultSearch = searchData.defaultSearch;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchFilterValues = searchData.searchFieldValues;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].defaultSearch = searchData.defaultSearch;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].chkFields = searchData.chkFields;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].chkFieldValues = searchData.chkFieldValues;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchName = searchData.searchName;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].highlightRecordID = null;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].selectedRecordID = null;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].keyWordSearch = searchData.keyWordSearch;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].chkDisplayControl = searchData.chkDisplayControl;
              tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].filterConfigKey = searchData.filterConfigKey;
            }

            this.displaytemplates = tempDisplayTemplates.filter(a => a.hasParent == 0 && a.isDependent == 0 && a.displayControlsID != 0 && a.moduleID == this.moduleID);
            this.IsDisplayControls = true;

            this.getControlTemplatesForTabs();


          }, 100);
        }

      });

      //close DAL popup


      this.moduleService.closeDAL.subscribe(data => {
        this.ProjectStatus = 0;
        this.GV.processTemplateID = "";
        this.fileInfo = this.GV.GetFileInfo();
        if (this.fileInfo != null && this.fileInfo != undefined && this.fileInfo.length > 0)
          if (this.dynamicForm != undefined && this.dynamicForm.controls["ProjectComponets"] != undefined)
            this.dynamicForm.controls["ProjectComponets"].setErrors(null);

        var fileType = this.GV.GetFileType();


        if (fileType == 1 || fileType == 0) {
          // "LocalUpload";

          this.tempDocumentsPath = this.moduleService.getBaseUrl() + "documents/temp";

        } else {
          //"DALUpload";

          this.tempDocumentsPath = this.moduleService.getBaseUrl() + "documents";


        }




        //this.uploadedFiles = components;
        this.myFiles = this.fileInfo;
        this.CloseCustomUpload();

        this.showLoader = false;


      });




      //when click on record check for dependent/parent /independent template and and load templates as required
      this.dependencyTemplateSubscription = this.moduleService.dependencyTemplate.subscribe(data => {

        if (data.componentType == "1") {
          this.showLoader = true;
          //this.SelectedRecord = data.selectedRecordId;
          // this.getProjectStatus();

          if (!(data.moduleID == "0" && data.templateId == "0" && data.selectedRecordId == 0)) {


            //check for parent template
            this.CheckIsParentTemplateSubscription = this.moduleService.CheckIsParentTemplate(data.moduleID, data.templateId, data.targetTemplateId).subscribe(dataParent => {


              if (dataParent != null && dataParent.length > 0) {



                let getFirstTemplateID = this.displaytemplates[0].templateID;

                if (this.backTemplates != null && this.backTemplates.length > 0) {
                  let firstTemplate = this.backTemplates.filter(a => a.templateID == getFirstTemplateID);
                  let index = this.backTemplates.indexOf(firstTemplate[0]);
                  if (index == -1) {

                    let objBackTemplate = new backTemplate();

                    objBackTemplate.templateID = getFirstTemplateID;
                    objBackTemplate.displayTemplates = this.displaytemplates;

                    this.backTemplates.push(objBackTemplate)
                  } else {
                    this.backTemplates[index].displayTemplates = this.displaytemplates;

                  }
                } else {
                  let objBackTemplate = new backTemplate();

                  objBackTemplate.templateID = getFirstTemplateID;
                  objBackTemplate.displayTemplates = this.displaytemplates;

                  this.backTemplates.push(objBackTemplate)
                }




                this.displaytemplates = null;

                this.controlActionNotForMenuItems = [];
                this.controltemplatesforMenu = [];
                this.controltemplates = [];
                this.SelectedRecord = data.selectedRecordId;
                //this.getProjectStatus();

                if (dataParent[0].isDependent != "0") {
                  dataParent.forEach(function (part, index) {
                    this[index].recordID = data.selectedRecordId;
                    this[index].selectedRecordID = data.selectedRecordId;
                    this[index].parentTemplateID = data.templateId;
                    this[index].targetTemplateId = data.targetTemplateId;


                  }, dataParent);


                  this.displaytemplates = dataParent.filter(a => a.moduleID == this.moduleID);
                  this.getControlTemplatesForTabs();

                } else {
                  dataParent.forEach(function (part, index) {
                    this[index].recordID = data.dependentRecordID;
                    this[index].selectedRecordID = data.selectedRecordId;
                    this[index].parentTemplateID = data.templateId;
                    this[index].targetTemplateId = data.targetTemplateId;


                  }, dataParent);

                  this.displaytemplates = dataParent.filter(a => a.isDependent == 0 && a.moduleID == this.moduleID);
                  this.getControlTemplatesForTabs();

                }






              }
              else {
                //check  has dependents
                this.CheckHasDependentsSubscription = this.moduleService.CheckHasDependents(data.moduleID, data.templateId).subscribe(dataDependent => {



                  if (dataDependent.isDependent != null && dataDependent.isDependent != "0") {

                    this.SelectedRecord = data.selectedRecordId;

                    //this.getProjectStatus();

                    var templateUpdate = this.displaytemplates.filter(a => a.templateID == dataDependent.templateID && a.moduleID == this.moduleID);
                    var index = this.displaytemplates.indexOf(templateUpdate[0]);
                    if (index != -1) {

                      this.displaytemplates.splice(index, 1);
                      dataDependent.recordID = data.selectedRecordId;
                      dataDependent.selectedRecordID = data.selectedRecordId;
                      dataDependent.targetTemplateId = data.targetTemplateId;
                      dataDependent.selectedRecordIDs = data.selectedRecordIds;
                      dataDependent.filteredRecordsSelected = data.filteredRecordsSelected;
                      this.displaytemplates.push(dataDependent);

                      if (this.displaytemplates.some(a => a.templateID == data.templateId && a.moduleID == this.moduleID)) {
                        this.displaytemplates.filter(a => a.templateID == data.templateId && a.moduleID == this.moduleID)[0].highlightRecordID = data.highlightRecordID;

                      }
                      var tempDisplayTemplates = this.displaytemplates;

                      tempDisplayTemplates.forEach(function (part, index) {

                        tempDisplayTemplates[index].recordType = data.recordType;
                        tempDisplayTemplates[index].collectionId = data.collectionId;
                      }, tempDisplayTemplates);


                      this.displaytemplates = tempDisplayTemplates;


                      this.getControlTemplatesForTabs();

                      var displayControlsID = dataDependent.displayControlsID.split(",");
                      if (displayControlsID != null && displayControlsID.length > 1) {

                        this.onGroupTemplateClick(displayControlsID[0]);


                      }

                    }
                  }
                });
              }
            });
          }
          this.showLoader = false;
        }
      }, error => {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
      });


      this.loadTemplates();
      this.dialogForm = this.fb.group({
        ddlValue: [],
      });

      //get passed dependent template and push to existing template list
      this.templateRecordSubscription = this.moduleService.templateRecord.subscribe(data => {
        if (this.displaytemplates != null && this.displaytemplates != undefined && data.templateID != null && (data["hasParent"] != data["isDependent"])) {
          var templateID = data.templateID;
          var moduleID = data.moduleID;


          this.SelectedRecord = data.selectedRecordID;
          this.getProjectStatus();

          var templateUpdate = this.displaytemplates.filter(a => a.templateID == data.templateID && a.moduleID == this.moduleID);
          var index = this.displaytemplates.indexOf(templateUpdate[0]);
          if (index != -1) {
            this.displaytemplates.splice(index, 1);
          }

          //check template exist if not push the template
          var checkTempalte = this.displaytemplates.filter(a => a.templateID == templateID && a.moduleID == this.moduleID);

          if (checkTempalte.length == 0) {

            if (data.moduleID == this.moduleID) {
              this.displaytemplates.push(data);

            }
          }


          if (this.displaytemplates != null && this.displaytemplates != undefined) {

            this.displaytemplates.sort((a, b) => {
              return a.templateOrder - b.templateOrder;
            });

            if (this.id != null && this.id != undefined) {
              this.displaytemplates.filter(a => a.templateID == 16)[0].classess = "col-md-9 reports_top";
              this.getControlTemplatesForTabs();

            }
          }
        }
      });

      //get passed dependent template and push to remove template list

      this.templateRecordRemoveSubscription = this.moduleService.templateRecordRemove.subscribe(data => {




        if (this.displaytemplates != null && this.displaytemplates != undefined && data.templateID != null) {

          var templateID = data.templateID;

          this.SelectedRecord = data.selectedRecordID;
          this.getProjectStatus();



          var templateUpdate = this.displaytemplates.filter(a => a.templateID == data.templateID && a.moduleID == this.moduleID);
          var index = this.displaytemplates.indexOf(templateUpdate[0]);
          if (index != -1) {
            this.displaytemplates.splice(index, 1);


          }

        }

        if (this.displaytemplates != null && this.displaytemplates != undefined) {

          this.displaytemplates.sort((a, b) => {
            return a.templateOrder - b.templateOrder;
          });

          this.getControlTemplatesForTabs();


        }



      });

      // check is dependent and refresh the parent dependent
      this.moduleService.dependentRecord.subscribe(dataDependent => {




        if (dataDependent.templateID != 0) {
          this.IsDisplayControls = false;

          setTimeout(() => {



            var templateUpdate = this.displaytemplates.filter(a => a.templateID == dataDependent.templateID && a.moduleID == this.moduleID);
            var index = this.displaytemplates.indexOf(templateUpdate[0]);
            if (index != -1) {
              this.displaytemplates.splice(index, 1);
              templateUpdate[0].id = 0;
              this.displaytemplates.unshift(templateUpdate[0]);

              this.getControlTemplatesForTabs();

            }

            this.IsDisplayControls = true;


          }, 100);

        }

      });
      //if outofofficestatus is true send an info message.
      if (this.outofOfficeStatus) {
        this.commonfeaturesservice.GetAllActionsMessages(1, 0, 13, 999, "OFFIc", 1).subscribe((dataUActionsMessages1) => {
          localStorage.setItem("OutofOfficeStatus", 'false');
          this.notifyService.show(dataUActionsMessages1[0].displayMessage, dataUActionsMessages1[0].messageType);
        });
      }
    } catch (error) {
      this.showLoader = false;
      this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  GetMessageByLanguage(aliasKey, orgMsg) {
    let tData = this.languageService.dataByLanguage.find(x => x.LanguageKey == aliasKey);
    if (tData)
      return tData.Message;
    else
      return orgMsg;
  }



  trackByFn(index, item) {
    return index; // or item.id
  }

  ngOnDestroy() {
    if (this.tempDataUnSub != undefined) this.tempDataUnSub.unsubscribe();
    this.dependencyTemplateSubscription.unsubscribe();
    this.templateRecordSubscription.unsubscribe();
    this.templateRecordRemoveSubscription.unsubscribe();
    this.CheckHasDependentsSubscription.unsubscribe();
  }
  controlActionForMenuItems: any = []
  controlActionForMenuItems1: any = []
  loadTemplates() {

    this.showLoader = true;


    this.moduleService.unSubTemplateData.subscribe(response => {
      if (this.tempDataUnSub) {
        this.tempDataUnSub.unsubscribe();
      }
    })


    this.tempDataUnSub = this.moduleService.templateData.subscribe(templateData => {


      if (templateData.initialLoad)
        return false;
      if (localStorage.getItem("moduleID") == "99999")
        templateData.moduleID = 1;
      this.moduleID = templateData.moduleID;
      if (templateData.moduleID != 0) {

        //getting control template and actions
        this.moduleService.GetControlTemplatesByModuleID(templateData).subscribe(data => {

          this.controltemplates = data.filter(a => a.displayControlsID == 0);
          //console.log("this.controltemplates", this.controltemplates);

          //not for Menu Items
          this.controlActionNotForMenuItems = this.controltemplates.filter(ta => ta.isMenuItem == "False");
          this.controlActionForMenuItems = this.controltemplates.filter(ta => ta.isMenuItem == "True");
          this.controlActionForMenuItems1 = this.controltemplates.filter(ta => ta.isMenuItem == "True");
          //console.log("this.controlActionForMenuItems", this.controlActionForMenuItems);
          if (this.id != null) {
            this.controlActionForMenuItems = this.controlActionForMenuItems.filter(ta => ta.ActionEvent != "Create")
            this.controlActionForMenuItems = [];
            this.controlActionForMenuItems1 = [];
          }
          if (this.id != null) {
            this.controlActionNotForMenuItems = this.controlActionNotForMenuItems.filter(ta => ta.ActionEvent != "Create")
            this.controlActionNotForMenuItems = [];
          }

          // code changes need to done using role specific
          this.sessionUserRoleTrim = this.sessionUserRole.replace(/ /g, "");
          if (this.sessionUserRoleTrim == 'ProjectInitiator' || this.sessionUserRoleTrim == 'GraphicCoordinator' ||
            this.sessionUserRoleTrim == 'ProjectOwner' || this.sessionUserRoleTrim == 'AllAccess') {
          }
          else {
            for (let index = 0; index <= this.controlActionForMenuItems1.length; index++) {
              var actionID = this.controlActionForMenuItems1[index].actionEventID
              var response = this.checkUserPrivilege(actionID, 3);
              if (response != null && response == "display-none") {
                this.controlActionForMenuItems.splice(index, 1);
              }
            }
          }

          this.controltemplatesforMenu = [
            {
              text: "More",
              templateName: "More",
              items: this.controlActionForMenuItems
            }
          ];
        });
        ///getting display templates
        this.moduleService.GetTemplatesByModuleID(templateData).subscribe(data => {


          this.IsDisplayControls = true;
          this.displaytemplates = data.filter(a => a.hasParent == 0 && a.isDependent == 0 && a.displayControlsID != 0 && a.moduleID == this.moduleID);
          this.getControlTemplatesForTabs();
          this.assignBreadCrumbs();

          if (this.id != null && this.id != undefined) {
            let selectedItem = new templateSelectedRecord();
            selectedItem.templateId = "95";
            selectedItem.selectedRecordId = parseInt(this.id)
            selectedItem.moduleID = "1";
            selectedItem.dependentRecordID = "0";
            selectedItem.recordType = 1
            selectedItem.collectionId = "0";
            selectedItem.targetTemplateId = "0";
            selectedItem.componentType = "1";
            localStorage.setItem("projectTaskRedirection", "true");
            this.moduleService.passDependencyTemplate(selectedItem);
          }

        });
      }
      this.showLoader = false;

    }, error => {
      this.showLoader = false;
      this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
    });
    let moduleID = localStorage.getItem("moduleID");

    this.moduleService.getToogleActionsByModule(parseInt(moduleID)).subscribe(data => {

      this.toogleActtions = data;
    });

  }
  checkActionDisable(disableAction): boolean {


    if (disableAction.displayControlSelectedCount == 0) {
      return false;

    } else if (disableAction.displayControlSelectedCount == 1 && this.SelectedRecord > 0) {
      return false;

    } else {
      return true;

    }


  }
  public removeEventHandler(e: RemoveEvent, fieldName: any): void {
    //console.log(e);
    if (e.files.length > 0) {
      let index = this.uploadedFiles[fieldName].indexOf(e.files[0].name);
      if (index != -1) {
        this.moduleService.removeUploadFiles(this.uploadedFiles[fieldName][index]).subscribe(data => {
        });
        this.uploadedFiles[fieldName].splice(index, 1);
      }
    }
  }

  public errorEventHandler(e) {
    //console.log(e);
    return false;
  }

  public assignBreadCrumbs() {


    var tempbreadcrumbs = [];

    var temData = new templateData();
    temData.moduleID = this.moduleID

    this.moduleService.GetAllTemplatesByModuleID(temData).subscribe(data => {
      this.allTemplates = data;

      data.forEach(function (part, index) {


        var breadcrumbs = new breadCrumbs()
        breadcrumbs.id = part.templateID;
        breadcrumbs.displayName = "";
        breadcrumbs.templateId = part.templateID;
        breadcrumbs.templateName = part.templateName;
        breadcrumbs.defaultHeader = (part.defaultHeader == null || part.defaultHeader == undefined || part.defaultHeader == "") ? part.tabHeaderName : part.defaultHeader;
        breadcrumbs.isDependent = part.isDependent;
        breadcrumbs.hasParent = part.hasParent;
        breadcrumbs.displayControlsID = part.displayControlsID;
        breadcrumbs.displayControlsTabID = part.displayControlsTabID;
        breadcrumbs.selectedRecordID = part.selectedRecordID;
        breadcrumbs.highlightRecordID = part.highlightRecordID;
        breadcrumbs.breadCrumOrder = part.breadCrumOrder;

        tempbreadcrumbs.push(breadcrumbs);
      });

      this.breadcrumbs = tempbreadcrumbs.sort((a, b) => {
        return a.breadCrumOrder - b.breadCrumOrder;
      });

      this.moduleService.passbreadCrumbs(this.breadcrumbs);

      if (this.id != null && this.id != undefined) {
        setTimeout(() => {
          var breadcrumbs = new breadCrumbs()
          breadcrumbs.id = 95;
          breadcrumbs.displayName = localStorage.getItem("projectNameRedirection");
          breadcrumbs.templateId = 95;
          breadcrumbs.displayControlsID = 13;
          breadcrumbs.displayControlsTabID = 1106;
          breadcrumbs.selectedRecordID = 0;
          this.moduleService.passbreadCrumbName(breadcrumbs);
        }, 150);
      }
      else {
        localStorage.removeItem("projectNameRedirection");
      }

    });


  }

  public selectEventHandler(e: SelectEvent): void {
    const that = this;
    e.files.forEach((file) => {
      if (!file.validationErrors) {
        /**/
      }
    });

  }

  uploadEventHandler(e: UploadEvent, fieldName: any) {
    this.showLoader = true;
    if (this.DFS.checkUndefinedNullEmpty(this.uploadedFiles[fieldName])) {
      this.uploadedFiles[fieldName] = [];
    }
    let files: any;
    files = e.files;
    if (files.length === 0) {
      return;
    }
    let filesToUpload: File[] = files;
    let filelist = [];
    this.uploadedFiles[fieldName].push(files[0].name);
  }

  public completeEventHandler() {
    setTimeout(function () {
      var items = document.getElementsByClassName("k-text-error");
      for (var i = 0; i < items.length; i++) {
        document.getElementsByClassName("k-text-error")[i]["innerText"] = "Please upload a file without annotiations";
      }
    }, 500);

    this.showLoader = false;
  }
  getDisplaycontrolRecords() {
    this.showLoader = true;
    this.templateParentID = 0;
  }
  isrelatedProjectshow: boolean = false;
  DeleteConfrimopened: boolean = false;
  DeleteConfrimRelatedProject: boolean = false;
  onRelatedProjectEdit(template) {

    this.templateID = 30;
    this.RecordID = this.SelectedRecord;
    this.inputFormFields = template.inputFormFields;
    this.selectedTemplateID = 30;
    this.showLoader = true;
    this.service.getDynamicFormFields(30, this.SelectedRecord, 0, false, template.inputFormFields, 30, false).subscribe(response => {
      this.dynTemplateHeader = response.dynTemplateHeader;
      this.DynamicFields = response.dynamicFormFieldList;
      this.DynamicFormFieldsActions = response.displayActions;
      this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
      this.showLoader = false;
      this.setProjectOwnerdisplay = 'block';
      this.setProjectOwnerdisplaydialog = true;

    }, error => {
      this.showLoader = false;
      this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
    });
  }
  onKeypressEvent(event: any) {
    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }

  getJsonData() {
    return this.http.get('./assets/ProductsList.json');
  }

  addproduct() {
    let data1: any;
    this.moduleService.getAllProducts().subscribe(data => {
      debugger;


      let parsedData = JSON.parse(JSON.stringify(data));
      let data2 = JSON.parse(parsedData);


      this.gridData = data2;
      this.showProductPopUp = true;

    });
  }

    AddNewReocrd(template, notification ?: any) {
      this.languageDataDefault = this.languageService.dataByLanguage;
      this.formClass = "col-md-6";

      this.GV.SetFileType(1);//1 from Upload
      this.GV.SetFileInfo([]);//1 from Upload

      this.GV.SetActionEvent(template.actionEvent);

      this.fileInfo = [];

      this.actionEvent = template.actionEvent;
      this.ActionEventID = parseInt(template.actionEventID);
      this.workflowTasksActionID = parseInt(template.workflowTasksActionID);
      this.dynTemplateHeader = template.templateName;


      this.templateData.ActionEvent = this.actionEvent;

      this.targetModuleID = (template.targetModuleId == null || template.targetModuleId == "") ? 0 : template.targetModuleId;
      this.targetTemplateID = (template.targetTemplateId == null || template.targetTemplateId == "") ? 0 : template.targetTemplateId;

      if (template.actionEvent == "Create") {

        this.templateData.IsfromProject = true;
        this.templateData.IsfromAssigneUsers = false;
        this.inputFormFields = template.inputFormFields;
        this.disableFields = template.disableFields;
        this.selectedTemplateID = template.templateID;
        this.GV.SetProjectID(0);
        this.templateID = template.templateID;
        this.RecordID = 0;
        this.displayTemplateID = template.displayTemplateID;
        this.ProjectStatus = 0;
        this.invalidSubmitAttempt = false;
        this.onDisplayForm();
      }
      else if (template.actionEvent == "AssignUsers") {
        //this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
        if (this.ProjectStatus == 473 || this.ProjectStatus == 474 || this.ProjectStatus == 488 || this.ProjectStatus == 2737) {//pending completed cancelled Deleted
          this.showLoader = false;
          this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "PSCD", this.CompanyID).subscribe((dataCDActionsMessages) => {
            this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
          });
        }
        else {
          this.GetAssignListData();
          this.inputFormFields = template.inputFormFields;
          this.disableFields = template.disableFields;
          this.selectedTemplateID = 35;//template.templateID;
          this.templateData.IsfromAssigneUsers = true;
          this.templateData.IsfromProject = false;
          this.templateID = 35;//template.templateID;
          this.RecordID = this.SelectedRecord;
          this.invalidSubmitAttempt = false;
          this.openAssignuserswindow = true;
          this.showLoader = true;
          //this.onDisplayForm();
          this.service.getDynamicFormFields(this.templateID, this.SelectedRecord, 0, false, template.inputFormFields, template.templateID, this.templateData.IsfromAssigneUsers).subscribe(response => {
            this.dynTemplateHeader = response.dynTemplateHeader;
            this.DynamicFields = response.dynamicFormFieldList;
            this.moduleService.GetProjectTaskOwnerGroupsUsers(this.RecordID).subscribe(responseData => {
              if (responseData != null) {
                this.groupId = responseData.groupId;
                this.userId = responseData.userId;
              }
            })
            if (response.dynamicFormFieldList > 0) {
              this.languageService.TranslateDefaultItem(response.dynamicFormFieldList, this.languageDataDefault);
            }
            // this.DynamicFormFieldsActions = response.displayActions;
            this.dynPopUpHeading = template.templateName;
            this.dynPopUpHeadingKey = template.templateAliasName;
            // this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
            this.showLoader = false;
          });
          this.moduleService.BindProjectAssigments(this.RecordID).subscribe(response => {
            this.MainDataSource = response;
            let tasksCount = 0;
            //console.log(this.MainDataSource);
            for (let i = 0; i < this.MainDataSource[0].taskList.length; i++) {
              if (this.MainDataSource[0].taskList[i].workFlowStatusId == 276 &&
                this.MainDataSource[0].taskList[i].taskId == 1) {
                this.AutomationTaskStatus = true;
              }
            }
            for (let index1 = 0; index1 < this.MainDataSource.length; index1++) {
              for (let i = 0; i < this.MainDataSource[index1].taskList.length; i++) {
                if (this.MainDataSource[index1].taskList[i].workFlowStatusId == 274
                  || this.MainDataSource[index1].taskList[i].workFlowStatusId == 275
                ) {
                  tasksCount = tasksCount + 1;
                }
              }
              if (tasksCount <= 0) {
                this.MainDataSource[index1].isDisabled = true;
              }
              tasksCount = 0;
            }
            this.MainDataSource.forEach((departmentData, index) => {
              this.GroupDyForm.addControl('Groups' + index, new FormControl([]));
              this.GroupDyForm.addControl('Users' + index, new FormControl([]));
              if (departmentData.isDisabled) {
                this.GroupDyForm.controls['Groups' + index].disable();
                this.GroupDyForm.controls['Users' + index].disable();
              }
            })
            this.setAssignProjectOwnerdisplay = 'block';

            if (this.BindGroupsUsers.length > 0) {
              for (let i of this.BindGroupsUsers) {
                const depId = i.departmentId;
                const groupIds = i.groupId;
                const userIds = i.userId;
                const mainDataSourceItem = this.MainDataSource.find(j => j.departmentID === depId);
                if (mainDataSourceItem) {
                  const itemIndex = this.MainDataSource.findIndex(j => j.departmentID === depId);
                  const groupItemId = mainDataSourceItem.groupsList.find(g => g.value === groupIds);
                  this.MainDataSource[itemIndex].isSelectedGroup = groupItemId.value;
                  this.bindTaskOwnerGroupUsers(groupItemId.value, mainDataSourceItem.departmentID, "View")
                  this.bindTaskOwnerUsers(userIds, mainDataSourceItem.departmentID, "View");
                  setTimeout(() => {

                    this.MainDataSource[itemIndex].isSelectedUser = userIds;

                  }, 1000)
                }
              }
            }
          });
        }
        //})

      }

      else if (template.actionEvent == "btnReassign") {

        this.dynHeadingName = template.templateName;
        this.dynHeadingName = this.languageService.GetActionMessageByLanguage(template.templateAliasName, template.templateName);
        this.inputFormFields = template.inputFormFields;
        this.disableFields = template.disableFields;
        this.reassignpopup = "block";
        this.ShowReAssignWindow = true;
        this.openReassignwindow = true;

      }
      else if (this.SelectedRecord != 0) {
        if (template.actionEvent == "Edit") {
          this.moduleService.CheckForProjectAccess(this.SelectedRecord, 0).subscribe(projectData => {
            if (projectData == false) {
              this.IsProjectAccess = projectData;
              this.notifyService.show(this.GetMessageByLanguage('STC_PEDIT', "Project was not assigned to the user. you can't edit the project."), 4);
              return;
            }
            else {
              this.getEditID(template.templateID, this.SelectedRecord, template.inputFormFields, template.disableFields, template.templateID)
            }
          })
          //this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
          //if (this.ProjectStatus == 471)
          //this.getEditID(template.templateID, this.SelectedRecord, template.inputFormFields, template.disableFields, template.templateID)
          // else {
          //   this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "PSCE", this.CompanyID).subscribe((dataCDActionsMessages) => {
          //     this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
          //   });
          // }
          //})
        }
        else if (template.actionEvent == "Copy") {
          this.includeAttributeValues = true;
          this.count = 0;
          this.description = null;
          this.formClass = "col-md-12";
          this.getEditID(template.templateID, this.SelectedRecord, template.inputFormFields, template.disableFields, template.templateID)
        }
        else if (template.actionEvent == "View") {

          this.dynHeadingName = template.templateName;
          this.dynHeadingNameKey = template.templateAliasName;
          this.showLoader = true;
          this.moduleService.DisplayView(this.SelectedRecord).subscribe(data => {
            this.viewRecords = data;
            this.display = 'block';
            this.showLoader = false;
            this.documentsPath = this.moduleService.getBaseUrl();
            this.isrelatedProjectshow = false;
          })

        }
        else if (template.actionEvent == "btnAddEditRelatedProjects") {

          this.dynHeadingName = "Project Details";
          this.dynHeadingNameKey = "STC_PRODTL";
          this.showLoader = true;
          this.moduleService.DisplayView(this.SelectedRecord).subscribe(data => {
            this.viewRecords = data;
            this.display = 'block';
            this.showLoader = false;
            this.documentsPath = this.moduleService.getBaseUrl();
            this.isrelatedProjectshow = true;
            this.getRelatedProjectByProjectId(this.SelectedRecord);
          }, error => {
            this.showLoader = false;
            this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
          })

        }
        else if (template.actionEvent == "Delete") {

          //this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
          this.DeleteConfrimopened = true;
          // if (this.ProjectStatus == 471) {
          //   this.DeleteConfrimopened = true;
          // }
          // else {
          //   this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "PSCD", this.CompanyID).subscribe((dataCDActionsMessages) => {
          //     this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
          //   });
          // }
          //})
        }
        else if (template.actionEvent == "btnAddEditRelatedProjects") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "Download") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnAssignReviewers") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnAssignApprovers") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnSendForReview") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnSendForApproval") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }

        else if (template.actionEvent == "btnReview") {
          this.showLoader = true;

          //logic start

          //logic end

          this.showLoader = false;
        }
        else if (template.actionEvent == "btnApprove") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnReject") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnFinalize") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnRejectInfo") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }

        else if (template.actionEvent == "btnComplete") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }

        else if (template.actionEvent == "btnVersionTree") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnCheckOut") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnCheckIn") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnWorkflow") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnSecondaryAuthors") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnReviewApproval") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnCompleteSectionAuthoring") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnChangeSecondaryAuthors") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnChangePrimaryAuthor") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnCompareVersions") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnCompare") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnDocumentProperties") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnManageSections") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnCompleteMultipleSectionsAuthoring") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnVersionUpdate") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnDeviationManagement") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnDistribute") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnSPLView") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnSPLValidate") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnSPMView") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnSPMValidate") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (template.actionEvent == "btnEditContent") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }

        else if (template.actionEvent == "SetProjectOwner") {

          //this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
          if (this.ProjectStatus != 473 && this.ProjectStatus != 474 && this.ProjectStatus != 2737) {
            this.templateID = template.displayTemplateID;
            this.RecordID = this.SelectedRecord;
            this.inputFormFields = template.inputFormFields;
            this.disableFields = template.disableFields;
            this.selectedTemplateID = template.templateID;
            this.templateData.IsfromProject = true;
            this.templateData.IsfromAssigneUsers = false;
            //code for popup strat
            this.showLoader = true;
            this.invalidSubmitAttempt = false;
            this.service.getDynamicFormFields(template.displayTemplateID, this.SelectedRecord, 0, false, template.inputFormFields, template.templateID, false).subscribe(response => {
              this.languageService.TranslateDefaultItem(response.dynamicFormFieldList, this.languageDataDefault);
              this.dynTemplateHeader = response.dynTemplateHeader;
              this.DynamicFields = response.dynamicFormFieldList;
              this.DynamicFormFieldsActions = response.displayActions;
              this.dynPopUpHeading = template.templateName;
              this.dynPopUpHeadingKey = template.templateAliasName;
              this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
              this.showLoader = false;
              this.setProjectOwnerdisplay = 'block';
              this.setProjectOwnerdisplaydialog = true;

            });
            //code for popup end
          }
          else {
            this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "PCRCCCPW", this.CompanyID).subscribe((dataCDActionsMessages) => {
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
            });
          }
          //}, error => {
          //  this.showLoader = false;
          //  this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
          // console.log(error);
          //})
        }
        else if (template.actionEvent == "Cancel") {



          // this.getEditID(template.displayTemplateID, this.SelectedRecord, template.inputFormFields, template.templateID);
          //this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
          if (this.ProjectStatus != 471 && this.ProjectStatus != 473 && this.ProjectStatus != 474 && this.ProjectStatus != 2737) {//pending completed cancelled

            const dialog: DialogRef = this.dialogService.open({
              title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
              content: this.languageDataDefault.find(x => x.LanguageKey == 'SCF_CCLP').Message,
              actions: [
                { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
                { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
              ],
              width: 400,
              height: 150,
              minWidth: 200,
              cssClass: "suspend_proj",
            });

            dialog.result.subscribe((result) => {

              if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
                this.templateID = template.displayTemplateID;
                this.RecordID = this.SelectedRecord;
                this.inputFormFields = template.inputFormFields;
                this.disableFields = template.disableFields;
                this.selectedTemplateID = template.templateID;
                this.templateData.IsfromProject = true;
                this.templateData.IsfromAssigneUsers = false;
                this.invalidSubmitAttempt = false;

                //code for popup strat
                this.showLoader = true;
                this.service.getDynamicFormFields(template.displayTemplateID, this.SelectedRecord, 0, false, template.inputFormFields, template.templateID, false).subscribe(response => {
                  this.dynTemplateHeader = response.dynTemplateHeader;
                  this.DynamicFields = response.dynamicFormFieldList;
                  this.languageService.TranslateDefaultItem(response.dynamicFormFieldList, this.languageDataDefault);
                  this.dynPopUpHeading = template.templateName;
                  this.dynPopUpHeadingKey = template.templateAliasName;
                  this.DynamicFormFieldsActions = response.displayActions;
                  this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
                  this.showLoader = false;
                  this.setProjectOwnerdisplay = 'block';
                  this.setProjectOwnerdisplaydialog = true;

                });
                //code for popup end
              }

            });
          }
          else
            this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_CCP', "You cannot cancel this project."), 4);
          //})



        }
        else if (template.actionEvent == "SuspendProject") {




          //this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
          if (this.ProjectStatus != 471 && this.ProjectStatus != 473 && this.ProjectStatus != 474 && this.ProjectStatus != 488 && this.ProjectStatus != 2737) {//pending completed cancelled


            const dialog: DialogRef = this.dialogService.open({

              title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
              content: this.languageDataDefault.find(x => x.LanguageKey == 'SCF_SUSP').Message,
              actions: [
                { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
                { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
              ],
              width: 400,
              height: 150,
              minWidth: 200,
              cssClass: "suspend_proj",
              //cssClass: "custom-css-class",
            });

            dialog.result.subscribe((result) => {

              if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
                this.templateID = template.displayTemplateID;
                this.RecordID = this.SelectedRecord;
                this.inputFormFields = template.inputFormFields;
                this.disableFields = template.disableFields;
                this.selectedTemplateID = template.templateID;
                this.templateData.IsfromProject = true;
                this.templateData.IsfromAssigneUsers = false;
                this.invalidSubmitAttempt = false;

                //code for popup strat
                this.showLoader = true;
                this.service.getDynamicFormFields(template.displayTemplateID, this.SelectedRecord, 0, false, template.inputFormFields, template.templateID, false).subscribe(response => {
                  this.dynTemplateHeader = response.dynTemplateHeader;
                  this.DynamicFields = response.dynamicFormFieldList;
                  this.languageService.TranslateDefaultItem(response.dynamicFormFieldList, this.languageDataDefault);
                  this.dynPopUpHeading = template.templateName;
                  this.dynPopUpHeadingKey = template.templateAliasName;
                  this.DynamicFormFieldsActions = response.displayActions;
                  this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
                  this.showLoader = false;
                  this.setProjectOwnerdisplay = 'block';
                  this.setProjectOwnerdisplaydialog = true;

                }, error => {
                  this.showLoader = false;
                  this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
                });
                //code for popup end
              }

            });
          }
          else {
            this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "YCSP", this.CompanyID).subscribe((dataCDActionsMessages) => {
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
            });
          }
          //})
        }

        else if (template.actionEvent == "btnHideBypassTasks") {
          //this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
          if (this.ProjectStatus != 471 && this.ProjectStatus != 473 && this.ProjectStatus != 474 && this.ProjectStatus != 488 && this.ProjectStatus != 2737) {//pending completed cancelled

            const dialog: DialogRef = this.dialogService.open({
              title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
              content: this.languageDataDefault.find(x => x.LanguageKey == 'SCF_HBTL').Message,
              actions: [
                { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
                { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
              ],
              width: 400,
              height: 150,
              minWidth: 200,
              cssClass: "suspend_proj",
            });

            dialog.result.subscribe((result) => {
              if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
                this.templateID = template.displayTemplateID;
                this.RecordID = this.SelectedRecord;
                this.inputFormFields = template.inputFormFields;
                this.disableFields = template.disableFields;
                this.selectedTemplateID = template.templateID;
                this.templateData.IsfromProject = true;
                this.templateData.IsfromAssigneUsers = false;
                this.invalidSubmitAttempt = false;
                this.GV.isHideBypassTasks = 'HideProjectLevelBypass';
                this.asyncloadTemplates();
              }
            });
          }
          else {
            this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "YCSP", this.CompanyID).subscribe((dataCDActionsMessages) => {
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
            });
          }  //})
        }

        else if (template.actionEvent == "btnShowBypassTasks") {
          //this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
          if (this.ProjectStatus != 471 && this.ProjectStatus != 473 && this.ProjectStatus != 474 && this.ProjectStatus != 488 && this.ProjectStatus != 2737) {//pending completed cancelled
            const dialog: DialogRef = this.dialogService.open({
              title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
              content: this.languageDataDefault.find(x => x.LanguageKey == 'STC_AYSSBTL').Message,
              actions: [
                { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
                { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
              ],
              width: 400,
              height: 150,
              minWidth: 200,
              cssClass: "suspend_proj",
            });

            dialog.result.subscribe((result) => {
              if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
                this.templateID = template.displayTemplateID;
                this.RecordID = this.SelectedRecord;
                this.inputFormFields = template.inputFormFields;
                this.disableFields = template.disableFields;
                this.selectedTemplateID = template.templateID;
                this.templateData.IsfromProject = true;
                this.templateData.IsfromAssigneUsers = false;
                this.invalidSubmitAttempt = false;
                this.GV.isHideBypassTasks = '';
                this.GV.SubProcessID = 0;
                this.GV.subProcessIDList = [];
                this.GV.filteredRecordsSave = [];
                this.asyncloadTemplates();
              }
            });
          }
          else {
            this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "YCSP", this.CompanyID).subscribe((dataCDActionsMessages) => {
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
            });
          }
          //})
        }

        else if (template.actionEvent == "ResumeProject") {



          //this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
          if (this.ProjectStatus == 488) {//check suspend status

            const dialog: DialogRef = this.dialogService.open({
              title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
              content: this.languageDataDefault.find(x => x.LanguageKey == 'SCF_RESP').Message,
              actions: [
                { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
                { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
              ],
              width: 350,
              height: 150,
              minWidth: 200,
              cssClass: "suspend_proj"
            });

            dialog.result.subscribe((result) => {

              if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
                this.templateID = template.displayTemplateID;
                this.RecordID = this.SelectedRecord;
                this.inputFormFields = template.inputFormFields;
                this.disableFields = template.disableFields;

                this.selectedTemplateID = template.templateID;
                this.templateData.IsfromProject = true;
                this.templateData.IsfromAssigneUsers = false;
                this.invalidSubmitAttempt = false;

                //code for popup strat
                this.showLoader = true;
                this.service.getDynamicFormFields(template.displayTemplateID, this.SelectedRecord, 0, false, template.inputFormFields, template.templateID, false).subscribe(response => {
                  this.dynTemplateHeader = response.dynTemplateHeader;
                  this.DynamicFields = response.dynamicFormFieldList;
                  this.languageService.TranslateDefaultItem(response.dynamicFormFieldList, this.languageDataDefault);
                  this.dynPopUpHeading = template.templateName;
                  this.dynPopUpHeadingKey = template.templateAliasName;
                  this.DynamicFormFieldsActions = response.displayActions;
                  this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
                  this.showLoader = false;
                  this.setProjectOwnerdisplay = 'block';
                  this.setProjectOwnerdisplaydialog = true;
                  //code for sending email notification
                }, error => {
                  this.showLoader = false;
                  this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
                });
                //code for popup end




              } else {


              }

            });

          }
          else {
            this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "YCRP", this.CompanyID).subscribe((dataCDActionsMessages) => {
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
            });
          }
          //});

        }
        else if (template.actionEvent == "ACMIDetails") {
          this.showLoader = true; this.templateID = template.displayTemplateID; this.RecordID = this.SelectedRecord; this.ACMIDetails = 'block';
          this.showLoader = false;
        }
        else if (template.actionEvent == "bypassUnbypassOpen") {
          this.showLoader = true;
          this.templateID = this.templateID;
          this.RecordID = this.SelectedRecord;
          this.bypassUnbypassOpen();
          this.showLoader = false;
        }
        else if (template.actionEvent == "AuditHistory") {
          this.showLoader = true;
          this.templateID = template.displayTemplateID;
          this.RecordID = this.SelectedRecord;
          this.moduleService.GetDynamicAuditStatus(this.SelectedRecord, 1).subscribe(data => {
            if (data == this.RecordID)
              this.AuditHistory = 'block';
            else
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_NADA', "No audit data available for this record."), 4);
          });
          this.showLoader = false;
        }

        else if (template.actionEvent == "btnInvite") {
          //this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
          if (this.ProjectStatus != 473 && this.ProjectStatus != 474 && this.ProjectStatus != 2737) {

            this.templateID = template.templateID;
            this.RecordID = this.SelectedRecord;
            this.inputFormFields = template.inputFormFields;
            this.disableFields = template.disableFields;

            this.selectedTemplateID = template.templateID;
            this.templateData.IsfromProject = true;
            this.templateData.IsfromAssigneUsers = false;
            this.invalidSubmitAttempt = false;
            this.showLoader = true;
            this.service.getDynamicFormFields(template.templateID, this.SelectedRecord, 0, false, template.inputFormFields, template.templateID, false).subscribe(response => {
              this.dynTemplateHeader = response.dynTemplateHeader;
              for (var j = 0; j < response.dynamicFormFieldList.length; j++) {
                for (var i = 0; i < response.dynamicFormFieldList[j]["dynamicFields"].length; i++) {
                  if (response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 4) {
                    response.dynamicFormFieldList[j]["dynamicFields"][i].selectedItems = response.dynamicFormFieldList[j]["dynamicFields"][i].value != "" ? JSON.parse(response.dynamicFormFieldList[j]["dynamicFields"][i].value) : [];
                    response.dynamicFormFieldList[j]["dynamicFields"][i].isChecked = response.dynamicFormFieldList[j]["dynamicFields"][i].selectedItems.length === response.dynamicFormFieldList[j]["dynamicFields"][i].options.length;
                  }
                }
              }
              this.DynamicFields = response.dynamicFormFieldList;
              this.dynPopUpHeading = template.templateName;
              this.dynPopUpHeadingKey = template.templateAliasName;
              this.languageService.TranslateDefaultItem(response.dynamicFormFieldList, this.languageDataDefault);
              this.DynamicFormFieldsActions = response.displayActions;

              this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
              this.showLoader = false;
              this.setProjectOwnerdisplay = 'block';
              this.setProjectOwnerdisplaydialog = true;
              //code for sending email notification
            }, error => {
              this.showLoader = false;
              this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
            });
          }
          else {

            if (this.ProjectStatus == 473) {
              this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "UINCP", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
            }
            else if (this.ProjectStatus == 474 || this.ProjectStatus == 2737) {
              this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "PINCP", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
            }
            /* else if (this.ProjectStatus == 2737) {
                     this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "PINCP", this.CompanyID).subscribe((dataCDActionsMessages) => {
                       this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
                     });
                   }*/
          }
          // })

        }

        else if (template.actionEvent == "CreateSubProcess") {


          this.service.getDynamicFormFields(1, '0', 0, false, "101", 1, false).subscribe(response => {

            for (var j = 0; j < response.dynamicFormFieldList.length; j++) {

              for (var i = 0; i < response.dynamicFormFieldList[j]["dynamicFields"].length; i++) {
                if (response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 9 || response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 24) {
                  this.fileUploadDynamicField = response.dynamicFormFieldList[j]["dynamicFields"].filter(x => x.controlTypeID == 24)[0];
                }

              }
            }


          });

          this.moduleService.DisplayView(this.SelectedRecord).subscribe(response => {
            if (response.find(x => x.fieldName == 'ProcessTemplate').displayValue == 'Cancellation')
              this.GV.processTemplateID = 'Cancellation';
            else
              this.GV.processTemplateID = '';
          });

          this.tempSelectedRecord = this.SelectedRecord;

          this.GV.SetProjectID(this.tempSelectedRecord);

          this.setSubProcessdisplay = true;
          this.windowState = 'maximized';
          this.resizeWindow = false;
          this.showArtWorkWindow = true;

        }
        else if (template.actionEvent == "btnChat") {

          this.dynHeadingName = template.templateName;
          this.dynHeadingName = this.languageService.GetActionMessageByLanguage(template.templateAliasName, template.templateName);
          this.inputFormFields = template.inputFormFields;
          this.disableFields = template.disableFields;


          this.moduleService.getProjectByselectedProjectId(this.SelectedRecord, "chat").subscribe(data => {
            if (data) {
              this.viewRelProjects = data;
              this.dynHeadingName = this.dynHeadingName + " - " + data[data.length - 1].projectName;
              this.chatpopup = "block";
              this.ShowChatWindow = true;
            }
          })
        }
        else if (template.actionEvent == "btnChatNav") {
          localStorage.setItem("notificationSubProcessId", notification.SubProcessID);
          localStorage.setItem("notificationProjectId", notification.ProjectID);
          localStorage.setItem("notificationSubProcessName", notification.SubProcessName);
          localStorage.setItem("notificationTopic", notification.Topic);
          localStorage.setItem("notificationPriority", notification.Priority);
          this.dynHeadingName = template.templateName;
          this.dynHeadingName = this.languageService.GetActionMessageByLanguage(template.templateAliasName, template.templateName);
          this.inputFormFields = template.inputFormFields;
          this.disableFields = template.disableFields;
          this.moduleService.getProjectByselectedProjectId(this.SelectedRecord, "chat").subscribe(data => {
            if (data) {
              this.viewRelProjects = data;
              //this.dynHeadingName = this.dynHeadingName + " - " + data[data.length - 1].projectName;
              this.dynHeadingName = 'chat' + " - " + data[data.length - 1].projectName;
              this.chatpopup = "block";
              this.ShowChatWindow = true;
            }
          })
        }

        else if (template.actionEvent == "AssignUser") {
          /**/
        }

      }

      else {
        this.commonfeaturesservice.GetAllActionsMessages(4, template.workflowTasksActionID, template.actionEventID, 999, "SAP", this.CompanyID).subscribe((dataCDActionsMessages) => {
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
        });
      }
    }

    artWorkReviewClose() {

      this.showArtWorkWindow = false;

      this.CloseCustomUpload();
    }

    ShowChatWindowClose() {

      this.ShowChatWindow = false;
    }

    checkUserPrivilege(actionEventID, LevelID) {

      return this.amService.GetPrivilegeAccesType(actionEventID, LevelID);
    }
    ///action for hiding and showing templates like splitters


    onToggleActionClick(template: any) {


      //geting aciton for toogle button
      let toogleActionClasss = this.toogleActtions.filter(a => a.templateId == template.templateID && a.targetTemplateId == template.templateID)[0].actionClass;

      if (document.getElementById("toogleAction" + template.templateID).classList.contains(toogleActionClasss)) {

        document.getElementById("toogleAction" + template.templateID).classList.remove(toogleActionClasss);

      }
      else {
        document.getElementById("toogleAction" + template.templateID).classList.add(toogleActionClasss);

      }


      let className = this.toogleActtions.filter(a => a.templateId == template.templateID && a.targetTemplateId == template.templateID)[0].className;

      if (document.getElementById("template" + template.templateID).classList.contains(className)) {
        this.toogleActtions.filter(a => a.templateId == template.templateID)
          .forEach(function (part) {
            document.getElementById("template" + part.targetTemplateId).classList.remove(part.className);
          });
      }
      else {
        this.toogleActtions.filter(a => a.templateId == template.templateID)
          .forEach(function (part) {
            document.getElementById("template" + part.targetTemplateId).classList.add(part.className);
          });

      }
    }
    openDAL(dynamicField: any, dynamicForm: any) {
      if (dynamicForm.get('ProcessTemplate').value == '5')
        this.GV.processTemplateID = "Cancellation";
      else
        this.GV.processTemplateID = "";

      this.customUploadType = dynamicField.validationRule;
      this.tempSelectedRecord = 0;
      this.setSubProcessdisplay = true;
      this.windowState = 'maximized';
      this.resizeWindow = false;
      this.showArtWorkWindow = true;

    }

    removeFile(file) {


      this.fileInfo = this.fileInfo.filter(a => a.name != file.name);

      this.GV.SetFileInfo(this.fileInfo);
      this.myFiles = this.fileInfo;

    }

    CloseCustomUpload() {

      this.PassedmoduleID = 0;
      this.setSubProcessdisplay = false;

      this.FileType = this.GV.FileType;

      this.fileInfo = this.GV.GetFileInfo();

      this.SelectedRecord = this.GV.GetProjectID();
      this.getProjectStatus();
      this.showPopUp = false;

      if (this.tempSelectedRecord != 0) {



        this.asyncloadTemplates();
      }
    }

    DeleteConfrim() {
      this.DeleteConfrimopened = false;
      this.showLoader = true;
      this.moduleService.DeleteDisplayFieldRecord(this.SelectedRecord, this.GV.UserId).subscribe(data => {
        this.IsDisplayControls = true;
        this.showLoader = false;
        this.loadTemplates();
        this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 1, "CDUAT", this.CompanyID).subscribe((dataCDActionsMessages) => {
          this.notifyService.show(this.GetMessageByLanguage('PDS', " Project deleted successfully !!!!"), 1);

        });
      });

      this.SelectedRecord = 0;

    }
    closeDialog() {
      this.DeleteConfrimopened = false;
    }
    closeRelatedProjectDialog() {
      this.DeleteConfrimRelatedProject = false;
      this.deleteProjectId = 0;
      this.deleteRelatedProjectId = 0;
      this.SendEmailToFRAConfirm = false;
    }

    getEditID(templateID, ID, inputFormFields, disableFields, selectedTemplateID) {
      this.templateData.IsfromProject = true;
      this.templateData.IsfromAssigneUsers = false;
      this.templateID = templateID;
      this.RecordID = ID;
      this.inputFormFields = inputFormFields;
      this.disableFields = disableFields;
      this.selectedTemplateID = selectedTemplateID;
      this.onDisplayForm();
    }

    CloseSubDisplayForm() {
      this.templateParentID = 0;

      this.moduleService.passparentRecordID(0);


      //this.IsDisplaySubFormView = false;
    }
    closeModalDialog() {
      this.display = 'none'; //set none css after close dialog
    }
    closechatModalDialog() {
      this.GetSourceDataProjectAndUserId();
      this.chatpopup = 'none'; //set none css after close dialog
      this.ShowChatWindow = false;
    }
    closereassignModalDialog() {
      this.reassignpopup = 'none'; //set none css after close dialog
      this.ShowReAssignWindow = false;
    }

    closesetProjrectModalDialog() {
      this.setProjectOwnerdisplay = 'none';
      this.openAssignuserswindow = false;
      this.setProjectOwnerdisplaydialog = false;
      this.setAssignProjectOwnerdisplay = 'none'; //set none css after close dialog
      this.frmsubmitted = false;
      this.userData = null;
      this.userDataList = [];
      this.BindGroupsUsers = [];
      this.MainDataSource.forEach((departmentData, index) => {
        this.GroupDyForm.get('Users' + index).clearValidators();
        this.GroupDyForm.get('Users' + index).setErrors(null);
        this.GroupDyForm.get('Users' + index).updateValueAndValidity();
        this.GroupDyForm.get('Users' + index).setValue("");
        this.GroupDyForm.controls['Groups' + index].enable();
        this.GroupDyForm.controls['Users' + index].enable();
      })

      const dataitem = {
        "ID": '0',
        "SubProcessID": 0,
        "ProjectID": '0'
      }
      this.moduleService.passLoadProjectTask(dataitem);
    }
    GetAssignListData() {
      this.moduleService.BindProjectAssignGroupUsers(this.SelectedRecord).subscribe(data => {
        if (data != null)
          this.BindGroupsUsers = data;
      }, error => {
        this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
      });
    }
    onDisplayForm() {
      this.showLoader = true;
      this.uploadedFiles = [];
      this.service.getDynamicFormFields(this.templateID, this.RecordID, 0, false, this.inputFormFields, this.selectedTemplateID, this.templateData.IsfromAssigneUsers).subscribe(response => {
        this.IsDisplayControls = false;
        if (this.includeAttributeValues == true && this.actionEvent == 'Copy') {
          for (var j = 0; j < response.dynamicFormFieldList.length; j++) {
            for (var i = 0; i < response.dynamicFormFieldList[j]["dynamicFields"].length; i++) {
              if (response.dynamicFormFieldList[j]["dynamicFields"][i].fieldId == 8) {
                response.dynamicFormFieldList[j]["dynamicFields"][i].value = this.description;
              }
              if (response.dynamicFormFieldList[j]["dynamicFields"][i].fieldId == 2) {
                response.dynamicFormFieldList[j]["dynamicFields"][i].value = this.projectName;

              }
              if (response.dynamicFormFieldList[j]["dynamicFields"][i].fieldId == 1) {
                response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID = 11;
              }
              // if (response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 9 || response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 24) 
              if (response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 9) {
                response.dynamicFormFieldList[j]["dynamicFields"][i].value = '';
              }
            }
          }
        }
        if (this.includeAttributeValues == false && this.actionEvent == 'Copy') {
          for (var j = 0; j < response.dynamicFormFieldList.length; j++) {
            for (var i = 0; i < response.dynamicFormFieldList[j]["dynamicFields"].length; i++) {
              if (response.dynamicFormFieldList[j]["dynamicFields"][i].fieldId > 9 && response.dynamicFormFieldList[j]["dynamicFields"][i].fieldId != 101) {
                response.dynamicFormFieldList[j]["dynamicFields"][i].value = '';
              }
              if (response.dynamicFormFieldList[j]["dynamicFields"][i].fieldId == 8) {
                response.dynamicFormFieldList[j]["dynamicFields"][i].value = this.description;
              }
              if (response.dynamicFormFieldList[j]["dynamicFields"][i].fieldId == 1) {
                response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID = 11;
              }
              if (response.dynamicFormFieldList[j]["dynamicFields"][i].fieldId == 101) {
                response.dynamicFormFieldList[j]["dynamicFields"][i].value = 0
              }
            }
          }
        }
        this.DynamicFields = response.dynamicFormFieldList;
        if (this.DynamicFields[1] != undefined || this.DynamicFields[1] != null) {
          this.setPriority = this.DynamicFields[1].dynamicFields.find(x => x.key == "Priority").value
          this.setWorkflowID = this.DynamicFields[1].dynamicFields.find(x => x.key == "RequestSubCategory").value
          if (typeof this.DynamicFields[1].dynamicFields.find(x => x.key == "ForecastStartDate") !== 'undefined')
            this.setForCStartDate = this.DynamicFields[1].dynamicFields.find(x => x.key == "ForecastStartDate").value
          else
            this.setForCStartDate = '';
        }

        this.languageService.TranslateDefaultItem(response.dynamicFormFieldList, this.languageService.dataByLanguage, this.RecordID);
        this.DynamicFormFieldsActions = response.displayActions;
        this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
        for (var j = 0; j < response.dynamicFormFieldList.length; j++) {
          for (var i = 0; i < response.dynamicFormFieldList[j]["dynamicFields"].length; i++) {
            if (response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 4) {
              response.dynamicFormFieldList[j]["dynamicFields"][i].selectedItems = response.dynamicFormFieldList[j]["dynamicFields"][i].value != "" ? JSON.parse(response.dynamicFormFieldList[j]["dynamicFields"][i].value) : [];
              response.dynamicFormFieldList[j]["dynamicFields"][i].isChecked = response.dynamicFormFieldList[j]["dynamicFields"][i].selectedItems.length === response.dynamicFormFieldList[j]["dynamicFields"][i].options.length;

            }
            if (response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 5) {
              if (response.dynamicFormFieldList[j]["dynamicFields"][i].isParentFieldIdDatePicker) {
                this.handleParentdatepickerChange(response.dynamicFormFieldList[j]["dynamicFields"][i].value, response.dynamicFormFieldList[j]["dynamicFields"][i], true)
              }
            }

            if (response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 9 || response.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 24) {
              this.fileUploadDynamicField = response.dynamicFormFieldList[j]["dynamicFields"].filter(x => x.controlTypeID == 24)[0];
              this.myFiles = [];
              let uploadItems: any = [];
              this.uploadedFiles[response.dynamicFormFieldList[j]["dynamicFields"][i].key] = [];
              uploadItems = response.dynamicFormFieldList[j]["dynamicFields"][i].value;
              for (let a = 0; a < uploadItems.length; a++) {
                let obj: any = {};
                debugger;
                obj.name = uploadItems[a].fileName;
                obj.size = parseInt(uploadItems[a].fileSize);
                obj.extension = uploadItems[a].fileType;
                obj.filepath = uploadItems[a].filePath;
                obj.filepath = obj.filepath.split('\\').join('/');
                obj.uid = uploadItems[a].id
                this.uploadedFiles[response.dynamicFormFieldList[j]["dynamicFields"][i].key].push(uploadItems[a].fileName);
                this.myFiles.push(obj);
              }

              this.fileInfo = this.myFiles;
              if (this.actionEvent == 'Copy') {
                this.GV.SetFileInfo(this.fileInfo);
                this.GV.SetFileType(3);
                this.tempDocumentsPath = this.moduleService.getBaseUrl() + "documents/";

              }
            }
            //showing and hiding conrols

            if (response.dynamicFormFieldList[j]["dynamicFields"][i].fieldId == 2) {
              if (this.actionEvent == 'Copy') {
                response.dynamicFormFieldList[j]["dynamicFields"][i].defaultItem.key = this.projectName;
              }
            }
            // setTimeout(() => {
            if (response.dynamicFormFieldList[j]["dynamicFields"][i].dependentControlDisplay == false) {
              this.dynamicForm.controls[response.dynamicFormFieldList[j]["dynamicFields"][i].key].setErrors(null);

            }
            // }, 1000);


          }
        }
        this.showLoader = false;
      });
    }

    //getDisplaycontrolRecords() {
    //  this.moduleService.GetTemplatesByModuleID(this.moduleID).subscribe(data => {
    //    this.IsDisplayControls = true;
    //    this.templates = data;
    //  });
    //}

    getDisabledField(objField: any): boolean {
      if (this.disableFields == "" || this.disableFields == null || this.disableFields == undefined) {
        return false;
      } else {

        var disableFieldIds = this.disableFields.split(',');

        if (disableFieldIds != null && disableFieldIds.length > 0 && disableFieldIds.includes(objField.fieldId.toString())) {

          return true;
        } else {
          return false;
        }

      }

    }
    closeAudithistory(evt: boolean) {

      if (!evt) {
        this.AuditHistory = "none";
      }
    }

    acmiclose(evt: boolean) {

      if (!evt) {
        this.ACMIDetails = "none";
      }
    }

    FromAction(action: any) {

      if (action.actionEvent == "Submit") {
        this.onSubmit();
        if (!this.dynamicForm.invalid) {
          this.setProjectOwnerdisplay = 'none'
          this.setProjectOwnerdisplaydialog = false;
          this.setAssignProjectOwnerdisplay = 'none';
        }
      }
      else if (action.actionEvent == "SaveAsDraft") {
        this.templateData.ActionEvent = action.actionEvent;
        this.onSubmit();
      }
      else {
        this.cancel();

        this.setProjectOwnerdisplay = 'none';
        this.setProjectOwnerdisplaydialog = false;
        this.setAssignProjectOwnerdisplay = 'none';
        this.setPriority = undefined;
        this.setWorkflowID = 0;
        this.setForCStartDate = undefined;
      }
    };



    showOrHideBackAction(): boolean {
      let showhideaction = false;
      let showhideList = this.displaytemplates.filter(a => a.hasParent == "0");
      if (showhideList != null && showhideList != undefined && showhideList.length == 0) {
        showhideaction = true;
      }
      return showhideaction;
    }

    getControlTemplatesForTabs() {
      this.getProjectStatus();
      this.moduleService.CheckForProjectAccess(this.SelectedRecord, 0).subscribe(projectData => {
        if (projectData == true) {
          this.IsProjectAccess = projectData;
        }
      })
      var template = this.displaytemplates.filter(a => a.displayControlsID == "22");

      if (template != null && template.length > 0) {
        this.moduleService.GetControlTemplatesByTemplateID(template[0].templateID, template[0], 6).subscribe(data => {
          if (data.length > 0) {
            this.controltemplatesForTabs = data;

            this.controltemplatesForTabs.forEach(actions => {
              if (actions.actionEvent == 'Create') {
                this.CreateinputFormFields = actions.inputFormFields;

              }
            });
            this.controlActionNotForMenuTabs = this.controltemplatesForTabs.filter(ta => ta.isMenuItem == "False" && this.ShowHideControl(ta) == true && this.checkUserPrivilege(ta.actionEventID, 3) != "display-none");
            //console.log("this.controlActionNotForMenuTabs", this.controlActionNotForMenuTabs);
            this.controlActionForMenuItemsTabs = this.controltemplatesForTabs.filter(ta => ta.isMenuItem == "True" && this.ShowHideControl(ta) == true && this.checkUserPrivilege(ta.actionEventID, 3) != "display-none");
            this.controltemplatesforMenuTab = [
              {
                text: "More",
                templateName: "More",
                items: this.controlActionForMenuItemsTabs
              }
            ];

            if (this.GV.subProcessIDList.length > 0 || this.GV.isHideBypassTasks == 'HideProjectLevelBypass') {
              const foundRecord = this.controlActionForMenuItemsTabs.findIndex(record => record.actionEvent === 'btnHideBypassTasks');
              if (foundRecord != undefined && foundRecord !== -1)
                this.controlActionForMenuItemsTabs.splice(foundRecord, 1);
            }
            else {
              const foundRecord = this.controlActionForMenuItemsTabs.findIndex(record => record.actionEvent === 'btnShowBypassTasks');
              if (foundRecord != undefined && foundRecord !== -1)
                this.controlActionForMenuItemsTabs.splice(foundRecord, 1);
            }

            this.GetSourceDataProjectAndUserId();
          }
        });
      }

      this.showOrHideBackAction();

    }


    onBackClick() {
      this.DynamicFields = null;
      var tempTemplates = this.displaytemplates;
      this.displaytemplates = null;
      this.IsDisplayControls = false;


      if (this.router.snapshot.paramMap.get('ID') != null && JSON.parse(localStorage.getItem("reportsProjectID")) != null) {
        this._router.navigate(['/Projects'], { replaceUrl: true });
      }
      else {
        setTimeout(() => {

          let parentTemplate = tempTemplates[0].hasParent;
          this.SelectedRecord = tempTemplates[0].recordID;
          this.getProjectStatus();

          this.displaytemplates = this.backTemplates.filter(a => a.templateID == parentTemplate)[0].displayTemplates;

          this.getControlTemplatesForTabs();

          this.IsDisplayControls = true;
        }, 300);
      }
    }
    onSubmit() {
      if (this.dynamicForm.controls['ForecastStartDate'] != undefined) {
        var dateValid = this.dynamicForm.controls['ForecastStartDate'].value;
        if (dateValid instanceof Date) {
          this.dynamicForm.controls['ForecastStartDate'].setErrors(null);
        }
        else {
          if (this.dynamicForm.controls['ForecastStartDate'].value == null ||
            this.dynamicForm.controls['ForecastStartDate'].value === "") {
            this.dynamicForm.controls["ForecastStartDate"].setErrors({ required: true });
          }
          else {
            this.dynamicForm.controls['ForecastStartDate'].setErrors(null);
          }
        }
      }

      if (this.dynamicForm.controls['FirstReviewByDate'] != undefined) {
        var dateValid = this.dynamicForm.controls['FirstReviewByDate'].value;
        if (dateValid instanceof Date) {
          this.dynamicForm.controls['FirstReviewByDate'].setErrors(null);
        }
        else {
          if (this.dynamicForm.controls['FirstReviewByDate'].value == null ||
            this.dynamicForm.controls['FirstReviewByDate'].value === "") {
            this.dynamicForm.controls["FirstReviewByDate"].setErrors({ required: true });
          }
          else {
            this.dynamicForm.controls['FirstReviewByDate'].setErrors(null);
          }
        }
      }
      this.IpAddress = this.GV.UserIpAddress;
      this.PassedmoduleID = 0;
      var items = document.getElementsByClassName("k-text-error");
      for (var i = 0; i < items.length; i++) {
        if (document.getElementsByClassName("k-text-error")[i]["innerText"] == this.languageService.GetActionMessageByLanguage('STC_UFWA', "Please upload a file without annotiations")) {
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_RADF', "Please remove  annotiations document(s) file."), 4);
          this.dynamicForm.invalid == true;
          this.invalidSubmitAttempt = true;
          return;
        }
      }
      //custom fileuploader
      this.fileInfo = this.GV.GetFileInfo();
      var components = [];
      if (this.FileType == 1) {//upload
        this.fileInfo.forEach(function (part, index) {
          components.push(part.name);
        });
        this.dynamicForm.value["ProjectComponets"] = components;
      } else if (this.FileType == 2) {// 2 dal
        this.fileInfo.forEach(function (part, index) {
          components.push(part.uid);
        });
        this.dynamicForm.value["ProjectComponets"] = components;
      }
      else {//File Upload Code
        let uploadFields = Object.keys(this.uploadedFiles);
        if (uploadFields.length > 0) {
          for (var i = 0; i < uploadFields.length; i++) {
            this.dynamicForm.value[uploadFields[i]] = this.uploadedFiles[uploadFields[i]];
          }
        }
      }
      if (this.dynamicForm.invalid) {
        this.invalidSubmitAttempt = true;
        return false;
      }
      if (this.actionEvent == 'Copy' && this.count === 0) {


        if (this.dynamicForm.value.IncludeAttributeValues == '271') {
          this.description = this.dynamicForm.value.Description;
          this.projectName = this.dynamicForm.value.Name;
          this.getEditID(this.templateID, this.SelectedRecord, this.CreateinputFormFields, "", this.templateID);
        }
        else {
          this.includeAttributeValues = false;
          this.processTemplate = this.dynamicForm.value.ProcessTemplate;
          this.description = this.dynamicForm.value.Description;
          this.projectName = this.dynamicForm.value.Name;
          this.getEditID(this.templateID, this.SelectedRecord, this.CreateinputFormFields, "", this.templateID);
        }
        this.count++;
      }
      else {
        this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
        this.templateData.ID = parseInt(this.RecordID);
        this.templateData.TemplateID = parseInt(this.templateID);
        this.templateData.InputFormFields = this.inputFormFields;
        this.templateData.UserID = this.GV.UserId
        this.showLoader = true;

        if (this.actionEvent == "Cancel" || this.actionEvent == "SetProjectOwner" || this.actionEvent == "SuspendProject" || this.actionEvent == "ResumeProject" || this.actionEvent == "btnInvite") {


          this.templateData.Ip = this.IpAddress;
          this.DFS.addorUpdateDynamicFormFieldsdata(this.templateData).subscribe(responce => {
            if (this.actionEvent == "Cancel") {
              this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 0, "PCNS", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
            } else if (this.actionEvent == "SetProjectOwner") {
              this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 0, "POWSS", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
            } else if (this.actionEvent == "SuspendProject") {

              this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 0, "PSS", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
            } else if (this.actionEvent == "ResumeProject") {

              this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 0, "PRS", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
            }
            else if (this.actionEvent == "btnInvite") {
              this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 0, "UITP", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
            }
            //sending emails
            if (this.actionEvent == "ResumeProject")
              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoResume, 1, this.RecordID, 0, 1, 0, 1, 0, encodeURIComponent(this.offset)).subscribe();
            else if (this.actionEvent == "SetProjectOwner")
              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoSetProjectOwner, 1, this.RecordID, 0, 1, 0, 1, 0, encodeURIComponent(this.offset)).subscribe();
            else if (this.actionEvent == "SuspendProject")
              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoSuspend, 1, this.RecordID, 0, 1, 0, 1, 0, encodeURIComponent(this.offset)).subscribe();
            else if (this.actionEvent == "Cancel")
              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoCancel, 1, this.RecordID, 0, 1, 0, 1, 0, encodeURIComponent(this.offset)).subscribe();
            else if (this.actionEvent == "btnInvite")
              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationProjectInvite, 1, this.RecordID, 0, 1, 0, 1, 0, encodeURIComponent(this.offset)).subscribe();
            this.dynamicForm.reset();
            this.showLoader = false;
            this.asyncloadTemplates();
          })
        } else {
          this.templateData.Ip = this.IpAddress;
          this.templateData.UploadFileType = this.GV.GetFileType();
          this.templateData.moduleID = +this.moduleID;
          if (this.templateData.ActionEvent == "Copy") {
            let dynamicFormFieldData = JSON.parse(this.templateData.DynFormFieldData);
            dynamicFormFieldData.ProjectComponets.length = 0;
            this.uids.length = 0;
            this.uidsText = "";
            for (let l = 0; l < this.fileInfo.length; l++) {
              dynamicFormFieldData.ProjectComponets.push(this.fileInfo[l].name);
              this.uids.push(this.fileInfo[l].uid.toString() + '÷' + this.fileInfo[l].name);
            }
            this.uidsText = this.uids.toString();
            this.templateData.DynFormFieldData = JSON.stringify(dynamicFormFieldData);
            this.templateData.selectedTakIds = this.uidsText;
          }
          this.DFS.saveDynamicFormFieldsdata(this.templateData).subscribe(responce => {
            if (this.templateData.IsfromAssigneUsers) {
              this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 1, "UASS", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
            }
            else if (this.RecordID == 0) {
              this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 1, "PCS", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationForResourceGroup, 1, parseInt(responce), 0, 1, 0, 1, 0, encodeURIComponent(this.offset)).subscribe();

              this.loadTemplates();
            }
            else if (this.actionEvent == 'Copy') {
              this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "PCOS", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
              this.loadTemplates();
            }
            else {
              this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "PUS", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
              this.asyncloadTemplates();
            }
            this.showLoader = false;
            this.cancel();
          })
        }
      }
    }
    cancel() {
      this.IsDisplayControls = true;
      this.uploadedFiles = [];
      this.GV.processTemplateID = "";
    }

    asyncloadTemplates() {

      this.IsDisplayControls = false;
      var displaytemplates = this.displaytemplates.filter(a => a.moduleID == this.moduleID);
      this.displaytemplates = null;

      setTimeout(() => {
        this.getProjectStatus();

        this.displaytemplates = displaytemplates;
        this.getControlTemplatesForTabs();

        this.IsDisplayControls = true;
      }, 200);
    }

    close() {
      /*this.showPopUp = false;*/
      this.IsDisplayControls = true;

      this.display = 'block';

    }

    closerel() {
      this.showPopUp = false;
      this.submitted = false;
    }
    closeProd()
    {
      this.showProductPopUp = false;
      this.showPopUp = false;
    }
  public toggleAllText(data: any) {

    return data.isChecked ? "Deselect All" : "Select All";
  }
  public isIndet(data: any) {
    return (
      data.selectedItems.length !== 0 && data.selectedItems.length !== data.options.length
    );
  }
  public onClick(data: any) {
    data.selectedItems = [];
    data.isChecked = !data.isChecked;
    if (data.isChecked) {
      if (data.options.length > 0) {
        data.options.forEach(function (item) {
          data.selectedItems.push(item.value);

        });
      }
      else {
        data.selectedItems = [];
      }
    }
    else {
      data.selectedItems = [];
    }
    this.handleParentTemplateChange(data.selectedItems, data);
  }
  public isItemSelected(item: any, dynamicfiled: any) {
    return dynamicfiled.selectedItems.some((x) => x === item.value);
  }
  handleParentTemplateChange(selectedTempID: any, dynamicField: any) {

    if (dynamicField.key == "ProcessTemplate" && selectedTempID == "5") {
      this.GV.processTemplateID = "Cancellation";
      this.dynamicForm.controls.ProjectComponets.setValue('');
      // this.removeFile(this.dynamicForm.controls.ProjectComponets.value);
      // this.uploadRemoveUrl = getBaseUrl() + 'api/dynamic/DynACUR?loginUserId=' + this.GV.UserId;
    }
    dynamicField.isChecked = dynamicField.selectedItems.length === dynamicField.options.length;
    if (dynamicField.isDependentFieldId == true) {
      this.showLoader = true;
      selectedTempID = selectedTempID != null ? selectedTempID : "0";
      this.service.getDynamicFormFields(this.templateID, this.RecordID, selectedTempID, false, this.inputFormFields, "", false).subscribe(response => {
        this.DynamicFields = response.dynamicFormFieldList;
        this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
        this.showLoader = false;
      });
    }

    //this.showLoader = true;
    this.cascadingFormFields = [];
    for (var key in this.dynamicForm.controls) {
      if (this.dynamicForm.controls.hasOwnProperty(key)) {
        if (this.dynamicForm.controls[key] != undefined) {
          var formField = new cascadingField();
          formField.dynamicField = key;
          formField.selectedValues = (this.dynamicForm.controls[key].value == null || this.dynamicForm.controls[key].value == undefined) ? "" : this.dynamicForm.controls[key].value.toString();
          if (formField.selectedValues != null && formField.selectedValues != "") {
            this.cascadingFormFields.push(formField);
          }


        }
      }
    }
    var selectedAllValues = JSON.stringify(this.cascadingFormFields);
    var selectedValues = (selectedTempID == null || selectedTempID == undefined) ? "" : selectedTempID;
    this.moduleService.GetCascadingDataForFormFields(this.templateID, dynamicField.fieldId, selectedValues, selectedAllValues).subscribe(data => {
      if (data != null) {
        Array.prototype.forEach.call(data, dataField => {
          var groupID = dataField.groupID;
          var gropuIDIndex = 0;
          var groupIndexCtr = 0;
          Array.prototype.forEach.call(this.DynamicFields, DynamicFieldGroup => {
            if (DynamicFieldGroup.groupId == groupID) {
              gropuIDIndex = groupIndexCtr;
            }
            groupIndexCtr = groupIndexCtr + 1;
          });
          var index = this.DynamicFields[gropuIDIndex].dynamicFields.findIndex(p => p.fieldId == dataField.fieldID);
          if (index != -1) {
            this.DynamicFields[gropuIDIndex].dynamicFields[index].options = dataField.options;

            if (this.DynamicFields[gropuIDIndex].dynamicFields[index].controlTypeID == 4)
              this.DynamicFields[gropuIDIndex].dynamicFields[index].selectedItems = [];
            this.dynamicForm.controls[this.DynamicFields[gropuIDIndex].dynamicFields[index].key].setValue(null);

          }
        });
      }

      this.showLoader = false;

    })

    //hide /show/disble/enable controls
    var selectedValues = (selectedTempID == null || selectedTempID == undefined) ? "" : selectedTempID;
    this.moduleService.GetDependentFields(this.templateID, dynamicField.fieldId, selectedValues).subscribe(data => {
      if (data != null) {

        Array.prototype.forEach.call(data, dataField => {


          var groupID = parseInt(dataField.targetFieldGroupID);
          var gropuIDIndex = 0;
          var groupIndexCtr = 0;
          Array.prototype.forEach.call(this.DynamicFields, DynamicFieldGroup => {


            if (DynamicFieldGroup.groupId == groupID) {
              gropuIDIndex = groupIndexCtr;

            }
            groupIndexCtr = groupIndexCtr + 1;
          });
          var index = this.DynamicFields[gropuIDIndex].dynamicFields.findIndex(p => p.fieldId == dataField.targetFieldId);
          if (index != -1) {

            if (dataField.conditionType == 1 && dataField.sourceFiledValue == selectedValues) {
              this.DynamicFields[gropuIDIndex].dynamicFields[index].dependentControlDisplay = true;
              this.dynamicForm.controls[this.DynamicFields[gropuIDIndex].dynamicFields[index].key].setValue(null);

              this.dynamicForm.controls[this.DynamicFields[gropuIDIndex].dynamicFields[index].key].setErrors({ required: true });


            } else {
              this.DynamicFields[gropuIDIndex].dynamicFields[index].dependentControlDisplay = false;
              this.dynamicForm.controls[this.DynamicFields[gropuIDIndex].dynamicFields[index].key].setValue(null);
              this.dynamicForm.controls[this.DynamicFields[gropuIDIndex].dynamicFields[index].key].setErrors(null);

            }
          }


        });
      }

      this.showLoader = false;

    })
    if (dynamicField.key == "RequestSubCategory") {
      this.setWorkflowID = selectedValues;
      this.setPriority = undefined;
      this.setForCStartDate = undefined;
    }

    if (dynamicField.key == "Priority") {
      if (selectedValues == "")
        this.setPriority = undefined;
      else
        this.setPriority = selectedValues;
      this.BindCompletedDate("", false);
    }

  }
  BindCompletedDate(dynamicField: any, isEditOrNot: boolean) {
    var value: any;
    if (this.setPriority != undefined && this.setWorkflowID != 0 && this.setForCStartDate != undefined) {
      this.moduleService.CalculateCompleteDate(this.setPriority, this.setWorkflowID, this.setForCStartDate, false).subscribe(data => {
        if (data != null) {
          this.setCompletetDate = data;
          this.dynamicForm.controls["CompleteProjectByDate"].setValue(new Date(data));
          this.dynamicForm.controls["CompleteProjectByDate"].setErrors(null);

          if (this.setFirstReviewtDate > this.setCompletetDate) {
            this.dynamicForm.controls["FirstReviewByDate"].setValue(null);
            this.dynamicForm.controls["FirstReviewByDate"].setErrors({ required: true });

            Array.prototype.forEach.call(this.DynamicFields, DynamicFieldGroup => {
              Array.prototype.forEach.call(DynamicFieldGroup.dynamicFields, DynamicField => {
                // if (DynamicField.controlTypeID == dynamicField.controlTypeID && DynamicField.parentFieldIdDatePicker == dynamicField.fieldId) {
                if (DynamicField.controlTypeID == 5 && DynamicField.parentFieldIdDatePicker == 9 && DynamicField.key == "FirstReviewByDate") {
                  if (typeof (this.setForCStartDate) === 'string') {
                    value = new Date(this.setForCStartDate);
                  }
                  let day = value.getDate();
                  let month = value.getMonth();
                  let year = value.getFullYear();
                  if (this.setForCStartDate != undefined && this.setCompletetDate != undefined) {
                    DynamicField.minimumDate = new Date(this.setForCStartDate);
                    DynamicField.miximumDate = new Date(this.setCompletetDate);
                  }
                  else {
                    DynamicField.minimumDate = new Date(year, month, day);
                    DynamicField.miximumDate = new Date(year + 10, month, day);
                  }
                  if (isEditOrNot) return;
                  if (this.dynamicForm.controls[DynamicField.key].value < DynamicField.minimumDate || this.dynamicForm.controls[DynamicField.key].value > DynamicField.miximumDate) {
                    this.dynamicForm.controls[DynamicField.key].setValue(null);
                  }
                }
              });
            });
          }

          Array.prototype.forEach.call(this.DynamicFields, DynamicFieldGroup => {
            Array.prototype.forEach.call(DynamicFieldGroup.dynamicFields, DynamicField => {
              if (DynamicField.controlTypeID == dynamicField.controlTypeID && DynamicField.parentFieldIdDatePicker == dynamicField.fieldId) {
                if (typeof (this.setForCStartDate) === 'string') {
                  value = new Date(this.setForCStartDate);
                }
                let day = value.getDate();
                let month = value.getMonth();
                let year = value.getFullYear();
                if (this.setForCStartDate != undefined && this.setCompletetDate != undefined) {
                  DynamicField.minimumDate = new Date(this.setForCStartDate);
                  DynamicField.miximumDate = new Date(this.setCompletetDate);
                }
                else {
                  DynamicField.minimumDate = new Date(year, month, day);
                  DynamicField.miximumDate = new Date(year + 10, month, day);
                }
                if (isEditOrNot) return;
                if (this.dynamicForm.controls[DynamicField.key].value < DynamicField.minimumDate || this.dynamicForm.controls[DynamicField.key].value > DynamicField.miximumDate) {
                  this.dynamicForm.controls[DynamicField.key].setValue(null);
                }
              }
            });
          });
        }
      });
    }
    else {
      // this.setForCStartDate = undefined;
      this.dynamicForm.controls["CompleteProjectByDate"].setValue(null);
      this.dynamicForm.controls["CompleteProjectByDate"].setErrors({ required: true });
      // this.dynamicForm.controls["ForecastStartDate"].setValue(null);
      // this.dynamicForm.controls["ForecastStartDate"].setErrors({ required: true });
    }
  }

  IsMandatoryStatuschanged(filedId: number) {
    this.FieldStatusobj.FieldId = filedId;
    var ans = confirm(this.languageService.GetActionMessageByLanguage('STC_CMSF', "Do you want to change the mandatory status for this field?"));
    if (ans) {
      this.moduleService.FieldMandatoryStatuschanged(this.FieldStatusobj).subscribe(data => {
        if (data) {
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_MSCS', "Mandatory status has been changed successfully"), 1);
          this.invalidSubmitAttempt = false;
          this.onDisplayForm();
        }
      })
    }
  }

  handleParentdatepickerChange(value: any, dynamicField: any, isEditOrNot: boolean) {

    if (value) {
      this.showLoader = true;
      if (dynamicField.key == "ForecastStartDate") {
        value = new Date(value);
        let day = value.getDate();
        let Cmonth = value.getMonth() + 1;
        let year = value.getFullYear();
        if (Cmonth < 10) {
          Cmonth = '0' + Cmonth;
        }
        if (day < 10) {
          day = '0' + day;
        }
        this.setForCStartDate = year + '-' + Cmonth + '-' + day
        this.BindCompletedDate(dynamicField, isEditOrNot);
      }
      Array.prototype.forEach.call(this.DynamicFields, DynamicFieldGroup => {
        Array.prototype.forEach.call(DynamicFieldGroup.dynamicFields, DynamicField => {
          // if (DynamicField.controlTypeID == dynamicField.controlTypeID && DynamicField.parentFieldIdDatePicker == dynamicField.fieldId) {
          //   if (typeof (value) === 'string') {
          //     value = new Date(value);
          //   }
          //   let day = value.getDate();
          //   let month = value.getMonth();
          //   let year = value.getFullYear();
          //     DynamicField.minimumDate = new Date(year, month, day);
          //     DynamicField.miximumDate = new Date(year + 10, month, day);
          //   if (isEditOrNot) return;
          //   if (this.dynamicForm.controls[DynamicField.key].value < DynamicField.minimumDate || this.dynamicForm.controls[DynamicField.key].value > DynamicField.miximumDate) {
          //     this.dynamicForm.controls[DynamicField.key].setValue(null);
          //   }
          // }
          if (DynamicField.controlTypeID == dynamicField.controlTypeID && DynamicField.parentFieldIdDatePicker == null) {
            let date = new Date();
            let day = date.getDate();
            let month = date.getMonth();
            let year = date.getFullYear();
            DynamicField.minimumDate = new Date(year - 10, month, day);
            DynamicField.miximumDate = new Date(year + 10, month, day);
            if (isEditOrNot) return;
            if (this.dynamicForm.controls[DynamicField.key].value < DynamicField.minimumDate || this.dynamicForm.controls[DynamicField.key].value > DynamicField.miximumDate) {
              this.dynamicForm.controls[DynamicField.key].setValue(null);
            }
          }
        });
      });
      this.showLoader = false;

    }
    //this.setForCStartDate = undefined;

    if (dynamicField.key == "FirstReviewByDate") {
      value = new Date(value);
      let day = value.getDate();
      let Cmonth = value.getMonth() + 1;
      let year = value.getFullYear();
      if (Cmonth < 10) {
        Cmonth = '0' + Cmonth;
      }
      if (day < 10) {
        day = '0' + day;
      }
      this.setFirstReviewtDate = year + '-' + Cmonth + '-' + day
      //this.BindCompletedDate(dynamicField, isEditOrNot);
    }
    this.DynamicFields[0].dynamicFields.find(x => x.key == "ForecastStartDate").minimumDate = new Date();
    // if (this.dynamicForm.controls['ForecastStartDate'].value != undefined 
    // || this.dynamicForm.controls['ForecastStartDate'].value != null || this.dynamicForm.controls['ForecastStartDate'].value != "")
    //   this.dynamicForm.controls['ForecastStartDate'].setErrors(null);
  }
  GetSourceDataProjectAndUserId() {
    this.isChatNotificatioData = true;
    if (this.controlActionNotForMenuTabs != undefined) {
      if (this.controlActionNotForMenuTabs.length > 0) {
        let a = this.controlActionNotForMenuTabs.filter(a => a.actionEvent == 'btnChatNav');
        if (a.length > 0) {
          let userId = localStorage.getItem("userId");
          this.moduleService.GetSourceDataProjectAndUserId(this.SelectedRecord, Number(userId)).subscribe(data => {
            this.chatNotificatioData = data.Table;
            if (this.chatNotificatioData.length > 0)
              this.isChatNotificatioData = false;
          });
        }
      }
    }
  }
  filevalidation(event: boolean) {
    this.dynamicForm.controls["DisplayName"].errors.required = false;

  }

  SaveRelProjects() {
    if (this.dialogForm.invalid) {
      this.submitted = true;
      this.showLoader = false;
      this.showPopUp = true;
      return;
    }
    this.showLoader = true;
    this.showPopUp = false;

    this.IpAddress = this.GV.UserIpAddress;

    if (this.dialogForm.invalid) {
      this.submitted = true;
      this.showLoader = false;
      this.showPopUp = true;
      return;
    }
    this.projectid = this.projectid;
    var userId = localStorage.getItem("userId");

    var obj = this.dialogForm.controls['ddlValue'].value;

    if (obj != null && obj != undefined) {
      this.moduleService.saveRelatedProjects(obj, this.projectid, userId, this.IpAddress).subscribe(data => {
        if (data) {
          this.showLoader = false;

        }
      })
      this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "RPAS", this.CompanyID).subscribe((dataCDActionsMessages) => {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
      });
    }


    this.moduleService.DisplayView(this.SelectedRecord).subscribe(data => {
      this.viewRecords = data;
      this.display = 'block';
      this.showLoader = false;
      this.documentsPath = this.moduleService.getBaseUrl();
      this.getRelatedProjectByProjectId(this.projectid);
      this.showLoader = false;

    })
  }



  onListFieldAction(relprojectId: any) {
    this.GV.SetIsRedirection(true);
    this.showPopUp = false;
    let selectedItem = new templateSelectedRecord();
    selectedItem.templateId = "95";
    selectedItem.selectedRecordId = relprojectId.relatedProjectId;
    selectedItem.highlightRecordID = relprojectId.relatedProjectId;
    selectedItem.moduleID = "1";
    selectedItem.dependentRecordID = "0";


    this.display = 'none';
    this.moduleService.UpdateModifiedDateForDynamicForms(relprojectId.relatedProjectId).subscribe(data => {
      //this.asyncloadTemplates();
      this.moduleService.DisplayView(relprojectId.relatedProjectId).subscribe(data => {
        this.viewRecords = data;
        this.display = 'block';
        this.showLoader = false;
        this.documentsPath = this.moduleService.getBaseUrl();
        this.getRelatedProjectByProjectId(relprojectId.relatedProjectId);


        setTimeout(() => {
          this.moduleService.passDependencyTemplate(selectedItem);

        }, 200);
      });
    });
    this.breadcrumbs.filter(a => a.templateId == parseInt(selectedItem.templateId) && a.displayName != '')[0].displayName = relprojectId.projectName;

    this.moduleService.passbreadCrumbs(this.breadcrumbs);
  }

  public getAllProjets(projectId: number) {
    this.moduleService.getAllProjetcs(projectId).subscribe(data => {
      if (data) {
        this.selectedItems = [];
        this.listItems = data;
        this.projectLists = data;
        for (let i of this.viewRelProjects) {
          let item = this.listItems.filter(x => x["projectId"] == i["relatedProjectId"]);
          if (item.length > 0) {
            this.selectedItems.push(item[0]);
          }
        }
      }
    })
  }

  public getRelatedProjectByProjectId(selectedId: number) {
    this.projectid = selectedId;
    this.moduleService.getRelatedProjectByProjectId(this.projectid).subscribe(data => {
      if (data) {


        this.viewRelProjects = data;

      }
    })
  }

  closerelDialog() {
    /**/
  }

  DeleteRelProj() {
    /**/

  }
  deleteRelatedProjectbyProjectIdConfirm(projId: number, relProjId: number) {
    this.deleteProjectId = projId;
    this.deleteRelatedProjectId = relProjId;
    this.DeleteConfrimRelatedProject = true;
  }
  public deleteRelatedProjectbyProjectId() {
    this.showLoader = true;
    this.DeleteConfrimRelatedProject = false;

    this.IpAddress = this.GV.UserIpAddress;

    this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
      if (data != 473 && data != 474) {
        let obj: any = {}
        obj.projectid = this.deleteProjectId;
        obj.relatedProjectId = this.deleteRelatedProjectId;

        obj.userId = localStorage.getItem("userId");
        obj.ip = this.IpAddress;
        this.moduleService.deleteRelatedProjectByProjectId(obj).subscribe(data => {
          this.commonfeaturesservice.GetAllActionsMessages(1, 0, this.ActionEventID, 1, "DRPS", this.CompanyID).subscribe((dataCDActionsMessages) => {
            this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
          });
        })

        this.moduleService.DisplayView(this.SelectedRecord).subscribe(data => {
          this.viewRecords = data;
          this.display = 'block';
          this.showLoader = false;
          this.documentsPath = this.moduleService.getBaseUrl();
          this.getRelatedProjectByProjectId(this.projectid);
        })
      }
      else {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_CPCAEP', "Project was completed or Cancelled. you cannot add/edit related projects."), 4);
      }
      this.showLoader = false;

    })
  }

  public OpenWindow() {
    this.submitted = false;
    this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
      if (data != 473 && data != 474 && data != 2737) { //Canclled, Deleted, Completed
        this.showPopUp = true;
        this.getAllProjets(this.projectid);
      }
      else {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_CPCDRP', 'Project was completed or Cancelled. you cannot delete related projects.'), 4);
      }
    })
  }

  public CancelRel() {
    this.submitted = false;
    this.showPopUp = false;
    this.submitted = false;
  }

  public startProgress() {
    this.running = setInterval(() => {
      if (this.value <= 100) {
        this.value++;
      } else {
        this.stopProgress();
      }
    }, 50);
  }

  public stopProgress() {
    if (this.running) {
      clearInterval(this.running);
      this.running = null;
    }
  }

  public resetProgress() {
    this.value = 0;
    this.stopProgress();
  }

  checkDisplayTemplateCount(template): boolean {


    this.IsdisplaytemplatesControlID = false;

    this.displaytemplatesControlIDs = template.displayControlsID.split(",");

    if (this.displaytemplatesControlIDs != null && this.displaytemplatesControlIDs.length > 1) {
      this.IsdisplaytemplatesControlID = true;


    } else {
      this.IsdisplaytemplatesControlID = false;

    }

    return this.IsdisplaytemplatesControlID;
  }


  onGroupTemplateClick(controlID) {

    this.displayFiltertemplatesControlIDs = controlID.split(",");
  }


  bindTaskOwnerGroupUsers(value: any, id: any, viewOrUpdate: string) {
    this.frmsubmitted = false;

    // Getting Users based on Groups
    if (!value) {
      const currentItem = this.MainDataSource.find(z => z.departmentID === id);

      if (currentItem) {
        var currentItemIndex = this.MainDataSource.findIndex(y => y.departmentID === currentItem.departmentID);

        if (currentItemIndex !== -1) {
          this.GroupDyForm.get('Users' + currentItemIndex).clearValidators();
          this.GroupDyForm.get('Users' + currentItemIndex).setErrors(null);
          this.GroupDyForm.get('Users' + currentItemIndex).updateValueAndValidity();

          this.GroupDyForm.get('Users' + currentItemIndex).setValue("");
          this.MainDataSource[currentItemIndex].isSelectedUser = "";
          this.MainDataSource[currentItemIndex].groupsUsersList = null;
        }
      }
      const itemIndex = this.userDataList.findIndex(j => j.DepartmentId === id);
      this.userDataList.splice(itemIndex, 1);
      return;
    }

    var departmnentTask = this.MainDataSource.find(a => a.departmentID === id);
    var tasklist = departmnentTask.taskList;
    const taskid = [];
    const processDataIDs = [];
    for (let index = 0; index < tasklist.length; index++) {
      taskid.push(tasklist[index].taskId);
      processDataIDs.push(tasklist[index].processDataID);
    }

    if (viewOrUpdate) {
      const depIndex = this.userDataList.findIndex(j => j.DepartmentId === id);
      if (depIndex !== -1) {
        this.userDataList.splice(depIndex, 1);
        this.userData = new AssignUsersModal();
        this.userData.DepartmentId = id;
        this.userData.GroupId = value;
        this.userData.lstProcessDataIDs = taskid;
        this.userDataList.push(this.userData);
      }
      else {
        this.userData = new AssignUsersModal();
        this.userData.DepartmentId = id;
        this.userData.GroupId = value;
        this.userData.lstProcessDataIDs = taskid;
        this.userDataList.push(this.userData);
      }
    }
    if (viewOrUpdate === "Create") {
      const depIndex = this.userDataList.findIndex(j => j.DepartmentId === id);
      if (depIndex !== -1) {
        this.userDataList.splice(depIndex, 1);
        this.userData = new AssignUsersModal();
        this.userData.DepartmentId = id;
        this.userData.GroupId = value;
        this.userData.lstProcessDataIDs = taskid;
        this.userDataList.push(this.userData);
        this.userDataListCheck.push(this.userData);
      }
      else {
        this.userData = new AssignUsersModal();
        this.userData.DepartmentId = id;
        this.userData.GroupId = value;
        this.userData.lstProcessDataIDs = taskid;
        this.userDataList.push(this.userData);
        this.userDataListCheck.push(this.userData);
      }
    }
    this.selectedTaskItems = taskid.toString();
    this.selectedProcessIDItems = processDataIDs.toString();
    if (value != null && value != undefined && value != "") {
      this.showLoader = true;
      this.moduleService.GetTaskOwnerGroupUsers(2, value, this.GV.UserId, this.selectedProcessIDItems).subscribe(data => {
        var depId = this.MainDataSource.find(a => a.departmentID === id);
        depId.groupsUsersList = [...data];

        this.showLoader = false;
      },
        error => {
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
        })
    }
  }
  bindTaskOwnerUsers(value: any, id: any, viewCreate: any) {

    if (!value) {
      this.userGroupDataListCheck.push(1);
      // return;
    }
    var users = this.userDataList.find(a => a.DepartmentId === id);
    if (users) {
      users.UserId = value;
    }
    if (viewCreate == 'Create') {
      this.userGroupDataListCheck.push(1);
    }
  }
  getProjectStatus() {

    if (this.SelectedRecord != null && this.SelectedRecord != 0) {
      this.moduleService.GetDynamicProjectStatus(this.SelectedRecord, 1).subscribe(data => {
        this.ProjectStatus = data;
      })
    }
  }

  ShowHideControl(template: any): boolean {

    if (template.projectStatusDependency == null || template.projectStatusDependency == "") {
      return true;
    } else {
      let checkStatus = template.projectStatusDependency.split(",").indexOf(this.ProjectStatus.toString());
      if (checkStatus >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  showHideMenuItem(action: any): boolean {
    if (this.ProjectStatus == 2737 || this.ProjectStatus == 474) //|| this.ProjectStatus == 473
      return false;
    else
      return true;
  }


  SaveTaskOwner() {

    this.IpAddress = this.GV.UserIpAddress;

    if (this.GroupDyForm.invalid) {
      this.frmsubmitted = true;
      return;
    }

    if (this.userDataListCheck.length == 0 && this.userGroupDataListCheck.length == 0) {
      this.notifyService.show(this.GetMessageByLanguage('STO_PSAO', "Please select at least one"), 4);
      return;
    }
    else {
      this.taskOwnermodal.assignUsersModals = this.userDataList;
      this.taskOwnermodal.projectId = parseInt(this.RecordID);
      this.taskOwnermodal.moduleType = "Assign Users";
      this.taskOwnermodal.CurrentLogin_UserID = +this.GV.UserId;
      this.taskOwnermodal.Ip = this.IpAddress
      this.showLoader = true;
      this.moduleService.SaveProjectTaskOwnerGroupUsers(this.taskOwnermodal).subscribe(data => {
        if (data == "success") {
          this.userDataListCheck = [];
          this.userGroupDataListCheck = [];
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_TOUS', "Task Owner has been Updated successfully"));
        }
      },
        error => {
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_TOUF', "Task Owner updated failed !!. ") + error.statusText, 4);
        })
    }


  }

  // Multi Level Bypass/Unbypass
  bypassUnbypassOpen() {
    this.invalidSubmitAttempt = false;
    this.showgriderror = false;
    this.showLoader = true;
    this.BypassunpassAduit = true;
    this.moduleService.GetTaskByPassFields(this.RecordID, 0).subscribe(data => {
      this.DynamicFields = data.dynamicFormFieldList;
      this.languageService.TranslateDefaultItem(data.dynamicFormFieldList, this.languageDataDefault);
      this.byPassForm = this.qcs.toFormGroup(this.DynamicFields);
      this.BypassItems = [];
      this.byPassItemsSelected = [];
      this.showBypassUnbypassPopUp = true;
      this.data = this.source;
      this.byPassForm.addControl('processId', new FormControl(this.source[0], Validators.required));
      this.byPassForm.addControl('bypassItem', new FormControl('', Validators.required));
      this.showLoader = false;
    });
  }

  UpdatemultiTaskbypass() {
    this.IpAddress = this.GV.UserIpAddress;

    let selectedItems: any = this.byPassItemsSelected.length > 0 ? String(this.byPassItemsSelected) : "";
    let processId: any = this.byPassForm.controls.processId.value.value !== undefined ? this.byPassForm.controls.processId.value.value : 0;
    let bypassItems: any = this.byPassForm.controls.bypassItem.value !== undefined ? this.byPassForm.controls.bypassItem.value : 0;

    this.templateData.DynFormFieldData = JSON.stringify(this.byPassForm.value);
    this.templateData.ProcessIds = selectedItems;
    this.templateData.TaskAction = bypassItems;
    this.templateData.Level = processId;
    this.templateData.UserID = this.GV.UserId;
    this.templateData.Ip = this.IpAddress;

    let NotificationPreferences = this.byPassForm.controls["NotificationPreferences"].value;
    let NTriggerEvent: any
    let NTriggerType: any = bypassItems == "Bypass" ? 1 : 2
    if (processId == 1)
      NTriggerEvent = NTriggerType == 1 ? this._NotificationEmailTriggerEvent.NotificationtoProjectBypassed : this._NotificationEmailTriggerEvent.NotificationtoProjectUnBypassed;
    else
      NTriggerEvent = NTriggerType == 1 ? this._NotificationEmailTriggerEvent.NotificationtoSubprocessBypassed : this._NotificationEmailTriggerEvent.NotificationtoSubProcessUnBypassed;

    this.moduleService.getBypassUnbypassWorkFlowTaskList(this.RecordID, selectedItems, bypassItems, processId, this.templateData).subscribe(data => {
      this.BypassedItems = [];
      if (data != null && data != "" && data != undefined) {
        this.BypassedItems = data;
        this.showBypassedList = true;
        this.notifyService.show("Tasks '" + bypassItems + "' successfully");

        this.multiTaskBypassConfrimopened = false;
        this.multiTaskUnBypassConfrimopened = false;
        this.asyncloadTemplates();
        this.moduleService.saveMultiTaskBypassComments(this.templateData).subscribe(data => {
          if (NotificationPreferences != 467)
            this.amService.GetActionEmailNotification(NTriggerEvent, NTriggerType, this.RecordID, 0, selectedItems, 0, this.CompanyID, 0, encodeURIComponent(this.offset)).subscribe();
        })
      }
    })
  }

  multiTaskbypass() {
    this.submitCount = 1;
    this.showgriderror = false;
    this.showradioerror = false;
    this.byPassForm.addControl('processId', new FormControl({}, Validators.required));
    this.byPassForm.addControl('bypassItem', new FormControl('', Validators.required));
    let bypassItems: any = this.byPassForm.controls.bypassItem.value !== undefined ? this.byPassForm.controls.bypassItem.value : "";
    let selectedItems: any = this.byPassItemsSelected.length > 0 ? String(this.byPassItemsSelected) : "";
    if (selectedItems.length == 0 || this.byPassForm.invalid || bypassItems.length == "") {

      if (bypassItems.length == "") {
        this.showradioerror = true;
      }
      if (selectedItems.length == 0) {
        this.showgriderror = true;
      }

      if (this.byPassForm.invalid) {
        this.invalidSubmitAttempt = true;
      }
      return;
    }

    if (bypassItems == "Bypass") {
      this.multiTaskBypassConfrimopened = true;
      this.showBypassUnbypassPopUp = false;
    }
    else {
      this.multiTaskUnBypassConfrimopened = true;
      this.showBypassUnbypassPopUp = false;
    }
  }

  ChangeBypassType(selectedId: any) {
    let rblbypassItems: any = this.byPassForm.controls.bypassItem.value !== undefined ? this.byPassForm.controls.bypassItem.value : 0;
    if (selectedId.value !== undefined && rblbypassItems !== undefined && rblbypassItems != 0) {
      //this.RecordID Project ID
      this.moduleService.getBypassTasksByWorkFlow(this.RecordID, rblbypassItems, selectedId.value).subscribe(data => {
        this.BypassItems = [];
        this.byPassItemsSelected = [];
        this.selectedKeysChange();
        if (data != null && data != "" && data != undefined) {
          this.BypassItems = data;
          if (rblbypassItems == "Bypass")
            this.showBypassUnbypassPopUp = true;
          else
            this.showBypassTasks = true;
        }
      })
    }
  }

  bypassChange(actionName: string) {
    this.showradioerror = false;
    let level: any = this.byPassForm.controls.processId.value.value !== undefined ? this.byPassForm.controls.processId.value.value : 0;
    if (actionName == "Bypass") {
      this.moduleService.getBypassTasksByWorkFlow(this.RecordID, actionName, level).subscribe(data => {
        this.BypassItems = [];
        this.byPassItemsSelected = [];
        this.selectedKeysChange();
        if (data != null && data != "" && data != undefined) {
          this.BypassItems = data;
          this.showBypassUnbypassPopUp = true;

        }
      })
    }
    else {
      this.moduleService.getBypassTasksByWorkFlow(this.RecordID, actionName, level).subscribe(data => {
        this.BypassItems = [];
        this.byPassItemsSelected = [];
        this.selectedKeysChange();
        if (data != null && data != "" && data != undefined) {
          this.BypassItems = data;
          this.showBypassTasks = true;
        }
      })
    }
  }

  closebypassDialog() {
    this.TaskBypassConfrimopened = false;
    this.TaskUnbypassBypassConfrimopened = false
    this.showBypassUnbypassPopUp = false;
    this.multiTaskBypassConfrimopened = false;
    this.multiTaskUnBypassConfrimopened = false;
  }
  closeMultibypassDialog() {
    this.multiTaskBypassConfrimopened = false;
    this.multiTaskUnBypassConfrimopened = false;
  }
  public CancelBypassList() {
    this.showBypassedList = false;
    this.showBypassTasks = false;
    this.showBypassUnbypassPopUp = false;
  }

  selectedKeysChange = function () {
    if (this.byPassItemsSelected.length == 0 && this.submitCount == 1)
      this.showgriderror = true;
    else
      this.showgriderror = false;
  }

  AuditLogData() {
    this.amService.AuditLogData(this.RecordID, 55, this.GV.UserId, 1).subscribe((data) => {
      this.divHisdata = true;
      var AuditDisplay = "";
      if (data.length > 0) {
        this.showDialogPopUp = false;
        this.displaypopup = "none";
        this.AuditexcelBtn = true;
        this.AuditLogId = true;
        this.IsAuditExportExcel = true;
        //     AuditDisplay = AuditDisplay + "<div class='row  padding-5 border-tb-ccc'><div class='col s2'>Field name</div><div class='col s5 '>Before change</div><div class='col s5'>After change</div></div>";
        for (var i = 0; i < data.length; i++) {
          var seraizedata = JSON.parse(data[i]["changes"]);

          AuditDisplay += "<div class=''>";
          AuditDisplay += "<table class='auditclass'>";
          AuditDisplay += "<tr class='auditclass margin-top-10' style='background: rgba(245, 247, 250, 0.5) !important;'>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>User: <b class='newtext-color'>" + data[i].userId + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Event date:<b class='newtext-color'> " + this.DateTimeService.convertdate(data[i].eventDate) + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Action type:<b class='newtext-color'> " + data[i].actionType + "</b></td>";
          AuditDisplay = AuditDisplay + "</tr>"
          AuditDisplay = AuditDisplay + "<tr class='auditclass'><td><b>Field name</b></td><td><b> New Changes</b></td></tr>";
          if (seraizedata.length > 0) {
            for (var j = 0; j < seraizedata.length; j++) {
              AuditDisplay = AuditDisplay + "<tr class='auditclass'>";
              AuditDisplay = AuditDisplay + "<td>" + this.DisplayAllFields.GetFieldName(seraizedata[j].FieldName, 55) + "</div>";
              AuditDisplay = AuditDisplay + "<td style='width:33%!important;'>" + (seraizedata[j].ValueAfter == "(null)" ? "" : seraizedata[j].ValueAfter) + "</div>";
              AuditDisplay = AuditDisplay + "</tr>";
            }
          }
          else {
            AuditDisplay = AuditDisplay + "<tr class='newtext-color'><td></td><td class='center'>No updates to show!</td><td></td></tr>";
          }
          AuditDisplay = AuditDisplay + "</table><br/>";
          AuditDisplay = AuditDisplay + "</div>";
        }
        this.htmlToAdd = AuditDisplay;
      }
      else {
        this.notifyService.show(this.GetMessageByLanguage('STC_NAD', "No audit data for this record."), 4);
      }


    });
  }

  auditclose() {
    this.AuditLogId = false;
  }

  ExportAuditToPdfFile(htmlcontent: string) {
    this.ObjAuditExpPdf.htmlcontent = htmlcontent;
    this.ObjAuditExpPdf.offset = this.TZoffset;;
    this.ObjAuditExpPdf.TableEnum = '55';
    this.showLoader = true;
    this.amService.DownloadAuditPdfFile(this.ObjAuditExpPdf).subscribe((event) => {
      this.DownLoadExportPdfFile(event.filePath, event.auditFile);
      this.showLoader = false;
    },
      error => {
        this.showLoader = false;
        this.notifyService.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }
  DownloadArtWorkPdfFile(filepath: string, name: string) {


    debugger;


    this.FileName = name;
    this.moduleService.DownloadFile(filepath, name).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event);
      }
    });
  }
  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;//.substring(1).slice(0,-1);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }
  DownLoadExportPdfFile(Filepath: string, FileName: string) {
    this.FileName = FileName;
    this.amService.GetDownloadAuditPdfFilePath(Filepath, FileName).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadExportPdfFile(event);
      }
    },
      error => {
        this.showLoader = false;
        this.notifyService.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }

  private downloadExportPdfFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  submitOrSaveAsDraftCondition(actionEvent: any): boolean {
    if (actionEvent == 'Submit' || actionEvent == 'SaveAsDraft')
      return true;
    else
      false;
  }

  closeReAssign() {
    this.openReassignwindow = false;
    this.openAssignuserswindow = false;
  }
  filterChange(filter: any): void {
    this.filteredRecords = this.projectLists.filter(item => item.projectName.toLowerCase().includes(filter.toLowerCase()));

    this.listItems = this.filteredRecords;
  }
}
export class FieldStatusDTO {
  FieldId: number;
  Status: string;
}

export class templateSelectedRecord {

  moduleID: string = "0";
  templateId: string = "0";
  selectedRecordId: number = 0;
  selectedRecordIds: any = [];
  dependentRecordID: string = "0";
  highlightRecordID: number = 0;
  recordType: number = 0;
  targetTemplateId: string = "0";
  componentType: string = "1";//1-dynamic form view  2-template view componet
  collectionId: string = "0";//For maintaining id for  remove collection 


}
