import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { DynamicFieldService } from '../../dynamic-fields-service/dynamic-field.service';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ModuleService } from '../../../services/module.service';
import { GroupDescriptor, process, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { IntlService } from '@progress/kendo-angular-intl';
import { DynamicFormGroupBase } from '../dynamic-field-base';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { getBaseUrl } from '../../../../main';
import { DynamicFieldControlService } from '../../dynamic-fields-service/dynamic-field-control.service';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { SelectableSettings, DataStateChangeEvent, PagerPosition, ScrollRequest } from '@progress/kendo-angular-grid';
import { headerCellClass, headerRowClass, templateSelectedRecord, searchFilter } from '../dynamic-field-list/dynamic-field-list.component';
import { NotificationAlertsService } from '../../../services/notification.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { saveAs } from "@progress/kendo-file-saver";
import { Workbook, WorkbookSheet, ExcelExportData } from "@progress/kendo-angular-excel-export";
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { LanguageService } from '../../../services/language.service';
import { Router } from '@angular/router';
import { breadCrumbs } from '../../../interfaces/ItemplateFields';
import { AdminUserManagementService, NotificationEmailTriggerEvent } from '../../../services/adminusermanagement.service';
import { DateConvertions } from '../../../services/BPM/workflowtasksmanagement.service';
import { AuditTrailsDTOData } from 'src/app/services/AMFormsDataDetails';
import { LoaderService } from 'src/app/services/Loader/loader.service';
import { Subscription } from 'rxjs';
import { PDFDocument } from 'pdf-lib';
@Component({
  selector: 'app-dynamic-field-grid',
  templateUrl: './dynamic-field-grid.component.html',
  styleUrls: ['./dynamic-field-grid.component.css'],
  providers: [DateConvertions]
})
export class DynamicFieldGridComponent implements OnInit, OnChanges {
  _gridData = new gridViewData;
  @Output() passEditID = new EventEmitter();
  @Output() closeAudithistorymodel = new EventEmitter();
  @Input() public componentType: any;
  @Input() refreshGrid: number = 0;
  thumbnaildocumentsPath: string;
  public checkboxOnly = false;
  public expandedDetailKeys: number[] = [1];
  public checkHasAccordion: boolean = false;
  mode: string = "multiple";
  public drag = false;
  public selectableSettings: SelectableSettings;
  public mySelection: number[] = [];
  uploadedFiles = [];
  ItemIDs = [];
  divHisdata: boolean = false;
  RecordID: any;
  moduleID: any;
  templates: any;
  ISTaskData: boolean = false;
  IsDisplayControls: boolean = false;
  IsDisplayView: boolean = false;
  templateParentID: number = 0;
  IsDisplaySubFormView: boolean = false;
  //public position: PagerPosition = "bottom";
  // gridProperties = new gridProperties()   
  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;
  IsSearchedApplied: string = "";
  templateFilters: any;
  checkedtemplateFilters: Array<string> = [];
  itemsChecked = [];

  skip: number = 0;
  take: number = 10;
  targetModuleID: any;
  keyWordSearch: string = "";
  SearchName: string = "";
  chkFields: string = "";
  chkFieldValues: string = "";
  IsColumnGrouping: any;

  targetTemplateID: any;
  inputFormFields: any;
  showSearch: boolean = false;
  columns: any;
  FileStatusColumnExists: boolean = false;
  excelColumns: any;
  excelRecords: any;
  SelectedRecord: number = 0;
  name: string;
  total: number;
  @Input() public ID: any;
  //templateID: any;
  AuditexcelBtn: boolean = false;
  displayViewID: any;
  displayActions: any;
  records: any;
  filteredRecords: any;
  EnableSearchOption: any;
  IsFilter: any;
  IsPaging: any;
  IsSorting: any;
  checkBoxCheckIDs: any = [];
  checkBoxCheckIDs1: any = [];
  checkBoxCheckIDslength: number = 0;
  FileName: string;
  progress: number;
  message: string;

  IpAddres: string;
  chkstatus = false;
  IsExportPDF: any;
  EnableMultiSelect: any;
  selectableMode: string = "";
  ShowMultiSelect: boolean = false;
  AuditLogId: boolean = false;
  isFromDialog: boolean = false;
  EnableselectedValue: any = 0;
  EnableFiltersSearch: any;
  ShowBreadcrumbs: any;
  ResponseData: AuditTrailsDTOData[] = [];
  breadcrumbs: Array<breadCrumbs>;
  selectedRecordID: number = 0;
  BaseType: number = 0;
  OtherType: number = 0;
  ReferenceID: number = 0;
  ShowSearchPopUp: boolean = false;
  ShowSearchDirect: boolean = false;
  ShowFilterSearchInPopUp: boolean = false;
  EnableFilterSearchSaving: any;
  CompanyID: number = 0;
  IsExportExcel: any;
  showPopUp: boolean = false;
  viewRecords: any;
  filters: any;
  Name: string;
  showLoader: boolean = false;
  parentRecordID: number;
  invalidSubmitAttempt: boolean = false;
  templateData = new DynamicTemplateData();
  DynamicFields: DynamicFormGroupBase<any>;
  dynamicForm: FormGroup;
  DynamicFormFieldsActions: any = [];
  Taskcount: Array<Tasksmodel> = [];
  TaskData: Array<Tasksmodel> = [];
  Taskmcount: Array<Tasksmodel> = [];
  Taskmdcount: Array<Tasksmodel> = [];
  checkDefault: boolean = true;
  dateFormats: FormatSettings = {
    displayFormat: 'dd-MMM-yyyy',
    inputFormat: 'dd-MMM-yyyy'
  };
  //IsFilter: any;
  dynTemplateHeader: string = null;
  //uploadSaveUrl = getBaseUrl() + 'api/dynamic/uploadDocument';
  uploadSaveUrl = getBaseUrl() + 'api/dynamic/DynACU';
  searchFilters = new searchFilter();

  //uploadRemoveUrl = 'http://localhost:5001/api/dynamic/uploadDocument';
  EnableFilter: boolean = false;
  CheckHasDependentsSubscription: Subscription = new Subscription();
  // public groups: GroupDescriptor[] = [];//{ field: "Status" }
  public groups: GroupDescriptor[];

  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();
  offSet: string = "+05:30";
  public filter: CompositeFilterDescriptor;
  TZoffset = this.DateTimeService.setUTCTimeZone();
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";
  public pageSettings: any;
  checkedData: any = [] = [];
  parseData: any = [];
  loginUserId: number;
  TasksFlag: number;
  deleteReferenceFile: boolean = false;
  unassginConfrimopened: boolean = false;
  filterOccured: boolean = false;
  IpAddress: string;
  tempDataUnSub: Subscription;
  onChangeCall: boolean = false;
  _nestedgridData = new gridViewData;
 
  scrollConfig: any;

  // public filter: CompositeFilterDescriptor;
  constructor(private dataService: DynamicFieldService,
    private notifyService: NotificationAlertsService,
    private commonfeaturesservice: CommonFeaturesService,
    private qcs: DynamicFieldControlService,
    private DFS: DynamicFieldService,
    private http: HttpClient,
    private moduleService: ModuleService,
    public intl: IntlService, private dialogService: DialogService,
    private GV: GlobalVeriables,
    private router: Router,
    private loader: LoaderService,
    private languageService: LanguageService,

    private formbuilder: FormBuilder, private amService: AdminUserManagementService, private DateTimeService: DateConvertions
  ) {
    this.allData = this.allData.bind(this);

    this.setSelectableSettings();
  }





  ngOnInit() {

    this.CompanyID = +localStorage.getItem("usercompanyID");
    this.loginUserId = this.GV.UserId;
    this.TasksFlag = parseInt(localStorage.getItem("TasksFlag"))
    this.displayViewID = 0;
    this.templateParentID = 0;
    this.thumbnaildocumentsPath = this.moduleService.getBaseUrl() + "documents/";
    this.Name = localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName');

    this.IpAddress = this.GV.UserIpAddress;

    localStorage.setItem("filterConfigKey", this.template.filterConfigKey);
    //this.moduleService.moduleID.subscribe(moduleid => {
    //  if (moduleid == 0 || moduleid == null) {
    //    this.moduleID = 0;
    //  } else {
    //    this.moduleID = moduleid;
    //  }
    //  this.moduleService.GetTemplatesByModuleID(this.moduleID, this.templateParentID).subscribe(data => {
    //    this.IsDisplayControls = true;

    //    this.templates = data.filter(function (template) {
    //      return template.parentTemplate == 0;
    //    });
    //  });
    //});



    //this.templateID = "2";
    this.moduleService.GetFiltersByTemplateID(this.templateID, "0").subscribe(filterData => {
      if (filterData != null && filterData.length > 0) {
        this.templateFilters = filterData;
        this.checkedtemplateFilters = [];

        if (this.template.chkFieldValues != "") {
          var selectedFiltes = JSON.parse(this.template.chkFieldValues);

          selectedFiltes.forEach(element => {
            this.templateFilters.filter(a => a.searchDisplayName == element)[0].isChecked = true;
            this.checkedtemplateFilters.push(element);
          });
          if (this.template.filterConfigKey == 1) {
            this.templateFilters.forEach(function (item: { searchConfig: number; isDisabled: boolean; isChecked: boolean; }) {
              if (item.searchConfig == 0) {
                item.isChecked = false;
                item.isDisabled = true;
              }
            })
          }
          else {
            this.templateFilters.forEach(function (item: { searchConfig: number; isDisabled: boolean; }) {
              if (item.searchConfig == 0) {
                item.isDisabled = false;
              }
            })
          }
        }
      }
    });

    this.showPopUp = false;
    this.parentRecordID = 0;

    this.moduleService.breadCrumbs.subscribe(breadcrumbs => {

      var depeendentTemplateID = this.template.isDependent;
      var parentTemplateID = this.template.hasParent;

      this.breadcrumbs = breadcrumbs.filter(a => a.templateId == depeendentTemplateID || a.templateId == parentTemplateID);//|| (a.templateId == templateID && a.displayControlsID == displayControlsID)


    });


    this.moduleService.breadCrumbName.subscribe(breadcrumb => {
      if (!this.GV.GetIsRedirection())
        if (breadcrumb.displayName != "" && breadcrumb.displayName != undefined) {

          var displayData = this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID);
          if (displayData == null || displayData == undefined || displayData.length == 0) {
            /**/
          } else {
            this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID)[0].displayName = breadcrumb.displayName;

          }


        }


    });
    this.groups = this.templateID == 87 ? [{ field: "Group Name" }] : [];
    if (!this.onChangeCall) { this.loadTemplate(); }
    else {
      this.onChangeCall = false;
    }

  }
  ngOnChanges() {
    if (this.refreshGrid >= 0) {
      this.onChangeCall = true;
      this.loadTemplate();
      //this.filter = undefined;
      //this.EnableFilter = false;

    }
  }

  EnableDisableFilter() {

    if (this.EnableFilter) {
      this.EnableFilter = false;
      this._gridData.filter = null;
      let obj: any = {};
      obj.skip = this._gridData.skipPage;
      obj.take = this._gridData.pageSize;
      obj.filter = null;
      this.pageChange(obj);
    }
    else
      this.EnableFilter = true;
  }

  public groupChange(groups: GroupDescriptor[]): void {

    this.groups = groups;
    this.loadGridData();
  }

  private loadGridData(): void {
    this.filteredRecords = filterBy(this.records, this.filter);
    this._gridData.viewData = process(this.filteredRecords, { group: this.groups });
  }
  public setSelectableSettings(): void {


    this.selectableSettings = {
      checkboxOnly: true,
      mode: "multiple",
      drag: this.drag,
      enabled: true
    };
  }
  loadTemplate() {
    localStorage.setItem("filterConfigKey", this.template.filterConfigKey);
    this.ShowMultiSelect = false;
    this.checkBoxCheckIDs = [];
    this.checkBoxCheckIDslength = 0;
    this.showLoader = true;
    this.passEditID;
    localStorage.setItem("IsColumnDragged", "0");
    this.moduleService.parentRecordID.subscribe(parentRecordID => {

      this.parentRecordID = parentRecordID;
      if (this.template.moduleID == "35") {
        this.moduleID = 35;
        this.TasksFlag = parseInt(localStorage.getItem("TasksFlag"));

        this.template.recordType = parseInt(localStorage.getItem("TasksFlag"));
      }
      if (this.templateID == 39) {
        this.ISTaskData = true;
        this.keyWordSearch = (this.keyWordSearch == "" || this.keyWordSearch == undefined) ? this.template.keyWordSearch : this.keyWordSearch;
        this.moduleService.GetTaskCounts(this.template.searchFilters, this.keyWordSearch, this.template.chkFields, this.template.filter).subscribe(x => {
          var data = x;
          if (this.Taskmcount.length > 0) {
            this.Taskmcount.forEach(item => {

              data.forEach(element => {


                var m = new Tasksmodel;
                m.id = element.id;
                m.Alias = element.alias;
                m.TaskName = element.taskName;
                m.Taskcount = element.taskcount;
                m.classname = element.classname;
                // m.actcls=element.actcls;
                m.icon = element.icon;
                if (item.TaskName == element.taskName) {
                  item.TaskName = element.taskName;
                  item.Taskcount = element.taskcount
                  item.id = element.id;
                  item.classname = element.classname;
                  item.Alias = element.alias;
                }
              })


            })

          }

          else {
            data.forEach(element => {
              var m = new Tasksmodel;
              m.id = element.id;
              m.Alias = element.alias;
              m.TaskName = element.taskName;
              m.Taskcount = element.taskcount;
              m.classname = element.classname;
              m.icon = element.icon;
              this.Taskmcount.push(m);
            });
          }
          this.showLoader = true;
          if (this.Taskmcount.length > 0)
            this.clickcountrecords(this.Taskmcount[0]);
        })

      }


      else {
        this.keyWordSearch = (this.keyWordSearch == "" || this.keyWordSearch == undefined) ? this.template.keyWordSearch : this.keyWordSearch;

        this.filter = (this.filter == undefined && this.template != undefined && this.template.filter != undefined) ? this.template.filter : this.filter;
        if (this.filter != undefined && this.filter.filters != undefined && this.filter.filters.length > 0) {
          this.EnableFilter = true;
          this._gridData.filter = this.filter;
        }
        else this.EnableFilter = false;

        this.IsKeyWordSearched = this.keyWordSearch != "" ? true : false;
        var pageSize = 10;
        if (this.IsPaging == false) {
          pageSize = 100000;
        }


        this.moduleService.GetDisplayFieldData(this.templateID, this.recordID, this.template.displayControlsID, this.template.recordType, 0, this.template.searchFilters, 'DueToday', 0, pageSize, this.keyWordSearch, this.template.chkFields, this.filter).subscribe(data => {
          if (this.template.isSplitter == "1" && (this.template.moduleID == "3" || this.template.moduleID == "14")) {
            var splitterData = {};
            splitterData["templateID"] = this.template.templateID;
            splitterData["ID"] = JSON.parse(data.record).length > 0 ? JSON.parse(data.record)[0].ID : 0;
            splitterData["IDs"] = JSON.parse(data.record).length > 0 ? [JSON.parse(data.record)[0].ID] : [];

            splitterData["ProjectID"] = JSON.parse(data.record).length > 0 ? JSON.parse(data.record)[0].ID : 0;
            this.moduleService.passSplitterTemplate(splitterData);

          }

          this.commonOptions(this.template, data);
          if (this.GV.ProcessDataID > 0) {
            //debugger;
            const dataitem = this.records.find(item => item.ID === this.GV.ProcessDataID);
            dataitem["templateID"] = this.template.templateID;
            this.moduleService.passSplitterTemplate(dataitem);

          }


        });
      }
      if (this.showLoader) { this.showLoader = false; }
    });
  }

  onCheckChange(dataObject, index) {
    if (this.templateFilters != null && this.templateFilters != undefined) {
      if (dataObject.searchConfig == 1) {
        let element = <HTMLInputElement>document.getElementById("chkFilter" + index);
        if (element.checked) {
          this.templateFilters.forEach(function (filterItems: { searchConfig: number; isDisabled: boolean; isChecked: boolean; }) {
            if (filterItems.searchConfig == 0) {
              filterItems.isChecked = false;
              filterItems.isDisabled = true;
            }
          })
        }
        else {
          this.templateFilters.forEach(function (item: { searchConfig: number; isDisabled: boolean; }) {
            if (item.searchConfig == 0) {
              item.isDisabled = false;
            }
          })
        }
      }
    }
  }
  closeDialog() {
    let searchOptions = [];
    let searchOptionValues = [];
    let searchKey = "";
    let filterKeyConfig = 0;
    let searchDisplayName = "";

    this.checkedtemplateFilters = null
    this.searchFilters.searchFields = this.template.searchFilters;
    this.searchFilters.searchFieldValues = this.template.searchFilterValues;
    this.searchFilters.chkFields = "[{\"options\":[" + searchOptions.join(",") + "],\"key\":\"" + searchKey + "\"}]";
    this.searchFilters.chkFieldValues = JSON.stringify(searchOptionValues);
    this.searchFilters.keyWordSearch = this.keyWordSearch;
    this.searchFilters.displayControlsID = this.template.displayControlsID;

    this.searchFilters.chkDisplayControl = "13";

    this.searchFilters.templateId = this.templateID;
    this.searchFilters.filterConfigKey = filterKeyConfig;
    this.moduleService.passsearchFilters(this.searchFilters);
    this.showFilter = false;
  }

  onCheckSubmit() {
    let searchOptions = [];
    let searchOptionValues = [];
    let searchKey = "";
    let filterKeyConfig = 0;
    let searchDisplayName = "";

    if (this.templateFilters != null && this.templateFilters != undefined) {
      this.templateFilters.forEach(function (value, index) {
        let element = <HTMLInputElement>document.getElementById("chkFilter" + index);

        if (element.checked) {
          searchOptions.push(value.searchOptions);

          searchKey = value.searchKey;
          if (value.searchConfig == 1) {
            filterKeyConfig = 1
          }
          searchDisplayName = value.searchDisplayName;
          searchOptionValues.push(searchDisplayName)
        }
      });
    }

    if (searchOptionValues.length == 0) {
      this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SELFLT', "Please select atleast one filter."), 4);
      return;
    }


    this.checkedtemplateFilters = searchOptionValues
    this.searchFilters.searchFields = this.template.searchFilters;
    this.searchFilters.searchFieldValues = this.template.searchFilterValues;
    this.searchFilters.chkFields = "[{\"options\":[" + searchOptions.join(",") + "],\"key\":\"" + searchKey + "\"}]";
    this.searchFilters.chkFieldValues = JSON.stringify(searchOptionValues);
    this.searchFilters.keyWordSearch = this.keyWordSearch;
    this.searchFilters.displayControlsID = this.template.displayControlsID;

    this.searchFilters.chkDisplayControl = "13";

    this.searchFilters.templateId = this.templateID;
    this.searchFilters.filterConfigKey = filterKeyConfig;
    this.moduleService.passsearchFilters(this.searchFilters);
  }
  gridUserSelectionChange(selection) {


    //this.checkBoxCheckIDs = selection.filter(a => a > 0);

    if (this.checkBoxCheckIDs.length > 0) {
      if (this.template.templateID == 61 || this.template.templateID == 80) {
        if (this.template.templateID == 61) {
          this.checkBoxCheckIDs.length = this.checkBoxCheckIDslength;
        }
        //this.checkBoxCheckIDslength=this.checkBoxCheckIDs.length;
        //this.moduleService.passReferenceFileIds(this.checkBoxCheckIDs);
      }


      else {
        let selectedItem = new templateSelectedRecord();
        selectedItem.templateId = this.template.templateID;
        selectedItem.selectedRecordId = this.checkBoxCheckIDs[0];
        this.selectedRecordID = this.checkBoxCheckIDs[0];



        // selectedItem.selectedRecordIds = this.checkBoxCheckIDs;
        // this.mySelection = this.checkBoxCheckIDs;
        selectedItem.moduleID = this.template.moduleID;
        selectedItem.dependentRecordID = this.template.recordID;
        selectedItem.componentType = this.componentType;
        selectedItem.recordType = this.template.recordType;
        selectedItem.collectionId = this.template.collectionId;
        if (this.checkBoxCheckIDslength > 0) {
          selectedItem.filteredRecordsSelected = this.filteredRecords;

        }
        //if (this.template.isSplitter == "1") {
        //  selectedItem.selectedRecordId = this.template.recordID;
        //}

        if (this.template.isSplitter == "1" && (this.template.moduleID == "3" || this.template.moduleID == "14")) {
          var splitterData = {};
          splitterData["templateID"] = this.template.templateID;
          splitterData["ID"] = this.checkBoxCheckIDs[0];
          splitterData["IDs"] = [this.checkBoxCheckIDs[0]];
          splitterData["ProjectID"] = this.checkBoxCheckIDs[0];
          this.moduleService.passSplitterTemplate(splitterData);

        } else {
          this.moduleService.passDependencyTemplate(selectedItem);

        }
      }
    } else {
      if (this.template.templateID == 61 || this.template.templateID == 80)
        this.moduleService.passReferenceFileIds(this.checkBoxCheckIDs);
      else {
        let selectedItem = new templateSelectedRecord();
        selectedItem.templateId = this.template.templateID;
        selectedItem.selectedRecordId = selection;
        this.selectedRecordID = selection;



        // this.checkBoxCheckIDs = [this.filteredRecords[0].ID];
        // this.mySelection = this.checkBoxCheckIDs;
        selectedItem.selectedRecordIds = selection
        selectedItem.moduleID = this.template.moduleID;
        selectedItem.dependentRecordID = this.template.recordID;
        selectedItem.componentType = this.componentType;
        selectedItem.recordType = this.template.recordType;
        selectedItem.collectionId = this.template.collectionId;
        if (this.checkBoxCheckIDslength > 0) {
          selectedItem.filteredRecordsSelected = this.filteredRecords;
        }


        if (this.template.isSplitter == "1" && (this.template.moduleID == "3" || this.template.moduleID == "14")) {
          var splitterData = {};
          splitterData["templateID"] = this.template.templateID;
          splitterData["ID"] = selection;
          splitterData["IDs"] = selection;
          splitterData["ProjectID"] = selection;
          this.moduleService.passSplitterTemplate(splitterData);

        } else {


          this.moduleService.passDependencyTemplate(selectedItem);

        }
      }
    }



  }

  close() {
    this.showPopUp = false;
    this.parentRecordID = 0;
  }


  public dataStateChange(state: DataStateChangeEvent): void {
    this._gridData.pageSize = state.take;
    this._gridData.skipPage = state.skip;
    this._gridData.filter = state.filter;

    var elementAll = <HTMLInputElement>document.getElementById("chkSelectALL" + this.template.templateID);
    if (elementAll != null && elementAll.checked) {
      elementAll.checked = false;
      this.onCheckAll(this.template.templateID);
    }
    if (state.group.length > 0) {
      localStorage.setItem("IsColumnDragged", state.group.length.toString());
    }
    else {
      localStorage.setItem("IsColumnDragged", "0");
    }
    //if ((state.filter == undefined || state.filter.filters.length <= 0))
    if (state.filter != null && state.filter.filters.length > 0) {
      let filterValue = 0;
      let isNumber = false;

      for (var i = 0; i < state.filter.filters.length; i++) {
        let fieldName = (<any>(<CompositeFilterDescriptor>state.filter).filters[i]).field;
        if (this._gridData.viewData.data.length > 0) {
          isNumber = typeof (this._gridData.viewData.data[0][fieldName]) === "number" ? true : false;
        }
        filterValue = ((<any>(<CompositeFilterDescriptor>state.filter).filters[i]).value).length;
        if (filterValue > 3 || isNumber) {
          break;
        }

      }

      if (filterValue > 3 || this.filterOccured || isNumber) {
        if (filterValue <= 3 && !isNumber) {
          this.filterOccured = false;
          state.filter.filters = [];
          this.pageChange(state);
        }
        else {
          this.filterOccured = true;
          this.pageChange(state);
        }

      }
    }
    else
      this.pageChange(state);
    //}


  }



  pageChange(obj) {
    this.skip = obj.skip;
    this.take = obj.take;
    this.filter = obj.filter;

    this.filter = this.filter != undefined && this.filter.filters.length == 0 ? null : obj.filter;

    this.template.filter = this.filter;

    this.moduleService.parentRecordID.subscribe(parentRecordID => {
      if (this.name == undefined)
        this.name = 'DueToday';

      this.parentRecordID = parentRecordID;
      this.moduleService.GetDisplayFieldData(this.template.templateID, this.recordID, this.template.displayControlsID, this.template.recordType, 0, this.template.searchFilters, this.name, this._gridData.skipPage, this._gridData.pageSize, this.keyWordSearch, this.template.chkFields, this.filter).subscribe(data => {

        if (data.record == null && data.columns == null && data.displayProperties == null) {
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW', "Something went wrong. Please try again "), 4);
          this.showLoader = false;
          this.loader.setLoading(false);
          return;
        }

        this.SearchName = this.template.searchName;

        if (this.template.searchFilters == "" || this.template.searchFilters == [] || this.template.searchFilters == undefined) {
          this.IsSearchedApplied = "";
        } else {
          //this.IsSearchedApplied = this.template.moduleID == "30" ? this.template.searchFilters : this.template.searchFilterValues;
          this.IsSearchedApplied = this.template.searchFilterValues;
        }
        localStorage.setItem("selectedSearchReportValues", this.IsSearchedApplied);
        this.records = "";
        this.records = JSON.parse(data.record);

        if (this.templateID == 39) {
          this.ISTaskData = true;
          this.TaskData.length = 0;
        }

        this.filteredRecords.length = 0;


        let obj: any = {

          paginationData: {
            page: this._gridData.skipPage !== 0 ? (this._gridData.skipPage / this._gridData.pageSize) + 1 : 1, //skip
            pageSize: this._gridData.pageSize, //take
          },

        };
        this.filteredRecords = this._gridData.viewData.data = JSON.parse(JSON.stringify(this.records));
        //this.filteredRecords = filterBy(this.filteredRecords, this.filter);
        this._gridData.viewData = process(this.filteredRecords, { group: this.groups });
        if (this.templateID != 39 && (this.filter == undefined || this.filter.filters.length <= 0) && this.records.length > 0) {
          this._gridData.viewData.total = this.records[0].Total;
        }
        else if (this.templateID != 39 && this.filter.filters.length > 0 && this.records.length > 0) {
          this._gridData.viewData.total = this.records[0].Total;// this._gridData.viewData.total;
        }
        else if ((this.filter != undefined && this.filter.filters.length > 0) && this.records.length > 0) {
          this._gridData.viewData.total = this.records[0].Total;
        }
        else if (this.template.templateID == 39 && (this.filter != undefined && this.filter.filters.length > 0) && this.records.length > 0) {
          this._gridData.viewData.total = this.records[0].Total;
        }
        else if (this.template.templateID == 39 && (this.records == undefined || this.records.length == 0)) {
          this._gridData.viewData.total = 0;
        }
        else {
          //this._gridData.viewData.total = this.total;
          this._gridData.viewData.total = this.total == undefined ? 0 : this.total;
        }
        this.showLoader = false;

        //#region check for dependent template and get add to template list for loading dependent template
        this.CheckHasDependentsSubscription = this.moduleService.CheckHasDependents(this.template.moduleID, this.template.templateID).subscribe(data => {

          if (this.template.moduleID == '3' || this.template.moduleID == '14') {
            for (var i = 0; i < this.records.length; i++) {
              document.getElementById('chk' + String(this.template.templateID) + String(this.records[i].ID))["checked"] = false;
            }
          }
          if ((this.template.moduleID == '3' || this.template.moduleID == '14') && this.template.isSplitter == "1") {
            data["templateID"] = this.template.templateID;
            data["ID"] = this.records[0].ID;
            data["IDs"] = [this.records[0].ID];
            data["ProjectID"] = this.records[0].ProjectId;
            this.moduleService.passSplitterTemplate(data);
          }

          if (data.isDependent != null && data.isDependent != "0" && this.records != null && this.records.length > 0) {
            data.recordID = this.records[0].ID;
            data.selectedRecordID = this.records[0].ID;
            this.selectedRecordID = this.records[0].ID;
            this.checkBoxCheckIDs = [this.records[0].ID];
            this.mySelection = this.checkBoxCheckIDs;


            data.selectedTemplateId = this.templateID;
            data.recordType = this.template.recordType;
            this.showLoader = false;
            this.moduleService.passTemplateRecord(data);
          } else {
            data.selectedTemplateId = this.templateID;
            data.recordType = this.template.recordType;
            this.checkBoxCheckIDs = [];
            this.mySelection = this.checkBoxCheckIDs;
            this.showLoader = false;
            this.moduleService.passTemplateRecordRemove(data);
          }

          if (this.template.templateID == 61 || this.template.templateID == 80 || this.template.templateID == 93) {
            // if (this.records != null)
            //   this.checkBoxCheckIDslength = 1;

            // this.moduleService.passReferenceFileIds(this.checkBoxCheckIDs);
          }
        });
        //#endregion

      });
    });
  }

  /*ngAfterContentChecked() {

    var elementAll = <HTMLInputElement>document.getElementById("chkSelectALL" + this.template.templateID);
    if (elementAll != null) {

      var isChecked = elementAll.checked;
      if (isChecked) {

        for (let i of this.records) {

          var element = <HTMLInputElement>document.getElementById("chk" + this.template.templateID + i.ID);
          if (element != null) {

            element.checked = true;


          }
        }


      }
      else {
        this.itemsChecked = [];

        if (this.records != null && this.records != undefined && this.records != '') {
          for (let i of this.records) {
            if (this.checkBoxCheckIDs.indexOf(i.ID) > -1) {
            } else {
              this.itemsChecked.push(i.ID);
            }
          }
          for (let i of this.checkBoxCheckIDs) {
            var element = <HTMLInputElement>document.getElementById("chk" + this.template.templateID + i);
            if (element != null) {
              element.checked = true;
            }

            for (let i of this.itemsChecked) {
              var element = <HTMLInputElement>document.getElementById("chk" + this.template.templateID + i);
              if (element != null) {
                element.checked = false;
              }

            }
          }
        }

      }


    }
  }*/
  clickcountrecords(obj) {

    if (obj.TaskName == "Total") {
      //this.filteredRecords = this.records;

      this.Taskmcount.forEach(item => {
        if (obj.TaskName == item.TaskName) {
          item.actcls = 'task-active';
        }
        else {
          item.actcls = '';
        }
      })

      this.name = obj.TaskName;
      this.total = obj.Taskcount;
    }
    else {
      // this.filteredRecords.length = obj.Taskcount;
      this.Taskmcount.forEach(item => {
        if (obj.TaskName == item.TaskName) {
          item.actcls = 'task-active';
        }
        else {
          item.actcls = '';
        }
      })
      this.name = obj.TaskName.replace(/\s/g, "");
      this.total = obj.Taskcount;
    }

    this._gridData.skipPage = 0;
    this._gridData.pageSize = 10;
    this._gridData.filter = this.filter;


    this.showLoader = true;
    this.moduleService.parentRecordID.subscribe(parentRecordID => {


      this.parentRecordID = parentRecordID;

      this.keyWordSearch = (this.keyWordSearch == "" || this.keyWordSearch == undefined) ? this.template.keyWordSearch : this.keyWordSearch;

      this.filter = (this.filter == undefined && this.template != undefined && this.template.filter != undefined) ? this.template.filter : this.filter;
      if (this.filter != undefined && this.filter.filters != undefined && this.filter.filters.length > 0) {
        this.EnableFilter = true;
        this._gridData.filter = this.filter;
      }
      else this.EnableFilter = false;

      this.IsKeyWordSearched = this.keyWordSearch != "" ? true : false;

      this.moduleService.GetDisplayFieldData(this.templateID, this.recordID, this.template.displayControlsID, this.template.recordType, 0, this.template.searchFilters, this.name, this._gridData.skipPage, this._gridData.pageSize, this.keyWordSearch, this.template.chkFields, this._gridData.filter).subscribe(data => {

        this.commonOptions(this.template, data)

      });
      this.showLoader = false;
    });
  }
  commonOptions(template, data) {
    if (data.record == null && data.columns == null && data.displayProperties == null) {
      this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW', "Something went wrong. Please try again "), 4);
      this.showLoader = false;
      this.loader.setLoading(false);
      return;
    }

    this.SearchName = this.template.searchName;

    if (this.template.searchFilters == "" || this.template.searchFilters == [] || this.template.searchFilters == "[]" || this.template.searchFilters == undefined) {
      this.IsSearchedApplied = "";
    } else {
      //this.IsSearchedApplied = this.template.moduleID == "30" ? this.template.searchFilters : this.template.searchFilterValues;
      this.IsSearchedApplied = this.template.searchFilterValues;

    }
    localStorage.setItem("selectedSearchReportValues", this.IsSearchedApplied);
    this.records = [];
    this.chkFields = this.template.chkFields;
    this.chkFieldValues = this.template.chkFieldValues;

    if (this.templateID == 39) {
      this.ISTaskData = true;
      this.TaskData.length = 0;
    }
    this.checkHasAccordion = false;

    this.records = JSON.parse(data.record);

    
    if(this.GV.isHideBypassTasks=='HideProjectLevelBypass')
    {
      this.records = this.records.filter(record => record.WorkFlowStatusID !== 280);
    }
    else if(this.GV.isHideBypassTasks=='HideSubProcessLevelBypass')
    {
      if(this.GV.SubProcessID!=0 && this.GV.SubProcessID!= null &&  this.GV.filteredRecordsSave.length<=0)
      {
        this.records = this.records.filter(record => {
          return !(record.WorkFlowStatusID === 280 && record.SubProcessID === this.GV.SubProcessID);
      });
      this.GV.filteredRecordsSave = this.records;
     }
      else
      {
        this.GV.filteredRecordsSave =  this.GV.filteredRecordsSave.filter(record => {
          return !(record.WorkFlowStatusID === 280 && record.SubProcessID === this.GV.SubProcessID);
      });
      this.records=this.GV.filteredRecordsSave;
      }
    }
    else if(this.GV.isHideBypassTasks=='ShowSubProcessLevelBypass')
    {
        // for(let i=0;i<this.records.length;i++)
        // {
        //    if(this.GV.subProcessIDList.includes(this.records[i].SubProcessID) && this.records[i].WorkFlowStatusID==280)
        //          this.records.splice(i,1)
        // }   

        this.records = this.records.filter(record => {
          return !(this.GV.subProcessIDList.includes(record.SubProcessID) && record.WorkFlowStatusID === 280);
      });
    }
    else
        this.GV.filteredRecordsSave=[];



    if (this.records && this.records.length > 0 && this.records[0].hasOwnProperty("AccordianID")) {

      this.filteredRecords = this._gridData.viewData.data = JSON.parse(data.record).filter(a => a.AccordianID == 0);

      this.expandedDetailKeys = JSON.parse(data.record).filter(a => a.AccordianID == 0 && a.SubProcessID != 0).map(a => a.ID)


      let CheckAccordianCount = JSON.parse(data.record).filter(a => a.AccordianID != 0);

      if (CheckAccordianCount != null && CheckAccordianCount != undefined && CheckAccordianCount.length > 0) {
        this.checkHasAccordion = true;
      }


    } else {
      this.filteredRecords = this._gridData.viewData.data = JSON.parse(data.record);

    }

    this._gridData.viewData = process(this.filteredRecords, { group: this.groups });
    this._gridData.viewData.total = this.total;

    if (this.templateID != 39 && this.records.length > 0) {
      //this.checkBoxCheckIDslength = 1;
      this._gridData.viewData.total = this.records[0].Total;
    }
    else if (this.records.length == 0)
      this._gridData.viewData.total = 0;
    this.columns = data.columns.filter(a => a.controlType != 43);
    this.columns.forEach(item => {
      item.templateID = this.templateID;
    })
    this.FileStatusColumnExists = data.columns.filter(a => a.controlType == 43).length > 0 ? true : false;//checking for file status colunm

    var i = 0;
    if (this.templateID == 61 && data.displayActions[0].name == 'Delete') {
      this.records.forEach(element => {
        if (this.records[i].FileType == 'Base File' || this.records[i].FileType == 'Upload Annotated Artwork') {
          this.BaseType = 1;
          this.displayActions = null;
          i++;
        }
        else {
          this.OtherType = 1;
          this.displayActions = data.displayActions;
        }
      });
    }
    else {
      this.displayActions = data.displayActions;
    }

    this.EnableSearchOption = data.displayProperties[0].enableSearchOption == "True" ? true : false;
    this.EnableFiltersSearch = this.template.enableFiltersSearch == "True" ? true : false;
    this.ShowBreadcrumbs = this.template.showBreadcrumbs == "True" ? true : false;
    this.ShowFilterSearchInPopUp = this.template.showFilterSearchInPopUp == "True" ? true : false;
    this.EnableFilterSearchSaving = this.template.enableFilterSearchSaving == "True" ? true : false;
    if (this.ShowFilterSearchInPopUp) {
      this.ShowSearchPopUp = true;
      this.ShowSearchDirect = false;
    } else {
      this.ShowSearchPopUp = false;
      this.ShowSearchDirect = true;
    }
    this.IsFilter = data.displayProperties[0].isFilter == "True" ? true : false;
    this.IsPaging = data.displayProperties[0].isPaging == "True" ? true : false;
    this.IsSorting = data.displayProperties[0].isSorting == "True" ? true : false;
    this.IsExportPDF = data.displayProperties[0].isExportPDF == "True" ? true : false;
    this.EnableMultiSelect = data.displayProperties[0].enableMultiSelect == "True" ? true : false;
    this.IsColumnGrouping = data.displayProperties[0].isColumnGrouping == "True" ? true : false;
    this.IsExportExcel = data.displayProperties[0].isExportExcel == "True" ? true : false;

    if (this.EnableMultiSelect) {
      this.selectableMode = "multiple";
      this.ShowMultiSelect = true;
    } else {
      this.selectableMode = "single";
      this.ShowMultiSelect = false;

    }


    if (this.IsPaging) {

      this.pageSettings = {
        buttonCount: this.buttonCount,
        info: this.info,

        pageSizes: this.pageSizes,
        previousNext: this.previousNext,
        position: this.position
      };

    } else {
      this.pageSettings = false;
    }


    this.scrollConfig = {
      virtual: true,
      scrollToIndex: 3 // Replace with the index of your selected row
    };


    if (this.template.moduleID == "35" && parseInt(localStorage.getItem('TasksFlag')) == 1) {
      this.EnableMultiSelect = false;
    }
    this.showLoader = false;
    //#region check for dependent template and get add to template list for loading dependent template
    if (this._gridData.viewData.length > 0) { localStorage.setItem('GridTotal', this._gridData.viewData.total.toString()); }
    this.CheckHasDependentsSubscription = this.moduleService.CheckHasDependents(this.template.moduleID, this.template.templateID).subscribe(data => {
      if (data.isDependent != null && data.isDependent != "0" && this.records != null && this.records.length > 0) {
        //this.selectedRecordID = this.records[0].ID;
        data.recordID = this.records[0].ID;
        data.selectedRecordID = this.selectedRecordID = this.records[0].ID;
        this.mySelection = [this.selectedRecordID];
        data.selectedTemplateId = this.templateID;
        //this.checkBoxCheckIDs = this.mySelection;
        //this.checkBoxCheckIDslength = this.checkBoxCheckIDs.length;
        data.recordType = this.template.recordType;
        this.showLoader = false;
        this.moduleService.passTemplateRecord(data);

      } else {
        data.selectedTemplateId = this.templateID;
        if (this.records != null && this.records.length > 0) {
          //this.checkBoxCheckIDs = [this.records[0].ID];
          this.mySelection = this.checkBoxCheckIDs;
        } else {
          this.checkBoxCheckIDs = [];
          this.mySelection = this.checkBoxCheckIDs;
        }


        if (this.template.isSplitter == "1") {

          this.mySelection = [this.template.highlightRecordID];

          this.moveScroll();



        }

        data.recordType = this.template.recordType;
        data.collectionId = this.template.collectionId;
        this.showLoader = false;
        this.moduleService.passTemplateRecordRemove(data);

      }
      if (this.template.templateID == 61 || this.template.templateID == 80 || this.template.templateID == 93) {
        //  alert(this.template.templateID);
        this.moduleService.passReferenceFileIds(this.checkBoxCheckIDs);
      }
    });
  }

  getNestedGridData(dataItem: any) {




    return this.records.filter(a => a.AccordianID == dataItem.IsAccordian)


  }




  public expandDetailsBy = (dataItem: any): number => {
    return dataItem.ID;
  };
  public showOnlyExpand(dataItem: any): boolean {

    return dataItem.IsAccordian != 0;
  }
  gridActionView(dataItem, rowIndex, event, column) {

    this.GV.isHideBypassTasks='';
    this.GV.subProcessIDList=[];
    this.GV.SubProcessID=0;

    this.GV.SetIsRedirection(false);

    this.GV.ProcessDataID = 0;

    if (this.template.isSplitter == "1") {
      dataItem["templateID"] = this.template.templateID;
      dataItem["ID"] = dataItem.ID;
      dataItem["IDs"] = [dataItem.ID];
      dataItem["ProjectID"] = dataItem.ProjectID;

      this.moduleService.passSplitterTemplate(dataItem);

    } else {

      if (column.actionType == "8") {
        localStorage.setItem("projectTaskRedirection", "true");
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/Projects/' + dataItem.ProjectID])
        );

        window.open(url);
      }
      else {

        if (!event.ctrlKey) {


          this.mySelection = [rowIndex];
          let selectedItem = new templateSelectedRecord();
          selectedItem.templateId = this.template.templateID;
          selectedItem.selectedRecordId = dataItem.ID;
          this.selectedRecordID = dataItem.ID;
          this.mySelection = [this.selectedRecordID];

          selectedItem.moduleID = this.template.moduleID;
          selectedItem.dependentRecordID = this.template.recordID;
          selectedItem.recordType = this.template.recordType;
          selectedItem.collectionId = this.template.collectionId;
          selectedItem.targetTemplateId = "0";
          selectedItem.componentType = this.componentType;
          this.template.highlightRecordID = dataItem.ID;
          if (this.checkBoxCheckIDslength > 0) {
            selectedItem.filteredRecordsSelected = this.filteredRecords;
          }




          if (this.template.isSplitter == "1" && (this.template.moduleID == "3" || this.template.moduleID == "14")) {
            var splitterData = {};
            splitterData["templateID"] = this.template.templateID;
            splitterData["ID"] = dataItem.ID;
            splitterData["IDs"] = [dataItem.ID];
            splitterData["ProjectID"] = dataItem.ID;
            this.moduleService.passSplitterTemplate(splitterData);

          } else {
            this.moduleService.passDependencyTemplate(selectedItem);

          }


          var breadcrumbs = new breadCrumbs()
          breadcrumbs.id = this.template.templateID;
          if (this.columns.filter(a => a.isBreadCrumbField == "True") != null && this.columns.filter(a => a.isBreadCrumbField == "True") != undefined && this.columns.filter(a => a.isBreadCrumbField == "True").length > 0) {
            breadcrumbs.displayName = dataItem[this.columns.filter(a => a.isBreadCrumbField == "True")[0].fieldName];

          } else {
            breadcrumbs.displayName = "";

          }

          breadcrumbs.templateId = this.template.templateID;

          breadcrumbs.displayControlsID = this.template.displayControlsID;
          breadcrumbs.defaultHeader = (this.template.defaultHeader == null || this.template.defaultHeader == undefined || this.template.defaultHeader == "") ? this.template.tabHeaderName : this.template.defaultHeader;

          breadcrumbs.displayControlsTabID = this.template.displayControlsTabID;
          breadcrumbs.selectedRecordID = this.template.selectedRecordID;
          breadcrumbs.highlightRecordID = this.template.highlightRecordID;


          this.moduleService.passbreadCrumbName(breadcrumbs);
        }
      }

    }
  }

  gridTaskRedirection(dataItem, rowIndex, event, column) {

    localStorage.setItem("reportsProjectID", JSON.stringify(dataItem.ProjectID));
    localStorage.setItem("cryptoProjectID", dataItem.crypto_id);
    localStorage.setItem("projectNameRedirection", dataItem.Project);

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/Projects/' + dataItem.crypto_id + '/' + dataItem.TaskID])
    );
    window.open(url);
  }

  onCheckAll(templateID) {

    var checkBoxCheckIDs = []


    this.filteredRecords = this._gridData.viewData.data;

    var isChecked = false;
    var elementAll = <HTMLInputElement>document.getElementById("chkSelectALL" + templateID);
    if (elementAll != null) {
      isChecked = elementAll.checked;
      this._gridData.viewData.data.forEach(function (value) {

        var element = <HTMLInputElement>document.getElementById("chk" + templateID + value.ID);
        if (element != null) {
          if (isChecked) {
            element.checked = true;
            checkBoxCheckIDs.push(parseInt(value.ID));

          } else {
            element.checked = false;
            checkBoxCheckIDs.splice(parseInt(value.ID));

          }
        }

      });
      /*if (isChecked) { this.checkBoxCheckIDslength = this._gridData.viewData.total;}  
      else { this.checkBoxCheckIDslength = 0 };*/
      this.checkBoxCheckIDslength = checkBoxCheckIDs.length;
    }

    this.checkBoxCheckIDs = checkBoxCheckIDs;

    if (this.template.templateID == 61 || this.template.templateID == 80) {
      //this.checkBoxCheckIDs = [this.filteredRecords.data[0].ID];
      this.mySelection = [this.filteredRecords[0].ID];
      this.moduleService.passReferenceFileIds(this.checkBoxCheckIDs);
    }
    else {
      let selectedItem = new templateSelectedRecord();
      selectedItem.templateId = this.template.templateID;
      selectedItem.selectedRecordId = this.filteredRecords[0].ID;
      this.selectedRecordID = this.filteredRecords[0].ID;



      if (!isChecked) {
        //this.checkBoxCheckIDs = [this.filteredRecords[0].ID];
        this.checkBoxCheckIDslength = this.checkBoxCheckIDs.length;
      }
      selectedItem.selectedRecordIds = this.checkBoxCheckIDs;



      if (this.checkBoxCheckIDs.length == 0) {
        this.mySelection = [this.filteredRecords[0].ID];

      } else {
        this.mySelection = this.checkBoxCheckIDs;

      }
      selectedItem.moduleID = this.template.moduleID;
      selectedItem.dependentRecordID = this.template.recordID;
      selectedItem.componentType = this.componentType;
      selectedItem.recordType = this.template.recordType;
      selectedItem.collectionId = this.template.collectionId;


      if (this.template.isSplitter == "1" && (this.template.moduleID == "3" || this.template.moduleID == "14")) {

        var splitterData = {};
        splitterData["templateID"] = this.template.templateID;
        splitterData["ID"] = this.checkBoxCheckIDs.length == 0 ? this.filteredRecords[0].ID : this.checkBoxCheckIDs[this.checkBoxCheckIDs.length - 1];
        splitterData["IDs"] = this.checkBoxCheckIDs;
        splitterData["ProjectID"] = this.filteredRecords[0].ID;
        this.moduleService.passSplitterTemplate(splitterData);

      } else {
        this.moduleService.passDependencyTemplate(selectedItem);

      }
    }
  }


  moveScroll() {
    setTimeout(() => document.querySelector('.k-state-selected').scrollIntoView());
  }



  onCheck(dataItem, templateID) {

    var isChecked = false;
    this.checkedData = dataItem;

    var element = <HTMLInputElement>document.getElementById("chk" + templateID + dataItem.ID);
    if (element == null) {
      // var isChecked = element.checked;
    }
    else {
      isChecked = element.checked;

      const arr: number[] = [];
      this._gridData.viewData.data.forEach(function (value) {

        var element = <HTMLInputElement>document.getElementById("chk" + templateID + value.ID);


        if (element != null) {
          if (element.checked) {

            arr.push(value.ID);

          }
        }

      });

      this.checkBoxCheckIDs = arr;
      this.checkBoxCheckIDslength = this.checkBoxCheckIDs.length;

      // if (isChecked) {
      //   if (this.checkBoxCheckIDs.indexOf(dataItem.ID) > -1) {
      //    // this.checkBoxCheckIDs.push(dataItem.ID);
      //    alert(this.checkBoxCheckIDs.indexOf(dataItem.ID));
      //     //this.checkBoxCheckIDs.push(dataItem.ID);

      //    if(this.checkBoxCheckIDs>0)
      //     this.checkBoxCheckIDslength = this.checkBoxCheckIDs.length;
      //     /**/
      //   } else {
      //     this.checkBoxCheckIDs.push(dataItem.ID);
      //     this.checkBoxCheckIDslength = this.checkBoxCheckIDs.length;//this.checkBoxCheckIDslength + 1;
      //   }
      // } else {
      //   const index: number = this.checkBoxCheckIDs.indexOf(dataItem.ID);
      //   if (index !== -1) {
      //     this.checkBoxCheckIDs.splice(index, 1);


      //   }
      //   if (this.checkBoxCheckIDslength > 0) {
      //     this.checkBoxCheckIDslength = this.checkBoxCheckIDs.length;//this.checkBoxCheckIDslength - 1;
      //   }
      // }
    }

    var elementAll = <HTMLInputElement>document.getElementById("chkSelectALL" + templateID);


    if (elementAll != null) {
      if (this.checkBoxCheckIDs.length == this._gridData.viewData.data.length) {
        elementAll.checked = true;
      } else {
        elementAll.checked = false;
      }

    }


    if (this.template.templateID == 61 || this.template.templateID == 80 || this.template.templateID == 93)
      this.moduleService.passReferenceFileIds(this.checkBoxCheckIDs);
    else {
      let selectedItem = new templateSelectedRecord();
      selectedItem.templateId = this.template.templateID;
      if (this.checkBoxCheckIDs.length == 1)
        selectedItem.selectedRecordId = this.checkBoxCheckIDs;
      else
        selectedItem.selectedRecordId = dataItem.ID;

      this.selectedRecordID = selectedItem.selectedRecordId;

      selectedItem.selectedRecordIds = this.checkBoxCheckIDs;

      //  this.mySelection = this.checkBoxCheckIDs;
      selectedItem.moduleID = this.template.moduleID;
      selectedItem.dependentRecordID = this.template.recordID;
      selectedItem.componentType = this.componentType;
      selectedItem.recordType = this.template.recordType;
      selectedItem.collectionId = this.template.collectionId;
      this.template.highlightRecordID = dataItem.ID;


      if (this.template.isSplitter == "1" && (this.template.moduleID == "3" || this.template.moduleID == "14")) {
        //Optimized
        var splitterData = {};
        splitterData["templateID"] = this.template.templateID;
        splitterData["ID"] = isChecked ? dataItem.ID : this.checkBoxCheckIDs.length > 1 ? Math.random() : this.checkBoxCheckIDs[0];
        splitterData["IDs"] = this.checkBoxCheckIDs;
        splitterData["ProjectID"] = dataItem.ID;
        this.moduleService.passSplitterTemplate(splitterData);

      } else {
        this.moduleService.passDependencyTemplate(selectedItem);

      }
    }

  }

  onSearchClick() {



    var inputFormFields = [];



    this.columns.filter(a => a.isSearchFilterField == "True").forEach(function (value) {
      inputFormFields.push(parseInt(value.formFieldID));
    });

    this.SelectedRecord = 0
    this.targetModuleID = this.template.moduleID;
    this.targetTemplateID = this.template.templateID;
    this.inputFormFields = inputFormFields.join(',');
    this.showSearch = true;

  }

  closeSearch() {

    this.showSearch = false;

  }
  gridAction(dataItem, action) {
    this.ID = dataItem.ID;
    if (action.actionEvent == "Edit") {

      this.passEditID.emit(dataItem.ID);

    } else if (action.actionEvent == "Audit") {

    } else if (action.actionEvent == "Delete") {

      var ans = confirm("Do you want to delete this record");
      if (ans) {
        this.showLoader = true;
        this.moduleService.DeleteDisplayFieldRecord(dataItem.ID, this.GV.UserId).subscribe(data => {
          this.loadTemplate();
        });
      }


    }
    else if (action.actionEvent == "DeleteDocument") {
      this.ReferenceID = dataItem.ID;
      const dialog: DialogRef = this.dialogService.open({
        title: this.languageService.GetActionMessageByLanguage('LGT_CONH', "confirmation"),
        content: this.languageService.GetActionMessageByLanguage('STC_CDTD', 'Do you want to delete this document?'),
        actions: [
          { text: this.languageService.GetActionMessageByLanguage('NO', 'NO') },
          { text: this.languageService.GetActionMessageByLanguage('YES', 'YES'), primary: true }
        ],
        width: 350,
        height: 150,
        minWidth: 200,
        cssClass: "custom-css-class",

        closeTitle: this.languageService.GetActionMessageByLanguage('STC_CLOSE', 'Close')
      });
      dialog.result.subscribe((result) => {

        if (result["text"] == this.languageService.GetActionMessageByLanguage('YES', 'YES')) {
          //code for popup end
          this.moduleService.DeleteReferencefiles(dataItem.ID.toString(), this.template.selectedRecordID, this.GV.UserId, this.IpAddress).subscribe(data => {
            if (data.status == "success") {
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_RFDS', "Reference file(s) deleted successfully"), 1);
              this.loadTemplate();
            }
            else
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_PRFCND', " File(s) are project related, you can't delete."), 4);
          });
        }
      });


    }
    else if (action.actionEvent == "SubProcess Edit") {

      this.showPopUp = true;
      this.RecordID = dataItem.ID;

      this.showLoader = true;
      this.dataService.getDynamicFormFields(11, dataItem.ID, 0, true, "", "", false).subscribe(response => {

        this.dynTemplateHeader = response.dynTemplateHeader;
        this.DynamicFields = response.dynamicFormFieldList;
        this.DynamicFormFieldsActions = response.displayActions;
        this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
        this.showLoader = false;
      });
    }

    else if (action.actionEvent == "View") {
      this.showPopUp = true;
      this.moduleService.DisplayView(dataItem.ID).subscribe(data => {
        this.viewRecords = data;
      })
    }
    else if (action.actionEvent == "btnOpenProject") {

      var selectReport = new OpenReportType();
      selectReport.type = action;
      selectReport.dataItem = JSON.stringify(dataItem);
      localStorage.setItem("reportsSelectedData", JSON.stringify(selectReport));

      localStorage.setItem("reportsProjectID", JSON.stringify(dataItem.ProjectID));
      localStorage.setItem("cryptoProjectID", dataItem.crypto_id);
      localStorage.setItem("projectNameRedirection", dataItem.Name);
      const url = this.router.serializeUrl(


        this.router.createUrlTree(['/Projects/' + dataItem.crypto_id])
      );

      window.open(url);
    }
    else if (action.actionEvent == "btnOpenTask") {

      localStorage.setItem("reportsProjectID", JSON.stringify(dataItem.ProjectID));
      localStorage.setItem("cryptoProjectID", dataItem.crypto_id);
      localStorage.setItem("projectNameRedirection", dataItem.Project);

      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/Projects/' + dataItem.crypto_id + '/' + dataItem.ProcessDataID])
      );

      window.open(url);
    }

    else if (action.actionEvent == "btnPreview") {
      this.showLoader = false;
      this.loader.setLoading(false);
      this.moduleService.ConvertTOPDFView(dataItem.File, dataItem.FilePath).subscribe(response => {
        var filee = getBaseUrl() + "Documents/FilesAITOPDF/" + response;
        window.open(filee, '_blank');
      });
      this.loader.setLoading(false);
    }
  }
 

  FromAction(action: any) {
    if (action.actionEvent == "Submit")
      this.onSubmit();
    else
      this.cancel();
  };

  public clearEventHandler(): void {

    this.uploadedFiles = [];
  }


  onSubmit() {
    if (this.dynamicForm.invalid) {
      this.invalidSubmitAttempt = true;
      return;
    }

    if (this.uploadedFiles.length > 0 && this.uploadedFiles != undefined) {
      let uploadFields = Object.keys(this.uploadedFiles);
      for (let i of uploadFields) {
        this.dynamicForm.value[i] = this.uploadedFiles[i];
      }
    }

    //console.log(this.dynamicForm.value);
    this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);

    this.templateData.ID = this.ID !== undefined ? parseInt(this.ID) : this.ID;
    this.templateData.TemplateID = parseInt(this.templateID);
    this.showLoader = true;
    this.templateData.UploadFileType = this.GV.GetFileType();
    this.dataService.saveDynamicFormFieldsdata(this.templateData).subscribe(responce => {
      this.showLoader = true;
      this.showPopUp = false;
      this.loadTemplate();
      this.uploadedFiles = [];
      // this.cancel();
    })

  }

  cancel() {
    this.IsDisplayView = false;
    this.displayViewID = 0;

    this.getDisplaycontrolRecords();

  }

  getDisplaycontrolRecords() {
    this.showLoader = true;
    this.templateParentID = 12;
  }

  CloseSubDisplayForm() {
    this.templateParentID = 0;
    this.moduleService.passparentRecordID(0);
    this.IsDisplaySubFormView = false;
  }
  onKeypressEvent(event: any) {

    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }


  onClearSearh() {
    this.template.searchName = ''
    this.template.searchFilters = "";
    this.SearchName = "";

    this.keyWordSearch = "";
    this.loadTemplate();
  }

  public onSearch(inputValue: string): void {
    this.keyWordSearch = inputValue

    let filters = [];
    this.columns.forEach(function (value) {
      let objFilter = new filter();
      objFilter.field = value.fieldName;
      objFilter.operator = "contains";
      objFilter.value = inputValue;
      filters.push(objFilter);
    });
    this.filteredRecords = this._gridData.viewData.data = process(this.records, {
      filter: {
        logic: "or",
        filters: filters
      }, group: this.groups
    }).data;
    if (inputValue.length > 0) {
      this._gridData.viewData.total = this._gridData.viewData.data.length;
    }
    else {
      if (this.templateID != 39) {
        this._gridData.viewData.total = this.records[0].Total;
      }
      else {
        this._gridData.viewData.total = this.total;
      }
    }

  }
  /*public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredRecords = this._gridData.viewData.data = filterBy(this.records, filter);
    this._gridData.viewData = process(this.filteredRecords, { group: this.groups });

    if (this.filter.filters.length > 0)
      this._gridData.viewData.total = this._gridData.viewData.data.length;
    else {
      if (this.templateID != 39) {
        this._gridData.viewData.total = this.records[0].Total;
      }
      else {
        this._gridData.viewData.total = this.total;
      }


    }

    // this._gridData.viewData = filterBy(this._gridData.viewData, filter);

  }*/
  dynHeadingName: string = "";
  ShowChatWindow: boolean = false;
  addEditPopup: string = "none"
  closechatModalDialog() {
    this.addEditPopup = "none";
    this.ShowChatWindow = false;
  }
  GroupDyForm = this.formbuilder.group({
    selectedNewUser: ['', Validators.required],
  });
  isInvalid: boolean = false;
  ReassignUsersConfrimopened: boolean = false;
  dsNewUserList: any = [];
  closeReassignUsers() {
    this.ReassignUsersConfrimopened = false;
    this.isInvalid = false;
    this.GroupDyForm.controls['selectedNewUser'].setValue('');
  }
  SaveReassignUsers() {
    if (this.GroupDyForm.invalid) {
      this.isInvalid = true;
      return false;
    }
    else {
      if (this.checkBoxCheckIDs.length > 0) {
        localStorage.setItem("SelectedTasks", this.checkBoxCheckIDs);
        this.showLoader = true;
        this.templateData.UserID = +this.GroupDyForm.controls['selectedNewUser'].value;
        localStorage.setItem('proxyUserId', this.templateData.UserID.toString());
        this.templateData.ModuleID = this.moduleID;
        this.templateData.LoginUserId = this.loginUserId;
        let SelectedTakIds = this.checkBoxCheckIDs.join(",");
        this.templateData.SelectedTakIds = SelectedTakIds;
        this.templateData.Ip = this.IpAddress;
        this.DFS.saveReassigneUserData(this.templateData).subscribe(responce => {
          this.showLoader = false;
          if (responce == "success") {

            this.closeReassignUsers();

            if (this.templateData.ModuleID != 35) {
              this.notifyService.show("Tasks Reassigned successfully.", 1);
              this.moduleService.passReferenceFileIds([]);
              this.loadTemplate();

              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoReassignTasksUser, 1, 0, 0, SelectedTakIds, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
            }
          }
        })
      }
    }
  }

  closeUnassignTasks() {
    this.unassginConfrimopened = false;
    this.isInvalid = false;
    this.GroupDyForm.controls['selectedNewUser'].setValue('');
  }
  unAssignTask(dataItem: any) {
    this.unassginConfrimopened = true;
    this.templateData.ModuleID = this.moduleID;
    this.templateData.LoginUserId = this.loginUserId;
    this.templateData.UserID = 0;
    this.templateData.SelectedTakIds = dataItem.ID.toString();

  }
  DeleteTasks() {
    this.showLoader = true;
    this.unassginConfrimopened = false;
    this.DFS.saveReassigneUserData(this.templateData).subscribe(responce => {
      this.showLoader = false;
      this.loadTemplate();
    });
  }
  listGridTemplateAction(evt) {//debugger

    if (evt.actionEvent == "AddReferenceFile") {

      //this.dynHeadingName=evt.templateName;
      this.dynHeadingName = this.languageService.GetActionMessageByLanguage(evt.alias, evt.templateName);
      this.SelectedRecord = this.recordID;
      this.targetModuleID = evt.moduleID;
      this.targetTemplateID = evt.templateID;
      this.inputFormFields = evt.inputFormFields;
      this.addEditPopup = "block";
      this.ShowChatWindow = true;
    }
    else if (evt.actionEvent == "btnAssignUsers") {
      this.dynHeadingName = this.languageService.GetActionMessageByLanguage(evt.alias, evt.templateName);

      if (this.checkBoxCheckIDslength > 0) {

        this.showLoader = true;
        if (this.templateID == 93) {
          this.moduleService.GetTaskOwnerGroupUsers(7, 0, this.GV.UserId, this.checkBoxCheckIDs.join(",")).subscribe(data => {
            this.dsNewUserList = data;
            this.showLoader = false;


          },
            error => {
              this.showLoader = false;
              this.notifyService.show("Something went wrong. Try again !!. " + error.statusText, 4);
              //console.log(error);
            })
        }
        else {
          this.moduleService.GetTaskOwnerGroupUsers(6, 0, this.GV.UserId, this.checkBoxCheckIDs.join(",")).subscribe(data => {
            this.dsNewUserList = data;
            if (this.dsNewUserList.length == 0) {
              this.notifyService.show("Please select the same task owner group tasks.", 4);
            }
            else {
              this.ReassignUsersConfrimopened = true;
            }
            this.showLoader = false;
          },
            error => {
              this.showLoader = false;
              this.notifyService.show("Something went wrong. Try again !!. " + error.statusText, 4);
              //console.log(error);
            })
        }
      }
      else {
        this.notifyService.show("Please select required Tasks to Reassign", 4);
      }
    }
    else if (evt.actionEvent == "DownloadAllReferenceFile") {
      /**/
    }
    else if (evt.actionEvent == "DeleteAllReferenceFile") {
      this.loadTemplate();
    }
  }
  cancelprojectReference(evtnt) {
    if (evtnt == true) {
      this.addEditPopup = "none";
      this.ShowChatWindow = false;
      this.loadTemplate();
    }
    else {
      this.addEditPopup = "none";
      this.ShowChatWindow = false;
    }
  }

  onExcelExport(template) {


    if (template.moduleID == 30) {
      this.checkBoxCheckIDs = [];
    }
    this.showLoader = true;
    if (this.name == undefined) {
      this.name = "Default";
    }

    this.moduleService.GetDisplayFieldData(this.templateID, this.recordID, this.template.displayControlsID, this.template.recordType, 1, this.template.searchFilters, this.name, 0, 10, this.keyWordSearch, this.template.chkFields, this._gridData.filter).subscribe(data => {

      if (data.record == null && data.columns == null && data.displayActions == null && data.displayProperties == null) {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW', "Something went wrong. Please try again "), 4);
        this.showLoader = false;
        this.loader.setLoading(false);
        return;
      }

      //this.inventory.filter(x => value.includes(x.GroupId));
      //var filterIDS = this.filteredRecords.map(ele => ele.ID);

      if ((this.keyWordSearch == null || this.keyWordSearch == '' || this.keyWordSearch == undefined) && (this.filter == undefined || this.filter.filters.length <= 0)) {

        if (this.checkBoxCheckIDs.length > 0) {

          this.parseData = JSON.parse(data.record);
          this.excelRecords = [];

          for (let i = 0; i <= this.checkBoxCheckIDs.length - 1; i++) {
            for (let j = 0; j <= this.parseData.length - 1; j++) {

              if (this.checkBoxCheckIDs[i] == this.parseData[j].ID) {

                this.excelRecords.push(this.parseData[j]);
              }
            }
          }
        }

        else {
          this.excelRecords = JSON.parse(data.record);
        }
      }
      else if (this._gridData.filter != undefined && this._gridData.filter.filters != undefined && this._gridData.filter.filters.length > 0) {
        this.excelRecords = JSON.parse(data.record);
      }
      else
        this.excelRecords = this._gridData.viewData.data;



      this.excelColumns = data.columns;

      if (this.excelColumns == null || this.excelColumns.length == 0) {

        this.commonfeaturesservice.GetAllActionsMessages(4, 0, 0, 999, "NCCFEE", this.CompanyID).subscribe((dataCDActionsMessages) => {
          this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
        });
        this.showLoader = false;
        return;
      }


      var rows = [];
      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];


      var columnName = [];

      ///Downloaded by

      var headerCell = new headerCellClass();
      headerCell.value = this.languageService.GetActionMessageByLanguage('STC_DLDBY', "Downloaded By");
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      var headerCell = new headerCellClass();
      headerCell.value = this.Name + '(' + this.GV.UserName + ')'; //this.GV.UserName;
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      //Downloaded Date

      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];
      var headerCell = new headerCellClass();
      headerCell.value = this.languageService.GetActionMessageByLanguage('STC_DLDDT', "Downloaded Date");
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      var headerCell = new headerCellClass();

      var CurrentDate = new Date();
      headerCell.value = CurrentDate.toString();;
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);


      //Filter Name 

      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];
      var headerCell = new headerCellClass();
      headerCell.value = this.languageService.GetActionMessageByLanguage('STC_FLTNM', "Filter Name");
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      var headerCell = new headerCellClass();

      var CurrentDate = new Date();
      headerCell.value = "[" + this.languageService.GetActionMessageByLanguage('STC_KEYSCH', 'Keyword Search') + " : " + this.keyWordSearch + "] [" + this.languageService.GetActionMessageByLanguage('STC_SCHNM', "Search Name") + " : " + this.SearchName + "]";
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);


      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];
      //binding column name 
      this.excelColumns.filter(a => a.isExcelExportField == "True").forEach(function (value) {
        var headerCell = new headerCellClass();
        headerCell.value = this.GetTranslatedExcelColumnName(this.templateID, value.formFieldID, value.workFlowTaskId, value.displayName); //value.displayName;
        headerCell.bold = true;
        headerCell.background = "#227447"
        headerCell.color = "#ffffff"

        headerRowcells.push(headerCell);

        columnName.push(value.fieldName);

      }.bind(this));

      headerRow.cells = headerRowcells;
      rows.push(headerRow);


      //binding data
      this.excelRecords.forEach(function (datavalue) {

        var headerRow = new headerRowClass();
        var headerRowcells: headerCellClass[] = [];
        columnName.forEach(function (columnvalue) {
          var headerCell = new headerCellClass();
          headerCell.value = datavalue[columnvalue];
          headerCell.bold = false;
          headerRowcells.push(headerCell);

        });

        headerRow.cells = headerRowcells;
        rows.push(headerRow);
      });
      let excelName = this.GetTranslatedFileName() + " " + new Date();
      const workbook = new Workbook({
        sheets: <WorkbookSheet[]>[
          {
            // Column settings (width)
            columns: [
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },

            ],
            // Title of the sheet            
            //name: template.templateName,
            name: excelName,
            // Rows of the sheet
            rows: rows,
          },
        ],
      });

      this.commonfeaturesservice.GetAllActionsMessages(4, 0, 0, 999, "EEDS", this.CompanyID).subscribe((dataCDActionsMessages) => {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
      });

      workbook.toDataURL().then((dataUrl) => {
        saveAs(dataUrl, excelName + ".xlsx");
      });





      this.showLoader = false;


    });
  }

  DownloadArtWorkPdfFile(Filepath: string, FileName: string, ProcessDataID: number) {
    this.IpAddres = this.GV.UserIpAddress;
    this.FileName = FileName;
    this.moduleService.DownloadPdfFile(Filepath, FileName, ProcessDataID, 0, this.GV.UserId, this.IpAddres).subscribe((event) => {

      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event, FileName);

      }

    });


  }
  async downloadFile(data: HttpResponse<Blob>, FileName: any) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = FileName;//.substring(1).slice(0,-1);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }
  async downloadPDF(data: HttpResponse<Blob>, FileName: any) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const URLSaving = URL.createObjectURL(downloadedFile);
    const pdfData = await this.http.get(URLSaving, { responseType: 'arraybuffer' }).toPromise();
    const pdfDoc = await PDFDocument.load(pdfData);
    pdfDoc.setTitle(FileName);
    const modifiedPdfData = await pdfDoc.save();
    saveAs(new Blob([modifiedPdfData], { type: 'application/pdf' }), FileName);
  }



  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.ResponseData, {
      }).data,

    };

    return result;

  }
  AuditLogData() {
    this.AuditLogId = true;
    this.amService.AuditHistory(-1, 100, this.GV.UserId, 1, this.TZoffset).subscribe((data) => {

      this.ResponseData = data;
      if (this.ResponseData.length > 0) {
        for (let i of this.ResponseData) {
          i.eventDate = this.DateTimeService.convertdate(i.eventDate);
        }
      }
      this.divHisdata = true;
      this.isFromDialog = true;
      var AuditDisplay = "";
      if (data.length > 0) {
        this.AuditexcelBtn = true;

      }
      else {
        AuditDisplay = AuditDisplay + "No audit data for this record.";
        this.AuditexcelBtn = false;
      }



    });
  }
  GetTranslatedExcelColumnName(templateId, formFieldId, workFlowTaskId, OrgVal) {
    if (this.languageService.dataByLanguage.find(x => x.TemplateID == templateId && x.FormFieldID == formFieldId && x.WorkFlowTaskID == workFlowTaskId && x.Type == 2)) {
      return this.languageService.dataByLanguage.find(x => x.TemplateID == templateId && x.FormFieldID == formFieldId && x.WorkFlowTaskID == workFlowTaskId && x.Type == 2).Message;
    } else {
      return OrgVal;
    }
  }

  getClassName(name): string {

    var ClassName = "";


    ClassName = name.replace(/\s/g, "");
    return ClassName;



  }

  auditclose() {

    this.AuditLogId = false;
    this.closeAudithistorymodel.emit(false);
  }

  getColumnClassName(column, dataItem): string {
    var className = "";
    if (column.isCommonClass == "True") {
      className = column.classess;
    } else {
      //this.moduleService.GetClassName(column.fieldName, dataItem[column.fieldName], column.metadataLookUpId).subscribe(data => {
      //  className = data;
      //});
      if (column.templateID == "97") {
        className = dataItem[column.fieldName].replace(/\([^)]*\)/g, '').replace(/\s+/g, '');
      }
      else if (dataItem[column.fieldName] != null) {
        className = dataItem[column.fieldName].replace(/\s/g, "");
      }

    }


    return className;

  }
  GetTranslatedFileName() {
    let excelName = '';
    if (this.languageService.dataByLanguage.find(x => x.TemplateID == this.templateID && x.Type == 7)) {
      let excelNameObj = this.languageService.dataByLanguage.find(x => x.TemplateID == this.templateID && x.Type == 7);
      if (excelNameObj.HelpText)
        excelName = excelNameObj.HelpText;
      else
        excelName = this.template.templateName;
    } else {
      excelName = this.template.templateName;
    }
    return excelName;
  }


  IsKeyWordSearched: boolean = false;

  public onKeyWordSearch() {

    this.IsKeyWordSearched = true;

    this.skip = 0;
    this.take = 10;
    this.template.keyWordSearch = this.keyWordSearch;
    this.template.filter = this.filter;

    this.loadTemplate();


    if (this.keyWordSearch == "" || this.keyWordSearch == null || this.keyWordSearch == undefined) {
      this.IsKeyWordSearched = false;


    }
  }


  public onClearKeyWordSearch() {
    this.IsKeyWordSearched = false;
    this.skip = 0;
    this.take = 10;
    this.keyWordSearch = "";
    this.template.keyWordSearch = "";
    this.loadTemplate();
  }

  showFilter: boolean = false;
  toggleFilter() {
    this.showFilter = !this.showFilter;

  }

  getShortName(strText) {
    var strTxt = "";
    if (strText.length >= 50)
      strTxt = strText.slice(0, 50).concat('...');
    else
      strTxt = strText;
    return strTxt;
  }

}



export class filter {

  field: string;
  operator: string;
  value: string;
}

class DynamicTemplateData {
  ID: number;
  DynFormFieldData: string;
  TemplateID: number;
  UploadFileType: number = 0;
  SelectedTakIds: string = "";
  UserID: number = 0;
  ModuleID: number = 0;
  LoginUserId: number = 0;
  Ip: string;
}
class Tasksmodel {


  id: number;
  // Alias:string;
  TaskName: string;
  Taskcount: number;
  classname: string;
  actcls: string;
  icon: string;
  Alias: string;



}
export class gridViewData {
  public pageSize: number = 10;
  public skipPage: number = 0;
  public viewData: any = {};
  public filter: any = null;
}
export class OpenReportType {
  type: string;
  dataItem: string;
}
