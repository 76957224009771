import { ModuleService } from '../../services/module.service';

import { Component, Input, OnInit } from '@angular/core';
import * as shape from 'd3-shape';
import { colorSets } from '../../color-sets';
import { Node } from '@swimlane/ngx-graph';
import { breadCrumbs } from '../../interfaces/ItemplateFields';
import { Subject } from 'rxjs';
import { GlobalVeriables } from '../../Global/GlobalVaribales';

@Component({
  selector: 'app-dynamic-workflow-tasks-diagram',
  templateUrl: './dynamic-workflow-tasks-diagram.component.html',
  styleUrls: ['./dynamic-workflow-tasks-diagram.component.css']
})
export class DynamicWorkflowTasksDiagramComponent implements OnInit {
  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;
  @Input() public componentType: any;
  @Input() public subProcessId: any;
  // Observable for update 
  update$: Subject<any> = new Subject();
	mZoomToFit$: Subject<any> = new Subject();
  center$: Subject<boolean> = new Subject();
  margintop:any;
  marginBottom:any
  hierarchicalGraph = { nodes: [], links: [] };
  view: any[];
  width = 600;
  height = 500;
  breadcrumbs: Array<breadCrumbs>;

  zooomLevel:any= 1
  panXposition:number;
  panYposition:number
  fitContainer = true;
  autoZoom = true;
  panOnZoom = true;
  enableZoom = true;
  autoCenter = true;
  isFullScreenMode:boolean=false;
  
  sampleNodes = [{ 'id': '1', 'label': 'Project ID Automation', 'color': '#29CC5C', 'owner': 'All Access User' }, { 'id': '2', 'label': '[Molecule Name - Component] [Dosage form] [Strength] [Pack Size] [Type of Component] [Market - Component] [Customer]', 'color': '#29CC5C', 'owner': 'All Access User' }, { 'id': '3', 'label': 'Print Proof Upload\r\n', 'color': '#29CC5C', 'owner': 'All Access User' }, { 'id': '4', 'label': 'Share Approved PDF with Printer\r\n', 'color': '#29CC5C', 'owner': 'All Access User' }, { 'id': '5', 'label': 'Submit\/Upload Artwork for Approval', 'color': '#29CC5C', 'owner': 'All Access User' }, { 'id': '6', 'label': 'Proof Reader Approval', 'color': '#29CC5C', 'owner': 'All Access User' }, { 'id': '7', 'label': 'Packaging Site Technical Approval', 'color': '#29CC5C', 'owner': 'All Access User' }, { 'id': '8', 'label': 'Workflow Subprocess Identifier Automation', 'color': '#29CC5C', 'owner': '' }, { 'id': '9', 'label': 'Auto Transfer to DAL', 'color': '#29CC5C', 'owner': '' }, { 'id': '10', 'label': 'Complete Request', 'color': '#29CC5C', 'owner': 'All Access User' }];
  sampleLinks = [{ 'source': '1', 'target': '2' }, { 'source': '2', 'target': '3' }, { 'source': '2', 'target': '4' }, { 'source': '3', 'target': '5' }, { 'source': '4', 'target': '5' }, { 'source': '5', 'target': '6' }, { 'source': '6', 'target': '7' }, { 'source': '7', 'target': '8' }, { 'source': '7', 'target': '9' }, { 'source': '8', 'target': '10' }, { 'source': '9', 'target': '10' }];
  showLegend = true;
  orientation = 'LR'; // LR, RL, TB, BT
  orientations: any[] = [
    {
      label: 'Left to Right',
      value: 'LR',
    },
    {
      label: 'Right to Left',
      value: 'RL',
    },
    {
      label: 'Top to Bottom',
      value: 'TB',
    },
    {
      label: 'Bottom to Top',
      value: 'BT',
    },
  ];

  // line interpolation
  curveType = 'Linear';
  curve = shape.curveBundle;//.beta(1);
  interpolationTypes = [
    'Bundle',
    'Cardinal',
    'Catmull Rom',
    'Linear',
    'Monotone X',
    'Monotone Y',
    'Natural',
    'Step',
    'Step After',
    'Step Before',
  ];

  colorSchemes: any;
  colorScheme: any;
  schemeType = 'ordinal';
  selectedColorScheme: string;
  LegendData: any[] = [];
  constructor(private moduleService: ModuleService, private GV: GlobalVeriables,) {
    Object.assign(this, {
      colorSchemes: colorSets,
    });
    this.setColorScheme('picnic');
    this.setInterpolationType('Bundle');
  }
  showLoader:boolean=false;
  treelist:any;
  ngOnInit() {
    this.GetProjectTaskList(this.recordID);
    this.moduleService.breadCrumbs.subscribe(breadcrumbs => {

      var depeendentTemplateID = this.template.isDependent;
      var parentTemplateID = this.template.hasParent;

      this.breadcrumbs = breadcrumbs.filter(a => a.templateId == depeendentTemplateID || a.templateId == parentTemplateID);//|| (a.templateId == templateID && a.displayControlsID == displayControlsID)
    });


    this.moduleService.breadCrumbName.subscribe(breadcrumb => {
      if (!this.GV.GetIsRedirection())
      if (breadcrumb.displayName != "" && breadcrumb.displayName != undefined) {


        this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID)[0].displayName = breadcrumb.displayName;

      }


    });
  }
  GetProjectTaskList(ProjectID) {
    this.showLoader = true;
    this.subProcessId = this.subProcessId == undefined ? 0 : this.subProcessId;
    this.moduleService.GetWorkFlowDiagramDataByProjectID(this.recordID, 0, this.subProcessId).subscribe(data => {
      this.sampleNodes = data.Table;
      this.sampleLinks = data.Table1;
      this.hierarchicalGraph.nodes = this.sampleNodes;
      this.hierarchicalGraph.links = this.sampleLinks;
      let templegendstatus: any[] = [];
      

      if (this.sampleNodes.length > 0) {
        templegendstatus = data.Table.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj['workflowstatus']).indexOf(obj['workflowstatus']) === pos;
        });
        for (let i of templegendstatus) {
          this.LegendData.push({ "workflowstatus": i.workflowstatus, "color": i.color });
      }
      
    }
      if (!this.fitContainer) {
        this.applyDimensions();
      }
      this.updateChart();
      this.showLoader = false;
   })
  }
  // Update function
  updateChart() {
    this.update$.next(true);
  }

  zooomLevelFunction()
  {
  }

  applyDimensions() {
    this.view = [this.width, this.height];
  }

  toggleEnableZoom(enableZoom: boolean) {
    this.enableZoom = enableZoom;
  }

  toggleFitContainer(
    fitContainer: boolean,
    autoZoom: boolean,
    autoCenter: boolean
  ): void {
    this.fitContainer = fitContainer;
    this.autoZoom = autoZoom;
    this.autoCenter = autoCenter;

    if (this.fitContainer) {
      this.view = undefined;
    } else {
      this.applyDimensions();
    }
  }

  nodeselect(data) {
    this.onListFieldAction(data);
  }
  //onListFieldAction(data) {
  //    let selectedItem = new templateSelectedRecord();
  //    selectedItem.templateId = this.template.templateID;
  //    selectedItem.selectedRecordId = data.workflowtaskid;
  //    selectedItem.selectedRecordIds = data.workflowtaskid;

  //    selectedItem.moduleID = this.template.moduleID;
  //    selectedItem.highlightRecordID = data.workflowtaskid;

  //    selectedItem.dependentRecordID = this.template.recordID;
  //    selectedItem.recordType = this.template.recordType;
  //    selectedItem.collectionId = this.template.collectionId;
  //    selectedItem.targetTemplateId = "0";



  //    selectedItem.componentType = this.componentType;
  //    this.template.highlightRecordID = data.workflowtaskid;
  //    this.moduleService.passDependencyTemplate(selectedItem);


  //  var breadcrumbs = new breadCrumbs()
  //  breadcrumbs.id = this.template.templateID;
  //  breadcrumbs.displayName = data.label;
  //  breadcrumbs.templateId = this.template.templateID;

  //  breadcrumbs.displayControlsID = this.template.displayControlsID;
  //  breadcrumbs.defaultHeader = (this.template.defaultHeader == null || this.template.defaultHeader == undefined || this.template.defaultHeader == "") ? this.template.tabHeaderName : this.template.defaultHeader;

  //  breadcrumbs.displayControlsTabID = this.template.displayControlsTabID;
  //  breadcrumbs.selectedRecordID = this.template.selectedRecordID;
  //  breadcrumbs.highlightRecordID = this.template.highlightRecordID;


  //  this.moduleService.passbreadCrumbName(breadcrumbs);
  //}


  onListFieldAction(data) {

    const dataitem = {

      "ID": data.workflowtaskid,

      "SubProcessID": 0,

      "ProjectID": this.template.recordID
    }
    this.moduleService.passLoadTask(dataitem);
  }

  setColorScheme(name) {
    this.selectedColorScheme = name;
    let result: any[] = [];
    this.colorScheme = {
      domain: []
    };
  }
  
  customColors = (value) => {
    if (value == 'Project ID Automation')
      return "#007bff";
    else
      return "#29CC5C";
  }
  public getStyles(node: Node, workflowstatus:any): any {
    let hnumber: number = 100
    if (workflowstatus == "Completed" || workflowstatus == "Approved" || workflowstatus == "Pending" || workflowstatus == "Rejected"
      || workflowstatus == "In Progress" || workflowstatus == "Bypassed" || workflowstatus == "Suspended" || workflowstatus == "Cycle Suspended"     ) {
      hnumber = 120
      if (node.label.length <= 30)
        hnumber = 75;
      else if (node.label.length <= 70)
        hnumber = 85;
    }
    else {
      if (node.label.length <= 30)
        hnumber = 75;
      else if (node.label.length <= 70)
        hnumber = 85;
    }
    return {
      "height": hnumber,
    }
  }
  public getStylelegendcolor(lcolor: string):any {
    return { "background-color": lcolor,"width":20,"height":20 }
  }
  setInterpolationType(curveType) {
    this.curveType = curveType;
    if (curveType === 'Bundle') {
      this.curve = shape.curveBundle;
    }
    if (curveType === 'Cardinal') {
      this.curve = shape.curveCardinal;
    }
    if (curveType === 'Catmull Rom') {
      this.curve = shape.curveCatmullRom;
    }
    if (curveType === 'Linear') {
      this.curve = shape.curveLinear;
    }
    if (curveType === 'Monotone X') {
      this.curve = shape.curveMonotoneX;
    }
    if (curveType === 'Monotone Y') {
      this.curve = shape.curveMonotoneY;
    }
    if (curveType === 'Natural') {
      this.curve = shape.curveNatural;
    }
    if (curveType === 'Step') {
      this.curve = shape.curveStep;
    }
    if (curveType === 'Step After') {
      this.curve = shape.curveStepAfter;
    }
    if (curveType === 'Step Before') {
      this.curve = shape.curveStepBefore;
    }
  }

  onLegendLabelClick(entry) {
    /**/
  }

  toggleExpand(node) {
    /**/
  }


  zoomToFit() {
    //this.center$.next(true)

    this.zooomLevel = 1

  }

  center() {
    /**/
  }
  onResize(event) {
    this.view = [event.target.innerWidth / 50, 400];
  }
  getShortName(strText) {
    var strTxt = "";
    if (strText.length >= 40)
      strTxt = strText.slice(0, 40).concat('...');
    else
      strTxt = strText;
    return strTxt;
  }

  zoomIn()
  {
      this.zooomLevel += 0.5
  }
  zoomOut()
  {
    if(this.zooomLevel== 0.5)
    {
      return 
    }
    else
    {
    this.zooomLevel -= 0.5
    }

  }

  toggleFullScreen() {
    if(this.isFullScreenMode)
     this.isFullScreenMode=false;
    else
     this.isFullScreenMode=true;
 
   }
   
}

export class templateSelectedRecord {

  moduleID: string = "0";
  templateId: string = "0";
  selectedRecordId: number = 0;
  selectedRecordIds: any = [];
  dependentRecordID: string = "0";

  highlightRecordID: number = 0;
  targetTemplateId: string = "0";
  componentType: string = "1";//1-dynamic form view  2-template view componet
  collectionId: string = "0";//For maintaining id for  remove collection 

  recordType: number = 1;//using for dal to identiry file colleciton selection or treview node selection---tree view=1
}
