import { Component, OnInit } from '@angular/core';
import { GlobalVeriables } from '../../Global/GlobalVaribales';
import { getBaseUrl } from '../../../main';
import { AdminUserManagementService, GetAuditAllFieldName, DateConvertions } from '../../services/adminusermanagement.service';
import { PlaceHolderData } from '../../services/AMFormsDataDetails';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { PagerPosition } from '@progress/kendo-angular-grid';
import { NotificationAlertsService } from 'src/app/services/notification.service';
import { CommonFeaturesService } from 'src/app/services/commonfeatures.service';
import { LanguageService } from 'src/app/services/language.service';
import { Workbook, WorkbookSheet} from "@progress/kendo-angular-excel-export";
import { saveAs } from "@progress/kendo-file-saver";
import { CompositeFilterDescriptor, filterBy} from '@progress/kendo-data-query';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModuleService } from 'src/app/services/module.service';

@Component({
  selector: 'app-placeholderfiles',
  templateUrl: './placeholderfiles.component.html',
  styleUrls: ['./placeholderfiles.component.css'],
  providers: [GetAuditAllFieldName, NotificationAlertsService, DateConvertions]
})
export class PlaceholderfilesComponent implements OnInit {
  public buttonCount = 5;
  artWorkReviewURL: SafeResourceUrl;

  public info = true;
  public pageSizes = [5,10,20,100,200];
  public previousNext = true;
  public position: PagerPosition = "bottom";
  PlaceHolderComponents: string;
  physicalFilePath: string;
  filePath: string;
  CompanyID: number = 0;
  addScreen: boolean = false;
  viewPlaceHolderFile: boolean = false;
  IsDisable: boolean = false;
  btnDisable: boolean = true;
  PlaceHolderAllData: PlaceHolderData[] = [];
  placeholderData:any=[];
  savingforFilter:any=[];
  public deletePopup = false;
  submitted: boolean=false;
  PlaceHolderID:number;
  auditData:any;
  excelRecords:any;
  userName=this.GV.UserName;
  columnNames:any=["File Name","File Path","Physical File Path","Thumbnail File Path","File Size"]
  placeholderAudit:boolean=false;
  timeStamp: Date = new Date();
  pdfData = new PDFDownloadData;
  pdfDataList : PDFDownloadData[] = [];
  fileRestrictions: FileRestrictions = {
    allowedExtensions: [".pdf",],
  };
  uploadSaveUrl = getBaseUrl() + "api/adminmanagement/SavePlaceHolderDoc";
  uploadRemoveUrl = getBaseUrl()+ "api/adminmanagement/removePlaceHolderDoc";
  filteredRecords: any;
  filter: CompositeFilterDescriptor;
  gridData: any;
  iframeSource:SafeResourceUrl;
  FileNameDisplay: string;

  constructor(private GV: GlobalVeriables,private notification:NotificationAlertsService,private notifications: NotificationAlertsService,private DisplayAllFields: GetAuditAllFieldName ,private formBuilder:FormBuilder ,
    private dataservice: AdminUserManagementService,private DateTimeService: DateConvertions,
    private http: HttpClient, private languageService: LanguageService, private av_router: ActivatedRoute, private modelService:ModuleService, private router: Router, private commonfeaturesservice: CommonFeaturesService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.PlaceHolderComponents = getBaseUrl() + "Documents/PlaceHolder/";
    this.IsDisable = false;
    this.btnDisable = true;
    this.CompanyID = +localStorage.getItem("usercompanyID");
    this.GV.UserId = +localStorage.getItem("userId");
    this.GetAllPlaceHolderFilesData();
  }

  placeholderForm = this.formBuilder.group({
    txtplaceHolder: ['',[Validators.required]]
  })

  GetAllPlaceHolderFilesData() {
    this.dataservice.GetAllPlaceHolderFilesData(0, 0, 1).subscribe(data => {
      debugger;
      this.gridData=data;
      this.PlaceHolderAllData = data;
      this.savingforFilter=data;
    });
  }


  uploadPlaceholderFile() {
    this.addScreen = true;
  }

  Cancelupload() {
    this.addScreen = false;
    this.submitted=false;
    this.deletePopup=false;
    this.placeholderForm.reset();
    this.deletePopup=false;
    this.placeholderAudit=false;
    this.viewPlaceHolderFile = false;
  }
  //save function for the place holder files
  savePlaceholder()
  {
    if(this.placeholderForm.invalid)
    {
      this.submitted=true;
      return
    }
    this.placeholderData=[];
    for(let i=0;i<this.placeholderForm.controls['txtplaceHolder'].value.length; i++)
    {
      this.placeholderData.push({
        FileName: this.placeholderForm.controls['txtplaceHolder'].value[i].name,
        FileSize:this.convertSize(this.placeholderForm.controls['txtplaceHolder'].value[i].size),
        FileExtention:this.placeholderForm.controls['txtplaceHolder'].value[i].extension,
        CompanyID : this.CompanyID,
        UserID:this.GV.UserId
      });
    }
  this.dataservice.savePlaceHolderFiles(this.placeholderData).subscribe(response=>{
     if(response.responseText=="success" && response.savingExistedFileNames.length ==0)
     {
        this.notification.show(this.languageService.GetActionMessageByLanguage('PHC_FSS' , ""),1);
     }
     else 
     {
      this.notification.show(response.savingExistedFileNames + " file(s) already exists",4);
     }
     this.Cancelupload();
      this.GetAllPlaceHolderFilesData();
  });
  }

  //converting bytes to KB's and MB's 
  convertSize(bytes: number): string {
    if (bytes < 1024) {
      return bytes + ' B';
    } else if (bytes < 1024 * 1024) {
      const kilobytes = (bytes / 1024).toFixed(2);
      return kilobytes + ' KB';
    } else {
      const megabytes = (bytes / (1024 * 1024)).toFixed(2);
      return megabytes + ' MB';
    }
  }

  viewPlaceHolderFileClick(filePath: string, FileName: string) {
    this.FileNameDisplay=FileName;
      this.modelService.PreviewPDFDoc(filePath, FileName).subscribe(response => {
    const url = `data:application/pdf;base64,${response}`;
    this.iframeSource = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.viewPlaceHolderFile = true;
      });
  }

  //Delete confirm popup
  deleteConfirm(ID: number)
  {
    this.PlaceHolderID=0;
    this.deletePopup=true;
    this.PlaceHolderID=ID;
  }
  DeletePlaceHolder()
  {
     this.dataservice.deletePlaceHolder(this.PlaceHolderID,this.GV.UserId).subscribe((res:any) =>{
       if(res=="success")
       {
        this.notification.show(this.languageService.GetActionMessageByLanguage('PHC_FDS' , ""),1);
        this.Cancelupload();
        this.GetAllPlaceHolderFilesData();
       }
       else{

       }
     });
  }
  getAuditHistory(KeyID:number,TableEnum:number,CompanyID:number)
  {
    this.dataservice.AuditLogData(KeyID, TableEnum, this.GV.UserId, this.CompanyID).subscribe((data) => {
      this.auditData=data;
      if (this.auditData.length > 0) {
        for (let i = 0; i < this.auditData.length; i++) {
          this.pdfData = new PDFDownloadData;
          this.pdfData.userId=data[i].userId;
          this.pdfData.actionType = data[i].actionType;
          this.pdfData.eventDate = this.DateTimeService.convertdate(data[i].eventDate);
          let seraizedata = JSON.parse(this.auditData[i]["changes"]);
           for(let i=0;i<=seraizedata.length-1;i++)
           {
             seraizedata[i].FieldName= this.DisplayAllFields.GetFieldName(seraizedata[i].FieldName, TableEnum);
            seraizedata[i].ValueBefore=(seraizedata[i].ValueBefore == "(null)" ? "" : seraizedata[i].ValueBefore);
            seraizedata[i].ValueAfter=(seraizedata[i].ValueAfter == "(null)" ? "" : seraizedata[i].ValueAfter);  
            }
          this.pdfData.listdata=seraizedata;
          this.pdfDataList.push(this.pdfData);
        }
        this.placeholderAudit = true;
      }
      else{
        this.notifications.show("No Audit data found");
      }
    });
  }
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredRecords = filterBy(this.PlaceHolderAllData, filter);
    if (this.filteredRecords.length == 0) {
      this.gridData = this.filteredRecords;
      this.IsDisable = true;
     }
     else{
       this.gridData = this.filteredRecords;
       this.IsDisable = false;
     }

  }

  
    onExcelExport() {
    
      this.excelRecords =   this.gridData;
  
      let rows = [];
      //Application Name
      let headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];
      let headerCell = new HeaderCellClass();
      headerCell.value = "Application Name"
      headerCell.bold = false;
      headerRowcells.push(headerCell);
       headerCell = new HeaderCellClass();
      headerCell.value = "Artwork360"
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      headerRow.cells = headerRowcells;
      rows.push(headerRow);
  
      //Module Name
       headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];
       headerCell = new HeaderCellClass();
      headerCell.value = "Module Name"
      headerCell.bold = false;
      headerRowcells.push(headerCell);
       headerCell = new HeaderCellClass();
      headerCell.value = "Admin Settings>>Place Holder Components"
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      headerRow.cells = headerRowcells;
      rows.push(headerRow);
  
      ///Downloaded by
       headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];
       headerCell = new HeaderCellClass();
      headerCell.value = "Downloaded By";
      headerCell.bold = false;
      headerRowcells.push(headerCell);
  
       headerCell = new HeaderCellClass();
      headerCell.value = this.GV.UserName;
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      headerRow.cells = headerRowcells;
      rows.push(headerRow);
  
      //Downloaded Date
       headerRow = new HeaderRowClass();
       var headerRowcells: HeaderCellClass[] = [];
       headerCell = new HeaderCellClass();
      headerCell.value = "Downloaded Date";
      headerCell.bold = false;
      headerRowcells.push(headerCell);
       headerCell = new HeaderCellClass();
  
      let CurrentDate = new Date();
      headerCell.value = CurrentDate.toString();;
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      headerRow.cells = headerRowcells;
      rows.push(headerRow);
  
      //Binding Columns
       headerRow = new HeaderRowClass();
       var headerRowcells: HeaderCellClass[] = [];
  
      this.columnNames.forEach(element => {
         headerCell = new HeaderCellClass();
        headerCell.value = element
        headerCell.bold = true;
        headerRowcells.push(headerCell);
        headerCell.background = "#227447"
        headerCell.color = "#ffffff"
      });
  
      headerRow.cells = headerRowcells;
      rows.push(headerRow);
      //Binding Grid Data
       headerRow = new HeaderRowClass();
       var headerRowcells: HeaderCellClass[] = [];
  
      for (let i of this.excelRecords) {
         headerRow = new HeaderRowClass();
        let headerRowcells: HeaderCellClass[] = [];
         headerCell = new HeaderCellClass();
        headerCell.value = i.fileName;
        headerCell.bold = false;
        headerRowcells.push(headerCell);
        headerCell.background = ""
        headerCell.color = ""
  

     headerCell = new HeaderCellClass();
        headerCell.value = i.filePath;
        headerCell.bold = false;
        headerRowcells.push(headerCell);
        headerCell.background = ""
        headerCell.color = ""
        headerRow.cells = headerRowcells;

     headerCell = new HeaderCellClass();
        headerCell.value = i.physicalFilePath;
        headerCell.bold = false;
        headerRowcells.push(headerCell);
        headerCell.background = ""
        headerCell.color = ""

     headerCell = new HeaderCellClass();
        headerCell.value = i.thumbnailFilePath;
        headerCell.bold = false;
        headerRowcells.push(headerCell);
        headerCell.background = ""
        headerCell.color = ""
        headerRow.cells = headerRowcells;

        headerCell = new HeaderCellClass();
        headerCell.value = i.fileSize;
        headerCell.bold = false;
        headerRowcells.push(headerCell);
        headerCell.background = ""
        headerCell.color = ""
        headerRow.cells = headerRowcells;

        rows.push(headerRow);
  
      }
      let excelName = "Place Holder Components";
      const workbook = new Workbook({
        sheets: <WorkbookSheet[]>[
          {
            // Column settings (width)
            columns: [
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
            ],
            // Title of the sheet
            name: excelName,
            rows: rows
          },
        ],
      });
      workbook.toDataURL().then((dataUrl) => saveAs(dataUrl, excelName + ".xlsx"));
    
  }
}

export class PDFDownloadData{
  userId : string;
  eventDate: string;
  actionType: string;
  listdata: PDFFieldNames[] = [];
}
export class PDFFieldNames{
  Name: string;
  ValueBefore: string;
  ValueAfter: string;
}
export class GridViewData {
  public pageSize: number = 10;
  public skipPage: number = 0;
  public viewData: any = {};
  public filter: any = null;
}

export class HeaderCellClass {
  value: string;
  bold: boolean;
  background: string;
  color: string;
}

export class HeaderRowClass {
  cells: any;
}


