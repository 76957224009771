import { Component, OnInit } from '@angular/core';
import { AdminUserManagementService, NotificationEmailTriggerEvent, GetAuditAllFieldName, DateConvertions } from '../../../services/adminusermanagement.service';
import { Router } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { RoleDetails, AduitForm, AuditDashboardForm, GroupsData, PDfExpAuditSave, UserGroupData } from '../../../services/AMFormsDataDetails';
import { NotificationAlertsService } from '../../../services/notification.service';
import { ModuleService } from '../../../services/module.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { LanguageService } from '../../../services/language.service';
import { CompositeFilterDescriptor, filterBy, process} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import{Workbook,
  WorkbookSheet,
  } from "@progress/kendo-angular-excel-export";
  import { saveAs } from "@progress/kendo-file-saver";
import { PageChangeEvent } from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css'],
  providers: [GetAuditAllFieldName, NotificationAlertsService, DateConvertions]
})
export class GroupListComponent implements OnInit {

  OperationType: number = 1;
  UserID: number = 0;
  CompanyID: number = 0;
  ResponseData: GroupsData[] = [];
  IsExportExcel: boolean = false;
  IsExportToPdf: boolean = false;
  countryID: boolean = false;
  btnAddGroup: boolean = true;
  btndisabledGroup: boolean = true;
  btnActive: boolean = false;
  btnActions: boolean = true;
  AuditResponseData: AuditDashboardForm[] = [];
  AuditLogId: boolean = false;
  Audituserdata: AduitForm[] = [];
  htmlToAdd: any;
  divHisdata: boolean = false;
  IsAuditExportExcel: boolean = false;
  btnEditAction: boolean = true;
  btnDeleteAction: boolean = true;
  AuditexcelBtn: boolean = false;
  GroupID: number = 0;
  GroupsId: boolean = true;
  public opened = false;
  ObjAuditExpPdf=new PDfExpAuditSave();
  progress: number;
  message: string;
  FileName: string;
  FilePath: string;
  GroupsType: string;
  TZoffset = this.DateTimeService.setUTCTimeZone();
  showLoader: boolean = false;
  UsersDialogID: boolean = false;
  TypesDialogID: boolean = false;
  TypesLoader: boolean = false;
  UsersLoader: boolean = false;
  RolesData: RoleDetails[] = [];
  UsersData: UserGroupData[] = [];

  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();
  offSet: string = "+05:30";
  submitted = false;
  workflowgroupHeader: string = 'Active Workflow Groups';
  gridData: any;
  WorkflowType: string;
  timeStamp : Date = new Date();
  excelRecords:any;
  columnNamesUser:any=["Group Name","Master Group","Description","Type"];
  IsDisable: boolean = false;
  filter: CompositeFilterDescriptor;
  filteredRecords: any;
  pageSize:number=0;
  skipPage:number=0;

  constructor(private router: Router, private dataservice: AdminUserManagementService, private commonfeaturesservice: CommonFeaturesService,
    private notifications: NotificationAlertsService, private DisplayAllFields: GetAuditAllFieldName, private moduleService: ModuleService, private DateTimeService: DateConvertions, private GV: GlobalVeriables, private formbuilder: FormBuilder, private languageService: LanguageService) {
    this.allData = this.allData.bind(this);
  }

  ngOnInit() {
    this.CompanyID= +localStorage.getItem("usercompanyID");
    this.GV.UserId = +localStorage.getItem("userId");
    this.loadGroupsData(this.OperationType, this.GroupID);
    this.GroupsType = "Groups";

    this.WorkflowType = "Active Workflow Groups";
  }
  GroupDeactivateForm = this.formbuilder.group({
    comments: ['', [Validators.required]]
  });

  loadGroupsData(OperationType: number, GroupID: number) {
    this.pageSize=20;
    this.skipPage=0;
    this.filter=null
    this.dataservice.getGroupsData(OperationType, GroupID).subscribe(data => {
      this.ResponseData = data;
      this.gridData = this.ResponseData;

      this.btnEditAction = true;
      this.btnDeleteAction = true;
      if (this.ResponseData.length > 0) {
        this.IsExportExcel = true;
        this.IsExportToPdf = true;
        this.IsDisable = false;
      }
      else {
        this.IsExportExcel = false;
        this.IsExportToPdf = false;
        this.IsDisable = true;
      }
    });
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.gridData, {filter:this.filter
      }).data,

    };

    return result;

  }
  public closeDialog() {
    this.submitted = false;
    this.GroupDeactivateForm.controls['comments'].setValue('');
    this.GroupDeactivateForm.controls['comments'].setErrors({ required: false });
    this.opened = false;
  }

  public openDialog(GroupID) {
    this.submitted = false;
    this.GroupDeactivateForm.controls['comments'].setValue('');
    this.GroupDeactivateForm.controls['comments'].setErrors({ required: false });
    this.GroupID = 0;
    this.opened = true;
    this.GroupID = GroupID;
  }


  DeleteGroupsData() {
    if (this.GroupDeactivateForm.invalid) {
      this.submitted = true;
      return;
    }
  var comments = this.GroupDeactivateForm.controls['comments'].value;
    this.dataservice.DeleteSingleGroup(this.GroupID, this.GV.UserId,comments).subscribe((data) => {
      this.opened = false;
      if (data == "1") {
        this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "GDS", this.CompanyID).subscribe((dataGAActionsMessages) => {
          this.notifications.show(this.languageService.GetActionMessageByLanguage(dataGAActionsMessages[0].aliasKey, dataGAActionsMessages[0].displayMessage), dataGAActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
        });
        this.dataservice.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForUserAddOrUpdateOrDeactivateGroup, 3, 0, 0, this.GroupID, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
        
      }
      else if (data == "2")
        this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "GDSAT", this.CompanyID).subscribe((dataCDActionsMessages) => {
          this.notifications.show(this.languageService.GetActionMessageByLanguage(dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
        });
      this.GroupDeactivateForm.controls['comments'].setValue('');
      this.GroupDeactivateForm.controls['comments'].setErrors({ required: false });
      this.loadGroupsData(this.OperationType, this.GroupID);
    });
  }

  DisabledGroups() {
    this.pageSize=20;
    this.skipPage=0;
    this.filter=null
    this.workflowgroupHeader ='Disabled Workflow Groups'
    this.dataservice.getDisabledGroups(4, 0).subscribe(data => {

      this.ResponseData = data;
      this.gridData = this.ResponseData;
      this.btnAddGroup = false;
      this.btnActive = true;
      this.btndisabledGroup = false;
      this.btnActions = true;
      this.btnEditAction = false;
      this.btnDeleteAction = false;
      if (this.ResponseData.length > 0) {
        this.IsExportExcel = true;
        this.IsExportToPdf = true;
      }
      else {
        this.IsExportExcel = false;
        this.IsExportToPdf = false;
      }
    });
  }

  ActiveGroups() {
    this.workflowgroupHeader = 'Active Workflow Groups'
    this.btnAddGroup = true;
    this.btnActive = false;
    this.btndisabledGroup = true;
    this.btnActions = true;
    this.OperationType = 1;
    this.loadGroupsData(this.OperationType, this.GroupID);
  }

  auditclose() {

    this.AuditLogId = false;
    if (this.btnActive) {
      this.btnEditAction = false;
      this.btnDeleteAction = false;
    }
    else {
      this.btnEditAction = true;
      this.btnDeleteAction = true;
    }
    this.router.navigate(['/GroupsData']);
  }

  usersDialogClose() {
    this.UsersDialogID = false;
  }
  rolesDialogClose() {
    this.TypesDialogID = false;
  }

  RolesDataPopulate(groupid: any, type: any) {
    this.TypesDialogID = true;
    this.TypesLoader = true;
    this.dataservice.getSelectedRolesData(7, '', groupid).subscribe(response => {
      this.RolesData = response;
      this.IsExportExcel = true;
      this.TypesLoader = false;
    }),
      error => {
        this.TypesLoader = false;
      }
  }
  pageChange(event: PageChangeEvent) {
    this.skipPage = event.skip;
  }

  UsersDataPopulate(groupid: any,type:any) {
    this.UsersDialogID = true;
    this.UsersLoader = true;
    this.dataservice.getUsersGroup(9, type, groupid).subscribe(response => {
      this.UsersData = response;
      this.IsExportExcel = true;
      this.UsersLoader = false;
    }),
      error => {
        this.UsersLoader = false;
      }
  }

  GroupsAuditLogData(keyID: any, TablenumID: any,  CompanyID: any) {
    this.AuditLogId = true;
    this.IsAuditExportExcel = true;

    this.dataservice.AuditLogData(keyID, TablenumID, this.GV.UserId, CompanyID).subscribe((data) => {


      this.divHisdata = true;
      var AuditDisplay = "";
      if (data.length > 0) {
        this.AuditexcelBtn = true;
        for (let i of data) {
          var seraizedata = JSON.parse(i["changes"]);
          
            AuditDisplay += "<div class=''>";
            AuditDisplay += "<table class='auditclass'>";
            AuditDisplay += "<tr class='auditclass margin-top-10' style='background: rgba(245, 247, 250, 0.5) !important;'>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>User: <b class='newtext-color'>" + i.userId + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Event date:<b class='newtext-color'> " + this.DateTimeService.convertdate(i.eventDate) + "</b></td>";
            AuditDisplay = AuditDisplay + "<td style='width:30%;'>Action type:<b class='newtext-color'> " + i.actionType + "</b></td>";
            AuditDisplay = AuditDisplay + "</tr>"
            AuditDisplay = AuditDisplay + "<tr class='auditclass'><td><b>Field name</b></td><td><b>Before change</b></td><td><b>After change</b></td></tr>";
          if (seraizedata.length > 0) {
            for (let j of seraizedata) {
              AuditDisplay = AuditDisplay + "<tr class='auditclass'>";
              AuditDisplay = AuditDisplay + "<td>" + this.DisplayAllFields.GetFieldName(j.FieldName, 22) + "</div>";
              AuditDisplay = AuditDisplay + "<td>" + (j.ValueBefore == "(null)" ? "" : j.ValueBefore) + "</div>";
              AuditDisplay = AuditDisplay + "<td style='width:33%!important;'>" + (j.ValueAfter == "(null)" ? "" : j.ValueAfter) + "</div>";
              AuditDisplay = AuditDisplay + "</tr>";
            }
          
          }
          else {
            AuditDisplay = AuditDisplay + "<tr class='newtext-color'><td></td><td class='center'>No updates to show!</td><td></td></tr>";
          }
          AuditDisplay = AuditDisplay + "</table><br/>";
          AuditDisplay = AuditDisplay + "</div>";
        }

      }
      else {
        AuditDisplay = AuditDisplay + "No audit data for this record.";
        this.AuditexcelBtn = false;
      }
      this.htmlToAdd = AuditDisplay;


    });
  }

  checkUserPrivilege(actionEventID, LevelID) {
    return this.dataservice.GetPrivilegeAccesType(actionEventID, LevelID);
  }

  ExportAuditToPdfFile(htmlcontent: string) {
    this.ObjAuditExpPdf.htmlcontent = htmlcontent;
    this.ObjAuditExpPdf.offset = this.TZoffset;
    this.ObjAuditExpPdf.TableEnum = '22';
    this.showLoader = true;
    this.dataservice.DownloadAuditPdfFile(this.ObjAuditExpPdf).subscribe((event) => {
      this.DownLoadExportPdfFile(event.filePath, event.auditFile);
      this.showLoader = false;
    },
      error => {
        this.showLoader = false;
        this.notifications.show("Download  Failed !! "+ error.statusText, 4);
      }
    );
    
  }

  DownLoadExportPdfFile(Filepath: string, FileName: string) {
    this.FileName = FileName;
    this.FilePath = Filepath;
    this.dataservice.GetDownloadAuditPdfFilePath(Filepath, FileName).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event);
      }
    },
      error => {
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }
  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredRecords = filterBy(this.ResponseData, filter);
    if (this.filteredRecords.length == 0) {
      this.gridData = this.filteredRecords;
      this.IsDisable = true;
     }
     else{
       this.gridData = this.filteredRecords;
       this.IsDisable = false;
     }

  }
  onExcelExport()
  {
    this.excelRecords =  this.gridData
    
      var rows = [];
      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];

      var headerCell = new HeaderCellClass();
      headerCell.value = "Application Name"
      headerCell.bold = false;
      headerRowcells.push(headerCell);


      var headerCell = new HeaderCellClass();
      headerCell.value = "Artwork360"
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      //Module Name
      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];

      var headerCell = new HeaderCellClass();
      headerCell.value = "Module Name"
      headerCell.bold = false;
      headerRowcells.push(headerCell);


      var headerCell = new HeaderCellClass();
      headerCell.value = "Admin Settings>>Workflow groups"+this.workflowgroupHeader
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      ///Downloaded by

      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];

      var headerCell = new HeaderCellClass();
      headerCell.value = "Downloaded By";
      headerCell.bold = false;
      headerRowcells.push(headerCell);


      var headerCell = new HeaderCellClass();
      headerCell.value = this.GV.UserName;
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      //Downloaded Date

      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];


      var headerCell = new HeaderCellClass();
      headerCell.value = "Downloaded Date";
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      var headerCell = new HeaderCellClass();

      var CurrentDate = new Date();
      headerCell.value = CurrentDate.toString();;
      headerCell.bold = true;
      headerRowcells.push(headerCell);


      headerRow.cells = headerRowcells;
      rows.push(headerRow);

      //Binding Columns

      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];

      this.columnNamesUser.forEach(element => {
        var headerCell = new HeaderCellClass();
        headerCell.value = element
        headerCell.bold = true;
        headerRowcells.push(headerCell);
        headerCell.background = "#227447"
        headerCell.color = "#ffffff"
      });

      headerRow.cells = headerRowcells;
      rows.push(headerRow);
//Binding Grid Data
     var headerRow = new HeaderRowClass();
     var headerRowcells: HeaderCellClass[] = [];

     for(let i of this.excelRecords)
     {
      var headerRow = new HeaderRowClass();
      var headerRowcells: HeaderCellClass[] = [];

          var headerCell = new HeaderCellClass();
          headerCell.value = i.groupName;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new HeaderCellClass();
          headerCell.value = i.masterModuleName;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new HeaderCellClass();
          headerCell.value = i.description;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

          var headerCell = new HeaderCellClass();
          headerCell.value = i.type;
          headerCell.bold = false;
          headerRowcells.push(headerCell);
          headerCell.background = ""
          headerCell.color = ""

           headerRow.cells = headerRowcells;
           rows.push(headerRow); 
     }
      let excelName=this.workflowgroupHeader
      debugger
      const workbook = new Workbook({
        sheets: <WorkbookSheet[]>[
          {
            // Column settings (width)
            columns: [
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
              { autoWidth: true },
            ],
            
            // Title of the sheet            
            name:  excelName,
            // Rows of the sheet
            rows:rows   
          },
        ],
      });
      workbook.toDataURL().then((dataUrl) => saveAs(dataUrl, excelName+".xlsx"));
    }
   
  }


export class GridViewData {
  public pageSize: number = 10;
  public skipPage: number = 0;
  public viewData: any = {};
  public filter: any = null;
}
export class HeaderCellClass {

  value: string;
  bold: boolean;
  background: string;
  color: string;
}

export class HeaderRowClass {

  cells: any;
}




  

