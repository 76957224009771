import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { templateData } from './interfaces/ItemplateFields';
import { LoginService } from './services/login.service';
import { ModuleService } from './services/module.service';
import { GlobalVeriables } from './Global/GlobalVaribales';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { templateSelectedRecord } from './dynamic-form-view/dynamic-form-view.component';
import { CommonFeaturesService } from './services/commonfeatures.service';
import { AdminUserManagementService, NotificationEmailTriggerEvent } from './services/adminusermanagement.service';
import { WorkflowTasksManagementService } from './services/BPM/workflowtasksmanagement.service';
import { DataByLanguage, LanguageService } from './services/language.service';
import { NotificationAlertsService } from './services/notification.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { fromEvent, Observable, Subscription, BehaviorSubject } from 'rxjs';

import {
  DrawerItem,
  DrawerSelectEvent,
} from "@progress/kendo-angular-layout";
import { environment } from '../environments/environment';

import { getBaseUrl } from '../main';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Artwork360 App';
  showHeader: boolean = false;
  showSidebar: boolean = false;
  masterModuleID: number;
  module: any;
  masterModules: any;
  showMasterModules: boolean = false;
  selectedMasterModule: any = {};
  navLinks: any[];
  modules: any;
  moduleID: any;
  isdownload: false;
  curCompanyID: number = 0;
  forgotPasswordID: boolean = false;
  Issetpassword: boolean = false;
  odataStaticModulesData: any = {};
  routingName: string;
  key: string;
  cuserRoleID: any;
  defaultModule: any;
  IsLoginSuccess: boolean = false;
  offSet: string = "+05:30";
  submitted = false;
  submit = false;
  validated = true;
  showLoader = false;
  Loginobj = new Login();
  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent()
  lblErrorMsg: string;
  lblErrorMsgYear: any;
  staticmoduleID: any;
  masterModuleName: string;
  defaultHeader: string;
  masterModuleDescription: string;
  logo: string;
  Login_img: string;
  outsideurl: string;
  showElement: boolean = false;
  routeData: any;
  public languagesList = [];
  public languageData: DataByLanguage[] = [];
  selectedLanguage;
  isDataLoaded = false;
  isConnected: any = true;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  networkDown: boolean = false;
  getMasterModulesCall: boolean = false;
  GetAllLanguagesCall: boolean = false;
  IsSSOUserInfo: boolean = false;
  IsSSOEnable: boolean = false;
  privilegeLoad: boolean = false;
  userHaveAccess: boolean = false;
  masterModuleCall: boolean = false;
  constructor(private moduleService: ModuleService, private notifyService: NotificationAlertsService,
    private http: HttpClient, private formBuilder: FormBuilder, public router: Router, private _loginService: LoginService, private av_router: ActivatedRoute,
    private GV: GlobalVeriables, private commonfeaturesservice: CommonFeaturesService, private amService: AdminUserManagementService,
    private dataservice: WorkflowTasksManagementService, private languageService: LanguageService, private dialogService: DialogService) {

    this.navLinks = [
      {
        label: 'Service',
        link: '/Service',
        index: 0
      }];
    if (window.location.href.includes('SetPassword') || window.location.href.includes('FileDownload')) {
      localStorage.setItem("token", "stoken");
    }

  }

  loginForm = this.formBuilder.group({
    userName: ['', Validators.required],
    password: ['', Validators.required],
  })

  UserDyForm = new FormGroup(
    {
      usrEmailUserName: new FormControl('',
        [Validators.required])
    },
    // insert here
  );

  ngOnInit() {
    if ((window.location.pathname == '/' || window.location.pathname == '/Login') && localStorage.getItem("ssoLogin") == "true") {
      localStorage.setItem("ssoLogin", "false");
    }
    this.IsSSOEnable = JSON.parse(localStorage.getItem("ssoLogin")) != null ? JSON.parse(localStorage.getItem("ssoLogin")) : false; //this.moduleService.enableSSO;
    this.GV.ssoLogin = this.IsSSOEnable;
    this._loginService.showHeader.subscribe(res => {
      this.showHeader = res;
      this.IsLoginSuccess = res;
      if (res) {
        this.getMasterModules();
        this.getMasterModulesCall = true;
        this.masterModuleCall = true;
      }
      else { this.getMasterModulesCall = false; }
    });
    if (this.IsSSOEnable) {
      if (localStorage.getItem("userLogin") == undefined || localStorage.getItem("userLogin") == "false") {
        let getEmail = window.location.search.replace("?", "").split("&");
        if (getEmail.length > 0) {
          let emailId = getEmail[0].split("=")[1];
          this.GetAllLanguagesCall = true;
          this.GetAllLanguages(emailId);
        }
      }
      else if (window.location.search != '' && window.location.search == '?email=esignSuccess') {
        window["autoClosed"] = "true";
        window.close()
      }
      else if (window.location.search != '' && window.location.search == '?email=esignFail') {
        this.privilegeLoad = true;
        return false;
      }
      else if (localStorage.getItem("userLogin") == "true") {
        this.isDataLoaded = true;
        this.validateUserPrivilege();
        this.GetAllLanguagesCall = false;
      }
    } else {
      if (localStorage.getItem("userLogin") == "true") {
        this.validateUserPrivilege();
        this.GetAllLanguagesCall = false;
      }
      else if (localStorage.getItem("userLogin") == "false" && window.location.pathname == '/') {
        this.router.navigate(["/Login"]);
        this.privilegeLoad = true;
      }
      else {
        this.validateUserPrivilege();
      }

    }
  }
  validateUserPrivilege() {
    let routeName = window.location.pathname.replace("/", "");
    if (routeName !== null && routeName !== "" && routeName !== "Login" && !routeName.includes("FileDownload") && !routeName.includes("SetPassword")) {

      this.GV.UserRoleID = +localStorage.getItem("userRoleID");
      this.GV.UserId = +localStorage.getItem("userId");
      this.amService.GetUserPrivilegesAccess(this.GV.UserId, this.GV.UserRoleID, routeName).subscribe(result => {
        this.userHaveAccess = result;
        this.privilegeLoad = true;
        this.callOnLoad();
      })
    } else {
      this.privilegeLoad = true;
      this.callOnLoad();
    }
  }

  callOnLoad() {
    this.moduleService.passAfterSSOLogin(true);
    this.getIPAddressforSystem();
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.getIPAddressforSystem();
      this.connectionStatusMessage = 'Back to online';
      this.networkDown = false;
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost!';
      this.networkDown = true;
    }));

    this._loginService.clearOutsideUrl.subscribe(response => {
      if (response)
        this.outsideurl = null;
    })
    let currentUrl = window.location.href;
    this._loginService.showHeader.subscribe(res => {
      this.showHeader = res;
      this.IsLoginSuccess = res;
      if (res && !this.masterModuleCall) {
        this.getMasterModules();
        this.getMasterModulesCall = true;
      }
      else { this.getMasterModulesCall = false; }
    })

    //this.outsideurl = window.location.pathname;

    var projectRedirection = localStorage.getItem("projectTaskRedirection");
    if (projectRedirection == null || projectRedirection == 'false') { this.outsideurl = (this.userHaveAccess && window.location.pathname != '/verifyuseraccess') ? window.location.pathname : "/"; }
    if (currentUrl.includes('SetPassword') || currentUrl.includes('FileDownload')) {
      this.outsideurl = window.location.pathname;
      this.IsLoginSuccess = true;
      this.Issetpassword = true;
      this.showLoader = false;
      this.showHeader = false;
      this.isDataLoaded = false;
      this.routingName = this.outsideurl;
      localStorage.setItem("userLogin", "false");
      this.moduleService.passDependencyTemplate(new templateSelectedRecord())
      this.router.navigate([this.routingName], { replaceUrl: true });
    }
    else if (currentUrl.includes('Projects/') && projectRedirection != 'true') {
      this.outsideurl = window.location.pathname;
      this.routingName = this.outsideurl;
      let templData = new templateData();
      templData.moduleID = 1;
      this.isDataLoaded = true;
      this.moduleService.passTemplateData(templData);
      this.router.navigate([this.routingName], { replaceUrl: true });
    }
    else {
      if (localStorage.getItem("userLogin") === "true" && this.Issetpassword == false) {
        this.IsLoginSuccess = true;
        this.showHeader = true;
        if (!this.showHeader) { this._loginService.setHeaderShow(true); }
        this.GV.UserRoleID = +localStorage.getItem("userRoleID");
        this.cuserRoleID = this.GV.UserRoleID;
        this.isDataLoaded = true;
        this.GV.UserName = localStorage.getItem("userName");
        this.GV.UserId = +localStorage.getItem("userId");
        this.GV.UserRole = localStorage.getItem("userRoleName");
        this.GV.Token = localStorage.getItem("user_Token")
        //getting user priviletes
        this.amService.GetUserPrivileges(this.GV.UserId, 1).subscribe(result => {
          this.GV.setUserPrivileges(result);
        })

        if (!this.getMasterModulesCall) { this.getMasterModules(); }

        if (this.outsideurl == '/' || this.outsideurl == undefined) {

          currentUrl = currentUrl + 'Projects';

        }
        if (currentUrl.includes('Projects')) {
          if (window.location.pathname == '/') {
            this.outsideurl = 'Projects';
            this.routingName = this.outsideurl;
          }
          else {
            //this.outsideurl = window.location.pathname ;
            this.outsideurl = (this.userHaveAccess && window.location.pathname != '/verifyuseraccess') ? window.location.pathname : '/Projects';
          }
          this.router.navigate([this.outsideurl], { replaceUrl: true });

        }
        else {
          this.outsideurl = window.location.pathname;
          this.router.navigate([this.outsideurl], { replaceUrl: true });
        }
        this.router.events.subscribe((data) => {
          if (data instanceof RoutesRecognized) {
            if (data.state.root.firstChild != null)
              this.routeData = data.state.root.firstChild.data;
          }
        });
        let currentTime = new Date()
        let year = currentTime.getFullYear();    // returns the year (four digits)
        this.lblErrorMsgYear = year;
      }
      else
        this.UserlogOut();

      this.moduleService.selectedModuleID.subscribe(Mid => {
        if (Mid > 0)
          this.moduleID = Mid;
      });
    }

    if (!this.GetAllLanguagesCall) { this.GetAllLanguages('') };

    this._loginService.GetUserSettingsData().subscribe((userdata) => {
      this.GV.PwdMinimumCharacters = userdata.pwdMinCharacters;
      this.GV.SpecialCharacters = userdata.reqSpecialCharacters;
      this.GV.UpperCaseCharacters = userdata.reqUppercaseCharacters;
      this.GV.NumericCharacters = userdata.reqNumericCharacters;
      this.GV.LowerCaseCharacters = userdata.reqLowercaseCharacters;
    });

    this.moduleService.SelectedModuleIDForDashboard.subscribe(moduleID => {
      this.moduleID = moduleID;
    });
  }


  getuserLoginDetails(username) {
    this.Loginobj.emailID = username;

    this._loginService.userLogin(this.Loginobj).subscribe(response => {
      this.lblErrorMsg = "";
      if (response.loginFailType == null) {
        localStorage.setItem("userLogin", "true");
        localStorage.setItem("userName", response.userName);
        localStorage.setItem("userId", response.userId);
        localStorage.setItem("userRoleID", response.roleID);
        localStorage.setItem("userRoleName", response.roleName);
        localStorage.setItem("userFirstName", response.firstName);
        localStorage.setItem("userLastName", response.lastName);
        localStorage.setItem("usercompanyID", response.companyID);
        localStorage.setItem("usertimeZone", response.timeZone);
        localStorage.setItem("OutofOfficeStatus", response.outofOfficeStatus); //to know the status is off or on
        this._loginService.isValid = true;
        localStorage.setItem("token", response.user_Token);
        localStorage.setItem("IsColumnDragged", "0");
        this.GV.setoutOfofficeState(response.outofOfficeStatus);
        this.showHeader = true;
        this._loginService.setHeaderShow(true);
        this.moduleService.reoladAppComponent(0);
        this.GV.UserName = localStorage.getItem("userName");
        this.GV.UserId = +localStorage.getItem("userId");
        this.GV.UserRole = localStorage.getItem("userRoleName");
        this.GV.UserRoleID = +localStorage.getItem("userRoleID");
        this.GV.CompanyID = +localStorage.getItem("usercompanyID");
        this.GV.TimezoneID = +localStorage.getItem("usertimeZone");
        this.curCompanyID = this.GV.CompanyID;
        this.cuserRoleID = this.GV.UserRoleID;

        this.validateUserPrivilege();
        this.amService.GetUserPrivileges(this.GV.UserId, 1).subscribe(result => {
          this.GV.setUserPrivileges(result);
        })
        if (!this.IsSSOEnable) {
          this.router.navigate(["/Projects"]);
        }
        //this.callOnLoad();
      }
      else {
        this.IsSSOUserInfo = true;
        if (response.loginFailType == "Locked" && response.loginFailAttempts > 0)
          this.functionInvalidNr1(response.loginFailType, response.loginFailAttempts);//User is locked due to incorrect password
        else if (response.loginFailType == "Locked")
          this.functionNr1("LOG_UIL");//User is locked
        else if (response.loginFailType == "Invalid" && response.loginFailAttempts >= 0)
          this.functionInvalidNr1(response.loginFailType, response.loginFailAttempts);//Invalid username or password
        else if (response.loginFailType == "Invalid")
          this.functionNr1("LOG_IUP");//Invalid username or password  
        else if (response.loginFailType == "NotSetPassword")
          this.functionNr1("LOG_NSP");
        else if (response.loginFailType == "Disabled")
          this.functionNr1("LOG_UIDI");//User is disabled
        else if (response.loginFailType == "Inactivated")
          this.functionNr1("LOG_UIDE");//User is deactivated
        else if (response.loginFailType == "NA")
          this.functionNr1("LOG_UNA");//User is not available
        else if (response.loginFailType == "AccountLocked")
          this.functionNr1("LOG_UIL");//User is locked SSO
      }
    })
  }

  functionInvalidNr1(msg, attempts) {
    this.lblErrorMsg = '';
    if (msg == 'Locked')
      this.lblErrorMsg = "Your account has been locked out due to multiple failed login attempts. Please contact Admin.";
    else if (msg == 'Invalid')
      this.lblErrorMsg = "Invalid credentials. You have " + attempts + " more attempt(s) before your account gets locked out.";
  }

  async getIPAddressforSystem() {
    this.GV.UserIpAddress = (await this.moduleService.getIPAddress());
  }

  getMasterModules() {
    this.moduleService.getMasterModules().subscribe(data => {
      this.masterModules = data;
      if (this.masterModules.length > 0) {
        this.selectedMasterModule = data[0];
        this.masterModuleID = data[0].id;
        this.bindModules();
      }
    });
  }

  GetDefaultMasterModuleData() {
    this.commonfeaturesservice.GetDefaultMasterModuleData().subscribe((dataDefaultMasterModuleData) => {
      this.masterModuleName = dataDefaultMasterModuleData[0].masterModuleName;
      this.defaultHeader = dataDefaultMasterModuleData[0].defaultHeader;
      this.masterModuleDescription = dataDefaultMasterModuleData[0].masterModuleDescription;
      if (dataDefaultMasterModuleData[0].logo != null) {
        let str_array = dataDefaultMasterModuleData[0].logo.split(',');
        this.logo = str_array[0];
        this.Login_img = str_array[1];
      }
    });
  }

  GetAllLanguages(loginUserEmail) {
    this.languageService.GetAllLanguages().subscribe(res => {
      this.languagesList = res;
      if (!localStorage.getItem("SelectedLanguageId")) {
        this.selectedLanguage = this.languagesList.find(x => x.isDefault == true);
        localStorage.setItem("SelectedLanguageId", this.selectedLanguage.id);
      } else
        this.selectedLanguage = this.languagesList.find(x => x.id == parseInt(localStorage.getItem("SelectedLanguageId")));
      this.GetDataByLanguageId(this.selectedLanguage.id, loginUserEmail);

    });
  }

  onShowMasterModules() {
    this.showMasterModules = true;
  }

  onMasterModuleClick(module) {
    this.masterModuleID = module.id;
    this.bindModules();
    this.showMasterModules = false;
    this.GV.isHideBypassTasks='';
    this.GV.subProcessIDList=[];
    this.GV.SubProcessID=0;
  }

  bindModules() {
    this.moduleService.getModules(this.masterModuleID).subscribe(data => {
      this.outsideurl = window.location.pathname;
      //this.defaultModule = this.GV.UserPrivileges.filter(a => a.levelID == '1' && a.accessType == '1').sort(b => parseInt(b.moduleOrder))[0];
      this.defaultModule = this.GV.UserPrivileges.filter(a => a.levelID == '1' && a.accessType == '1').sort((a, b) => (parseInt(a.moduleOrder) > parseInt(b.moduleOrder) ? 1 : -1))[0];
      this.modules = data;

      if (localStorage.getItem("moduleID") == "99999") {
        localStorage.setItem("moduleID", "1");
      }

      if (this.outsideurl != null || this.outsideurl != undefined) {
        if (this.outsideurl.includes("Projects/") || this.outsideurl.includes("Projects"))
          localStorage.setItem("moduleID", "1");
      }

      let moduleIDCurrent = '';
      if (this.outsideurl == '/Projects' || this.outsideurl == undefined || this.outsideurl.includes("Projects"))
        moduleIDCurrent = localStorage.getItem("moduleID");
      else
        moduleIDCurrent = data.filter(a => a.routingName == window.location.pathname.substring(1))[0].id;

      if (localStorage.getItem("userLogin") && this.outsideurl.includes("/") && moduleIDCurrent == "1") {
        if (window.location.pathname != "/") {
          this.outsideurl = window.location.pathname;
        }
        else {
          this.outsideurl = 'Projects';
          this.isDataLoaded = true
        }
      }
      this.isDataLoaded = true;
      if (moduleIDCurrent != "-1") {
        if (moduleIDCurrent != null && moduleIDCurrent != "1" && localStorage.getItem("userLogin")) {
          this.module = data.filter(a => a.id == moduleIDCurrent)[0];
          if (this.module != undefined)
            this.moduleID = this.module.id;
        }
        else {
          this.module = data.filter(a => a.id == this.defaultModule.moduleID)[0];
          this.moduleID = this.module.id;
        }
        if (this.module != undefined) { this.routingName = this.module.routingName; }
        let templData = new templateData();
        templData.moduleID = this.moduleID;
        templData.initialLoad = false;
        this.moduleService.passTemplateData(templData);

        if (this.outsideurl != undefined) {
          this.showHeader = false;
          if (this.outsideurl.includes('SetPassword')) {
            this.IsLoginSuccess = true;
            this.Issetpassword = true;
            this.showLoader = false;
            this.showHeader = false;
            this.isDataLoaded = false;
            this.routingName = this.outsideurl;
            localStorage.setItem("userLogin", "false");
            this.moduleService.passDependencyTemplate(new templateSelectedRecord())
            this.router.navigate([this.routingName], { replaceUrl: true });
          }
          else if (this.outsideurl.includes('Projects/') || this.outsideurl.includes('Projects')) {
            this.routingName = this.outsideurl;
            this.isDataLoaded = true;
            this.router.navigate([this.routingName], { replaceUrl: true });
          }
        } else {
          this.router.navigate([this.routingName], { replaceUrl: true });
          this.isDataLoaded = true;

        }

      }
    })
  }

  filterSubModules(module) {
    return this.modules.filter(x => x.parentId == module);
  }

  close() {
    this.showMasterModules = false;
  }

  onModuleClick(module) {

    this.GV.isHideBypassTasks='';
    this.GV.subProcessIDList=[];
    this.GV.SubProcessID=0;
    if (module.id == 38)
      window.open("https://demo2.contentcompare.com/FreyrDev/informcc/login.aspx", "_blank");
    else {
      this.GV.processTemplateID = "";
      this.GV.SetIsRedirection(false);
      localStorage.setItem("moduleID", module.id);
      this.staticmoduleID = 0;
      if (this.GV.UserPrivileges == undefined || this.GV.UserPrivileges == null) {
        this.amService.GetUserPrivileges(this.GV.UserId, 1).subscribe(result => {
          this.GV.setUserPrivileges(result);
        })
      }
      let templData1 = new templateData();
      this.moduleService.passunSubTemplateData(templData1);
      this.moduleID = module.id;
      let templData = new templateData();
      templData.moduleID = module.id;
      this.moduleService.passTemplateData(templData);
      let selectedItem = new templateSelectedRecord();
      selectedItem.selectedRecordId = 0;
      this.moduleService.passDependencyTemplate(selectedItem);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([module.routingName]));
    }
}

onAdminSettingClick(type, staticmoduleID) {
  this.GV.isHideBypassTasks='';
  this.GV.subProcessIDList=[];
  this.GV.SubProcessID=0;
  
  this.moduleID = type;
  this.staticmoduleID = staticmoduleID;
  switch (type) {
    case 111:
      this.router.navigate(['DynamicWorkDiagram']);
      break;
    case 1:
      this.moduleID = 0;
      this.router.navigate(['Users']);
      break;
    case 2:
      this.moduleID = 0;
      this.router.navigate(['Roles']);
      break;
    case 3:
      this.moduleID = 0;
      this.router.navigate(['MasterData/15']);
      break;
    case 4:
      this.moduleID = 0;
      this.router.navigate(['ErrorsLog']);
      break;
    case 5:
      this.moduleID = 0;
      this.router.navigate(['Groups']);
      break;
    case 6:
      this.moduleID = 100;
      this.router.navigate(['Workflowtasks']);
      break;
    case 7:
      this.moduleID = 100;
      this.router.navigate(['WorkflowMasterData']);
      break;
    case 8:
      this.moduleID = 100;
      this.router.navigate(['Modules']);
      break;
    case 9:
      this.moduleID = 100;
      this.router.navigate(['Dynamicforms']);
      break;
    case 10:
      this.moduleID = 100;
      this.router.navigate(['Resourcemessages']);
      break;
    case 11:
      this.moduleID = 100;
      this.router.navigate(['EmailTemplates']);
      break;
    case 12:
      this.moduleID = 0;
      this.router.navigate(['Audittrails']);
      break;
    case 13:
      this.moduleID = 99999;
      this.router.navigate(['Myprofile']);
      break;
    case 14:
      this.moduleID = 99999;
      this.router.navigate(['Managepassword']);
      break;
    case 15:
      this.moduleID = 0;
      this.router.navigate(['UserSettings']);
      break;
    case 16:
      this.moduleID = 99999;
      this.router.navigate(['SetUpOutOfOffice']);
      break;
    case 22:
      this.moduleID = 0;
      this.router.navigate(['PlaceHolderFiles']);
      break;
    default:
      //Do nothing
      break;
  }

  if (this.moduleID == 0)
    localStorage.setItem("moduleID", "-1");
  else
    localStorage.setItem("moduleID", this.moduleID);
}

LogOutConfrimopened: boolean = false;

closeDialog() {
  this.LogOutConfrimopened = false;
}

logOut() {
  this.LogOutConfrimopened = true;
}

UserlogOut() {
  //clear state of templates
  this.moduleService.passDependencyTemplate(new templateSelectedRecord())
  let templData = new templateData();
  templData.moduleID = 0;
  templData.initialLoad = true;
  this.moduleService.passTemplateData(templData);
  this.moduleService.passunSubTemplateData(templData)
  this.LogOutConfrimopened = false;
  this._loginService.setHeaderShow(false);
  this.showHeader = false;
  this._loginService.userLogoff();
  this.IsLoginSuccess = false;
  this.isDataLoaded = false;
  localStorage.setItem("userLogin", "false");
  localStorage.setItem("token", "");
  localStorage.removeItem("token");
  localStorage.setItem("proxyUserId", "");
  localStorage.removeItem("proxyUserId");
  localStorage.removeItem("moduleID");
  localStorage.removeItem("ssoLogin");

  if (this.IsSSOUserInfo) {
    this.IsSSOUserInfo = false;
    this.privilegeLoad = true;
  }
  this.router.navigate(['Login'], { replaceUrl: true });
  localStorage.removeItem("reportsProjectID");
  localStorage.removeItem("cryptoProjectID");
  localStorage.removeItem("projectNameRedirection");
}

checkUserPrivilege(actionEventID, LevelID) {
  return this.amService.GetPrivilegeAccesType(actionEventID, LevelID);
}
functionNr1(msg) {
  this.lblErrorMsg = this.languageData.find(x => x.LanguageKey == msg).Message;
}

changeLanguage(value: any): void {
  if(this.selectedLanguage.id != value.id) {
  this.selectedLanguage = value;
  localStorage.setItem("SelectedLanguageId", this.selectedLanguage.id);
  // this.GetDataByLanguageId(this.selectedLanguage.id);
  window.location.reload();
}
  }

GetDataByLanguageId(languageId: number, loginUserEmail) {
  this.languageService.GetDataByLanguageID(languageId).subscribe(res => {
    this.languageData = res;
    this.languageService.dataByLanguage = this.languageData;
    // this.isDataLoaded = true;
    if (loginUserEmail !== "") {
      this.getuserLoginDetails(loginUserEmail);
    }
  });
}

GetTranslatedText(key) {
  if (this.languageData) {
    let tData = this.languageData.find(x => x.LanguageKey == key);
    if (tData)
      return tData.Message;
  }
}

  public expandedIndices = [2];
}

class Login {
  emailID: string;
  password: string;
  userName: string;
  roles: string;
}

// $(function () {
//   $('#header').on("click", function () {
//     $('#demo, #demoad').removeClass("show");
//   });
// });


