import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationAlertsService } from '../../../services/notification.service';
import { WorkFlowData, WorkflowTasksActionsData, WorkflowTasksData } from '../../../services/BPM/workflowtasksDetails';
import { WorkflowTasksManagementService } from '../../../services/BPM/workflowtasksmanagement.service';
import { SelectEvent } from "@progress/kendo-angular-layout";
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl, FormControl } from '@angular/forms';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { GridDataResult } from '@progress/kendo-angular-grid';


@Component({
  selector: 'app-workflowtasks-list',
  templateUrl: './workflowtasks-list.component.html',
  styleUrls: ['./workflowtasks-list.component.css']
})
export class WorkflowtasksListComponent implements OnInit {

  ResponseWorkFlowData: WorkFlowData[] = [];
  ResponseWorkflowTasksActionsData: WorkflowTasksActionsData[] = [];
  ResponseWorkflowTasksData: WorkflowTasksData[] = [];
  ResponseWorkflowTasksDataitem: any;
  WorkflowID: number = 0;
  WorkFlowName: any;
  WorkFlowTaskName: any;
  WorkflowTaskActionsWindow: boolean = false;
  dynamicWorkflowName: string;
  TaskId: number;
  CompanyId: number;
  departmentsData: any;
  selectedDepartment: any;
  groupsData: any;
  rolesData: any;
  selectedGroup: any;
  usersData: any;
  selectedUser: any;
  processFlow: any;
  selecctedProcessFlow: any;
  rejectedParentTasks: any;
  selectedRejectedParentTask: any;
  isCyclevalue: any;
  isTaskEditable: any;
  isTaskByPass: any;
  isSetTaskOwner: any;
  isAutoCompleteTask: any;
  isAddApproversAction: any;
  isSuspendCycle: any;
  isFinalTask: any;
  enableTaskEditFields: any;
  enableProjectSummaryFields: any;
  enableSubprocessEditFields: any;
  enableEditSubscribeFields: any;
  enableSubprocessIDis: any;
  updatedTaskdata = {} as WorkflowTasksData;
  selectedRole: any;
  isExists: boolean;
  submitted = false;
  RejectedParentTask:string; 
  selectedStepNumber: number;
  gridata: any;
  editedRowIndex: number;
  formGroup: FormGroup;
  actionTypes: any;
  names: any;
  dynamictitle:any;
  actinname: string;
  disabled:boolean = false;
  taskActionData = {} as WorkflowTasksActionsData;

   public listItem: Array<Item>=[{currentTask:'NA',taskId:0},];

  constructor(private http: HttpClient, private av_router: ActivatedRoute, private router: Router, private dataservice: WorkflowTasksManagementService, private service: AdminUserManagementService
    , private notifications: NotificationAlertsService, private formbuilder: FormBuilder, private commonfeaturesservice: CommonFeaturesService) {
  }

  WorkFlowTaskEditDyForm = this.formbuilder.group({
    SequenceId: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_-]*$")]],
    StepNumber: ['', [Validators.required, this.stepNumberExistsValidator()]],
    ProcessFlow: ['', [Validators.required]],
    DefaultDepartment: ['', [Validators.required]],
    DeafultGroup: [''],
    DeafultUser: [''],
    RejectedParentTask: [''],
    IsCycle: ['', [Validators.required]],
    Role: [''],
    taskId:[''],
    IsTaskByPass: ['', [Validators.required]],
    IsSetTaskOwner: ['', [Validators.required]],
    IsTaskEditable: ['', [Validators.required]],
    IsAutoCompleteTask: ['', [Validators.required]],
    IsAddApproversAction: ['', [Validators.required]],
    IsSuspendCycle: ['', [Validators.required]],
    IsFinalTask: ['', [Validators.required]],
    EnableTaskEditFields: ['', [Validators.required]],
    EnableProjectSummaryFields: ['', [Validators.required]],
    EnableSubprocessEditFields: ['', [Validators.required]],
    EnableEditSubscribeFields: ['', [Validators.required]],
    EnableSubprocessIDis: ['', [Validators.required]],


  });

  ngOnInit() {
    this.loadWorkFlowData(1);
    this.getDepartments();
    this.getGroups(9, 0, 0, 0);
    this.getProcessFlow();
    this.getRoles();
    this.getActionTypes();

  }

  getDepartments() {
    this.service.getUserDepartments().subscribe(response => {
      this.departmentsData = response;

    });
  }
  getGroups(typeId: any, userId: any, companyId: any, roleId: any) {
    this.service.getUMUserGroups(typeId, userId, companyId, roleId).subscribe(response => {
      this.groupsData = response;
      //console.log("getgroups", response);
    });
  }

  getusers(groupId: number, companyId: number) {
    this.dataservice.getUsersByGroupId(groupId, companyId).subscribe(response => {
      this.usersData = response;
      //console.log("users", response);
    });
  }

  getProcessFlow() {
    this.processFlow = [{ id: 1, name: 'Project Level' },
    { id: 2, name: 'Sub Process Level' },
    { id: 3, name: 'Workflow Level' }
    ];
  }

  getActionTypes() {
    this.actionTypes = [{ id: 1, name: 'Page Level' },
      { id: 2, name: 'Form Level' },
      { id: 3, name: 'Display Control Level(inline)' },
      { id: 4, name: 'Display Template Level' },
      { id: 5, name: 'Task Level' },
    ];
  }


  getRoles() {
    this.service.getUMGetRoles().subscribe(response => {
      this.rolesData = response;
      //console.log("getgroups", response);
    });
  }

 
  loadWorkFlowData(CompanyID: number) {
    //calling the get all work flows method from services component-binding work flows to the tree view
    this.dataservice.GetAllWorkflows(CompanyID).subscribe(data => {
      //assigning the response to the pre defined class object
      this.ResponseWorkFlowData = data;
      this.WorkflowID = 1;
    //calling the get all work flows tasks method from services component-binding work flows tasks to the grid view
      this.dataservice.GetStandardWorkflowTasks(1, this.WorkflowID, 0).subscribe(data => {
        this.ResponseWorkflowTasksData = data;
        this.dynamicWorkflowName = data[0].workflowName;
      });
    });
  }

  public handleSelection(dataItem): void {
    this.WorkflowID = dataItem.dataItem.workflowID;
    this.dynamicWorkflowName = dataItem.dataItem.dynamicWorkflow;
    this.dataservice.GetStandardWorkflowTasks(1, this.WorkflowID, 0).subscribe(data => {      
      
      
      this.ResponseWorkflowTasksData = data;
    });
  }

  OpenWFWindow() {
    this.WorkflowTaskActionsWindow = true;
    this.dynamictitle = "Add WF";  
  }

  /* setting the task info details in Edit mode*/
  public GetWorkflowTasksActionsData(CompanyID: any, WorkflowID: any, TaskIDs: any) {
    this.TaskId = TaskIDs;
    this.CompanyId = CompanyID;
    this.WorkflowID = WorkflowID;
    this.dataservice.GetStandardWorkflowTasks(CompanyID, WorkflowID, TaskIDs).subscribe(data => {
      this.ResponseWorkflowTasksDataitem = data[0];
      this.WorkFlowTaskEditDyForm.controls['SequenceId'].setValue(this.ResponseWorkflowTasksDataitem.sequenceID);
      this.WorkFlowTaskEditDyForm.controls['StepNumber'].setValue(this.ResponseWorkflowTasksDataitem.stepNumber);
      this.WorkFlowTaskEditDyForm.controls['ProcessFlow'].setValue(this.ResponseWorkflowTasksDataitem.processFlow);
      this.WorkFlowTaskEditDyForm.controls['IsCycle'].setValue(this.ResponseWorkflowTasksDataitem.isCycle);
      this.WorkFlowTaskEditDyForm.controls['taskId'].setValue(this.ResponseWorkflowTasksDataitem.rejectedParentTaskid);
       this.WorkFlowTaskEditDyForm.controls['Role'].setValue(this.ResponseWorkflowTasksDataitem.roleId);
      this.WorkFlowTaskEditDyForm.controls['IsTaskEditable'].setValue(this.ResponseWorkflowTasksDataitem.isTaskEditable);
      this.WorkFlowTaskEditDyForm.controls['IsTaskByPass'].setValue(this.ResponseWorkflowTasksDataitem.isTaskByPass);
      this.WorkFlowTaskEditDyForm.controls['IsSetTaskOwner'].setValue(this.ResponseWorkflowTasksDataitem.isSetTaskOwner);
      this.WorkFlowTaskEditDyForm.controls['IsAutoCompleteTask'].setValue(this.ResponseWorkflowTasksDataitem.isAutoCompleteTask);
      this.WorkFlowTaskEditDyForm.controls['IsAddApproversAction'].setValue(this.ResponseWorkflowTasksDataitem.isAddApproversAction);
      this.WorkFlowTaskEditDyForm.controls['IsSuspendCycle'].setValue(this.ResponseWorkflowTasksDataitem.isSuspendCycle);
      this.WorkFlowTaskEditDyForm.controls['IsFinalTask'].setValue(this.ResponseWorkflowTasksDataitem.isFinalTask);
      this.WorkFlowTaskEditDyForm.controls['EnableTaskEditFields'].setValue(this.ResponseWorkflowTasksDataitem.enableTaskEditFields);
      this.WorkFlowTaskEditDyForm.controls['EnableProjectSummaryFields'].setValue(this.ResponseWorkflowTasksDataitem.enableProjectSummaryFields);
      this.WorkFlowTaskEditDyForm.controls['EnableSubprocessEditFields'].setValue(this.ResponseWorkflowTasksDataitem.enableSubprocessEditFields);
      this.WorkFlowTaskEditDyForm.controls['EnableEditSubscribeFields'].setValue(this.ResponseWorkflowTasksDataitem.enableEditSubscribeFields);
      this.WorkFlowTaskEditDyForm.controls['EnableSubprocessIDis'].setValue(this.ResponseWorkflowTasksDataitem.enableSubprocessIDis);
      this.WorkFlowTaskEditDyForm.controls['IsTaskEditable'].setValue(this.ResponseWorkflowTasksDataitem.isTaskEditable);
      this.selectedRejectedParentTask="NA";

      this.selectedStepNumber = this.ResponseWorkflowTasksDataitem.stepNumber;
      this.isCyclevalue = this.ResponseWorkflowTasksDataitem.isCycle;
      this.isTaskEditable = this.ResponseWorkflowTasksDataitem.isTaskEditable;
      this.isTaskByPass = this.ResponseWorkflowTasksDataitem.isTaskByPass;
      this.isSetTaskOwner = this.ResponseWorkflowTasksDataitem.isSetTaskOwner;
      this.isAutoCompleteTask = this.ResponseWorkflowTasksDataitem.isAutoCompleteTask;
      this.isAddApproversAction = this.ResponseWorkflowTasksDataitem.isAddApproversAction ;
      this.isSuspendCycle = this.ResponseWorkflowTasksDataitem.isSuspendCycle ;
      this.isFinalTask = this.ResponseWorkflowTasksDataitem.isFinalTask;
      this.enableTaskEditFields = this.ResponseWorkflowTasksDataitem.enableTaskEditFields;
      this.enableProjectSummaryFields = this.ResponseWorkflowTasksDataitem.enableProjectSummaryFields;
      this.enableSubprocessEditFields = this.ResponseWorkflowTasksDataitem.enableSubprocessEditFields;
      this.enableEditSubscribeFields = this.ResponseWorkflowTasksDataitem.enableEditSubscribeFields;
      this.enableSubprocessIDis = this.ResponseWorkflowTasksDataitem.enableSubprocessIDis;
      var department = this.ResponseWorkflowTasksDataitem.departmentName;
      if (department) {
        let selected = this.departmentsData.find(function getDepartment(departmentsData) {
          return departmentsData.department === department;
        });
        this.selectedDepartment = selected.id;
      }
      else {
        this.selectedDepartment = department;
      }

      var group = this.ResponseWorkflowTasksDataitem.groupName;
      if (group) {
        let selected = this.groupsData.find(function getGroup(groupData) {
          return groupData.groupname === group;
        });
        this.selectedGroup = selected.id;
      }
      else {
        this.selectedGroup = group;
      }
      var user = this.ResponseWorkflowTasksDataitem.userName;
      if (user) {
        this.getusers(this.selectedGroup, this.CompanyId);
        setTimeout(() => {                           //<<<---using ()=> syntax
          let selected = this.usersData.find(function getGroup(userData) {
            return userData.userName === user;
          });
          this.selectedUser = selected.userId;
        }, 1000);

      }
      else {
        this.selectedUser = user;
      }

      var flow = this.ResponseWorkflowTasksDataitem.typeOfTask;
      if (flow) {
        let selected = this.processFlow.find(function getProcess(processdata) {
          return processdata.name === flow;
        });
        this.selecctedProcessFlow = selected.id;

      }
      else {
        this.selecctedProcessFlow = flow;
      }
      var rejectedTask = this.ResponseWorkflowTasksDataitem.rejectedParentTaskid;
      
      if (rejectedTask != "0") {
        this.disabled = false   
        this.RejectedParentTask= rejectedTask;
          }
          else {
            this.disabled = true   
            this.RejectedParentTask=null;
          }

      this.WorkFlowTaskEditDyForm.controls['DefaultDepartment'].setValue(parseInt(this.ResponseWorkflowTasksDataitem.departmentName));
      this.WorkFlowTaskEditDyForm.controls['DeafultGroup'].setValue(parseInt(this.ResponseWorkflowTasksDataitem.groupName));
      this.WorkFlowTaskEditDyForm.controls['DeafultUser'].setValue(parseInt(this.ResponseWorkflowTasksDataitem.userName));
      this.WorkFlowTaskEditDyForm.controls['RejectedParentTask'].setValue(parseInt(this.ResponseWorkflowTasksDataitem.rejectedParentTaskid));
      this.WorkflowTaskActionsWindow = true;
    });

  
  }
  /* updating the task info details*/
  updateTaskData() {
    if (this.WorkFlowTaskEditDyForm.invalid) {
      this.submitted = true;
      return;
    }
    this.updatedTaskdata.TaskId = this.TaskId;
    this.updatedTaskdata.ProcessFlow = this.selecctedProcessFlow;
    this.updatedTaskdata.StepNumber = parseInt(this.WorkFlowTaskEditDyForm.controls['StepNumber'].value);
    this.updatedTaskdata.SequenceID = parseInt(this.WorkFlowTaskEditDyForm.controls['SequenceId'].value);
    this.updatedTaskdata.DepartmentName = this.selectedDepartment.toString();
    this.updatedTaskdata.WorkflowID = this.WorkflowID;
    this.updatedTaskdata.IsCycle = this.isCyclevalue;
    this.updatedTaskdata.RejectWorkFlowTaskID = parseInt(this.WorkFlowTaskEditDyForm.controls['RejectedParentTask'].value);
    this.updatedTaskdata.IsTaskEditable = this.isTaskEditable;
    this.updatedTaskdata.IsTaskByPass = this.isTaskByPass;
    this.updatedTaskdata.IsSetTaskOwner = this.isSetTaskOwner;
    this.updatedTaskdata.IsAutoCompleteTask = this.isAutoCompleteTask;
    this.updatedTaskdata.IsAddApproversAction = this.isAddApproversAction ;
    this.updatedTaskdata.IsSuspendCycle = this.isSuspendCycle ;
    this.updatedTaskdata.IsFinalTask = this.isFinalTask ;
    this.updatedTaskdata.EnableTaskEditFields = this.enableTaskEditFields ;
    this.updatedTaskdata.EnableProjectSummaryFields = this.enableProjectSummaryFields;
    this.updatedTaskdata.EnableSubprocessEditFields = this.enableSubprocessEditFields ;
    this.updatedTaskdata.EnableEditSubscribeFields = this.enableEditSubscribeFields ;
    this.updatedTaskdata.EnableSubprocessIDis = this.enableSubprocessIDis;
    this.updatedTaskdata.GroupId = this.WorkFlowTaskEditDyForm.controls['DeafultGroup'].value != "" ? this.WorkFlowTaskEditDyForm.controls['DeafultGroup'].value.toString():null ;
  
    this.dataservice.UpdateWorkFlowTaskData(this.updatedTaskdata).subscribe(
      (res) => {

        this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "WFTIUS", this.CompanyId).subscribe((dataUActionsMessages) => {
          this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
        });
        this.loadWorkFlowData(this.CompanyId);
        this.WorkflowTaskActionsWindow = false;
      });
    }

  countriesClose() {
    this.WorkflowTaskActionsWindow = false;
  }
  public actionType(id: number): any {
    return this.actionTypes.find((x) => x.id === id);
  }
  onChange(e, param: any) {
    switch (param) {
      case 'isCycle':
        debugger
        this.isCyclevalue = e.target.defaultValue;
        break;
      case 'isTaskByPass':
        this.isTaskByPass = Boolean(JSON.parse(e.target.defaultValue));
        break;
      case 'isTaskEditable':
        this.isTaskEditable = Boolean(JSON.parse(e.target.defaultValue));
        break;
      case 'isSetTaskOwner':
        this.isSetTaskOwner = Boolean(JSON.parse(e.target.defaultValue));
        break;
      case 'isAutoCompleteTask':
        this.isAutoCompleteTask = Boolean(JSON.parse(e.target.defaultValue));
        break;
      case 'enableSubprocessIDis':
        this.enableSubprocessIDis = Boolean(JSON.parse(e.target.defaultValue));
        break;
      case 'isSuspendCycle':
        this.isSuspendCycle = Boolean(JSON.parse(e.target.defaultValue));
        break;
      case 'isFinalTask':
        this.isFinalTask = Boolean(JSON.parse(e.target.defaultValue));
        break;
      case 'enableTaskEditFields':
        this.enableTaskEditFields = Boolean(JSON.parse(e.target.defaultValue));
        break;
      case 'enableProjectSummaryFields':
        this.enableProjectSummaryFields = Boolean(JSON.parse(e.target.defaultValue));
        break;
      case 'enableSubprocessEditFields':
        this.enableSubprocessEditFields = Boolean(JSON.parse(e.target.defaultValue));
        break;
      case 'isAddApproversAction':
        this.isAddApproversAction = Boolean(JSON.parse(e.target.defaultValue));
        break;

      default:
        break;
    }
  }
  public onTabSelect(e: SelectEvent) {
    this.dynamictitle=e.title;
    if (e.title == 'Task Actions') {
      this.dataservice.GetStandardWorkflowTasksActions(this.CompanyId, this.WorkflowID, this.TaskId).subscribe(data => {
        this.ResponseWorkflowTasksActionsData = data;
        this.gridata = data;
      });
    }
  }


  public addHandler({ sender }) {
    this.closeEditor(sender);

    this.formGroup = this.createFormGroup({
      actionName: "",
      iseSignatureEnabled: 0,
      actionType: "",
      stepNumber: 1,
    });

    sender.addRow(this.formGroup);
  }
  public createFormGroup = (dataItem) =>
    new FormGroup({
      actionID: new FormControl(dataItem.actionTypeID),
      actionName: new FormControl(dataItem.actionName),
      iseSignatureEnabled: new FormControl(dataItem.iseSignatureEnabled, Validators.required),
      actionType: new FormControl(dataItem.actionType),
      stepNumber: new FormControl(
        dataItem.stepNumber,
        Validators.compose([
          Validators.required,
          this.stepNumberExistsValidator(),
        ])
      )
   
    });
  public getNames(id: number) {
    this.names = id
      ? this.actionTypes
        .map((item) => item.name)
      : this.gridata.map((item) => item.actionType);
    }
  
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.actinname = dataItem.actionName;
    this.getNames(dataItem.actionTypeID);
    this.formGroup = this.createFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, dataItem }): void {
    this.taskActionData = formGroup.value;
    this.taskActionData.WorkFlowTaskID = dataItem.workFlowTaskID;

    if (formGroup.value.actionType) {
      
  var item= this.actionTypes.filter((item) => item.name === formGroup.value.actionType)
        .map((item) => item.id);
      this.taskActionData.ActionTypeID = item[0];
    }
    this.dataservice.UpdateWorkFlowTaskActionData(this.taskActionData).subscribe(
      (res) => {
        this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "WFTAIUS", this.CompanyId).subscribe((dataUActionsMessages) => {
          this.notifications.show(dataUActionsMessages[0].displayMessage, dataUActionsMessages[0].messageType);
        });
        this.dataservice.GetStandardWorkflowTasksActions(this.CompanyId, this.WorkflowID, this.TaskId).subscribe(data => {          
          this.ResponseWorkflowTasksActionsData = data;
          this.gridata = data;
        });
        });
    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }): void {
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public handleGroupChange(e: SelectEvent, selectedGroup: any) {
    this.selectedUser = null;
    this.getusers(selectedGroup, this.CompanyId);
  }
  public handleRoleChange(event: any, selectedRole: any) {
    this.selectedGroup = null;
    this.getGroups(5, 0, 0, selectedRole);
  }
  
  stepNumberExistsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.rejectedParentTasks = this.ResponseWorkflowTasksData;
  
      var seletced = this.rejectedParentTasks.find(function getProcess(data) {
        return data.stepNumber.toString() === control.value;

      });
      if (seletced != undefined && seletced.stepNumber != this.selectedStepNumber) {
       return  { numberExists: true }
      }
      else {
      return null}
      //return seletced != undefined ? (seletced.stepNumber == this.selectedStepNumber ? null : { numberExists: true }) : { numberExists: true };
    }

  }

  //onDeptCheckAll(DeptId: number) {
  //  debugger;
  //  var elementAll = <HTMLInputElement>document.getElementById("chkDeptSelectALL" + DeptId);
  //  var isChecked = elementAll.checked;
  //  var checkBoxCheckIDs = []
  //  this.ResponseWorkflowTasksData.forEach(function (deptSource) {
  //    if (deptSource.WorkflowID == DeptId) {
  //      deptSource.taskList.forEach(function (value) {
  //        var element = <HTMLInputElement>document.getElementById("chkDeptradio" + DeptId + value.processDataID);
  //        if (element != null) {
  //          if (isChecked) {
  //            element.checked = true;
  //            checkBoxCheckIDs.push(parseInt(value.processDataID));
  //          } else {
  //            element.checked = false;
  //          }
  //        }
  //      });
  //    }
  //    else {
  //      var defelementAll = <HTMLInputElement>document.getElementById("chkDeptSelectALL" + deptSource.departmentID);
  //      defelementAll.checked = false;
  //      deptSource.taskList.forEach(function (value) {
  //        var element = <HTMLInputElement>document.getElementById("chkDeptradio" + deptSource.departmentID + value.processDataID);
  //        if (element != null)
  //          element.checked = false;
  //      });
  //    }
  //  })
  //}

}

interface Item { currentTask:string; taskId: number;}
