import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DataByLanguage, LanguageService } from '../services/language.service';
import { LoginService } from '../services/login.service';
import { GlobalVeriables } from '../Global/GlobalVaribales';
import { ModuleService } from '../services/module.service';
import { AdminUserManagementService } from '../services/adminusermanagement.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from 'rxjs';
import { HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';
import { NotificationAlertsService } from '../services/notification.service';
import { PDFDocument } from 'pdf-lib';
import { saveAs } from "@progress/kendo-file-saver";
import { CommonFeaturesService } from '../services/commonfeatures.service';


@Component({
  selector: 'app-download-files',
  templateUrl: './download-files.component.html',
  styleUrls: ['./download-files.component.css']
})
export class DownloadFilesComponent implements OnInit {
  passwordType: any = "password";
  //public languagesList = [];
  public languagesList = [];
  submitted: boolean = false;
  currentYear: any;
  progress: number;
  message: string;
  FileName: string;
  public languageData: DataByLanguage[] = [];
  selectedLanguage;
  lblErrorMsg: string;
  showElement: boolean = false;
  showLoader: boolean = false;
  IsLoginSuccess: boolean = false;
  showHeader: boolean = false;
  Loginobj = new Login();
  Showlogin: boolean = true;
  id: any;
  showFileGrid: boolean = true;
  publishedFilesData: any;
  checkBoxIDs: any[] = [];
  documentRepositoryID: any;
  isChecked: boolean;
  ExpiryDate: any;
  expiryPopUp: boolean = false;
  constructor(private formBuilder: FormBuilder, private notifyService: NotificationAlertsService, private rout: Router, private http: HttpClient,
    private languageService: LanguageService, private _loginService: LoginService, private GV: GlobalVeriables, private commonfeature: CommonFeaturesService,
    private moduleService: ModuleService, private amService: AdminUserManagementService, private router: ActivatedRoute,) { }

  loginForm = this.formBuilder.group({
    userName: ['', Validators.required],
    password: ['', Validators.required],
  })

  ngOnInit(): void {

    this.id = this.router.snapshot.paramMap.get('FileID').split(',').map(Number).toString();;
    this.FileName = this.router.snapshot.paramMap.get('FileName');
    this.ExpiryDate = this.router.snapshot.paramMap.get('expiryDate').toString();
    let dateObj = new Date();
    this.currentYear = dateObj.getUTCFullYear();
    this.GetAllLanguages();
    this.GetConvertedDate();
  }

  GetConvertedDate() {
    this.commonfeature.ConvertGUIDToDate(this.ExpiryDate).subscribe(response => {
      let dateConvert = new Date(response);
      if (new Date > dateConvert) {
        this.expiryPopUp = true;
        return
      }
    });
  }

  GetAllLanguages() {
    this.languageService.GetAllLanguages().subscribe(res => {
      this.languagesList = res;
      if (!localStorage.getItem("SelectedLanguageId")) {
        this.selectedLanguage = this.languagesList.find(x => x.isDefault == true);
        localStorage.setItem("SelectedLanguageId", this.selectedLanguage.id);
      } else {
        this.selectedLanguage = this.languagesList.find(x => x.id == parseInt(localStorage.getItem("SelectedLanguageId")));
      }
      this.GetDataByLanguageId(this.selectedLanguage.id);
    });
  }
  changeLanguage(value: any): void {
    if (this.selectedLanguage.id != value.id) {
      this.selectedLanguage = value;
      localStorage.setItem("SelectedLanguageId", this.selectedLanguage.id);
      this.GetDataByLanguageId(this.selectedLanguage.id);
      window.location.reload();
    }
  }
  GetDataByLanguageId(languageId: number) {
    this.languageService.GetDataByLanguageID(languageId).subscribe(res => {
      this.languageData = res;
      //this.isDataLoaded = true;
    });
  }

  Login() {

    if (this.loginForm.invalid) {
      this.submitted = true;
      return;
    }
    this.showLoader = true;
    this.Loginobj.userName = this.loginForm.controls['userName'].value;
    this.Loginobj.password = this.loginForm.controls['password'].value;
    this._loginService.userverify(this.Loginobj).subscribe(response => {
      this.lblErrorMsg = "";

      if (response.loginFailType == null) {
        localStorage.setItem("userLogin", "true");
        localStorage.setItem("userName", response.userName);
        localStorage.setItem("userId", response.userId);
        localStorage.setItem("userRoleID", response.roleID);
        localStorage.setItem("userRoleName", response.roleName);
        localStorage.setItem("userFirstName", response.firstName);
        localStorage.setItem("userLastName", response.lastName);
        localStorage.setItem("usercompanyID", response.companyID);
        localStorage.setItem("usertimeZone", response.timeZone);
        localStorage.setItem("OutofOfficeStatus", response.outofOfficeStatus); //to know the status is off or on
        this._loginService.isValid = true;
        localStorage.setItem("token", response.user_Token);
        localStorage.setItem("IsColumnDragged", "0");
        this.GV.setoutOfofficeState(response.outofOfficeStatus);
        this.showHeader = true;
        this._loginService.setHeaderShow(true);
        // this.moduleService.reoladAppComponent(0);
        this.GV.UserName = localStorage.getItem("userName");
        this.GV.UserId = +localStorage.getItem("userId");
        this.GV.UserRole = localStorage.getItem("userRoleName");
        this.GV.UserRoleID = +localStorage.getItem("userRoleID");
        this.GV.CompanyID = +localStorage.getItem("usercompanyID");
        this.GV.TimezoneID = +localStorage.getItem("usertimeZone");
        // this.curCompanyID = this.GV.CompanyID;
        // this.cuserRoleID = this.GV.UserRoleID;
        this.FileName=response.fileName;
        this.amService.GetUserPrivileges(this.GV.UserId, 1).subscribe(result => {
          this.GV.setUserPrivileges(result);
        })
        this.moduleService.DisplayTableView(200, 0, this.id, 0).subscribe(data => {
          this.publishedFilesData = data;
          this.showFileGrid = false;
        });
      }
      else {
        this.IsLoginSuccess = false;
        if (response.loginFailType == "NA")
          this.notifyService.show("You are not authorised to download this file!", 4);
        else if (response.loginFailType == "FN")
          this.notifyService.show("File is not available!", 4);
        else if (response.loginFailType == "UAN")
          this.notifyService.show("User is not available!", 4);
        else if (response.loginFailType == "INC")
          this.notifyService.show("You have entered invalid credentials!", 4);


      }
      this.showLoader = false;
    })
  }
  functionNr1(msg) {
    this.showElement = true;
    this.lblErrorMsg = this.languageData.find(x => x.LanguageKey == msg).Message;
    setTimeout(() => {
      this.lblErrorMsg = "";
      this.showElement = false;
    }, 3000)
  }
  functionInvalidNr1(msg, attempts) {
    this.showElement = true;
    this.lblErrorMsg = '';
    if (msg == 'Locked')
      this.lblErrorMsg = "Your account has been locked out due to multiple failed login attempts. Please contact Admin.";
    else if (msg == 'Invalid')
      this.lblErrorMsg = "Invalid credentials. You have " + attempts + " more attempt(s) before your account gets locked out.";
    setTimeout(() => {
      this.lblErrorMsg = "";
      this.showElement = false;
    }, 5000);
  }
  showPassword() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text'
    }
    else {
      this.passwordType = 'password'
    }
  }
  closeTab() {
    //window.close();
    //  window.self.close();
    window.open();

  }
  onCheck(rowIndex: any, event: any) {
    var element = <HTMLInputElement>document.getElementById("checkBox" + event.fileID);
    if (element.checked) {
      element.checked = true;
      if (rowIndex >= 0 && rowIndex < this.publishedFilesData.length)
        this.checkBoxIDs.push(this.publishedFilesData[rowIndex].fileID);
    }
    else {
      element.checked = false;
      var elementAll = <HTMLInputElement>document.getElementById("checkAll");
      if (elementAll != null) 
          elementAll.checked=false;

      if (rowIndex >= 0 && rowIndex < this.publishedFilesData.length)
        this.checkBoxIDs.splice(rowIndex, 1)
    }
  }

  onCheckAll() {

    var elementAll = <HTMLInputElement>document.getElementById("checkAll");
    if (elementAll != null) {

      for (let i = 0; i < this.publishedFilesData.length; i++) {
        var element = <HTMLInputElement>document.getElementById("checkBox" + this.publishedFilesData[i].fileID);
        if (element != null) {
          if (elementAll.checked) {
            element.checked = true;
            this.checkBoxIDs.push(this.publishedFilesData[i].fileID);

          } else {
            element.checked = false;
            this.checkBoxIDs = [];

          }
        }
      }

    }

  }


  downloadAllselectedFiles() {
    if (this.checkBoxIDs.length > 1) {
      this.documentRepositoryID = this.checkBoxIDs.join(',')
      this.FileName = this.languageService.GetActionMessageByLanguage('DAL_PBFL', 'Published_Files') + ".zip";
      this.moduleService.DownloadDalorReferenceFiles(this.documentRepositoryID, this.GV.UserId, this.GV.UserIpAddress).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round((100 * event.loaded) / event.total);
        else if (event.type === HttpEventType.Response) {

          this.downloadFile(event, this.FileName);
        }

      });
    }
    else {
      this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SMOPF', "select more than 1  Published file(s)"), 4);
    }
  }

  DownloadArtWorkPdfFile(Filepath: string, FileName: string, ProcessDataID: number) {
    this.FileName = FileName;
    this.moduleService.DownloadPdfFile(Filepath, FileName, ProcessDataID, 0, this.GV.UserId, this.GV.UserIpAddress).subscribe((event) => {

      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadPDF(event, FileName);

      }

    });


  }
  async downloadFile(data: HttpResponse<Blob>, FileName: any) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = FileName;//.substring(1).slice(0,-1);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  async downloadPDF(data: HttpResponse<Blob>, FileName: any) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const URLSaving = URL.createObjectURL(downloadedFile);
    const pdfData = await this.http.get(URLSaving, { responseType: 'arraybuffer' }).toPromise();
    const pdfDoc = await PDFDocument.load(pdfData);
    pdfDoc.setTitle(FileName);
    const modifiedPdfData = await pdfDoc.save();
    saveAs(new Blob([modifiedPdfData], { type: 'application/pdf' }), FileName);
  }

}
class Login {
  emailID: string;
  password: string;
  userName: string;
  roles: string;
}
