import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { HAMMER_LOADER } from '@angular/platform-browser';
import { getBaseUrl } from '../../main';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { RoleDetails, PriviligeDetails, Privilige, CountriesDetails, DepartmentDetails, UserGroupsDetails, UserDomains, MasterData, GroupsData, PDfExpAuditSave, PrivilegeMasterDTO, AllPrivileges, UserPrivilege, AuditTrailsDTOData, Languages, Companies, BusinessUnits, dropDownOptions, UserAndGroupsDetails, Office, OfficeAudit } from '../services/AMFormsDataDetails';
import { DALPublish } from '../ArtworkManagement/dynamic-fields/dynamic-field-list/dynamic-field-list.component';
import { ModuleService } from './module.service';
import { GlobalVeriables } from '../Global/GlobalVaribales';

@Injectable({
  providedIn: 'root'
})
export class AdminUserManagementService {

  userPrivileges: UserPrivilege[];

  //private MODULES = getBaseUrl() + "api/AdminManagement/GetModules/";
  //private MASTERMODULES = getBaseUrl() + "api/AdminManagement/GetMasterModules";

  private UMGetUsers = getBaseUrl() + "api/adminmanagement/UMGetUsers/";
  private UMGetRoles = getBaseUrl() + "api/adminmanagement/UMGetRoles";
  private UMGetAllPrivleges = getBaseUrl() + "api/adminmanagement/GetAllPrivleges";
  private SaveUsersData = getBaseUrl() + 'api/adminmanagement/saveFormUsersFieldsdata';

  private osaveRole = getBaseUrl() + "api/adminmanagement/SaveRoleData";
  private UMGetCountries = getBaseUrl() + "api/adminmanagement/UMGetCountries";
  private GetDepartments = getBaseUrl() + "api/adminmanagement/GetDepartments";
  private GetGroups = getBaseUrl() + "api/adminmanagement/GetGroups";
  private GetGroupsAndUsers = getBaseUrl() + "api/adminmanagement/GetGroupsAndUsers";
  private GetOffice = getBaseUrl() + "api/adminmanagement/GetOutOfOfficeData";
  private SaveOffice = getBaseUrl() + "api/adminmanagement/SaveOfficeData";
  private GetProjectArtworkComponents = getBaseUrl() + "api/artworkreview/GetProjectArtworkComponents";
  private GetDomainsData = getBaseUrl() + "api/adminmanagement/GetDomainsData";
  private GETGAATESREGIONS = getBaseUrl() + "api/adminmanagement/GetGAATESRegions";
  private GETGAATESMARKETS = getBaseUrl() + "api/adminmanagement/getGAATESMarkets";

  private GetDisabledUser = getBaseUrl() + "api/adminmanagement/GetDisabledUser";
  private GetCountriesData = getBaseUrl() + "api/adminmanagement/GetCountriesData";
  private UpdatelockOrUnlockUser = getBaseUrl() + "api/adminmanagement/UpdatelockOrUnlockUser";
  private GetAuditLogData = getBaseUrl() + "api/adminmanagement/GetAuditLogData";
  private GetAuditHistory = getBaseUrl() + "api/adminmanagement/GetAuditHistory";
  private GetDALHistorylog = getBaseUrl() + "api/adminmanagement/GetDALHistory";
  private GetRoleBasePrivleges = getBaseUrl() + "api/adminmanagement/GetRoleBasedPrivileges";
  private GetRoleData = getBaseUrl() + "api/adminmanagement/GetRoleData";

  private GetTimeZones = getBaseUrl() + "api/adminmanagement/GetTimeZones";
  private GetLanguages = getBaseUrl() + "api/adminmanagement/GetLanguagesList";
  private GetCompanies = getBaseUrl() + "api/adminmanagement/GetCompaniesList";
  private GetBusinessUnits = getBaseUrl() + "api/adminmanagement/GetBusinessUnitList";
  private GetDisabledRole = getBaseUrl() + "api/adminmanagement/GetDisabledRole";

  private UMManageAllMasterData = getBaseUrl() + "api/adminmanagement/UMManageAllMasterData/";
  private saveAllMasterData = getBaseUrl() + 'api/adminmanagement/saveAllMasterData';
  private GetGroupMasterData = getBaseUrl() + "api/adminmanagement/GetGroupMasterData";
  private GetAllDisabledGroupsData = getBaseUrl() + "api/adminmanagement/GetAllDisabledGroupsData";
  private SaveOrUpdateGroupsData = getBaseUrl() + 'api/adminmanagement/SaveOrUpdateGroupsData';
  private GetAllUsersPriv = getBaseUrl() + 'api/adminmanagement/GetAllUsersPriv';
  private GetAllExceptionDetailsData = getBaseUrl() + 'api/adminmanagement/GetAllExceptionDetailsData';
  private UMAllMasteDataNames = getBaseUrl() + 'api/adminmanagement/UMAllMasteDataNames';
  private GetActionEmailNotificationData = getBaseUrl() + 'api/commonfeatures/GetActionEmailNotification';
  private GetActionEmailNotificationDataDAL = getBaseUrl() + 'api/commonfeatures/GetActionEmailNotificationDAL';
  private DownloadAuditPdfFileUrl = getBaseUrl() + "api/commonfeatures/DownloadAuditPDFFile";
  private GetDownloadPdfFilePathUrl = getBaseUrl() + "api/commonfeatures/GetDownloadPDFFilePath";
  private GetSelectedRolesDataUrl = getBaseUrl() + "api/adminmanagement/GetSelectedRolesData";
  private oGetAllPrivileges = getBaseUrl() + "api/adminmanagement/GetAllPrivilegesData";
  private oGetUserPrivileges = getBaseUrl() + "api/adminmanagement/GetUserPrivileges";
  private getUserPrivilegesAccess = getBaseUrl() + "api/adminmanagement/GetUserPrivilegesAccess";
  private oAddUpdateRolePrivileges = getBaseUrl() + "api/adminmanagement/addUpdateRolePrivileges";
  private oGetPrivilegesByRoleId = getBaseUrl() + "api/adminmanagement/GetPrivilegesByRoleId";

  private oDeleteRole = getBaseUrl() + "api/adminmanagement/DeleteRole";
  private oGetAllAuditTrails = getBaseUrl() + "api/adminmanagement/GetAllAuditTrails";
  private savePrefernces = getBaseUrl() + "api/adminmanagement/SavePrefernces";
  private getPrefernces = getBaseUrl() + "api/adminmanagement/GetPrefernces";
  private getuserExist = getBaseUrl() + "api/adminmanagement/GetUserExists";
  private oVerifyGVInspection = getBaseUrl() + "api/adminmanagement/VerifyGVInspection";
  private osendSourcefileGVAPI = getBaseUrl() + "api/adminmanagement/sendSourcefileGVAPI";
  private oFetchReportInspection = getBaseUrl() + "api/adminmanagement/FetchReportInspection";
  private saveUserSettings = getBaseUrl() + "api/adminmanagement/SaveUserSettings";
  private getUserSettings = getBaseUrl() + "api/adminmanagement/GetUserSettings";
  private PHGetAllPlaceHolderFiles = getBaseUrl() + 'api/adminmanagement/AllPlaceHolderFiles';
  private savePlaceHolderfiles = getBaseUrl() + 'api/adminmanagement/SavePlaceHolderFiles';
  private deletePlaceHolderFile = getBaseUrl() + 'api/adminmanagement/DeletePlaceHolder';

  constructor(private httpClient: HttpClient, private moduleService: ModuleService, private GV: GlobalVeriables) { }

  public GetAllPlaceHolderFilesData(TypeID: number, PlaceHolderID: number, CompanyID: number) {
    return this.httpClient.get<any>(this.PHGetAllPlaceHolderFiles, {});
  }

  public UMAllMasteDataNamesData() {
    return this.httpClient.get<any>(this.UMAllMasteDataNames, {});
  }

  public GetAllAuditTrails(CompanyID: number, ModuleID: number, fromDate: string, toDate: string, auditType: string) {
    let params = new HttpParams();
    params = params.set('CompanyID', CompanyID.toString());
    params = params.set('ModuleID', ModuleID.toString());
    params = params.set('fromDate', fromDate.toString());
    params = params.set('toDate', toDate.toString());
    if (auditType == null || auditType == '')
      params = params.set('auditType', '0');
    else
      params = params.set('auditType', auditType.toString());

    return this.httpClient.get<AuditTrailsDTOData[]>(this.oGetAllAuditTrails, { params: params });
  }

  public getUMGetUsers(OperationType: number, UserID: number, CompanyID: number) {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<any>(this.UMGetUsers, { params: params });
  }

  public getUMManageAllMasterData(OperationType: number, MasteDataType: number, MasteDataID: number, CompanyID: number) {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('MasteDataType', MasteDataType.toString());
    params = params.set('MasteDataID', MasteDataID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<any>(this.UMManageAllMasterData, { params: params });
  }


  public GetUTCOffset() {

    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes;

    //let params = new HttpParams();
    //params = params.append('offset', offset);

    return this.httpClient.get<any>(offset, {});
  }

  DeleteMasterData(MasteDataType: number, MasteDataID: number, UserID: number) {
    //debugger;
    return this.httpClient.delete(getBaseUrl() + 'api/adminmanagement/DeleteMasterData?MasteDataType=' + MasteDataType + "&MasteDataID=" + MasteDataID + "&UserID=" + UserID);
  }

  osaveAllMasterData(DynamicUserFormdataobj) {
    //debugger;
    return this.httpClient.post<any>(this.saveAllMasterData, DynamicUserFormdataobj);
  }

  public getUMGetRoles(): Observable<RoleDetails[]> {
    return this.httpClient.get<RoleDetails[]>(this.UMGetRoles);
  }


  public getUMGetAllPrivleges(OperationType: number, UserID: number, CompanyID: number): Observable<PriviligeDetails[]> {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<PriviligeDetails[]>(this.UMGetAllPrivleges, { params: params });
  }

  public getGetProjectArtworkComponents(TemplateID: number, PrjID: number, SPID: number, UserID: number, CompanyID: number, tType: number, taskId: number, cycleId: number, RecordID: number, workFlowId: number, Ip: string, languageId: string, token: string, ssoLogin: boolean) {
    let params = new HttpParams();
    params = params.set('TemplateID', TemplateID.toString());
    params = params.set('PrjID', PrjID.toString());
    params = params.set('SPID', SPID.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    params = params.set('tType', tType.toString());
    params = params.set('TaskId', taskId.toString());
    params = params.set('CycleId', cycleId.toString());
    params = params.set('RecordID', RecordID.toString());
    params = params.set('WorkFlowId', workFlowId.toString());
    if (this.GV.UserIpAddress != undefined && this.GV.UserIpAddress != "" && this.GV.UserIpAddress != null)
      params = params.set('Ip', this.GV.UserIpAddress.toString());
    else
      params = params.set('Ip', "127.0.0.1");

    params = params.set('LanguageID', languageId);
    params = params.set('token', token);
    params = params.set('ssoLogin', ssoLogin);
    //debugger;
    return this.httpClient.get<any>(this.GetProjectArtworkComponents, { params: params });
  }

  public saveRoleData(objPrivilige: Privilige) {
    // debugger;
    return this.httpClient.post<any>(this.osaveRole, objPrivilige);
  }

  //public getUMGetAllPrivleges() {

  //  return this.httpClient.get(this.UMGetAllPrivleges);
  //}
  public GetDisplayFieldData(templateID: number, parentRecordID: number) {


    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes


    let params = new HttpParams();
    params = params.set('templateID', templateID.toString());
    params = params.set('parentRecordID', parentRecordID.toString());

    params = params.append('offset', offset);

  }


  saveFormUsersFieldsdata(DynamicUserFormdataobj) {
    return this.httpClient.post<any>(this.SaveUsersData, DynamicUserFormdataobj);
  }


  public getUMGetCountries(): Observable<CountriesDetails[]> {
    return this.httpClient.get<CountriesDetails[]>(this.UMGetCountries);
  }


  public getUserDepartments(): Observable<DepartmentDetails[]> {
    return this.httpClient.get<DepartmentDetails[]>(this.GetDepartments);
  }

  public getUMUserGroups(OperationType: number, UserID: number, CompanyID: number, RoleID: any): Observable<UserGroupsDetails[]> {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    params = params.set('RoleID', RoleID.toString());
    return this.httpClient.get<UserGroupsDetails[]>(this.GetGroups, { params: params });
  }

  public getUMUserAndGroups(OperationType: number, UserID: number, GroupID: number, RoleID: any): Observable<UserAndGroupsDetails[]> {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('GroupID', GroupID.toString());
    params = params.set('RoleID', RoleID.toString());
    return this.httpClient.get<UserAndGroupsDetails[]>(this.GetGroupsAndUsers, { params: params });
  }

  public getOfficedata(OperationType: number, UserID: number, GroupID: number, RoleID: any): Observable<any> {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('GroupID', GroupID.toString());
    params = params.set('RoleID', RoleID.toString());
    return this.httpClient.get<any>(this.GetOffice, { params: params });
  }

  public saveOfficeData(data: OfficeAudit) {
    return this.httpClient.post<Office>(this.SaveOffice, data);
  }



  public getUserDomains(): Observable<UserDomains[]> {
    return this.httpClient.get<UserDomains[]>(this.GetDomainsData);
  }

  public getGAATESRegions(): Observable<dropDownOptions[]> {
    return this.httpClient.get<dropDownOptions[]>(this.GETGAATESREGIONS);
  }


  public getGAATESMarkets(RegionID: string): Observable<dropDownOptions[]> {

    let params = new HttpParams();
    params = params.set('RegionID', RegionID.toString());
    return this.httpClient.get<dropDownOptions[]>(this.GETGAATESMARKETS, { params: params });
  }
  DeleteUser(userid: number, CurrentUserid: number) {
    let params = new HttpParams();
    params = params.set('userid', userid.toString());
    params = params.set('CurrentUserid', CurrentUserid.toString());
    return this.httpClient.get<any>(getBaseUrl() + 'api/adminmanagement/DeleteUser/', { params: params });
  }

  //Disabled users
  public getDisabledUsers(OperationType: number, UserID: number, CompanyID: number) {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<any>(this.GetDisabledUser, { params: params });
  }

  public getCountriesData(OperationType: number, UserID: number, CompanyID: number) {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<any>(this.GetCountriesData, { params: params });
  }


  lockOrUnlockUser(Lockdata) {
    //debugger;
    return this.httpClient.post<any>(this.UpdatelockOrUnlockUser, Lockdata);
  }

  //int keyID, int TablenumID, int userID, int CompanyID
  public AuditLogData(keyID: number, TablenumID: number, userID: number, CompanyID: number) {
    let params = new HttpParams();
    params = params.set('keyID', keyID.toString());
    params = params.set('TablenumID', TablenumID.toString());
    params = params.set('userID', userID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<any>(this.GetAuditLogData, { params: params });
  }
  public AuditHistory(keyID: number, TablenumID: number, userID: number, CompanyID: number, TZoffset?: string) {
    let params = new HttpParams();
    params = params.set('keyID', keyID.toString());
    params = params.set('TablenumID', TablenumID.toString());
    params = params.set('userID', userID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    params = params.set('OffSet', TZoffset);
    return this.httpClient.get<any>(this.GetAuditHistory, { params: params });
  }

  public GetDALHistory(keyID: number, TablenumID: number, userID: number, CompanyID: number) {
    let params = new HttpParams();
    params = params.set('keyID', keyID.toString());
    params = params.set('TablenumID', TablenumID.toString());
    params = params.set('userID', userID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<any>(this.GetDALHistorylog, { params: params });
  }


  public GetSelectedPrivilages(OperationType: number, roleid: number) {
    let params = new HttpParams();
    //params = params.set('OperationType', OperationType.toString());
    params = params.set('roleid', roleid.toString());
    return this.httpClient.get<any>(this.GetRoleBasePrivleges, { params: params });
  }

  public LoadrolesDahsboard(OperationType: number, roleID: number) {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('roleID', roleID.toString());
    return this.httpClient.get<any>(this.GetRoleData, { params: params });
  }

  public LoadTimeZonesDahsboard() {
    return this.httpClient.get<any>(this.GetTimeZones);
  }

  public getlanguages() {
    return this.httpClient.get<Languages[]>(this.GetLanguages);
  }

  public getCompanies() {
    return this.httpClient.get<Companies[]>(this.GetCompanies);
  }

  public getBusinessUnits(companyId: number) {
    let params = new HttpParams();
    params = params.set('companyId', companyId);
    return this.httpClient.get<BusinessUnits[]>(this.GetBusinessUnits, { params: params });
  }

  public DeleteRoles(roleid: number, userID: number) {

    let params = new HttpParams();
    params = params.set('roleid', roleid.toString());
    params = params.set('userID', userID.toString());
    //return this.httpClient.delete(getBaseUrl() + 'api/adminmanagement/DeleteRole/', { params: params });
    return this.httpClient.get<any>(getBaseUrl() + 'api/adminmanagement/DeleteRole', { params: params });

    //  return this.httpClient.get<any>(this.oDeleteRole, { params: params });
  }

  public getDisabledRoles(OperationType: number, roleid: number) {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('roleid', roleid.toString());
    return this.httpClient.get<any>(this.GetRoleData, { params: params });

  }

  public AllExceptionDetailsData(NoofRecords: number, PageNo: number, MasteDataID: number, CompanyID: number) {
    let params = new HttpParams();
    params = params.set('NoofRecords', NoofRecords.toString());
    params = params.set('PageNo', PageNo.toString());
    params = params.set('MasteDataID', MasteDataID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<any>(this.GetAllExceptionDetailsData, { params: params });
  }

  public getGroupsData(OperationType: number, GroupID: number) {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('GroupID', GroupID.toString());
    return this.httpClient.get<any>(this.GetGroupMasterData, { params: params });
  }
  //DeleteSingleGroup(groupId: number, userid: number,comments:string) {
  //  let params = new HttpParams();
  //  params = params.set('groupId', groupId.toString());
  //  params = params.set('userid', userid.toString());
  //  params = params.set('comments', comments);
  //  return this.httpClient.delete(getBaseUrl() + 'api/adminmanagement/DeleteGroup/', { params: params });
  //}

  DeleteSingleGroup(groupId: number, userid: number, comments: string) {
    let params = new HttpParams();
    params = params.set('groupId', groupId.toString());
    params = params.set('userid', userid.toString());
    params = params.set('comments', comments);
    return this.httpClient.get<any>(getBaseUrl() + 'api/adminmanagement/DeleteGroup/', { params: params });
  }

  public getDisabledGroups(OperationType: number, groupid: number) {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('groupid', groupid.toString());
    return this.httpClient.get<any>(this.GetAllDisabledGroupsData, { params: params });

  }

  public saveGroupsData(objGroupsdata: GroupsData) {
    //debugger;
    return this.httpClient.post<any>(this.SaveOrUpdateGroupsData, objGroupsdata);
  }

  public getUsersGroup(OperationType: number, roleID: any, GroupID: any) {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('roleID', roleID.toString());
    params = params.set('GroupID', GroupID.toString());
    return this.httpClient.get<any>(this.GetAllUsersPriv, { params: params });

  }
  // Get notification details to send data
  public GetActionEmailNotification(TriggerEvent: number, TriggerType: number, ProjectID: number, SubProcessID: number, TaskID: any, WorkFlowID: number, CompanyID: number, Cycle: number, Offset: string) {
    let params = new HttpParams();
    params = params.set('TriggerEvent', TriggerEvent.toString());
    params = params.set('TriggerType', TriggerType.toString());
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('SubProcessID', SubProcessID.toString());
    params = params.set('TaskID', TaskID.toString());
    params = params.set('WorkFlowID', WorkFlowID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    params = params.set('Cycle', Cycle.toString());
    params = params.set('Offset', Offset.toString());
    return this.httpClient.get<any>(this.GetActionEmailNotificationData, { params: params });
  }


  // Get notification details to send data
  public GetActionEmailNotificationDAL(DalPublish: DALPublish) {

    let params = new HttpParams();
    params = params.set('ID', DalPublish.ID.toString());
    params = params.set('DynFormFieldData', DalPublish.DynFormFieldData.toString());
    params = params.set('ModifiedUser', DalPublish.ModifiedUser.toString());
    params = params.set('DocumentsPath', this.moduleService.getBaseUrl() + "documents/");
    params = params.set('UserID', DalPublish.UserID);
    params = params.set('Ip', DalPublish.Ip);
    return this.httpClient.get<any>(this.GetActionEmailNotificationDataDAL, { params: params });



  }

  public DownloadAuditPdfFile(ObjDataset) {
    //let params = new HttpParams();
    //params = params.set('HTMLContent', HTMLContent.toString());
    //params = params.set('offset', offset.toString());
    //params = params.set('TableEnum', TableEnum.toString());
    return this.httpClient.post<any>(this.DownloadAuditPdfFileUrl, ObjDataset);
  }

  GetDownloadAuditPdfFilePath(Filepath: string, FileName: string) {
    return this.httpClient.get(this.GetDownloadPdfFilePathUrl + "?filePath=" + Filepath + "&fileName=" + FileName, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }

  public getSelectedRolesData(OperationType: number, roleID: any, GroupID: any) {
    let params = new HttpParams();
    params = params.set('OperationType', OperationType.toString());
    params = params.set('roleID', roleID.toString());
    params = params.set('GroupID', GroupID.toString());
    return this.httpClient.get<any>(this.GetSelectedRolesDataUrl, { params: params });
  }

  public GetAllPrivileges(): Observable<PrivilegeMasterDTO[]> {

    return this.httpClient.get<PrivilegeMasterDTO[]>(this.oGetAllPrivileges);
  }

  public savePrefernce(data: any, user: string) {
    let params = new HttpParams();
    params = params.set('User', user);
    return this.httpClient.post<any>(this.savePrefernces, data, { params: params });

  }

  public getPrefernce(userId: any) {
    let params = new HttpParams();
    params = params.set('userId', userId.toString());
    return this.httpClient.get<any>(this.getPrefernces, { params: params });

  }
  public SaveUserSettingsData(saveUserSettings: any) {

    return this.httpClient.post<string>(this.saveUserSettings, saveUserSettings);
  }

  public GetUserSettingsData() {
    return this.httpClient.get<any>(this.getUserSettings);
  }

  public getUserExists(userId: any, CompanyId: any) {
    let params = new HttpParams();
    params = params.set('userId', userId.toString());
    params = params.set('companyId', CompanyId.toString());
    return this.httpClient.get<any>(this.getuserExist, { params: params });

  }

  public GetUserPrivileges(UserID: number, CompanyID: number): Observable<UserPrivilege[]> {

    let params = new HttpParams();
    //params = params.set('OperationType', OperationType.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('CompanyID', CompanyID.toString());
    return this.httpClient.get<UserPrivilege[]>(this.oGetUserPrivileges, { params: params });
  }

  public GetUserPrivilegesAccess(userID: number, roleID: number, routeName: string) {

    let params = new HttpParams();
    params = params.set('userID', userID);
    params = params.set('roleID', roleID);
    params = params.set('routeName', routeName);
    return this.httpClient.get<any>(this.getUserPrivilegesAccess, { params: params });
  }

  public addUpdateRolePrivileges(privilegeObj: AllPrivileges) {
    return this.httpClient.post<any>(this.oAddUpdateRolePrivileges, privilegeObj);
  }

  public GetPrivilagesByRoleId(roleid: number): Observable<AllPrivileges> {
    let params = new HttpParams();
    //params = params.set('OperationType', OperationType.toString());
    params = params.set('roleid', roleid.toString());
    return this.httpClient.get<AllPrivileges>(this.oGetPrivilegesByRoleId, { params: params });
  }

  public VerifyGVInspection(fileid1: string, fileid2: string) {
    let params = new HttpParams();
    params = params.set('fileid1', fileid1.toString());
    params = params.set('fileid2', fileid2.toString());
    return this.httpClient.get<any>(this.oVerifyGVInspection, { params: params });
  }
  public SendSourcefileGVAPI(sourcefilename: string, filesequence: string) {
    let params = new HttpParams();
    params = params.set('sourcefilename', sourcefilename.toString());
    params = params.set('filesequence', filesequence.toString());
    return this.httpClient.get<any>(this.osendSourcefileGVAPI, { params: params });

  }
  public FetchReportInspection(inspectionid: string) {
    let params = new HttpParams();
    params = params.set('inspectionid', inspectionid.toString());
    return this.httpClient.get<any>(this.oFetchReportInspection, { params: params });

  }
  public GetPrivilegeAccesType(ActionID: string, LevelID: string): any {
    //Level 1--module
    //Level 2-- sub module
    //Level 3 --Actions
    //Level 4 --Template tab Actions

    this.userPrivileges = this.GV.GetUserPrivileges();
    var returnClass = "";
    var accessType = '';

    if (this.userPrivileges == null || this.userPrivileges == undefined) {

    } else {


      if (LevelID == '4') {
        if (this.userPrivileges.filter(a => a.templateTabID == ActionID && a.levelID == '3').length > 0) {
          accessType = this.userPrivileges.filter(a => a.templateTabID == ActionID && a.levelID == '3')[0].accessType;
        }

      } else {
        if (this.userPrivileges.filter(a => a.privilegeID == ActionID && a.levelID == LevelID).length > 0) {
          accessType = this.userPrivileges.filter(a => a.privilegeID == ActionID && a.levelID == LevelID)[0].accessType;
        }
      }
      if (accessType == '1') {
        // returnClass = "display-block";
      } else if (accessType == '2') {
        returnClass = "disable-btn";

      } else if (accessType == '3') {
        returnClass = "display-none";

      }

      if ((LevelID == "1" || LevelID == "2") && (accessType == '2' || accessType == '1')) {
        returnClass = "";
      }
    }




    return returnClass;

  }

  //Service call for the place holder files saving 
  public savePlaceHolderFiles(files: any): Observable<any> {
    return this.httpClient.post<any>(this.savePlaceHolderfiles, files);
  }

  //service call for delete the place holder file
  public deletePlaceHolder(placeHolderID: number, UserID: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('placeHolderID', placeHolderID);
    params = params.set('UserID', UserID);
    return this.httpClient.delete<any>(this.deletePlaceHolderFile, { params: params });
  }

}

class roleData {
  id: string
  roleDescription: string
  roleName: string
}

export class NotificationPrefernces {
  emailNotificationsMasterID: number;
  UserCheckedNotification: string
  notifiedUsersType: number
  templateName: string;
}

export class NotificationEmailTriggerEvent {
  ProjectInitiation: number = 1;
  ProjectStarted: number = 2;
  PreviousArtworkAvailableinDAL: number = 3;
  AnnotatedfilesrequiredfromExternalDrive: number = 4;
  NotificationGraphicArtist: number = 5;
  NotificationtoProofReader: number = 6;
  NotificationtoRA: number = 7;
  NotificationtoMKT: number = 8;
  NotificationtoPackagingApprover: number = 9;
  NotificationtoCustomerApprover: number = 10;
  NotificationtoQAReviewer: number = 11;
  NotificationtoQAApprover: number = 12;
  NotificationtoGraphicArtist: number = 13;
  NotificationtoInitiator: number = 29;
  NotificationtoSetProjectOwner: number = 30;
  NotificationtoSuspend: number = 33;
  NotificationtoResume: number = 34;
  NotificationtoCancel: number = 46;
  NotificationtoSetTaskOwner: number = 32;
  NotificationtoProjectBypassed: number = 37;
  NotificationtoProjectUnBypassed: number = 38;
  NotificationtoSubprocessBypassed: number = 39;
  NotificationtoSubProcessUnBypassed: number = 40;
  NotificationtoClaimTask: number = 41;
  NotificationtoUnClaimTask: number = 42;
  NotificationtoDeligateTask: number = 43;
  NotificationSubscriber: number = 44;
  NotificationtoAddApproverTask: number = 48;
  NotificationtoSuspendCycleTask: number = 50;
  NotificationResumeCycleTask: number = 51;
  NotificationtoCancelCycleTask: number = 69;
  NotificationProjectInvite: number = 52;
  NotificationChatMessage: number = 53;
  NotificationtoProjectcompleted: number = 54;
  NotificationtoReassignTasksUser: number = 55;
  NotificationtoDisabledUser: number = 56;
  NotificationtoForgotLoginandESignPasswordUser: number = 57;
  NotificationtoForUserLockOrUnlock: number = 58;
  NotificationtoForUserAddOrUpdateOrDeactivateGroup = 59;
  NotificationForOutOfOffice = 61;
  NotificationForResourceGroup = 66;
  NotificationForUpdatePassword = 67;
  NotificationForUpdateEPassword = 68
  NotificationForCycleCompleted: number = 70;
}
@Injectable({
  providedIn: 'root'
})

export class GetAuditAllFieldName {
  public GetFieldName(filedname: string, TableEnum: any) {
    var returnFieldName = filedname
    switch (TableEnum) {
      case 23:
        switch (filedname) {
          case "firstName": returnFieldName = "First Name"; break;
          case "lastname": returnFieldName = "Last Name"; break;
          case "username": returnFieldName = "User Name"; break;
          case "emailID": returnFieldName = "Email"; break;
          case "PhoneNumber": returnFieldName = "Phone Number"; break;
          case "Address": returnFieldName = "Address"; break;
          case "AdminRoleSelected": returnFieldName = "Admin Role Selected"; break;
          case "roleName": returnFieldName = "Role"; break;
          //case "Regions": returnFieldName = "Region"; break;
          case "countryName": returnFieldName = "Countries"; break;
          case "IsEsignature": returnFieldName = "Esignature"; break;
          case "IsActive": returnFieldName = "Status"; break;
          case null: returnFieldName = "Status"; break;
          case "Reason": returnFieldName = "Reason"; break;
          //case "departmentName": returnFieldName = "Departement"; break;
          case "gropuName": returnFieldName = "Groups"; break;
          case "domainName": returnFieldName = "Domain"; break;
          case "groupowner": returnFieldName = "Group Owner"; break;
        }
        break;
      case 24:
        switch (filedname) {
          case "roleName": returnFieldName = "Role"; break;
          case "GroupName": returnFieldName = "Name"; break;
          case "RoleDescription": returnFieldName = "Description"; break;
          case "RoleGroups": returnFieldName = "Select Role(s)"; break;
          case "EditAccess": returnFieldName = "Edit Access"; break;
          case "ReadOnlyAccess": returnFieldName = "ReadOnly Access"; break;
          case "NoAccess": returnFieldName = "No Access"; break;
          case "IsAdmin": returnFieldName = "Is Admin"; break;
          case "privileges": returnFieldName = "Privileges"; break;
        }
        break;
      case 22:
        switch (filedname) {
          case "GroupName": returnFieldName = "Group Name"; break;
          case "Description": returnFieldName = "Description"; break;
          case "Users": returnFieldName = "Users"; break;
          case "Type": returnFieldName = "Type"; break;
          case "masterGroupName": returnFieldName = "Master Group"; break;

        }
        break;
      case 4:
        switch (filedname) {
          //case "StatusName": returnFieldName = "Status"; break;
          //case "Description": returnFieldName = "Description"; break;

          case "MasterDataName": returnFieldName = "Name"; break;
          case "MasterDataDescsiption": returnFieldName = "Description"; break;
          case "MasterDataFlag": returnFieldName = "Flag"; break;
          case "MasterDataCode": returnFieldName = "Code"; break;

        }
        break;
      case 29:
        switch (filedname) {
          case "Comments": returnFieldName = "Comments"; break;
          case "NotificationPreferences": returnFieldName = "Notification Preferences"; break;
          case "Tasks": returnFieldName = "Bypass/Unbypass Tasks"; break;
          case "ByPassLevel": returnFieldName = "Bypass Level"; break;
          case "ListOfTasks": returnFieldName = "List Of Tasks"; break;
          case "SuspendndResumeCycleTasks": returnFieldName = "Suspend/Resume Cycle Tasks"; break;
        }
        break;
      case 1056:
        switch (filedname) {
          case "PwdExpiryDays": returnFieldName = "Login Password Expiry Days"; break;
          case "PwdFailedAttempts": returnFieldName = "Max Login Password Failed Attempts"; break;
          case "PwdHistoryLimit": returnFieldName = "Login Password history Limit"; break;
          case "EsignPwdExpiryDays": returnFieldName = "E-Signature password Expiry Days"; break;
          case "MaxEsignPwdFailedAttempts": returnFieldName = "Max-E-Signature Password-Failed Attempts"; break;
          case "EsignPwdhistoryLimit": returnFieldName = "E-Signature Password History Limit"; break;
          case "PwdMinCharacters": returnFieldName = "Password Minimum Characteristics"; break;
          case "ReqSpecialCharacters": returnFieldName = "Required Special Characteristics"; break;
          case "ReqUppercaseCharacters": returnFieldName = "Required Upper Case Characters"; break;
          case "ReqNumericCharacters": returnFieldName = "Required Numeric Characters"; break;
          case "ReqLowercaseCharacters": returnFieldName = "Required Lower Case Characters"; break;
          case "EmailNotiDays": returnFieldName = "E-Mail Notification Days"; break;
        }
        break
      case 1057:
        switch (filedname) {
          case "IsProxy": returnFieldName = "Proxy Or Escalate"; break;
          case "ProxyName": returnFieldName = "Proxy Name"; break;


        }
        break;
      case 1:
        switch (filedname) {
          case "MasterDataName": returnFieldName = "Name"; break;
          case "MasterDataDescsiption": returnFieldName = "Description"; break;
        }
        break;
      case 5:
        switch (filedname) {
          case "MasterDataName": returnFieldName = "Name"; break;
          case "MasterDataDescsiption": returnFieldName = "Description"; break;
        }
        break;
      case 7:
        switch (filedname) {
          case "MasterDataName": returnFieldName = "Name"; break;
          case "MasterDataDescsiption": returnFieldName = "Description"; break;
        }
        break;
      case 8:
        switch (filedname) {
          //case "StatusName": returnFieldName = "Status"; break;
          //case "Description": returnFieldName = "Description"; break;

          case "MasterDataName": returnFieldName = "Name"; break;
          case "MasterDataDescsiption": returnFieldName = "Description"; break;
          case "MasterDataFlag": returnFieldName = "Flag"; break;
          case "MasterDataCode": returnFieldName = "Code"; break;

        }
        break;
      case 9:
        switch (filedname) {
          case "MasterDataName": returnFieldName = "Name"; break;
          case "MasterDataDescsiption": returnFieldName = "Description"; break;
        }
        break;
      case 15:
        switch (filedname) {
          case "MasterDataName": returnFieldName = "Name"; break;
          case "MasterDataDescsiption": returnFieldName = "Description"; break;
        }
        break;
      case 16:
        switch (filedname) {
          case "MasterDataName": returnFieldName = "Name"; break;
          case "MasterDataDescsiption": returnFieldName = "Description"; break;
        }
        break;
      case 17:
        switch (filedname) {
          case "MasterDataName": returnFieldName = "Name"; break;
          case "MasterDataDescsiption": returnFieldName = "Description"; break;
        }
        break;
      case 18:
        switch (filedname) {
          case "MasterDataName": returnFieldName = "Name"; break;
          case "MasterDataDescsiption": returnFieldName = "Description"; break;
        }
        break;
      default:
        break;
    }
    return returnFieldName;
  }
}
// Convert UTC to Local TimeZone

@Injectable({
  providedIn: 'root'
})

export class DateConvertions {
  public convertdate(date: any) {
    //loader = $rootScope.loading;
    const TZoffset = this.setUTCTimeZone();
    var parseDate = new Date(Date.parse(date));
    var aDate = new Date(parseDate.getTime() + this.setUTCTimeZoneInMinutes() * 60000);//UTC Time COnversion to local timezone
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var month = aDate.getMonth();
    var day = aDate.getDate() < 10 ? "0" + aDate.getDate() : aDate.getDate();
    var Year = aDate.getFullYear();
    var dates = day + " " + months[month] + " " + Year;
    var hours = aDate.getHours();
    hours = hours < 10 ? 0 + hours : hours;
    var minutes = aDate.getMinutes() < 10 ? "0" + aDate.getMinutes() : aDate.getMinutes();
    var seconds = aDate.getSeconds() < 10 ? "0" + aDate.getSeconds() : aDate.getSeconds();
    //var am_pm = aDate.getHours() >= 12 ? "PM" : "AM";
    //var time = hours + ":" + minutes + ":" + seconds + " " + am_pm;  
    var time = hours + ":" + minutes + ":" + seconds;
    var utctime = dates + " " + time;
    return dates + " " + time;


  }

  public setUTCTimeZone() {
    var hours;
    var setHrs = '';
    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();
    const Offset = -(addTimeZoneOffSet);
    //negative offset for method
    //var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = "+" + (hours);

    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    return hours + ':' + minutes
  }

  public setUTCTimeZoneInMinutes() {
    var currDate = new Date();
    var addTimeZoneOffSet = currDate.getTimezoneOffset();
    const Offset = -(addTimeZoneOffSet);

    return Offset


  };

}
