/// <reference path="services/dialog-width.service.ts" />
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutModule } from "@progress/kendo-angular-layout"
import { AppComponent } from './app.component';
import { DynamicFormComponent } from './ArtworkManagement/dynamic-forms/dynamic-form.component';
import { DynamicFormDynamicFieldComponent } from './ArtworkManagement/dynamic-forms/dynamic-form-field.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationModule } from "@progress/kendo-angular-notification";
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { EditorModule } from '@progress/kendo-angular-editor';
import { UploadModule } from '@progress/kendo-angular-upload';
import { MenusModule } from '@progress/kendo-angular-menu';
//import { ProgressBarModule } from '@progress/kendo-angular-';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtHelperService, JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { NotificationService } from '@progress/kendo-angular-notification';
import { PopupModule } from '@progress/kendo-angular-popup';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { ToastrModule } from 'ngx-toastr';
import { DynamicFieldGridComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-grid/dynamic-field-grid.component';
import { DynamicFieldListComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-list/dynamic-field-list.component';
import { DynamicFormViewComponent } from './dynamic-form-view/dynamic-form-view.component';
import { FileUploadDynamicField } from './ArtworkManagement/dynamic-fields/dynamic-field-fileupload';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';

import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GlobalVeriables } from './Global/GlobalVaribales';
import { ListViewModule } from "@progress/kendo-angular-listview";
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";
import { RolesListComponent } from './AdminManagement/roles/roles-list/roles-list.component';
import { AddRoleComponent } from './AdminManagement/roles/add-role/add-role.component';
import { EditRoleComponent } from './AdminManagement/roles/edit-role/edit-role.component';
import { DynamicTaskManagementComponent } from './ArtworkManagement/dynamic-task-management/dynamic-task-management.component';
import { UsersListComponent } from './AdminManagement/users/users-list/users-list.component';
import { AddUserComponent } from './AdminManagement/users/add-user/add-user.component';

import { MasterdataListComponent } from './AdminManagement/MasterData/masterdata-list/masterdata-list.component';
import { AddMasterdataComponent } from './AdminManagement/MasterData/add-masterdata/add-masterdata.component';
import { EditMasterdataComponent } from './AdminManagement/MasterData/edit-masterdata/edit-masterdata.component';
import { EditUserComponent } from './AdminManagement/users/edit-user/edit-user.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { GroupByPipe } from './Pipes/group-by.pipe';
import { DynamicFieldTreeComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-tree/dynamic-field-tree.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DynamicFieldChartComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-chart/dynamic-field-chart.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { ErrorsListComponent } from './AdminManagement/ErrorHandling/errors-list/errors-list.component';
import { DynamicFieldTableComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-table/dynamic-field-table.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AddGroupComponent } from './AdminManagement/Groups/add-group/add-group.component';
import { EditGroupComponent } from './AdminManagement/Groups/edit-group/edit-group.component';
import { GroupListComponent } from './AdminManagement/Groups/group-list/group-list.component';
import { DynamicFieldThumbnailComponent } from './ArtworkManagement/dynamic-field-thumbnail/dynamic-field-thumbnail.component';
import { DynamicFieldTabsComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-tabs/dynamic-field-tabs.component';
import { DialogWidthService } from './services/dialog-width.service';
import { DynamicFieldNestedGridComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-nested-grid/dynamic-field-nested-grid.component';
import { DynamicFieldNestedGridDetailsComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-nested-grid-details/dynamic-field-nested-grid-details.component';
import { DynamicFieldMultiLevelTaskBypass } from './ArtworkManagement/dynamic-fields/dynamic-field-multileveltasksbypass/dynamic-field-multileveltasksbypass';
import { DynamicFieldAuditlogComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-auditlog/dynamic-field-auditlog.component';
import { WorkflowtasksListComponent } from './BPMManagement/workflowtasks/workflowtasks-list/workflowtasks-list.component';
import { WorkflowtasksmasterdataListComponent } from './BPMManagement/workflowtasksmasterdata/workflowtasksmasterdata-list/workflowtasksmasterdata-list.component';
import { ModulesListComponent } from './BPMManagement/modules/modules-list/modules-list.component';
import { DynamicformsListComponent } from './BPMManagement/dynamicforms/dynamicforms-list/dynamicforms-list.component';

//import { WorkflowtasksmasterdataListComponent } from './BPMManagement/BPMManagement/BPMManagement/workflowtasksmasterdata/workflowtasksmasterdata-list/workflowtasksmasterdata-list.component';
import { DynamicMultiSelectCheckBoxComponent } from './ArtworkManagement/dynamic-fields/dynamic-multi-select-check-box/dynamic-multi-select-check-box.component';
import { ResourcemessagesListComponent } from './BPMManagement/resourcemessages/resourcemessages-list/resourcemessages-list.component';
import { DynamicFieldAceoffixEditorComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-aceoffix-editor/dynamic-field-aceoffix-editor.component';
import { DynamicformsManageComponent } from './BPMManagement/dynamicforms/dynamicforms-manage/dynamicforms-manage.component';
import { DynamicWorkflowTasksDiagramComponent } from './ArtworkManagement/dynamic-workflow-tasks-diagram/dynamic-workflow-tasks-diagram.component';
import { DynamicWorkflowTasksNodeComponent } from './ArtworkManagement/dynamic-workflow-tasks-node/dynamic-workflow-tasks-node.component';
import { EmailnotificationsViewComponent } from './BPMManagement/EmailNotifications/emailnotifications-view/emailnotifications-view.component';

import { DynamicFieldCustomUploaderComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-custom-uploader/dynamic-field-custom-uploader.component';
import { DynamicTemplateViewComponent } from './ArtworkManagement/dynamic-template-view/dynamic-template-view.component';
import { ESignatureComponent } from './AdminManagement/e-signature/e-signature.component';
import { AudittrailsListComponent } from './AdminManagement/audittrails/audittrails-list/audittrails-list.component';
import { DynamicControlViewComponent } from './ArtworkManagement/dynamic-control-view/dynamic-control-view.component';
import { MentionModule } from 'angular-mentions';
import { MyProfileComponent } from './AdminManagement/myprofile/my-profile/my-profile.component';
import { ManagepasswordComponent } from './AdminManagement/myprofile/managepassword/managepassword.component';
import { TranslatePipe } from './Pipes/translate.pipe';
import { PagerModule } from "@progress/kendo-angular-pager";
import { DynamicSearchViewComponent } from './ArtworkManagement/dynamic-search-view/dynamic-search-view.component';
import { DynamicTemplateActionsComponent } from './ArtworkManagement/dynamic-fields/dynamic-template-actions/dynamic-template-actions.component';
import { DynamicFieldCustomChartComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-custom-chart/dynamic-field-custom-chart.component';
import { DynamicFieldPieChartComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-pie-chart/dynamic-field-pie-chart.component';
import { DynamicFieldColumnChartComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-column-chart/dynamic-field-column-chart.component';
import { SetpasswordComponent } from './setpassword/setpassword.component';
import { CustomValidators } from './ArtworkManagement/Custom-Validator/CustomValidators';
import { LoginComponent } from './login/login.component';

import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DynamicSummaryViewComponent } from './ArtworkManagement/dynamic-summary-view/dynamic-summary-view.component';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { UserSettingsComponent } from './AdminManagement/UserSettings/user-settings.component';
import { SetUpOUtOfOfficeComponent } from './AdminManagement/myprofile/setup-outofoffice/setup-outofoffice.component';
import { DynamicFieldSchedulerComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-scheduler/dynamic-field-scheduler.component';
import { ACMListComponent } from './ArtworkManagement/acmlist/acmlist.component';
import { SpinnerComponent } from './services/Loader/spinner/spinner.component';
import { LoadingInterceptor } from './services/Loader/loading.interceptor';

import { PlaceholderfilesComponent } from './AdminManagement/placeholderfiles/placeholderfiles.component';

import { DatePipe } from '@angular/common';

import { DynamicFieldSplitterComponent } from './ArtworkManagement/dynamic-fields/dynamic-field-splitter/dynamic-field-splitter.component';

import { DownloadFilesComponent } from './download-files/download-files.component';
import { VerifyssouseraccessComponent } from './SSO/verifyssouseraccess/verifyssouseraccess.component';
import { CompareFilesComponent } from './ArtworkManagement/compare-files/compare-files.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    SideBarComponent,
    DynamicFormComponent,
    DynamicFormDynamicFieldComponent,
    DynamicFieldGridComponent,
    DynamicFieldListComponent,
    DynamicFormViewComponent,
    LoginComponent,
    FileUploadDynamicField,

    RolesListComponent,
    AddRoleComponent,
    EditRoleComponent,
    DynamicTaskManagementComponent,
    UsersListComponent,
    AddUserComponent,
    EditUserComponent,
    MasterdataListComponent,
    AddMasterdataComponent,
    EditMasterdataComponent,
    GroupByPipe,
    DynamicFieldTreeComponent,
    DynamicFieldChartComponent,
    ErrorsListComponent,
    AddGroupComponent,
    EditGroupComponent,
    GroupListComponent,
    DynamicFieldTableComponent,
    DynamicFieldThumbnailComponent,
    DynamicFieldTabsComponent,
    DynamicFieldNestedGridComponent,
    DynamicFieldNestedGridDetailsComponent,
    DynamicFieldMultiLevelTaskBypass,
    DynamicFieldAuditlogComponent,
    WorkflowtasksListComponent,
    WorkflowtasksmasterdataListComponent,
    ModulesListComponent,
    DynamicformsListComponent, DynamicMultiSelectCheckBoxComponent, ResourcemessagesListComponent,
    DynamicFieldAceoffixEditorComponent, DynamicformsManageComponent, EmailnotificationsViewComponent, DynamicFieldCustomUploaderComponent, DynamicTemplateViewComponent, DynamicWorkflowTasksDiagramComponent, DynamicWorkflowTasksNodeComponent, ESignatureComponent, AudittrailsListComponent

    , DynamicControlViewComponent, MyProfileComponent, ManagepasswordComponent

    , TranslatePipe, DynamicSearchViewComponent, DynamicTemplateActionsComponent, DynamicFieldCustomChartComponent, DynamicFieldPieChartComponent, DynamicFieldColumnChartComponent, DynamicSummaryViewComponent, SetpasswordComponent, UserSettingsComponent, SetUpOUtOfOfficeComponent, DynamicFieldSchedulerComponent, ACMListComponent, SpinnerComponent, PlaceholderfilesComponent, DynamicFieldSplitterComponent, DownloadFilesComponent,
    VerifyssouseraccessComponent, CompareFilesComponent



  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LayoutModule,
    LabelModule,
    ButtonsModule,
    PDFExportModule,
    DropDownsModule,
    DateInputsModule,
    EditorModule,
    UploadModule,
    HttpClientModule,
    PopupModule,
    ToastrModule.forRoot(),
    NotificationModule,
    MentionModule,
    //BackButtonDisableModule.forRoot(),
    RouterModule.forRoot([

      { path: 'Login', component: LoginComponent },
      { path: 'Projects', component: DynamicFormViewComponent, runGuardsAndResolvers: 'always' }, 
      { path: 'Projects/:ID', component: DynamicFormViewComponent },
      { path: 'Projects/:ID/:ProcessDataID', component: DynamicFormViewComponent },
      { path: 'Tasks', component: DynamicFormViewComponent },
      { path: 'DigitalAssetLibrary', component: DynamicFormViewComponent },
      { path: 'ArtworkAutomation', component: DynamicFormViewComponent },
      { path: 'Reports', component: DynamicFormViewComponent },
      { path: 'Dashboard', component: DynamicFormViewComponent },

      { path: 'Roles', component: RolesListComponent },
      { path: 'role-add-data', component: AddRoleComponent },
      { path: 'EditRolesData/:id', component: AddRoleComponent },

      { path: 'Users', component: UsersListComponent },
      { path: 'AddUserData', component: AddUserComponent },
      { path: 'EditUserData/:id/:roleid', component: AddUserComponent },

      { path: 'MasterData/:MasteDataTypeID', component: MasterdataListComponent },
      { path: 'AddMasterdata/:MasteDataTypeID', component: AddMasterdataComponent },
      //{ path: 'EditMasterdata/:masterDataID/:MasteDataTypeID/:MasteDataType', component: AddMasterdataComponent },

      { path: 'EditMasterdata/:masterDataID/:MasteDataTypeID', component: AddMasterdataComponent },
      //for artwork window open --28-Aug-2021
      { path: 'Groups', component: GroupListComponent },
      { path: 'AddGroups', component: AddGroupComponent },
      { path: 'EditGroupsData/:id', component: AddGroupComponent },
      { path: 'ErrorsLog', component: ErrorsListComponent },

      { path: 'Workflowtasks', component: WorkflowtasksListComponent },

      { path: 'WorkflowMasterData', component: WorkflowtasksmasterdataListComponent },

      { path: 'Modules', component: ModulesListComponent },
      { path: 'Dynamicforms', component: DynamicformsListComponent },

      { path: 'Resourcemessages', component: ResourcemessagesListComponent },
      { path: 'ManageDynamicForms/:FormFieldId/:TemplateID/:metadataLookUpId', component: DynamicformsManageComponent },
      { path: 'DynamicForms/:TemplateID', component: DynamicformsListComponent },
      { path: 'DynamicWorkDiagram', component: DynamicWorkflowTasksDiagramComponent },
      { path: 'EmailTemplates', component: EmailnotificationsViewComponent },

      { path: 'Audittrails', component: AudittrailsListComponent },

      { path: 'PlaceHolderFiles', component: PlaceholderfilesComponent },


      { path: 'Myprofile', component: MyProfileComponent },

      { path: 'Managepassword', component: ManagepasswordComponent },
      { path: 'SetUpOutOfOffice', component: SetUpOUtOfOfficeComponent },
      { path: 'WorflowDiagram', component: DynamicWorkflowTasksDiagramComponent },
      // { path: 'SetPassword/:key', component:SetpasswordComponent},
      { path: 'SetPassword/:key/:Status/:Type', component: SetpasswordComponent },
      { path: 'UserSettings', component: UserSettingsComponent },
      { path: 'FileDownload/:FileID/:expiryDate', component: DownloadFilesComponent },
      { path: 'verifyuseraccess', component: VerifyssouseraccessComponent },
      { path: 'compareFiles', component: CompareFilesComponent },
      { path: 'DynamicFieldTableComponent', component: DynamicFieldTableComponent },
      


    ], { relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload', enableTracing: false }),
    ButtonsModule, SchedulerModule,
    BrowserAnimationsModule, GridModule, ExcelModule, PDFModule, DialogsModule,
    ListViewModule, ChartsModule, ProgressBarModule, TooltipModule, TreeViewModule, PdfViewerModule, MenusModule, PagerModule, NgxGraphModule, NgxChartsModule
  ],
  providers: [GlobalVeriables, DatePipe,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    JwtHelperService, NotificationService, DialogWidthService, CustomValidators,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
