import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getBaseUrl } from '../../main';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private REST_API_SERVER = getBaseUrl();
  private viewHeader = new BehaviorSubject<boolean>(false);
  showHeader = this.viewHeader.asObservable();
  setHeaderShow(value: boolean) {
    this.viewHeader.next(value)
  }
  private getUserSettings = getBaseUrl() + "api/adminmanagement/GetUserSettings";
  private OutsideUrl = new BehaviorSubject<boolean>(false);
  clearOutsideUrl = this.OutsideUrl.asObservable();
  setclearOutsideUrl(value: boolean) {
    this.OutsideUrl.next(value)
  }

  private viewSidebar = new BehaviorSubject<boolean>(false);
  showSidebar = this.viewSidebar.asObservable();
  setNavBarShow(value: boolean) {
    this.viewSidebar.next(value)
  }
  constructor(private httpClient: HttpClient) { }
  public userLogin(Loginobj) {
    return this.httpClient.post<any>(this.REST_API_SERVER + "api/Account/Login", Loginobj);
  }
  public userverify(Loginobj) {
    return this.httpClient.post<any>(this.REST_API_SERVER + "api/Account/VerifyDownloadLogin", Loginobj);
  }
  public userLogoff() {
    return this.httpClient.post<any>(this.REST_API_SERVER + "api/Account/Logout","");
  }
  public userToken(UserId,type)
  {
    return this.httpClient.get<any>(this.REST_API_SERVER + "api/Account/userToken?id="+UserId+"&type="+type);
  }

  public GetEsign(UserId)
  {
    return this.httpClient.get<any>(this.REST_API_SERVER + "api/Account/GetEsign?id="+UserId);
  }

  public Getid(key)
  {
    return this.httpClient.get<any>(this.REST_API_SERVER + "api/Account/getid?id="+key);
  }

  public Gettype(type)
  {
    return this.httpClient.get<any>(this.REST_API_SERVER + "api/Account/Gettype?type="+type);
  }
  public Getuserdata(UserId)
  {
    return this.httpClient.get<any>(this.REST_API_SERVER + "api/Account/userdata?id="+UserId);
  }
  public GetuserCheck(UserId,currentpassword,Type)
  {
    return this.httpClient.get<any>(this.REST_API_SERVER + "api/Account/GetuserData?id="+UserId+"&Password="+currentpassword+"&Type="+Type);
  }
  public updatepassword(currentpassword,password,UserId,IsSetpassword)
  {
    return this.httpClient.get<any>(this.REST_API_SERVER + "api/Account/SetPassword?Password="+password+"&id="+UserId+"&status="+IsSetpassword+"&cpassword="+currentpassword);

  }


public getpasswordstatus(id,password,type)
{
  
  let params = new HttpParams();
  params = params.set('Password', password.toString());
  params = params.set('ID', id.toString());
  params = params.set('Type', type.toString());
  return this.httpClient.get<any>(this.REST_API_SERVER + "api/Account/GetPasswordStatus",{params:params});
}
public GetUserSettingsData() {
    return this.httpClient.get<any>(this.getUserSettings);
  }
  public SetEsignPassword(Currentpassword,password,UserId,IsSetpassword)
  {
    return this.httpClient.get<any>(this.REST_API_SERVER + "api/Account/SetEsignPassword?Password="+password+"&id="+UserId+"&status="+IsSetpassword+"&cpassword="+Currentpassword);

  }
  public getcustomerid(usernameemail, type)
  {
    return this.httpClient.get<any>(this.REST_API_SERVER + "api/Account/GetuserNameorEmail?Username=" + usernameemail + "&type=" + type);
  }
  public isValid = false;
}
