import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FileInfo } from '@progress/kendo-angular-upload';
import { stringify } from 'querystring';
import { Alert } from 'selenium-webdriver';
import { AuditTrailsDTOData } from 'src/app/services/AMFormsDataDetails';
import { LanguageService } from 'src/app/services/language.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { ModuleService } from '../../../services/module.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { DynamicFieldControlService } from '../../dynamic-fields-service/dynamic-field-control.service';
import { DynamicFieldService } from '../../dynamic-fields-service/dynamic-field.service';
import { DynamicFormGroupBase } from '../dynamic-field-base';
import { DALPublish } from '../dynamic-field-list/dynamic-field-list.component';
import {
  Workbook,
  WorkbookSheetColumn,
  WorkbookSheetRow,
  WorkbookSheetRowCell,
  WorkbookSheet,
} from "@progress/kendo-angular-excel-export";
import { saveAs } from "@progress/kendo-file-saver";
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-dynamic-field-table',
  templateUrl: './dynamic-field-table.component.html',
  styleUrls: ['./dynamic-field-table.component.css']
})
export class DynamicFieldTableComponent implements OnInit {

  public languageDataDefault: any;

  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;
  thumbnaildocumentsPath: string;
  showLoader: boolean = false;
  IsShowCollectionPopUp: boolean = false;
  IsShowHistoryPopUp: boolean = false;
  fileCollectionName: string = "";
  fileCollectionID: string = "0";
  documentRepositoryID: string = "0";
  recordIDs: string = "";
  viewRecords: any;
  viewSubProcessRecords: any;
  fileDetails: any;
  documentsPath: string;
  SelectedRecord: number = 0;
  projectID: number = 0;
  CompanyID: number = 0;
  AuditexcelBtn: boolean = false;
  TaskId: number = 0;
  SubProcessID: number = 0;
  CycleID: number = 0;
  ProjectConfrimopened: boolean = false;
  windowState = 'default';
  resizeWindow = false;
  projectNames: string = "";
  artWorkReviewURL: SafeResourceUrl;
  showArtWorkWindow: boolean = false;
  dalFileCollections: any;
  isMultiSelected: boolean = false;
  multiSelectedValue: boolean = false;
  projects: any;
  subprocess: any;
  FileName: string;
  progress: number;
  message: string;
  DALVersionData: any = [];
  IsShowPublishPopUp: boolean;
  invalidSubmitAttempt: boolean = false;
  IpAddres: string;
  DAllistIs: boolean = false;
  DynamicFields: DynamicFormGroupBase<any>;
  dynTemplateHeader: string = null;
  dynamicForm: FormGroup;
  DynamicFormFieldsActions: any = [];
  ResponseData: Dalver[] = [];
  ReferencesResponseData: Dalver[] = [];
  ReferencesByResponseData: Dalver[] = [];
  fileStatus: boolean;
  ProjectsResponseData: DalReferenceProjects[] = [];

  DALResponseData: AuditTrailsDTOData[] = [];
  DALPublishData = new DALPublish();
  tempfilesInfo: any = [];
  EnableFilter: boolean = false;
  IsDisable: boolean = false

  public token: string;
  languageDataListByLanguage;
  excelRecords: any;
  columnNames: any = ["Date Time", "Action Type", "User Name", "Version", "Description"];
  filter: any;
  filteredRecords: any;
  dataSaving: any;
  columnCondition: number;
  IsShowComparePopUp: boolean = false;
  selectedDALFileIds: any = [];
  isShowcompare: any;
  ismultiselectPublish: boolean = true;


  constructor(private moduleService: ModuleService,
    private commonfeaturesservice: CommonFeaturesService,
    private GV: GlobalVeriables, private dialogService: DialogService,
    private qcs: DynamicFieldControlService, private dataService: DynamicFieldService,
    private amService: AdminUserManagementService, public sanitizer: DomSanitizer,
    private notifyService: NotificationAlertsService, private languageService: LanguageService,
    private router: Router

  ) { }

  ngOnInit() {

    this.token = localStorage.getItem('token');
    this.CompanyID = +localStorage.getItem("usercompanyID");

    this.languageDataDefault = this.languageService.dataByLanguage;

    this.GV.SetFileType(2);//2 from DAL
    if (this.template.selectedRecordIDs != null && this.template.selectedRecordIDs.length > 1) {
      this.isMultiSelected = true;
      this.recordIDs = this.template.selectedRecordIDs.join(',');
      this.isShowcompare = false;

    }
    else if (this.template.filteredRecordsSelected != undefined && this.template.filteredRecordsSelected != '[]' && this.template.filteredRecordsSelected.length > 1 && !this.multiSelectedValue && !this.isMultiSelected) //|| !this.multiSelectedValue) {
    {
      this.multiSelectedValue = true;
      this.isMultiSelected = false;
    }
    else if (this.multiSelectedValue && this.isMultiSelected) {
      this.recordIDs = this.template.filteredRecordsSelected.map(obj => obj.ID).join(',');
      this.multiSelectedValue = false;
    }
    else {
      this.recordIDs = "";
      this.isMultiSelected = false;
      this.isShowcompare = true;


    }


    this.IpAddres = this.GV.UserIpAddress;




    this.showLoader = true;

    this.thumbnaildocumentsPath = this.moduleService.getBaseUrl() + "documents/";

    this.moduleService.DisplayTableView(this.templateID, this.recordID, this.recordIDs, this.template.recordType).subscribe(data => {
      this.fileDetails = data;
      if (data.find(x => x.fieldName === 'FileStatusName') != undefined) {
        this.fileStatus = data.find(x => x.fieldName === 'FileStatusName').displayValue === 'Approved Effective' ? true : false;
        this.ismultiselectPublish = this.fileStatus;
      }
      else if (data.length >= 1) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].fileStatusName != undefined && data[i].fileStatusName == 'Obsolete') {
            this.ismultiselectPublish = false;
            break;
          }
        }
      }

      var myFiles = [];
      if (data.length > 0) {
        if (this.recordID != "" && this.recordIDs == "") {
          //alert(data[1].fileID+"1");
          let obj: any = {};
          obj.name = data.find(x => x.fieldName == 'FileName').displayValue;
          obj.uid = data[1].fileID;
          obj.filepath = data[1].filePath;
          //obj.uid = data[1].id;
          myFiles.push(obj);

        } else {
          data.forEach(function (part, index) {
            // alert(part.fileID+"2");
            //this[index].recordID = data.dependentRecordID;
            //this[index].selectedRecordID = data.selectedRecordId;
            let obj: any = {};
            obj.name = part.displayValue;
            obj.uid = part.fileID;
            obj.filepath = part.filePath;
            //obj.uid = part.id;
            myFiles.push(obj);

          });
        }

      }


      this.tempfilesInfo = myFiles;


      //  if (this.recordIDs == "" || this.recordIDs == null) {

      if (data != null && data.length > 0) {
        this.projectID = data[0].projectID;
        this.TaskId = data[0].taskID;
        this.SubProcessID = data[0].subProcessID;
        this.CycleID = data.filter(a => a.fieldName == "CycleID").length > 0 ? data.filter(a => a.fieldName == "CycleID")[0].displayValue : "";
      }


      //    this.moduleService.DisplayView(this.projectID).subscribe(data => {
      //      this.viewRecords = data;


      //      if (data != null && data.length > 0) {
      // this.projects = data[0].projectandSubProcess.filter(a => a.isProject == 1);
      //        this.subprocess = data[0].projectandSubProcess.filter(a => a.isProject == 2 && a.id == this.SubProcessID);

      //      }
      //      this.documentsPath = this.moduleService.getBaseUrl();
      //      //this.getRelatedProjectByProjectId(this.SelectedRecord);

      //    })
      //  } else {

      //  }

      this.showLoader = false;

    })





  }

  ngOnChanges(changes: SimpleChanges) {
    const elementIDs = ["collapseTwo", "collapseFour", "collapseFive", "collapseSix", "collapseThree"];

    elementIDs.forEach(id => {
      const element = document.getElementById(id);
      if (element != null) {
        if (element.classList.contains("show")) {
          element.classList.remove("show");
        }
      }
    });

    this.viewRecords = null;
    this.ResponseData = null;
    this.ReferencesResponseData = null;
    this.ReferencesByResponseData = null;
    this.ProjectsResponseData = null;
    if (this.template.selectedRecordIDs != null && this.template.selectedRecordIDs.length > 1) {
      this.isMultiSelected = true;
      this.recordIDs = this.template.selectedRecordIDs.join(',');
      this.isShowcompare = false;

    }
    else if (this.template.filteredRecordsSelected != undefined && this.template.filteredRecordsSelected != '[]' && this.template.filteredRecordsSelected.length > 1 && !this.multiSelectedValue && !this.isMultiSelected) //|| !this.multiSelectedValue) {
    {
      this.multiSelectedValue = true;
      this.isMultiSelected = false;
      this.isShowcompare = true;

    }
    else if (this.multiSelectedValue && this.isMultiSelected) {
      this.recordIDs = this.template.filteredRecordsSelected.map(obj => obj.ID).join(',');
      this.multiSelectedValue = false;
    }
    else {
      this.recordIDs = "";
      this.isMultiSelected = false;
      this.isShowcompare = false;
    }

    this.moduleService.DisplayTableView(this.templateID, this.recordID, this.recordIDs, this.template.recordType).subscribe(data => {
      this.fileDetails = data;
      if (data.find(x => x.fieldName === 'FileStatusName') != undefined) {
        this.fileStatus = data.find(x => x.fieldName === 'FileStatusName').displayValue === 'Approved Effective' ? true : false;
      }

      //  this.moduleService.getDalVersion(this.recordID).subscribe(data => {

      //      this.AuditexcelBtn=true;
      //    this.ResponseData=data;

      //   // .push(obj);


      //  })


      //  //get project references
      //  this.moduleService.getDalProjectReference(this.recordID).subscribe(data => {
      //    this.ProjectsResponseData = data;
      //  })



      var myFiles = [];
      if (data.length > 0) {
        if (this.recordID != "" && this.recordIDs == "") {
          //alert(data[1].fileID+"1");
          let obj: any = {};
          obj.name = data.find(x => x.fieldName == 'FileName').displayValue;
          obj.uid = data[1].fileID;
          obj.filepath = data[1].filePath;
          //obj.uid = data[1].id;
          myFiles.push(obj);

        } else {
          data.forEach(function (part, index) {
            // alert(part.fileID+"2");
            //this[index].recordID = data.dependentRecordID;
            //this[index].selectedRecordID = data.selectedRecordId;
            let obj: any = {};
            obj.name = part.displayValue;
            obj.uid = part.fileID;
            obj.filepath = part.filePath;
            //obj.uid = part.id;
            myFiles.push(obj);

          });
        }

      }


      this.tempfilesInfo = myFiles;


      //  if (this.recordIDs == "" || this.recordIDs == null) {

      if (data != null && data.length > 0) {
        this.projectID = data[0].projectID;
        this.TaskId = data[0].taskID;
        this.SubProcessID = data[0].subProcessID;
        this.CycleID = data.filter(a => a.fieldName == "CycleID").length > 0 ? data.filter(a => a.fieldName == "CycleID")[0].displayValue : "";
      }


      //    this.moduleService.DisplayView(this.projectID).subscribe(data => {
      //      this.viewRecords = data;


      //      if (data != null && data.length > 0) {
      // this.projects = data[0].projectandSubProcess.filter(a => a.isProject == 1);
      //        this.subprocess = data[0].projectandSubProcess.filter(a => a.isProject == 2 && a.id == this.SubProcessID);

      //      }
      //      this.documentsPath = this.moduleService.getBaseUrl();
      //      //this.getRelatedProjectByProjectId(this.SelectedRecord);

      //    })
      //  } else {

      //  }

      this.showLoader = false;

    })
  }
  checkUserPrivilege(actionEventID, LevelID) {

    return this.amService.GetPrivilegeAccesType(actionEventID, LevelID);

  }
  DownloadArtWorkPdfFile(Filepath: string, FileName: string, ProcessDataID: number) {

    this.IpAddres = this.GV.UserIpAddress;
    this.FileName = FileName;
    this.moduleService.DownloadPdfFile( Filepath, FileName, ProcessDataID, 0, this.GV.UserId, this.IpAddres).subscribe((event) => {

      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event);
      }

    });


  }
  onReview() {

    var AutoTransferTaskID = 10;

    let selectedLanguageId = localStorage.getItem("SelectedLanguageId");
    if (!selectedLanguageId)
      return;
    this.amService.getGetProjectArtworkComponents(18, this.projectID, this.SubProcessID, 1, 1, 1, AutoTransferTaskID, this.CycleID, 1, 1, this.IpAddres, selectedLanguageId, this.token, false).subscribe(data => {

      this.windowState = 'maximized';
      this.resizeWindow = false;
      this.showArtWorkWindow = true;
      this.artWorkReviewURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.artworkReviewURLNavigation);
      /*"http://localhost:53035/home/index?DocPath=ProjectComponets&1=899&2=900&3=1&4=1&5=1&6=9&7=1");*/
      //console.log(this.artWorkReviewURL);
      this.showLoader = false;

    });


  }
  EnableDisableFilter() {

    if (this.EnableFilter == true) {
      this.filter = [];
      this.EnableFilter = false;
      this.DALResponseData = this.dataSaving;
    }
    else {
      this.EnableFilter = true;
    }
  }

  FileHistory() {
    this.columnCondition = 0;
    this.amService.GetDALHistory(this.recordID, 28, this.GV.UserId, 1).subscribe((data) => {
      this.DALResponseData = data;
      this.dataSaving = this.DALResponseData
      this.IsDisable = false;
      // this.divHisdata = true;
      var AuditDisplay = "";
      if (data.length > 0) {
        this.AuditexcelBtn = true;


      }
      else {
        AuditDisplay = AuditDisplay + this.languageService.GetActionMessageByLanguage('STC_NAD', "No audit data for this record.");
        // this.notifications.show("No audit data for this record.");
        this.AuditexcelBtn = false;
        this.IsDisable = true;
      }
      // this.htmlToAdd = AuditDisplay;


    });

    this.IsShowHistoryPopUp = true;
    //  alert(this.projectID);
    //  alert(this.SubProcessID);
  }
  onPublish() {

    if (this.template.selectedRecordIDs.length<=0) {
      this.notifyService.show(this.languageService.GetActionMessageByLanguage('PCA_OFP', ""), 3);
      return
    }

    this.IsShowPublishPopUp = true;
    this.invalidSubmitAttempt = false;

    this.dynamicForm = null;

    this.dataService.getDynamicFormFields(42, 0, 0, false, "", 1, false).subscribe(response => {


      //this.IsDisplayControls = false;

      this.dynTemplateHeader = response.dynTemplateHeader;

      this.DynamicFields = response.dynamicFormFieldList;
      this.languageService.TranslateDefaultItem(response.dynamicFormFieldList, this.languageService.dataByLanguage);
      this.DynamicFormFieldsActions = response.displayActions;

      this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);


    });



  }
  FromAction(action: any) {

    this.IpAddres = this.GV.UserIpAddress;



    if (action.actionEvent == "DALPublish") {
      if (this.dynamicForm.invalid) {
        this.invalidSubmitAttempt = true;
        return;
      }
      this.DALPublishData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
      this.DALPublishData.ID = this.template.selectedRecordIDs;
      this.DALPublishData.ModifiedUser = localStorage.getItem("userFirstName") + " " + localStorage.getItem("userLastName");
      this.DALPublishData.UserID = this.GV.UserId;
      this.DALPublishData.Ip = this.IpAddres;
      this.amService.GetActionEmailNotificationDAL(this.DALPublishData).subscribe();
      this.IsShowPublishPopUp = false;
      this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_FPSFLY', "File Published successfully !!"), 1);


    }
    else if (action.actionEvent == "Cancel") {
      this.IsShowPublishPopUp = false;
    }




  };
  ShowCollection() {

    this.LoadCollections();

    this.IsShowCollectionPopUp = true;

  }

  RemoveFromCollection() {

    this.IpAddres = this.GV.UserIpAddress;


    const dialog: DialogRef = this.dialogService.open({
      title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
      content: this.languageDataDefault.find(x => x.LanguageKey == 'STC_RAFFC').Message,
      actions: [
        { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
        { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
      ],
      width: 350,
      height: 150,
      minWidth: 200
    });

    dialog.result.subscribe((result) => {

      if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {



        if (this.template.selectedRecordIDs == null || this.template.selectedRecordIDs.length == 0) {
          this.documentRepositoryID = this.template.selectedRecordID;

        } else {
          this.documentRepositoryID = this.template.selectedRecordIDs.join(',');

        }

        this.moduleService.RemoveFromFileCollection(this.template.collectionId, this.documentRepositoryID, this.IpAddres, this.GV.UserId).subscribe(data => {
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_FILRCOL', "Removed file(s) from the collection"), 1);

        });


        this.moduleService.passdalFileCollection(this.template.collectionId);


      } else {


      }

    });




  }

  LoadCollections() {

    this.moduleService.getDalFileCollections(this.GV.UserId).subscribe(data => {

      this.dalFileCollections = data;
    });
  }



  AddCollection(fileCollectionID: string) {

    this.IpAddres = this.GV.UserIpAddress;


    if (this.template.selectedRecordIDs == null || this.template.selectedRecordIDs.length == 0) {
      this.documentRepositoryID = this.template.selectedRecordID;

    } else {
      this.documentRepositoryID = this.template.selectedRecordIDs.join(',');

    }



    this.moduleService.AddDALFileCollection(this.fileCollectionName, fileCollectionID, this.documentRepositoryID, this.IpAddres, this.GV.UserId).subscribe(data => {


      this.LoadCollections();
      var folderName = this.fileCollectionName;
      this.fileCollectionName = "";
      this.fileCollectionID = "0";
      this.IsShowCollectionPopUp = false;

      if (data.result == 'Exists') {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('FCAE', "File Collection Already Exists"), 4);


      } else if (data.result == 'FileExists') {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('FAATC', "File Already Added To The Collection"), 4);

      }
      else if (data.result == 'Folder is created successfully.') {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('FCCS', "File Collection " + folderName + " is created successfully."), 1);

      }
      else {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_FILCOL', "Added file(s) to the collection"), 1);
        this.moduleService.passdalCollection(0);

      }



    });


  }

  RemoveFileCollection(fileCollectionID: string) {


    this.IpAddres = this.GV.UserIpAddress;


    const dialog: DialogRef = this.dialogService.open({
      title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
      content: this.languageDataDefault.find(x => x.LanguageKey == 'STC_RAFFC').Message,
      actions: [
        { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
        { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
      ],
      width: 350,
      height: 150,
      minWidth: 200
    });

    dialog.result.subscribe((result) => {

      if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {

        this.moduleService.RemoveFileCollection(fileCollectionID, this.IpAddres, this.GV.UserId).subscribe(data => {


          this.LoadCollections();

          this.fileCollectionName = "";
          this.fileCollectionID = "0";
          this.IsShowCollectionPopUp = false;
          this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_FILCOLREM', "File Collection Removed Successfully"), 1);




        });
      } else {


      }

    });




  }


  DownloadDALFiles() {


    this.IpAddres = this.GV.UserIpAddress;


    if (this.template.selectedRecordIDs == null || this.template.selectedRecordIDs.length == 0) {
      this.documentRepositoryID = this.template.selectedRecordID;

    } else {
      this.documentRepositoryID = this.template.selectedRecordIDs.join(',');

    }

    this.FileName = "DAL_Files.zip";
    this.moduleService.DownloadDalorReferenceFiles(this.documentRepositoryID, this.GV.UserId, this.IpAddres).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {

        this.downloadFile(event);
      }
    });



  }

  AddFileFromDAL() {

    var GetActionEvent = this.GV.GetActionEvent();


    var ExistingFiles = this.GV.GetFileInfo();


    this.showLoader = true;

    var fileIDs = "";

    if (this.template.selectedRecordIDs == null || this.template.selectedRecordIDs.length == 0) {
      fileIDs = this.template.selectedRecordID.toString();

    } else {
      fileIDs = this.template.selectedRecordIDs.join();

    }

    //check file already added
    var duplicateFiles = [];
    var addedFiles = fileIDs.split(',');
    addedFiles.forEach(function (part, index) {
      if (ExistingFiles != null && ExistingFiles.length > 0) {
        var checkDuplicate = ExistingFiles.filter(a => a.uid == part);
        if (checkDuplicate.length > 0) {
          duplicateFiles.push(ExistingFiles.filter(a => a.uid == part)[0].name);
        }
      }

    });
    if (duplicateFiles != null && duplicateFiles.length > 0) {
      this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "FAA", this.CompanyID).subscribe((dataCDActionsMessages) => {
        this.notifyService.show(duplicateFiles.join() + " " + dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);

      });
      this.showLoader = false;
      return;
    }
    var myFiles = [];
    if (ExistingFiles != null && ExistingFiles.length > 0) {
      ExistingFiles.forEach(function (part, index) {
        myFiles.push(part);
      });
      this.tempfilesInfo.forEach(function (part, index) {
        myFiles.push(part);
      });

    } else {
      myFiles = this.tempfilesInfo;
    }
    var projectID = this.GV.GetProjectID();


    //this.GV.SetFileInfo(myFiles);
    if (GetActionEvent == "CreateSubProcess") {

      this.moduleService.CheckInprogressProject(fileIDs).subscribe(data => {
        if (data.result != "") {
          this.showLoader = false;
          this.projectNames = data.result;
          this.ProjectConfrimopened = true;
        } else {
          this.GV.SetFileInfo(myFiles);

          this.moduleService.CreateSubProcess(projectID.toString(), fileIDs, "", this.GV.UserId, this.IpAddres).subscribe(data => {


            if (data.result != "") {
              this.showLoader = false;
              //this.notifyService.show("File added already", 3);

              this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "FAA", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(data.result + " " + dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);

              });

              return;
            } else {

              this.showLoader = false;

              this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "SPCS", this.CompanyID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
              });
              //this.notifyService.show("Sub process created successfully", 1);
              this.moduleService.passCloseDAL("Close");
            }


          });
        }

      });

    }

    else if (GetActionEvent == "Edit") {
      this.GV.SetFileInfo(myFiles);
      this.moduleService.passCloseDAL("Close");
    }
    else {//create project
      this.moduleService.CheckInprogressProject(fileIDs).subscribe(data => {
        if (data.result != "") {
          this.showLoader = false;
          this.projectNames = data.result;
          this.ProjectConfrimopened = true;
        } else {
          this.GV.SetFileInfo(myFiles);
          this.moduleService.passCloseDAL("Close");
        }
      })
    }
  }

  closeDialog() {
    this.ProjectConfrimopened = false;
  }

  ProjectConfrim() {
    var GetActionEvent = this.GV.GetActionEvent();
    var ExistingFiles = this.GV.GetFileInfo();
    this.showLoader = true;
    var fileIDs = "";

    if (this.template.selectedRecordIDs == null || this.template.selectedRecordIDs.length == 0) {
      fileIDs = this.template.selectedRecordID.toString();

    } else {
      fileIDs = this.template.selectedRecordIDs.join();
    }

    //check file already added
    var duplicateFiles = [];
    var addedFiles = fileIDs.split(',');
    addedFiles.forEach(function (part, index) {
      if (ExistingFiles != null && ExistingFiles.length > 0) {
        var checkDuplicate = ExistingFiles.filter(a => a.uid == part);
        if (checkDuplicate.length > 0) {
          duplicateFiles.push(ExistingFiles.filter(a => a.uid == part)[0].name);
        }
      }

    });
    if (duplicateFiles != null && duplicateFiles.length > 0) {
      this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "FAA", this.CompanyID).subscribe((dataCDActionsMessages) => {
        this.notifyService.show(duplicateFiles.join() + " " + dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);

      });
      this.showLoader = false;
      return;
    }
    var myFiles = [];
    if (ExistingFiles != null && ExistingFiles.length > 0) {
      ExistingFiles.forEach(function (part, index) {
        myFiles.push(part);
      });
      this.tempfilesInfo.forEach(function (part, index) {
        myFiles.push(part);
      });

    } else {
      myFiles = this.tempfilesInfo;
    }
    var projectID = this.GV.GetProjectID();

    this.GV.SetFileInfo(myFiles);
    if (GetActionEvent == "CreateSubProcess") {

      this.moduleService.CreateSubProcess(projectID.toString(), fileIDs, "", this.GV.UserId, this.IpAddres).subscribe(data => {
        if (data.result != "") {
          this.showLoader = false;
          //this.notifyService.show("File added already", 3);

          this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "FAA", this.CompanyID).subscribe((dataCDActionsMessages) => {
            this.notifyService.show(data.result + " " + dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          });

          return;
        } else {

          this.showLoader = false;

          this.commonfeaturesservice.GetAllActionsMessages(4, 1, 1, 999, "SPCS", this.CompanyID).subscribe((dataCDActionsMessages) => {
            this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          });
          //this.notifyService.show("Sub process created successfully", 1);
          this.moduleService.passCloseDAL("Close");
        }

      });
    }

    else if (GetActionEvent == "Edit") {

      this.moduleService.passCloseDAL("Close");

    }
    else {//create project

      this.moduleService.passCloseDAL("Close");

    }


  }
  onProjectInfo() {
    if (document.getElementById("collapseTwo").classList.contains("show")) {
      //no code
    } else {
      if (this.viewRecords == undefined || this.viewRecords == null) {
        this.moduleService.DisplayView(this.projectID).subscribe(data => {
          this.viewRecords = data;
          this.moduleService.DisplayViewSubProcess(this.SubProcessID).subscribe(SubProcessdata => {
            this.viewSubProcessRecords = SubProcessdata;
          })

          if (data != null && data.length > 0) {
            this.projects = data[0].projectandSubProcess.filter(a => a.isProject == 1);
            this.subprocess = data[0].projectandSubProcess.filter(a => a.isProject == 2 && a.id == this.SubProcessID);
          }
          this.documentsPath = this.moduleService.getBaseUrl();
        })
      }
    }
  }

  //for getting version history data on demand
  onVersionHistoryClick() {

    if (document.getElementById("collapseFour").classList.contains("show")) {
      //no code
    } else {

      if (this.ResponseData == undefined || this.ResponseData == null || this.ResponseData.length == 0) {
        this.moduleService.getDalVersion(this.recordID).subscribe(data => {
          this.AuditexcelBtn = true;
          this.ResponseData = data;
        })

      }
    }
  }
  //for getting references
  onReferencesClick() {

    if (document.getElementById("collapseFive").classList.contains("show")) {
      //no code
    } else {

      if (this.ReferencesResponseData == undefined || this.ReferencesResponseData == null || this.ReferencesResponseData.length == 0) {
        this.moduleService.GetDalReferences(this.recordID).subscribe(data => {
          this.ReferencesResponseData = data.filter(a => a.isDispositionFolder == false);

        })

      }
    }
  }




  //for getting references by
  onReferencesByClick() {

    if (document.getElementById("collapseSix").classList.contains("show")) {
      //no code
    } else {

      if (this.ReferencesByResponseData == undefined || this.ReferencesByResponseData == null || this.ReferencesByResponseData.length == 0) {
        this.moduleService.GetDalReferences(this.recordID).subscribe(data => {
          this.ReferencesByResponseData = data.filter(a => a.isDispositionFolder == true);

        })

      }
    }
  }

  //onProjectsandSpClick
  //for getting process and sub process
  onProjectsandSpClick() {

    if (document.getElementById("collapseThree").classList.contains("show")) {
      //no code
    } else {
      debugger;
      if (this.ProjectsResponseData == undefined || this.ProjectsResponseData == null || this.ProjectsResponseData.length == 0) {
        this.moduleService.getDalProjectReference(this.recordID).subscribe(data => {
          this.ProjectsResponseData = data;
        })
      }
    }
  }


  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;//.substring(1).slice(0,-1);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  HideCollection() {
    this.fileCollectionName = "";
    this.fileCollectionID = "0";

    this.IsShowCollectionPopUp = false;

  }


  closeHistory() {
    this.IsShowHistoryPopUp = false;
  }


  navigatetoProjects(dataItem: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/Projects/' + dataItem.projectId])
    );
    window.open(url);


  }

  AuditLogData(value) {
    this.columnCondition = value;
    this.amService.GetDALHistory(0, 28, this.GV.UserId, 1).subscribe((data) => {

      this.DALResponseData = data;
      this.IsDisable = false
      this.dataSaving = this.DALResponseData
      // this.divHisdata = true;
      var AuditDisplay = "";
      if (data.length > 0) {
        this.AuditexcelBtn = true;


      }
      else {
        AuditDisplay = AuditDisplay + this.languageService.GetActionMessageByLanguage('STC_NAD', "No audit data for this record.");
        // this.notifications.show("No audit data for this record.");
        this.AuditexcelBtn = false;
        this.IsDisable = true;
      }
      // this.htmlToAdd = AuditDisplay;


    });

    this.IsShowHistoryPopUp = true;
  }


  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredRecords = filterBy(this.DALResponseData, this.filter);
    if (filter.filters.length > 0) {
      this.DALResponseData = this.filteredRecords;
      this.IsDisable = true;
    }
    else {
      this.DALResponseData = this.dataSaving;
      this.IsDisable = false;
      this.filteredRecords = [];
    }

  }
  HidePubish() {

    this.IsShowPublishPopUp = false;

  }

  //Close Event for artwork review window
  artWorkReviewClose() {

    this.showArtWorkWindow = false;
  }

  onMultiSelectBack() {
    if (this.template.filteredRecordsSelected.length > 1 && this.multiSelectedValue && !this.isMultiSelected) {
      this.multiSelectedValue = true;
      this.isMultiSelected = true;
      this.ngOnInit();
    }
  }

  onExcelExport() {
    if (this.columnCondition == 1) {
      this.columnNames = ["Date Time", "Action Type", "User Name", "Description"]
    }
    else {
      this.columnNames = ["Date Time", "Action Type", "User Name", "Version", "Description"]
    }

    this.excelRecords = this.DALResponseData


    var rows = [];

    //Application Name
    var headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    var headerCell = new headerCellClass();
    headerCell.value = "Application Name"
    headerCell.bold = false;
    headerRowcells.push(headerCell);


    var headerCell = new headerCellClass();
    headerCell.value = "Artwork360"
    headerCell.bold = true;
    headerRowcells.push(headerCell);


    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Module Name
    var headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    var headerCell = new headerCellClass();
    headerCell.value = "Module Name"
    headerCell.bold = false;
    headerRowcells.push(headerCell);


    var headerCell = new headerCellClass();
    headerCell.value = "DAL History";
    headerCell.bold = true;
    headerRowcells.push(headerCell);


    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    ///Downloaded by

    var headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    var headerCell = new headerCellClass();
    headerCell.value = "Downloaded By";
    headerCell.bold = false;
    headerRowcells.push(headerCell);


    var headerCell = new headerCellClass();
    headerCell.value = this.GV.UserName;
    headerCell.bold = true;
    headerRowcells.push(headerCell);


    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Downloaded Date

    var headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];


    var headerCell = new headerCellClass();
    headerCell.value = "Downloaded Date";
    headerCell.bold = false;
    headerRowcells.push(headerCell);
    var headerCell = new headerCellClass();

    var CurrentDate = new Date();
    headerCell.value = CurrentDate.toString();;
    headerCell.bold = true;
    headerRowcells.push(headerCell);


    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Binding Columns

    var headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    this.columnNames.forEach(element => {
      var headerCell = new headerCellClass();
      headerCell.value = element
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      headerCell.background = "#227447"
      headerCell.color = "#ffffff"
    });

    headerRow.cells = headerRowcells;
    rows.push(headerRow);
    /*debugger*/
    //Binding Grid Data
    var headerRow = new headerRowClass();
    var headerRowcells: headerCellClass[] = [];

    for (var i = 0; i <= this.excelRecords.length - 1; i++) {
      var headerRow = new headerRowClass();
      var headerRowcells: headerCellClass[] = [];

      var headerCell = new headerCellClass();
      headerCell.value = this.excelRecords[i].eventDate;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""

      var headerCell = new headerCellClass();
      headerCell.value = this.excelRecords[i].actionType;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""

      var headerCell = new headerCellClass();
      headerCell.value = this.excelRecords[i].userId;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""

      if (this.columnCondition != 1) {
        var headerCell = new headerCellClass();
        headerCell.value = this.excelRecords[i].version;
        headerCell.bold = false;
        headerRowcells.push(headerCell);
        headerCell.background = ""
        headerCell.color = ""
      }
      var headerCell = new headerCellClass();
      headerCell.value = this.excelRecords[i].description;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""

      headerRow.cells = headerRowcells;
      rows.push(headerRow);
    }
    let excelName = "DAL Audit History";
    //debugger
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: [
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
          ],

          // Title of the sheet            
          //name: template.templateName,
          name: excelName,
          // Rows of the sheet
          rows: rows
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => saveAs(dataUrl, excelName + ".xlsx"));
  }
  ShowCompare() {
    debugger;
    //this.LoadCollections();
    this.selectedDALFileIds = this.template.selectedRecordIDs;
    if (this.selectedDALFileIds.length < 1) {
      this.isShowcompare = false;
      this.IsShowComparePopUp = false;
      this.notifyService.show("Please select more than 1 file", 3);
    }
    else {
      this.isShowcompare = true;
      this.IsShowComparePopUp = true;
      return;
    } 
  }
  HideCompare() {
    this.IsShowComparePopUp = false;
  }
}
class Dalver {


  Docversion: string;
  fileName: string;
  DateAdded: string;
  Action: string;




}

export class headerCellClass {

  value: string;
  bold: boolean;
  background: string;
  color: string;
}

export class headerRowClass {

  cells: any;
}



class DalReferenceProjects {


  ProjectName: string;
  ProjectId: string;





}
