import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GlobalVeriables } from 'src/app/Global/GlobalVaribales';
import { AdminUserManagementService } from 'src/app/services/adminusermanagement.service';
import { LanguageService } from 'src/app/services/language.service';
import { ModuleService } from 'src/app/services/module.service';
import { NotificationAlertsService } from 'src/app/services/notification.service';
import { DynamicFieldControlService } from '../../dynamic-fields-service/dynamic-field-control.service';
import { DynamicFieldService } from '../../dynamic-fields-service/dynamic-field.service';
import { DynamicTemplateData } from '../dynamic-field-list/dynamic-field-list.component';


@Component({
  selector: 'app-dynamic-template-actions',
  templateUrl: './dynamic-template-actions.component.html',
  styleUrls: ['./dynamic-template-actions.component.css']
})
export class DynamicTemplateActionsComponent implements OnInit {
  @Input() DynamicActionType: number;
  @Input() public templateID: any;
  @Input() public template: any;
  @Input() public recordID: number;
  @Input() public SelectedRowsCount: number;
  @Input() public TasksFlag: number;
  @Output() templateAction = new EventEmitter();
  constructor(private moduleService: ModuleService, private dataservice: AdminUserManagementService, private GV: GlobalVeriables,
    public service: DynamicFieldService, private notifyService: NotificationAlertsService, private dialogService: DialogService,
    private languageService: LanguageService) { }
  controlActionNotForMenuItems: any = [];
  controlActionNotForMenu: any = [];
  controlActionForMenuItems: any = [];
  controltemplatesforMenu: any = [];
  controltemplates: any = [];
  selectedReferenceFileIds: any;
  showLoader: boolean = false;
  IpAddres: string;
  globalIndex: any;
  selectedRefFileIds: any = [];
  fileDetails: any;
  isShowcompare: any;
  IsShowComparePopUp: boolean = false;
  ngOnInit(): void {
    //debugger
    this.loadActionTemplates();
    this.moduleService.ReferenceFileIds.subscribe(data => {
      this.selectedReferenceFileIds = data;
      this.selectedRefFileIds = data;
      //  alert(this.selectedReferenceFileIds);
    })
    this.IpAddres = localStorage.getItem("Ip");

    if (this.IpAddres == undefined || this.IpAddres == "" || this.IpAddres == null) {

      this.IpAddres = this.GV.UserIpAddress;

    }
  }
  loadActionTemplates() {
    this.showLoader = true;
    if (this.template != 0) {
      this.moduleService.GetControlTemplatesByTemplateID(this.templateID, this.template, this.DynamicActionType).subscribe(data => {
        if (data.length > 0) {
          this.controltemplates = data;
          this.controlActionNotForMenu = this.controltemplates.filter(ta => ta.isMenuItem == "False");
          this.controlActionForMenuItems = this.controltemplates.filter(ta => ta.isMenuItem == "True");
          //this.moduleService.CheckForProjectAccess(this.recordID).subscribe(projectData => {
          this.moduleService.CheckForProjectAccess(this.recordID, 0).subscribe(projectData => {
            //if (projectData || this.template.templateAliasName == 'ReAssign')
            if (projectData || this.template.templateAliasName == 'ReAssign' || this.template.templateAliasName == 'OutOfOffice') {
              this.moduleService.GetDynamicProjectStatus(this.recordID, 1).subscribe(data => {
                if (data == 473 || data == 474 || data == 488 || data == 2737) {
                  this.controlActionNotForMenu.forEach((menuActions, index) => {
                    if (menuActions.actionEvent != "DownloadAllReferenceFile") {
                      this.controlActionNotForMenu.splice(index, 1);
                    }
                  })
                  this.controlActionNotForMenuItems = this.controlActionNotForMenu;
                }
                else {
                  this.controlActionNotForMenuItems = this.controlActionNotForMenu;
                }
              })
            }
          })


          // console.log(this.controlActionForMenuItems);
          this.controltemplatesforMenu = [
            {
              text: "More",
              templateName: "More",
              items: this.controlActionForMenuItems
            }
          ];
        }
      });
    }
    this.showLoader = false;
  }
  checkUserPrivilege(actionEventID, LevelID) {

    return this.dataservice.GetPrivilegeAccesType(actionEventID, LevelID);

  }

  FileName: any;
  documentRepositoryID: any;
  progress: any;
  AddNewReocrd(actionTemplate) {
    if (actionTemplate.actionEvent == "AddReferenceFile") {
      actionTemplate.recordID = this.recordID;
      this.templateAction.emit(actionTemplate);
    }
    else if (actionTemplate.actionEvent == "btnAssignUsers") {
      if (this.selectedReferenceFileIds.length > 0)
        this.templateAction.emit(actionTemplate);

      else this.notifyService.show("Please select required Tasks to Reassign", 4);
    }
    else if (actionTemplate.actionEvent == "DownloadAllReferenceFile") {

      if (this.selectedReferenceFileIds.length > 1) {
        this.documentRepositoryID = this.selectedReferenceFileIds.join(',')
        this.FileName = this.languageService.GetActionMessageByLanguage('STC_RFILS', 'Reference_Files') + ".zip";
        this.moduleService.DownloadDalorReferenceFiles(this.documentRepositoryID, this.GV.UserId, this.IpAddres).subscribe((event) => {
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round((100 * event.loaded) / event.total);
          else if (event.type === HttpEventType.Response) {

            this.downloadFile(event);
          }

        });
      }
      else {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SMORF', "select more than 1  Reference file(s)"), 4);
      }
    }
    else if (actionTemplate.actionEvent == "DeleteAllReferenceFile") {
      if (this.selectedReferenceFileIds.length > 1) {
        this.documentRepositoryID = this.selectedReferenceFileIds.join(',')
        const dialog: DialogRef = this.dialogService.open({
          title: this.languageService.GetActionMessageByLanguage('LGT_CONH', "confirmation"),
          content: this.languageService.GetActionMessageByLanguage('STC_CDTD', 'Do you want to delete this document?'),
          actions: [
            { text: this.languageService.GetActionMessageByLanguage('NO', 'NO') },
            { text: this.languageService.GetActionMessageByLanguage('YES', 'YES'), primary: true }

          ],
          width: 350,
          height: 150,
          minWidth: 200,
          cssClass: "custom-css-class",
          closeTitle: this.languageService.GetActionMessageByLanguage('STC_CLOSE', 'Close')
        });
        dialog.result.subscribe((result) => {

          if (result["text"] == this.languageService.GetActionMessageByLanguage('YES', 'YES')) {
            //code for popup end
            this.moduleService.DeleteReferencefiles(this.documentRepositoryID, this.template.selectedRecordID, this.GV.UserId, this.IpAddres).subscribe(data => {
              if (data.status == "success") {
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_RFDS', data.status + " Reference file(s) deleted successfully"), 1);
                this.selectedReferenceFileIds = [];
                //emit grid evnt here
                this.templateAction.emit(actionTemplate);
              }
              else
                this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_PRFCND', data.status + " File(s) are project related, you can't delete."), 4);
            });
          }
        });
      }
      else {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SMORF', "select more than 1 Reference file(s)"), 4);
      }
    }
    else if (actionTemplate.actionEvent == "btnCompare") {
      if (this.selectedReferenceFileIds.length > 1) {
        this.templateAction.emit(actionTemplate);
        var projectID = this.template.recordID;
        var skip = 0;
        var defaultRecordCount = this.template.defaultRecordCount;
        this.moduleService.GetFilesDetails(this.templateID, projectID, defaultRecordCount, skip).subscribe(data => {
          
          var dt = JSON.parse(data);
          this.selectedRefFileIds = this.selectedReferenceFileIds;
         
          var dtFiles = dt.Table;
          var addedFiles = this.selectedReferenceFileIds;
          var SelectedFilesData = [];
         dtFiles.forEach(function (part, index) {
           if (addedFiles != null && addedFiles.length > 0) {
              var filterdt = addedFiles.filter(a => a == part.ID);
             if (filterdt.length > 0) {
               SelectedFilesData.push(part);
             }
            }
         });
          if (SelectedFilesData.length > 0) {
            this.fileDetails = SelectedFilesData;
          }
          this.ShowCompare();
        })
      }
      else
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SMORF', "select more than 1 Reference file(s)"), 4);
    }
    else {
      // alert(123);
    }
  }

  ShowCompare() {
    debugger;
    //this.LoadCollections();
    this.selectedRefFileIds = this.selectedReferenceFileIds;
    if (this.selectedRefFileIds.length > 1) {
      this.isShowcompare = false;
    }
    else {
      this.isShowcompare = true;
      return;
    }

    this.IsShowComparePopUp = true;
  }
  HideCompare() {
    this.IsShowComparePopUp = false;
  }
  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;//.substring(1).slice(0,-1);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

}
