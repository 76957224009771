import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicFieldControlService } from '../dynamic-fields-service/dynamic-field-control.service';
import { DynamicFieldService } from '../dynamic-fields-service/dynamic-field.service';
import { DynamicFormGroupBase } from '../dynamic-fields/dynamic-field-base';
import { ModuleService } from '../../services/module.service';
import { NotificationAlertsService } from '../../services/notification.service';
import { getBaseUrl } from '../../../main';
import { FileInfo, FileRestrictions, SelectEvent, UploadEvent, RemoveEvent, ErrorEvent } from '@progress/kendo-angular-upload';
import { DynamicTemplateData, TaskSubDetails, TaskOwnermodal, TaskEditmodal } from '../dynamic-fields/dynamic-field-list/dynamic-field-list.component';
import { automation, breadCrumbs, ModificationInfo, templateData } from '../../interfaces/ItemplateFields';
import { AdminUserManagementService, GetAuditAllFieldName, DateConvertions } from '../../services/adminusermanagement.service';
import { NotificationEmailTriggerEvent } from '../../services/adminusermanagement.service'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DialogWidthService } from '../../services/dialog-width.service';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { DataStateChangeEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { GlobalVeriables } from '../../Global/GlobalVaribales';
import { AduitForm, PDfExpAuditSave } from '../../services/AMFormsDataDetails';
import { take } from 'rxjs/operators';
import { CommonFeaturesService } from '../../services/commonfeatures.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { LanguageService } from '../../services/language.service';
import { LoaderService } from 'src/app/services/Loader/loader.service';
import { Subscription, timer } from 'rxjs';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { filter, map, takeUntil } from 'rxjs/operators'
import { HttpClient, HttpHeaders } from '@angular/common/http'

declare var $: any;

@Component({
  selector: 'app-dynamic-task-management',
  templateUrl: './dynamic-task-management.component.html',
  styleUrls: ['./dynamic-task-management.component.css'],
  providers: [GetAuditAllFieldName, DateConvertions]
})
export class DynamicTaskManagementComponent implements OnInit {
  @Input() public template: any;
  @Input() public templateID: any;
  @Input() physicalPath: string = '';
  @Input() physicalFilePath: string = '';
  @Input() public componentType: any;
  @Input() public recordID: any;
  public ID: any;
  public RecordID: any = 0;
  public RecordIDs: any;
  SelectedRecord: number = 0;
  tempSelectedRecord: number = 0;
  setSubProcessdisplay: boolean = false;
  public fileInfo: Array<FileInfo> = [];
  public ParentID: any = 0;
  viewRecords: any;
  public SelectedTaskOwnerID: string;
  customUploadType: number = 0;
  filterActionEventId: number = 0;
  viewRelProjects: any;
  public selectableSettings: SelectableSettings = {
    enabled: false
  };

  byPassItemsSelected: any = [];
  projectSelectedItem: any;
  public source: Array<{ text: string; value: number }> = [
    { text: "Project level bypass", value: 1 },
    { text: "Sub process level bypass", value: 2 },
  ];
  public FileType: number = 0;
  public data: Array<{ text: string; value: number }>;
  showgriderror: boolean = false;
  showradioerror: boolean = false;
  records: any;
  selectedItems: any;
  uploadedFiles = [];
  documentsPath: any;
  PageOnLoad: boolean = false;
  showDialogPopUp: boolean = false;
  displaypopup: string = "none";
  showLoader: boolean = false;
  ISAutomationTask: boolean = false;
  dynTemplateHeader: string = null;
  DynamicFields: DynamicFormGroupBase<any>;
  DynamicFieldsEditTask: DynamicFormGroupBase<any>;
  DynamicFields1: DynamicFormGroupBase<any>;
  DynamicSubscriberFields: DynamicFormGroupBase<any>;
  DynamicFields2: DynamicFormGroupBase<any>;
  DynamicFormFieldsActions: any = [];
  SetTaskOwnerMail: boolean = false;
  dynamicForm: FormGroup;
  dynamicForm1: FormGroup;
  dynamicSubscriberForm: FormGroup;
  dynamicFormEditTask: FormGroup;
  dynamicFormBeforeEditTask: FormGroup;
  //dynamicForm2: FormGroup;
  isProjectStart: boolean = false;
  invalidSubmitAttempt: boolean = false;
  templateData = new DynamicTemplateData();
  taskSubDetails = new TaskSubDetails();
  taskOwnermodal = new TaskOwnermodal();
  taskeditmodel = new TaskEditmodal();
  submitted = false;
  formvalid = false;
  TaskAction: string = "";
  DisplayText: string = "";
  taskID: number = 0;
  Bypassunbypass = false;
  wrkflowdisplayfileds: any;
  wrkflowtaskActions: any
  WorkFlowProcessData: any;
  WorkFlowTaskStatus: any;
  TaskName: any;
  hasParent: boolean = false;
  workFlowStatusID: any;
  //uploadSaveUrl = getBaseUrl() + 'api/dynamic/uploadAnnotation';
  uploadSaveUrl = getBaseUrl() + 'api/dynamic/DynUAARTTFU?loginUserId=' + this.GV.UserId;
  //uploadRemoveUrl = getBaseUrl() + 'api/dynamic/RemoveDocument';
  uploadRemoveUrl = getBaseUrl() + 'api/dynamic/DynACUR?loginUserId=' + this.GV.UserId;
  ProjectStartConfrimopened: boolean = false;
  DocDeleteConfirmation: boolean = false;
  TaskBypassConfrimopened: boolean = false;
  TaskUnbypassBypassConfrimopened: boolean = false;
  multiTaskBypassConfrimopened: boolean = false;
  multiTaskUnBypassConfrimopened: boolean = false;
  Confirmationtitle: string = "";
  dsTaskuserList: any;
  fartworkReviewURLNavigation: any;
  SelectedUserAndGroup: any;
  unClaimDelegateTaskConfrimopened: boolean = false;
  editTaskConfirmopened: boolean = false;
  artWorkReviewURL: SafeResourceUrl;
  showArtWorkWindow: boolean = false;
  showCustomArtWorkWindow: boolean = false;
  BypassedItems: any = [];
  BypassItems: any = [];
  previousData: any;

  /* public listItems: Array<string>;*/
  showBypassedList: boolean = false;
  showBypassTasks: boolean = false;
  offSet: string = "+05:30";
  windowState = 'default';
  resizeWindow = false;
  showBypassUnbypassPopUp: boolean = false;
  showEditTaskPopup: boolean = false;
  showTaskOwnerPopUp: boolean = false;
  ShowEditTaskPopUp: boolean = false;
  ShowBtn: boolean = false;
  taskOwnerGroupSelectedValue: any;
  taskOwnerSelectedValue: any;
  addApproverGroupSelectedValue: any;
  addApproverUserSelectedValue: any;
  dstaskOwnerGroupList: any;
  dstaskGroup: any;
  IpAddres: string;
  dstaskOwnerList: any;
  ClaimTaskConfrimopened: boolean = false;
  btnShowBypassUnByPass: boolean = false;
  EnableEditTask: boolean = false;
  EnableSubproces: boolean = false;
  EnableProjectsum: boolean = false;
  EnableSubscriber: boolean = false;
  EnableSubIdentifer: boolean = false;
  editsubprofields = 2;
  editsubscrifields = 2;
  editsubproidfields = 2;
  ActionEventID: number;
  breadcrumbs: Array<breadCrumbs>;

  workflowTasksActionID: number;
  isResumeCycleAction: boolean = false;
  isSuspendCycleAction: boolean = false;
  isCancelCycleAction: boolean = false;
  islist: boolean = false;
  public isDraggable = false;
  public isedtit = false;
  taskActionforMenu: any;
  taskActionNotForMenuItems: any = [];
  sessionUserName: string;
  public AuthForm = new FormGroup(
    {
      UserName: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required),
      Comments: new FormControl(''),
    },

    // insert here
  );
  //taskActionNotForMenuItems: any = [];
  public isResizable = false;
  ObjAuditExpPdf = new PDfExpAuditSave();
  btnDisbleAction: boolean = true;
  TZoffset = this.DateTimeService.setUTCTimeZone();
  GroupDyForm = this.formbuilder.group({
    Groups: ['', Validators.required],
    Users: ['']
    // Users: ['', Validators.required]
  });
  GroupID: number;
  Taskowner: number;
  unclaimDelegateTaskDyForm = this.formbuilder.group({
    selectedUser: ['', Validators.required],
    commenttext: [''],
  });
  addApproverTaskDyForm = this.formbuilder.group({
    AddApproverGroup: ['', Validators.required],
    AddApproverTaskOwner: [''],
    comment: [''],
  });

  byPassForm: FormGroup;
  // byPassForm = this.formbuilder.group({

  //  byPassForm: ['', Validators.required],
  // });

  frmiFramewindow: FormGroup;
  dateFormats: FormatSettings = {
    displayFormat: 'dd-MMM-yyyy',
    inputFormat: 'dd-MMM-yyyy'
  };
  myRestrictions: FileRestrictions = {
    allowedExtensions: [".pdf",],
    maxFileSize: 2147483648,
  };

  value: any;
  ActionType: any;
  TaskActionType: any;
  currentWorkFlowData: any;
  wrkFlowTaskActions: any;
  wrkFlowCurrentTaskdata: any;
  wrkFlowCurrentProjectdata: any;
  wrkFlowCurrentsubProcessData: any;
  wrkFlowCurrentcustomData: any;
  wrkFlowCurrentcustomDataEdit: any;
  wrkFlowAnnotatedDocument: any;
  wrkFlowReferenceFilesData: any = {};
  wrkFlowCurrentProjectdata1: any;
  wrkFlowCurrentsubProcessData1: any;
  wrkFlowCurrentcustomData1: any;
  workFlowTaskAction: any;
  wrkFlowCurrentCCMInputData: any;
  wrkFlowSubProcessDiagram: any;
  wrkFlowSubProcessDiagrams: boolean = false;
  compnayID: number = 1;
  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent();
  progress: number;
  message: string;
  FileName: string;
  AuditLogId: boolean = false;
  Audituserdata: AduitForm[] = [];
  htmlToAdd: any;
  divHisdata: boolean = false;
  IsAuditExportExcel: boolean = false;
  AuditexcelBtn: boolean = false;
  BypassunpassAduit: boolean = false;
  IsSecondaryAuth: boolean = false;
  workflowId: number = 1
  addApproversConfrimopened: boolean = false;
  public languageDataDefault: any;
  public AuditHistoryPopUpShow = false;
  tempDocumentsPath: string = "";
  myFiles: Array<FileInfo> = [];
  displayAutomatePopUp: string = '';
  automationFileName = '';
  public ComponentsList: any;
  public selectedComponents = [];
  public isChecked = false;
  public selectAllValue = '';
  public singleComponent = true;
  public fileUploadControls = [];
  public showGaatesUploadPopUp = false;
  public validationMessage = '';
  public fileUploadRestrictions = [];
  public isDisableInputEdit = false;
  public showCompleteRqstPopUp = false;
  public updatedInputs: ModificationInfo[] = [];
  public CCMInputMasterData;
  public showAnnotationStatusBox = false;
  public CCMLanguages = [];
  public selectedLangValue;
  public languageName;
  public CCMInputNewData;
  public CCMInputAllLangFormsData = {};
  public previousLangId;

  public checked = false;
  public showCCMInputSubmitConifrm = false;
  public showCCMLanguageChangeConifrm = false;
  public QRDvalidationMessage = "";
  IsGAATESEnabled: boolean = false;
  ShowBreadcrumbs: boolean;
  submitCount: number = 0;
  projectStatus: any;
  FormValueSaving: any;
  openBypassUnbypasswindow: boolean = false;

  showProductPopUp: boolean = false;

  docPath: string;
  openDocPath: string;

  thumbnailFilePath: string;
  thumbnailFilePath1: string;

  fileName: string;

  thumbnailFileName: string = '';

  groupsDialogpopID: boolean = false;

  date: any;

  selectableMode: string = "";
  ShowMultiSelect: boolean = false;
  EnableMultiSelect: any;
  IsSearchedApplied: string = "";
  filteredRecords: any;
  EnableFilter: boolean = false;
  public mySelection: number[] = [];
  checkedData: any = [] = [];

  ActionEvent: string = "";
  gridData: any;
  IsDisable: boolean = false;
  IsProjectAccess: boolean = false;

  documentRepositoryID: any;
  public filter: CompositeFilterDescriptor;

  checkBoxCheckIDs: any = [];
  checkBoxCheckIDslength: number = 0;
  QAAPTaskStatus: number = 0;
  public token: string;
  subProcessId: number = 0;
  public _dialogService: DialogService;
  timerSubscription: Subscription

  oldMaterialDispositionPopup: boolean = false;
  dispositionValueSaving: any;
  dispositionValueLastselected: any = '';

  constructor(private moduleService: ModuleService, private dataService: DynamicFieldService, private router: Router,
    private qcs: DynamicFieldControlService, private amService: AdminUserManagementService,
    private DFS: DynamicFieldService, private notifyService: NotificationAlertsService, public sanitizer: DomSanitizer, public dialogService: DialogService,
    public dws: DialogWidthService, private GV: GlobalVeriables, private formbuilder: FormBuilder, private DateTimeService: DateConvertions, private DisplayAllFields: GetAuditAllFieldName, public service: DynamicFieldService, private commonfeaturesservice: CommonFeaturesService
    , private languageService: LanguageService, private loader: LoaderService, private http: HttpClient) {
    dialogService = this._dialogService;
  }

  ngOnInit() {

    //this.wrkFlowReferenceFilesData.filesList = [];
    this.showProductPopUp = false;
    this.token = localStorage.getItem('token');
    this.ISAutomationTask = false;
    this.displayAutomatePopUp = "none";
    this.hasParent = this.template.hasParent == "0" ? true : false;
    this.languageDataDefault = this.languageService.dataByLanguage;
    this.GV.UserRoleID = +localStorage.getItem("userRoleID");
    this.sessionUserName = localStorage.getItem("userName");
    this.data = this.source;
    this.fileInfo = [];
    this.GV.UserId = +localStorage.getItem("userId");
    //console.log(this.GV.UserId);
    this.template;
    this.templateID;
    this.showDialogPopUp = false;
    this.displaypopup = "none";
    this.openBypassUnbypasswindow = false;
    this.GV.SetProjectID(this.ParentID);
    // this.IpAddres = localStorage.getItem("Ip");
    this.GV.SetFileType(1);//1 from Upload
    this.GV.SetFileInfo([]);//1 from Upload


    this.IpAddres = this.GV.UserIpAddress;

    this.documentsPath = this.moduleService.getBaseUrl();
    this.docPath = this.moduleService.getBaseUrl() + "documents/";


    //Taking request  1 for permance improving
    this.moduleService.dependencyTemplate.pipe(take(1)).subscribe(data => {

      if (this.template.isSplitter == "1") {
        this.RecordID = this.template.recordID;
        this.RecordIDs = this.template.recordID;
        this.ParentID = this.template.parentRecordID;

      } else
        if (data.componentType == "1") {

          //if (data.selectedRecordId == 0) {
          this.RecordID = this.template.selectedRecordID;
          this.RecordIDs = this.template.selectedRecordIDs;

          this.ParentID = this.template.dependentRecordID;

          // } else {
          //   this.RecordID = data.selectedRecordId;
          //   this.RecordIDs = data.selectedRecordIds;

          //   this.ParentID = data.dependentRecordID;

          // }





          this.GV.SetRecordID(this.RecordID);
          this.GV.SetParentRecordID(this.ParentID);
          this.GV.SetProjectID(this.ParentID);
        }
        else {
          this.RecordID = this.GV.GetRecordID();
          this.RecordIDs = this.GV.GetRecordID();
          this.ParentID = this.GV.GetParentRecordID();
        }
      //alert(this.RecordID+","+this.ParentID);
      // this.moduleService.isProjectStart(this.ParentID).subscribe(data => {
      //   if (data.status == 276) {
      //     //if (this.GV.UserRoleID == 3)
      //     //  this.btnShowBypassUnByPass = true;
      //     //else
      //     //  this.btnShowBypassUnByPass = false;
      //   }
      // });
      this.islist = false;
      if (this.RecordID != 0 && this.RecordID != -1) {
        //   this.bindTaskManagementView();
      }
      else {
        this.islist = true;
      }


    });
    this.moduleService.closeDAL.subscribe(data => {



      this.fileInfo = this.GV.GetFileInfo();
      if (this.fileInfo != undefined && this.fileInfo.length > 0)
        if (this.dynamicForm != undefined && this.dynamicForm.controls["AnnotatedArtwork"] != undefined)
          this.dynamicForm.controls["AnnotatedArtwork"].setErrors(null);
      var fileType = this.GV.GetFileType();


      if (fileType == 1 || fileType == 0) {
        // "LocalUpload";

        this.tempDocumentsPath = this.moduleService.getBaseUrl() + "documents/temp";

      } else {
        //"DALUpload";

        this.tempDocumentsPath = this.moduleService.getBaseUrl() + "documents";


      }




      //this.uploadedFiles = components;
      this.myFiles = this.fileInfo;
      this.CloseCustomUpload();

      this.showLoader = false;


    });


    //this.moduleService.breadCrumbs.subscribe(breadcrumbs => {


    //  this.breadcrumbs = breadcrumbs;

    //});

    this.ShowBreadcrumbs = this.template.showBreadcrumbs == "True" ? true : false;


    this.moduleService.breadCrumbName.subscribe(breadcrumb => {





      this.moduleService.breadCrumbs.subscribe(breadcrumbs => {


        var templateID = this.template.templateID;
        var displayControlsID = this.template.displayControlsID;
        var depeendentTemplateID = this.template.isDependent;
        var parentTemplateID = this.template.hasParent;


        this.breadcrumbs = breadcrumbs.filter(a => a.templateId != parentTemplateID);
        if (breadcrumb.displayName != "" && breadcrumb.displayName != undefined) {

          var displayData = this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID);

          if (displayData == null || displayData == undefined || displayData.length == 0) {

          } else {
            this.breadcrumbs.filter(a => a.templateId == breadcrumb.templateId && a.displayControlsID == breadcrumb.displayControlsID)[0].displayName = breadcrumb.displayName;

          }


        }


      });





    });

    this.date = new Date().getTime();

  }
  getJsonData() {
    return this.http.get('./assets/ProductsList.json');
  }

  addproduct() {
    let data1: any;
    this.moduleService.getAllProducts().subscribe(data => {
      debugger;


      let parsedData = JSON.parse(JSON.stringify(data));
      let data2 = JSON.parse(parsedData);


      this.gridData = data2;
      this.showProductPopUp = true;

    });
  }

    closeProd()
    {
      this.showProductPopUp = false;

    }
    ngDoCheck() {
      if (JSON.stringify(this.template) !== JSON.stringify(this.previousData)) {
        // Perform the reloading logic here
        this.RecordID = this.template.recordID;
        this.RecordIDs = this.template.recordID;
        this.ParentID = this.template.parentRecordID;
        // This block of code will be executed whenever the 'inputData' object changes
        this.bindTaskManagementView();
        this.previousData = Object.assign({}, this.template);
      }
    }
    removeFile(file) {


      this.fileInfo = this.fileInfo.filter(a => a.name != file.name);

      this.GV.SetFileInfo(this.fileInfo);
      this.myFiles = this.fileInfo;

    }
  public zeroPad = (num, places) => String(num).padStart(places, '0');
  CloseCustomUpload() {

    this.showCustomArtWorkWindow = false;
    this.setSubProcessdisplay = false;

    this.FileType = this.GV.FileType;

    this.fileInfo = this.GV.GetFileInfo();

    this.SelectedRecord = this.RecordID;
  }
  Allowuser(event) {
    if (event.target.checked)
      this.ShowBtn = true;
    else
      this.ShowBtn = false;

  }
  Authformsubmited() {
    var id = 0;
    if (this.AuthForm.invalid) {
      this.formvalid = true;
      return;
    }
    var username = this.AuthForm.controls['UserName'].value;
    var password = this.AuthForm.controls['Password'].value;
    //if (this.AuthForm.controls['Comments'] != undefined)
    var comments = this.AuthForm.controls['Comments'].value;

    this.moduleService.getesignstatus(username, password, comments, this.ActionType.actionEvent, this.RecordID, this.IpAddres).subscribe(data => {

      id = data.userId;
      if (id > 0 && data.loginFailType == "NA") {
        this.onTaskFormActionClick(this.ActionType, this.TaskActionType);
        this.IsSecondaryAuth = false;
      }
      else if (data.loginFailType == "Invalid")
        this.notifyService.show("Invalid credentials. You have " + data.loginFailAttempts + " more attempt(s) before your account gets locked out.", 2);
      else {
        this.notifyService.show("Your account has been locked out due to multiple failed login attempts. Please contact Admin.", 2);
        this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForUserLockOrUnlock, 1, 0, 0, data.userId, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
      }
    })
  }

  actionStatus: any;
  onFormEditSubmit() {

    this.IpAddres = this.GV.UserIpAddress;

    let uploadFields = Object.keys(this.uploadedFiles);
    if (uploadFields.length > 0) {
      for (var i = 0; i < uploadFields.length; i++) {
        this.dynamicForm.value[uploadFields[i]] = this.uploadedFiles[uploadFields[i]];
      }
    }

    if (this.dynamicSubscriberForm != undefined) {

      if (this.dynamicSubscriberForm.invalid) {
        this.invalidSubmitAttempt = true;
        return;
      }
    }

    if (this.dynamicForm1 != undefined) {

      if (this.dynamicForm1.invalid) {
        this.invalidSubmitAttempt = true;
        return;
      }
    }
    if (this.dynamicFormEditTask.invalid) {
      this.invalidSubmitAttempt = true;
      return;
    }

    else {
      this.showLoader = true;
      this.taskSubDetails.projectID = parseInt(this.ParentID);
      this.taskSubDetails.processDataId = parseInt(this.RecordID);
      this.taskSubDetails.offset = "";
      this.taskSubDetails.actionType = "FormSubmit";
      var data = this.dynamicFormEditTask.value;
      this.taskSubDetails.formDataValues = JSON.stringify(this.dynamicFormEditTask.value);
      this.taskSubDetails.UserID = this.GV.UserId;
      this.taskSubDetails.Ip = this.IpAddres;
      if (this.showEditTaskPopup == true) {
        this.taskSubDetails.actionType = "EditTask";
        var bEditData = this.dynamicFormBeforeEditTask.value;
        if (data.GroupID == bEditData.GroupID && data.TaskOwner == bEditData.TaskOwner) {
          this.SetTaskOwnerMail = false;
        }
        else {
          this.SetTaskOwnerMail = true;
        }
        if (data.TaskName == bEditData.TaskName) {
          this.actionStatus = "No";
        }
        else {
          this.actionStatus = "Yes";
        }
      }
      this.taskSubDetails.actionStatus = this.actionStatus;
      if (this.EnableSubproces) {
        this.ID = this.ParentID;
        this.templateID = 11;
        this.templateData.ID = this.ID !== undefined ? parseInt(this.ID) : this.ID;
        this.templateData.TaskID = this.RecordID
        this.templateData.TemplateID = parseInt(this.templateID);
        this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm1.value);
        this.templateData.Isfromsubprocess = true;
        this.templateData.WorkFlowId = 0;
        this.templateData.UserID = this.GV.UserId;
        this.templateData.Ip = this.IpAddres;
        this.templateData.UploadFileType = this.GV.GetFileType();
        this.dataService.saveDynamicFormFieldsdata(this.templateData).subscribe(responce => {
          this.showLoader = false;
          this.dynamicForm1.reset();
          this.EnableSubproces = false;
          if (this.EnableEditTask) {
            this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
              this.showLoader = false;
              this.EnableEditTask = false;
              this.dynamicFormEditTask.reset();
              this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TDUS", this.compnayID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.GetMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
              if (this.SetTaskOwnerMail) {
                this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoSetTaskOwner, 1, this.ParentID, 0, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
              }
            },
              error => {
                this.showLoader = false;
                this.notifyService.show(this.GetMessageByLanguage('STC_TDUF', "Task details updated failed !!. ") + error.statusText, 4);
              }
            );
          }
          if (this.EnableSubscriber) {
            this.taskSubDetails.projectID = parseInt(this.ParentID);
            this.taskSubDetails.processDataId = parseInt(this.RecordID);
            this.taskSubDetails.offset = "";
            this.taskSubDetails.actionType = "EditSubscriber";
            this.taskSubDetails.formDataValues = JSON.stringify(this.dynamicSubscriberForm.value);
            this.taskSubDetails.UserID = this.GV.UserId;
            this.taskSubDetails.Ip = this.IpAddres;

            let subgroup = +this.dynamicSubscriberForm.controls["GroupID"].value;
            let subscriber = +this.dynamicSubscriberForm.controls["TaskOwner"].value;
            if (subgroup != 0) { //if (subgroup != 0 && subscriber != 0)
              this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
                this.showLoader = false;
                this.dynamicSubscriberForm.reset();
                this.EnableSubscriber = false;
                this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationSubscriber, 1, this.ParentID, this.wrkFlowCurrentsubProcessData.subProcessId, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
              },
                error => {
                  this.showLoader = false;
                }
              );
            }
            this.dynamicSubscriberForm.reset();
            this.EnableSubscriber = false;
          }
          this.bindTaskManagementView();
          this.reloadParentDependentTemplate();
        })
      }
      else {
        if (this.EnableEditTask) {
          this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
            this.showLoader = false;
            this.EnableEditTask = false;
            this.dynamicFormEditTask.reset();
            this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TDUS", this.compnayID).subscribe((dataCDActionsMessages) => {
              this.notifyService.show(this.GetMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
            });
            if (this.SetTaskOwnerMail) {
              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoSetTaskOwner, 1, this.ParentID, 0, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
            }
          },
            error => {
              this.showLoader = false;
              this.notifyService.show(this.GetMessageByLanguage('STC_TDUF', "Task details updated failed !!. ") + error.statusText, 4);
            }
          );
        }
        if (this.EnableSubscriber) {
          this.taskSubDetails.projectID = parseInt(this.ParentID);
          this.taskSubDetails.processDataId = parseInt(this.RecordID);
          this.taskSubDetails.offset = "";
          this.taskSubDetails.actionType = "EditSubscriber";
          this.taskSubDetails.formDataValues = JSON.stringify(this.dynamicSubscriberForm.value);
          this.taskSubDetails.UserID = this.GV.UserId;
          this.taskSubDetails.Ip = this.IpAddres;

          let subgroup = +this.dynamicSubscriberForm.controls["GroupID"].value;
          let subscriber = +this.dynamicSubscriberForm.controls["TaskOwner"].value;
          if (subgroup != 0) { //if (subgroup != 0 && subscriber != 0)
            this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
              this.showLoader = false;
              this.dynamicSubscriberForm.reset();
              this.EnableSubscriber = false;
              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationSubscriber, 1, this.ParentID, this.wrkFlowCurrentsubProcessData.subProcessId, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
            },
              error => {
                this.showLoader = false;
              }
            );
          }
          this.dynamicSubscriberForm.reset();
          this.EnableSubscriber = false;
        }
        this.bindTaskManagementView();
        this.reloadParentDependentTemplate();
      }
      this.isedtit = false;
      this.showEditTaskPopup = false;
      this.invalidSubmitAttempt = false;
    }
  }

  CCMInputSubmit() {
    if (this.dynamicForm.invalid) {
      this.invalidSubmitAttempt = true;
      return;
    }
    else {
      this.showCCMInputSubmitConifrm = true;
    }
  }
  CCMInputSubmitConifrm() {

    //debugger
    this.showLoader = true;
    //var filterComps= this.ComponentsList.filter(x=> this.selectedComponents.includes(x.key));
    //components to be copied with input values
    this.dynamicForm.value.CopyComponents = this.selectedComponents.map(o => o.key);

    //this.moduleService.GetCCMInputSubProcessTaskDetails(this.ParentID, this.RecordID).subscribe(result=>{

    //update data in form values from masterData if values are not updated.
    // for (var i = 0, len = this.CCMInputMasterData.length; i < len; i++) {
    //   if (this.dynamicForm.value[this.CCMInputMasterData[i].fieldName] == '') {
    //     this.dynamicForm.value[this.CCMInputMasterData[i].fieldName] = this.CCMInputMasterData[i].formValue;
    //   }
    // }
    // var currentLang=this.CCMLanguages.find(x=>x.id==this.selectedLangValue).name;
    // this.CCMInputAllLangFormsData[currentLang]=this.dynamicForm.value;
    // this.taskSubDetails.projectID = parseInt(this.ParentID);
    // this.taskSubDetails.processDataId = parseInt(this.wrkFlowCurrentCCMInputData.processDataID);
    // this.taskSubDetails.offset = "";
    // this.taskSubDetails.formDataValues = JSON.stringify(this.CCMInputAllLangFormsData);
    // if (this.isEditActionEnable)
    //   this.taskSubDetails.actionType = 'EditTask';
    // else
    //   this.taskSubDetails.actionType = 'Task Action';
    // this.taskSubDetails.UserID = this.GV.UserId;
    // this.taskSubDetails.Ip = this.IpAddres;

    // this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
    //   this.showLoader = false;
    //   //this.notifyService.show("Task updated successfully !!", 1);
    //   // this.commonfeaturesservice.GetAllActionsMessages(1, action.workflowTasksActionID, action.standardActionID, 999, "TPCS", this.compnayID).subscribe((dataCDActionsMessages) => {
    //   //   this.notifyService.show(this.GetMessageByLanguage('AMSG_'+dataCDActionsMessages[0].aliasKey,dataCDActionsMessages[0].displayMessage) , dataCDActionsMessages[0].messageType);
    //   // });
    //   this.notifyService.show("Task details updated successfully !!", 1);
    this.bindTaskManagementView();
    this.reloadParentDependentTemplate();

    // },
    //   error => {
    //     this.showLoader = false;
    //     this.notifyService.show(this.GetMessageByLanguage('STC_TDUF', "Task details updated failed !!. ") + error.statusText, 4);
    //   }
    // );
    //});


  }

  onFormSubmit(ActionName) {
    this.showLoader = true;

    this.IpAddres = this.GV.UserIpAddress;

    let fileCount = 0;
    if (this.fileInfo.length > 0) {
      var components = [];
      if (this.FileType == 1 || this.FileType == 0) {//upload
        this.fileInfo.forEach(function (part, index) {
          components.push(part.name);
        });
      } else {// 2 dal
        this.fileInfo.forEach(function (part, index) {
          components.push(part.uid);
        });
      }
      this.dynamicForm.value["AnnotatedArtwork"] = components;
    }
    else {
      let uploadFields = Object.keys(this.uploadedFiles);
      if (uploadFields.length > 0) {
        for (var i = 0; i < uploadFields.length; i++) {
          this.dynamicForm.value[uploadFields[i]] = this.uploadedFiles[uploadFields[i]];
          if (this.uploadedFiles[uploadFields[i]] && this.uploadedFiles[uploadFields[i]].length > 0)
            fileCount++;
          if (fileCount > 0 && i < uploadFields.length - 1 && this.uploadedFiles[uploadFields[i]].length > 0 &&
            this.uploadedFiles[uploadFields[i + 1]].length > 0 && this.uploadedFiles[uploadFields[i]][0] == this.uploadedFiles[uploadFields[i + 1]][0]) {
            this.invalidSubmitAttempt = true;
            this.QRDvalidationMessage = 'Duplicate Files are not allowed.';
            return;
          }
        }

      }
    }
    if (this.dynamicForm.controls.OriginalArtwork != undefined) {
      if (this.dynamicForm.value.OriginalArtwork.length <= 0) {
        this.dynamicForm.controls.OriginalArtwork.setErrors({ required: true })
      }
    }
    if (this.dynamicForm.invalid) {
      if (ActionName == 'GaatesUpload')//Gaates upload docs task
      {
        if (fileCount == 0) {
          this.invalidSubmitAttempt = true;
          this.validationMessage = 'Upload at least one file.';
          return;
        } else {
          let uploadFields = Object.keys(this.uploadedFiles);
          let QRDFileUploaded = false;
          for (var i = 0; i < uploadFields.length; i++) {
            if (uploadFields[i].includes("QRD")) {
              if (this.uploadedFiles[uploadFields[i]].length > 0)
                QRDFileUploaded = true;
            }
          }
          for (var i = 0; i < uploadFields.length; i++) {
            if (QRDFileUploaded && uploadFields[i].includes("QRD")) {
              if (this.uploadedFiles[uploadFields[i]].length == 0) {
                this.invalidSubmitAttempt = true;
                this.QRDvalidationMessage = 'All Languages QRD Files are Required.';
                return;
              }
            }
          }
        }
      } else {
        this.invalidSubmitAttempt = true;
        this.showLoader = false;
        return;
      }
    }
    if (this.isTaskBypassAction) {
      this.ID !== undefined ? parseInt(this.ID) : this.ID;
      this.templateData.ProjectId = parseInt(this.ParentID);
      this.templateData.ProcessDataId = parseInt(this.RecordID);
      this.templateData.UserID = this.GV.UserId;
      this.templateData.Ip = this.IpAddres;
      if (this.wrkFlowCurrentTaskdata.workFlowStatus == "In Progress" || this.wrkFlowCurrentTaskdata.workFlowStatus == "Pending") {//inprogress
        this.templateData.TaskAction = "Bypass";
        this.isTaskBypassAction = false;
        this.displaypopup = "none";
        this.openBypassUnbypasswindow = false;
        this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
        this.moduleService.saveTaskStatusComments(this.templateData).subscribe(data => {
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_TBPS', "Task bypassed successfully !!"), 1);
          //this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TBYS", this.compnayID).subscribe((dataCDActionsMessages) => {
          //  this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          //});
          //notification send email code ---
          let NotificationPreferences = this.dynamicForm.controls["NotificationPreferences"].value;
          let NTriggerEvent = this._NotificationEmailTriggerEvent.NotificationtoSubprocessBypassed;
          let NTriggerType = 1
          if (NotificationPreferences != 467)
            this.amService.GetActionEmailNotification(NTriggerEvent, NTriggerType, this.ParentID, 0, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
          this.bindTaskManagementView();
          this.reloadParentDependentTemplate();
        })
        //this.bindTaskManagementView();
        //this.reloadParentDependentTemplate();

      }
      else {
        this.templateData.TaskAction = "UnBypass";
        this.isTaskBypassAction = false;
        this.displaypopup = "none";
        this.openBypassUnbypasswindow = false;
        this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
        this.templateData.UserID = this.GV.UserId;
        this.templateData.Ip = this.IpAddres;
        this.moduleService.saveTaskStatusComments(this.templateData).subscribe(data => {
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_TUBS', "Task unbypassed successfully !!"), 1);
          //this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TUBYS", this.compnayID).subscribe((dataCDActionsMessages) => {
          //  this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          //});
          //notification send email code 
          let NotificationPreferences = this.dynamicForm.controls["NotificationPreferences"].value;
          let NTriggerEvent = this._NotificationEmailTriggerEvent.NotificationtoSubProcessUnBypassed;
          let NTriggerType = 2
          if (NotificationPreferences != 467)
            this.amService.GetActionEmailNotification(NTriggerEvent, NTriggerType, this.ParentID, 0, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();

          this.bindTaskManagementView();
          this.reloadParentDependentTemplate();
        })


      }

    }
    else if (this.isSuspendCycleAction) {
      this.displaypopup = "none";
      this.openBypassUnbypasswindow = false;
      this.showLoader = true;
      this.isSuspendCycleAction = false;
      this.templateData.TaskAction = "SuspendCycle";
      this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
      this.templateData.UserID = this.GV.UserId;
      this.templateData.Ip = this.IpAddres;
      this.templateData.SubprocessID = this.wrkFlowCurrentTaskdata.subProcessId;
      this.templateData.ProjectId = parseInt(this.ParentID);
      this.templateData.ProcessDataId = parseInt(this.RecordID);
      this.moduleService.updateTaskResumeSuspendCycle(this.templateData).subscribe(data => {
        //console.log(data);
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_CSUS', "Cycle suspended successfully !!"), 1);
        this.bindTaskManagementView();
        this.reloadParentDependentTemplate();
        //this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TUBYS", this.compnayID).subscribe((dataCDActionsMessages) => {
        //  this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
        //});
        //notification send email code 
        let NotificationPreferences = this.dynamicForm.controls["NotificationPreferences"].value;
        let NTriggerEvent = this._NotificationEmailTriggerEvent.NotificationtoSuspendCycleTask;
        let NTriggerType = 1
        if (NotificationPreferences != 467)
          this.amService.GetActionEmailNotification(NTriggerEvent, NTriggerType, this.ParentID, 0, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();

      })

    }
    else if (this.isCancelCycleAction) {
      this.displaypopup = "none";
      this.openBypassUnbypasswindow = false;
      this.showLoader = true;
      this.isCancelCycleAction = false;
      this.templateData.TaskAction = "CancelCycle";
      this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
      this.templateData.UserID = this.GV.UserId;
      this.templateData.Ip = this.IpAddres;
      this.templateData.SubprocessID = this.wrkFlowCurrentTaskdata.subProcessId;
      this.templateData.ProjectId = parseInt(this.ParentID);
      this.templateData.ProcessDataId = parseInt(this.RecordID);
      this.moduleService.updateTaskCancelCycle(this.templateData).subscribe(data => {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_CCYCLE', "Cycle cancelled successfully !!"), 1);
        this.bindTaskManagementView();
        this.reloadParentDependentTemplate();
        let NotificationPreferences = this.dynamicForm.controls["NotificationPreferences"].value;
        let NTriggerEvent = this._NotificationEmailTriggerEvent.NotificationtoCancelCycleTask;
        let NTriggerType = 3
        if (NotificationPreferences != 467)
          this.amService.GetActionEmailNotification(NTriggerEvent, NTriggerType, this.ParentID, 0, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();

      })

    }
    else if (this.isResumeCycleAction) {
      this.displaypopup = "none";
      this.openBypassUnbypasswindow = false;
      this.showLoader = true;
      this.isResumeCycleAction = false;
      this.templateData.TaskAction = "ResumeCycle";
      this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
      this.templateData.UserID = this.GV.UserId;
      this.templateData.Ip = this.IpAddres;
      this.templateData.SubprocessID = this.wrkFlowCurrentTaskdata.subProcessId;
      this.templateData.ProjectId = parseInt(this.ParentID);
      this.templateData.ProcessDataId = parseInt(this.RecordID);
      this.moduleService.updateTaskResumeSuspendCycle(this.templateData).subscribe(data => {
        //console.log(data);
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_CRES', "Cycle resumed  successfully !!"), 1);
        this.bindTaskManagementView();
        this.reloadParentDependentTemplate();
        //this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TUBYS", this.compnayID).subscribe((dataCDActionsMessages) => {
        //  this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
        //});
        //notification send email code 
        let NotificationPreferences = this.dynamicForm.controls["NotificationPreferences"].value;
        let NTriggerEvent = this._NotificationEmailTriggerEvent.NotificationResumeCycleTask;
        let NTriggerType = 2
        if (NotificationPreferences != 467)
          this.amService.GetActionEmailNotification(NTriggerEvent, NTriggerType, this.ParentID, 0, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();

      })

    }
    else if (this.wrkFlowCurrentTaskdata.taskId == 23) {

      this.showLoader = true;
      this.displaypopup = "none";
      this.openBypassUnbypasswindow = false;
      this.ID = this.ParentID;
      this.templateID = 11;
      this.templateData.ID = this.ID !== undefined ? parseInt(this.ID) : this.ID;
      this.templateData.TaskID = this.RecordID
      this.templateData.TemplateID = parseInt(this.templateID);
      this.templateData.DynFormFieldData = JSON.stringify(this.dynamicForm.value);
      this.templateData.Isfromsubprocess = true;
      this.templateData.Ip = this.IpAddres;
      this.templateData.WorkFlowId = this.wrkFlowCurrentTaskdata.workFlowId;
      this.templateData.UserID = this.GV.UserId;
      this.templateData.UploadFileType = this.GV.GetFileType();
      this.dataService.saveDynamicFormFieldsdata(this.templateData).subscribe(responce => {
        this.showLoader = false;
        //this.notifyService.show("Sub process details updated successfully !!", 1);
        this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "SPDUS", this.compnayID).subscribe((dataCDActionsMessages) => {
          this.notifyService.show(this.GetMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
        });
        this.bindTaskManagementView();
        this.reloadParentDependentTemplate();

      })
    }
    else {
      this.showLoader = true;
      this.showDialogPopUp = false;
      this.taskSubDetails.projectID = parseInt(this.ParentID);
      this.taskSubDetails.processDataId = parseInt(this.RecordID);
      this.taskSubDetails.offset = "";
      if (this.showCompleteRqstPopUp == true) {
        this.taskSubDetails.actionType = "Task Action";
      } else {
        this.taskSubDetails.actionType = "FormSubmit";
      }
      this.taskSubDetails.Ip = this.IpAddres;
      this.taskSubDetails.formDataValues = JSON.stringify(this.dynamicForm.value);
      this.taskSubDetails.UserID = this.GV.UserId;
      this.taskSubDetails.UploadFileType = this.GV.GetFileType();
      this.taskSubDetails.workFlowId = this.wrkFlowCurrentTaskdata.workFlowId;
      this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
        //this.showLoader = false;
        this.dynamicForm.reset();
        //this.notifyService.show("Task details updated successfully !!", 1);

        this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TDUS", this.compnayID).subscribe((dataCDActionsMessages) => {
          this.notifyService.show(this.GetMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
        });

        setTimeout(() => {
          this.showLoader = true;
          this.bindTaskManagementView();
          this.reloadParentDependentTemplate();
          this.isedtit = false;
        }, 2000);
        this.showLoader = false;
      },
        error => {
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_TDUF', "Task details updated failed !!. ") + error.statusText, 4);
        }
      );
    }


  }

  handleParentdatepickerChange(value: any, dynamicField: any) {
    if (value != null) {

      this.showLoader = true;

      Array.prototype.forEach.call(this.DynamicFields, DynamicFieldGroup => {

        Array.prototype.forEach.call(DynamicFieldGroup.dynamicFields, DynamicField => {

          if (DynamicField.controlTypeID == dynamicField.controlTypeID && DynamicField.parentFieldIdDatePicker == dynamicField.fieldId) {
            this.dynamicForm.controls[DynamicField.key].setValue(value);
          }

          else if (DynamicField.controlTypeID == dynamicField.controlTypeID && DynamicField.parentFieldIdDatePicker == null) {
            this.dynamicForm.controls[DynamicField.key].setValue(value);
          }

        });

      });

      this.showLoader = false;
    }
  }


  public getOffset() {
    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + this.zeroPad(hours, 2) + ':' + this.zeroPad(minutes, 2);
    this.offSet = offset;
  }

  UsersOnChange(selectedId: any, dynamicField: any) {
    this.selectedItems = '';
    if (selectedId != undefined) {
    }
    else {
      this.selectedItems = '';
    }
    var selectedValues = (selectedId == null || selectedId == undefined) ? "" : selectedId;
    this.moduleService.GetDependentFields('11', dynamicField.fieldId, selectedValues).subscribe(data => {
      if (data != 0) {
        if (this.DynamicFields != undefined) {
          if (this.DynamicFields[0].dynamicFields.length > 0) {
            var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.fieldId == data[0].targetFieldId);
            if (index != -1) {
              if (data[0].conditionType == 5 && data[0].sourceFiledValue != selectedValues) {
                this.DynamicFields[0].dynamicFields[index].required = false;
                this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[index].key].setValue(null);
                this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[index].key].setErrors(null);
              }
              else {
                this.DynamicFields[0].dynamicFields[index].required = true;
                this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[index].key].setValue(null);
                this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[index].key].setErrors({ required: true });
              }
            }
          }
        }
        if (this.DynamicFields1 != undefined) {
          if (this.DynamicFields1[0].dynamicFields.length > 0) {
            var index = this.DynamicFields1[0].dynamicFields.findIndex(p => p.fieldId == data[0].targetFieldId);
            if (index != -1) {
              if (data[0].conditionType == 2 && data[0].sourceFiledValue != selectedValues) {
                this.DynamicFields1[0].dynamicFields[index].dependentControlDisplay = false;
                this.dynamicForm1.controls[this.DynamicFields1[0].dynamicFields[index].key].setValue(null);
                this.dynamicForm1.controls[this.DynamicFields1[0].dynamicFields[index].key].setErrors(null);
              }
              else if (data[0].conditionType == 5 && data[0].sourceFiledValue != selectedValues) {
                this.DynamicFields1[0].dynamicFields[index].required = false;
                this.dynamicForm1.controls[this.DynamicFields1[0].dynamicFields[index].key].setValue(null);
                this.dynamicForm1.controls[this.DynamicFields1[0].dynamicFields[index].key].setErrors(null);
              }
              else {
                this.invalidSubmitAttempt = true;
                this.DynamicFields1[0].dynamicFields[index].required = true;
                this.DynamicFields1[0].dynamicFields[index].dependentControlDisplay = true;
                this.dynamicForm1.controls[this.DynamicFields1[0].dynamicFields[index].key].setValue(null);
                this.dynamicForm1.controls[this.DynamicFields1[0].dynamicFields[index].key].setErrors({ required: true });
              }
            }
          }
        }

        if (this.DynamicFieldsEditTask != undefined) {
          if (this.DynamicFieldsEditTask[0].dynamicFields > 0) {
            var index = this.DynamicFieldsEditTask[0].dynamicFields.findIndex(p => p.fieldId == data[0].targetFieldId);
            if (index != -1) {
              if (data[0].conditionType == 2 && data[0].sourceFiledValue != selectedValues) {
                this.DynamicFieldsEditTask[0].dynamicFields[index].dependentControlDisplay = false;
                this.dynamicFormEditTask.controls[this.DynamicFieldsEditTask[0].dynamicFields[index].key].setValue(null);
                this.dynamicFormEditTask.controls[this.DynamicFieldsEditTask[0].dynamicFields[index].key].setErrors(null);
              }
              else if (data[0].conditionType == 5 && data[0].sourceFiledValue != selectedValues) {
                this.DynamicFieldsEditTask[0].dynamicFields[index].required = false;
                this.dynamicFormEditTask.controls[this.DynamicFieldsEditTask[0].dynamicFields[index].key].setValue(null);
                this.dynamicFormEditTask.controls[this.DynamicFieldsEditTask[0].dynamicFields[index].key].setErrors(null);
              }
              else {
                this.invalidSubmitAttempt = true;
                this.DynamicFieldsEditTask[0].dynamicFields[index].required = true;
                this.DynamicFieldsEditTask[0].dynamicFields[index].dependentControlDisplay = true;
                this.dynamicFormEditTask.controls[this.DynamicFieldsEditTask[0].dynamicFields[index].key].setValue(null);
                this.dynamicFormEditTask.controls[this.DynamicFieldsEditTask[0].dynamicFields[index].key].setErrors({ required: true });
              }
            }
          }
        }
      }
      if (dynamicField.key == 'OldMaterialDispositionStatus' && dynamicField.value != '' && dynamicField.value != null && selectedId != null) {
        if (this.dispositionValueLastselected == '')
          this.dispositionValueSaving = dynamicField.value;

        this.oldMaterialDispositionPopup = true;
        return;
      }
      this.showLoader = false;
    })
  }

  public disabledDates = (date: Date): boolean => {
    this.value = new Date();
    return date <= new Date(this.value.setDate(this.value.getDate() - 1));
  }

  public removeEventHandler(e: RemoveEvent, fieldName: any): void {
    if (e.files.length > 0) {
      // service call to remove file from temp folder this.uploadedFiles[fieldName][0]
      let index = this.uploadedFiles[fieldName].indexOf(e.files[0].name);
      if (index != -1) {
        this.moduleService.removeUploadFiles(this.uploadedFiles[fieldName][index]).subscribe(data => {
        });
        this.uploadedFiles[fieldName].splice(index, 1);
      }
    }
  }

  public selectEventHandler(e: SelectEvent): void {
    this.myFiles = [];
    const that = this;
    e.files.forEach((file) => {
      this.myFiles.push(file);
      if (!file.validationErrors) {

      }
    });
  }

  uploadEventHandler(e: UploadEvent, fieldName: any, isMultiple: any) {
    //setTimeout(() => { }, 5000);
    //debugger
    this.showLoader = true;
    if (this.DFS.checkUndefinedNullEmpty(this.uploadedFiles[fieldName]) || !isMultiple) {
      if (!isMultiple && this.uploadedFiles[fieldName] !== undefined && this.uploadedFiles[fieldName].length > 0)
        this.moduleService.removeUploadFiles(this.uploadedFiles[fieldName][0]).subscribe(data => {
          this.showgriderror = true;
        });
      this.uploadedFiles[fieldName] = [];
    }
    let files: any;
    files = e.files;
    if (files.length === 0) {
      return;
    }
    let filesToUpload: File[] = files;
    let filelist = [];
    this.uploadedFiles[fieldName].push(files[0].name);

  }

  public errorEventHandler(e) {
    //console.log(e);
    return false;
  }
  public completeEventHandler() {
    //debugger
    setTimeout(function () {
      var items = document.getElementsByClassName("k-text-error");
      for (var i = 0; i < items.length; i++) {
        document.getElementsByClassName("k-text-error")[i]["innerText"] = "Please upload a file without annotiations";
      }
    }, 500);

    this.showLoader = false;
  }

  selectedKeysChange = function () {
    if (this.byPassItemsSelected.length == 0 && this.submitCount == 1)
      this.showgriderror = true;
    else
      this.showgriderror = false;
  }

  closeModalDialog() {
    this.displaypopup = "none";
    this.displayAutomatePopUp = "none";
    this.ISAutomationTask = false;
    this.uploadedFiles = [];
    this.isedtit = false;
    this.openBypassUnbypasswindow = false;
    this.templateData = null;
    this.isTaskBypassAction = null;
  }
  closeEdittaskDialog() {
    this.uploadedFiles = [];
    this.isedtit = false;
    this.showEditTaskPopup = false;
    this.invalidSubmitAttempt = false;
  }
  public toggleText(data: any) {

    return data.isChecked ? "Deselect All" : "Select All";
  }
  public isIndet(data: any) {
    return (
      data.selectedItems.length !== 0 && data.selectedItems.length !== data.options.length
    );
  }
  public onToggleClick(data: any) {
    data.selectedItems = [];
    data.isChecked = !data.isChecked;
    if (data.isChecked) {
      if (data.options.length > 0) {
        data.options.forEach(function (item) {
          data.selectedItems.push(item.value);

        });
      }
      else {
        data.selectedItems = [];
      }
    }
    else {
      data.selectedItems = [];
    }
    //data.selectedItems = data.isChecked ? data.options.slice() : [];
    this.valueChange(data, data.selectedItems);
  }
  public isItemSelected(item: any, dynamicfiled: any) {
    return dynamicfiled.selectedItems.some((x) => x === item.value);
  }

  valueChange(dynamicField, event) {
    this.showLoader = true;
    dynamicField.isChecked = dynamicField.selectedItems.length === dynamicField.options.length;
    var selectedValues = JSON.stringify(event);
    var template = "";
    if (this.template.displayControlsID == 32) {//checking for custom chart control and assign targetTemplateID
      template = this.template.targetTemplateId;
    } else {
      template = this.template.templateID;
    }

    this.moduleService.GetCascadingData(template, dynamicField.fieldId, selectedValues).subscribe(data => {
      if (data != null) {
        Array.prototype.forEach.call(data, dataField => {

          if (this.DynamicFields != undefined) {
            if (this.DynamicFields[0].dynamicFields.length > 0) {
              var index = this.DynamicFields[0].dynamicFields.findIndex(p => p.fieldId == dataField.fieldID);
              if (index != -1) {
                if (this.DynamicFields[0].dynamicFields[index].controlTypeID == 4) {
                  this.DynamicFields[0].dynamicFields[index].options = dataField.options;
                  this.DynamicFields[0].dynamicFields[index].value = "";
                  this.dynamicForm.controls[this.DynamicFields[0].dynamicFields[index].key].setValue("");
                  this.DynamicFields[0].dynamicFields[index].selectedItems = [];
                  this.DynamicFields[0].dynamicFields[index].isChecked = false;
                }
              }
            }
          }

          if (this.DynamicFields1 != undefined) {
            if (this.DynamicFields1[0].dynamicFields.length > 0) {
              var index = this.DynamicFields1[0].dynamicFields.findIndex(p => p.fieldId == dataField.fieldID);
              if (index != -1) {
                if (this.DynamicFields1[0].dynamicFields[index].controlTypeID == 4) {
                  this.DynamicFields1[0].dynamicFields[index].options = dataField.options;
                  this.DynamicFields1[0].dynamicFields[index].value = "";
                  this.dynamicForm1.controls[this.DynamicFields1[0].dynamicFields[index].key].setValue("");
                  this.DynamicFields1[0].dynamicFields[index].selectedItems = [];
                  this.DynamicFields1[0].dynamicFields[index].isChecked = false;
                }
              }
            }
          }

          if (this.DynamicSubscriberFields != undefined) {
            console.log("DynamicSubscriberFields", this.DynamicSubscriberFields);
            if (this.DynamicSubscriberFields[0].dynamicFields.length > 0) {
              var index = this.DynamicSubscriberFields[0].dynamicFields.findIndex(p => p.fieldId == dataField.fieldID);
              if (index != -1) {
                if (this.DynamicSubscriberFields[0].dynamicFields[index].controlTypeID == 4) {
                  this.DynamicSubscriberFields[0].dynamicFields[index].options = dataField.options;
                  this.DynamicSubscriberFields[0].dynamicFields[index].value = "";
                  this.dynamicSubscriberForm.controls[this.DynamicSubscriberFields[0].dynamicFields[index].key].setValue("");
                  this.DynamicSubscriberFields[0].dynamicFields[index].selectedItems = [];
                  this.DynamicSubscriberFields[0].dynamicFields[index].isChecked = false;
                }
              }
            }
          }

          if (this.DynamicFieldsEditTask != undefined) {
            if (this.DynamicFieldsEditTask[0].dynamicFields.length > 0) {
              var index = this.DynamicFieldsEditTask[0].dynamicFields.findIndex(p => p.fieldId == dataField.fieldID);
              if (index != -1) {
                if (this.DynamicFieldsEditTask[0].dynamicFields[index].controlTypeID == 4) {
                  this.DynamicFieldsEditTask[0].dynamicFields[index].options = dataField.options;
                  this.DynamicFieldsEditTask[0].dynamicFields[index].value = "";
                  this.dynamicSubscriberForm.controls[this.DynamicFieldsEditTask[0].dynamicFields[index].key].setValue("");
                  this.DynamicFieldsEditTask[0].dynamicFields[index].selectedItems = [];
                  this.DynamicFieldsEditTask[0].dynamicFields[index].isChecked = false;
                }
              }
            }
          }
        });
      }

      this.showLoader = false;

    })
  }

  closeshowDialogPopUp() {
    this.showDialogPopUp = false;
    this.showGaatesUploadPopUp = false;
    this.showCompleteRqstPopUp = false;
    this.uploadedFiles = [];
  }
  ProjectStartConfrim() {
    this.IpAddres = this.GV.UserIpAddress;


    //var ans = confirm("Are you sure you want to start this project?");
    //if (ans) {
    //debugger
    this.ProjectStartConfrimopened = false;
    this.showLoader = true;
    this.taskSubDetails.projectID = parseInt(this.ParentID);
    this.taskSubDetails.processDataId = parseInt(this.RecordID);
    this.taskSubDetails.offset = "";
    //this.taskSubDetails.formDataValues="";
    this.taskSubDetails.actionType = this.TaskAction;
    this.taskSubDetails.Ip = this.IpAddres;
    this.taskSubDetails.UserID = this.GV.UserId;
    this.taskSubDetails.offSet = this.offSet;
    this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {

      this.showLoader = false;
      if (data.status == "completed") {
        this.commonfeaturesservice.GetAllActionsMessages(1, 0, 13, 999, "TPOU", 1).subscribe((dataUActionsMessages2) => {
          this.notifyService.show(dataUActionsMessages2[0].displayMessage, dataUActionsMessages2[0].messageType);
        });
      }

      this.commonfeaturesservice.GetAllActionsMessages(1, 0, 7, 999, "PSS_CDSFSD", 1).subscribe((dataUActionsMessages2) => {
        this.notifyService.show(dataUActionsMessages2[0].displayMessage, dataUActionsMessages2[0].messageType);
      });

      this.bindTaskManagementView();
      this.reloadParentDependentTemplate();
      this.dataService.setValue('StartActionCompleted');

      // Calling Email Notifications
      if (data.status != "completed") {

        this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.ProjectStarted, 1, this.ParentID, 0, 0, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, 1, encodeURIComponent(this.offSet)).subscribe();
      }
    },
      error => {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_TDUF', "Task details updated failed !!. ") + error.statusText, 4);
      }
    );
    // }

  }
  closeDialog() {
    this.ProjectStartConfrimopened = false;
    this.showCCMInputSubmitConifrm = false;
  }
  closeDialog1() {
    this.IsSecondaryAuth = false;
    this.AuthForm.controls["Password"].reset();
    this.AuthForm.controls["Comments"].reset();

  }
  reloadParentDependentTemplate() {
    debugger;

    if (this.template.isSplitter == "1") {
      this.template.highlightRecordID = this.template.selectedRecordID;


      this.moduleService.passDependentSplitter(this.template);


    } else {

      this.moduleService.CheckIsDependents(this.template.moduleID, this.template.templateID).subscribe(dataDependent => {

        if (dataDependent.isDependent != null && dataDependent.isDependent == "0" && dataDependent.templateID != "0") {

          this.moduleService.passDependentRecord(dataDependent);
        }
      });
    }
  }
  isTaskBypassAction: boolean = false;
  isclaimTaskActionType: number = 0;
  ////all dynamic actions
  onTaskFormEsign(action: any, TaskAction: any) {
    this.ShowBtn = false;
    if (action.iseSignatureEnabled) {
      let IsSSOEnable = JSON.parse(localStorage.getItem("ssoLogin")) != null ? JSON.parse(localStorage.getItem("ssoLogin")) : false; //this.moduleService.enableSSO;
      if (IsSSOEnable) {
        this.openEsignWindow(action, TaskAction);
      }
      else {
        this.IsSecondaryAuth = true;
      }
      this.ActionType = action;
      this.TaskActionType = TaskAction;
    }
    else {
      this.onTaskFormActionClick(action, TaskAction);
    }

  }

  openEsignWindow(action: any, TaskAction: any) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let options = { headers: headers }
    this.http.get(getBaseUrl() + 'api/Account/SSOLogin?requestType=AppEsign&userID=' + this.GV.UserId, options).subscribe(
      (result) => {
        var popupWinWidth = 550, popupWinHeight = 650
        var left = (screen.width - popupWinWidth) / 2;
        var top = (screen.height - popupWinHeight) / 4;

        let windowncode: Window = window.open(
          result.toString(),
          "Electronic Signature.",
          "menubar=1,resizable=1,width=" + popupWinWidth + ",height=" + popupWinHeight + ", top=" + top + ", left=" + left
        )
        this.timerSubscription = timer(0, 10000)
          .pipe(
            map(() => {
              if (windowncode.closed) {
                this.checkWindow(windowncode, action, TaskAction)
              }
            }),
          )
          .subscribe()
      },
      (error) => console.error(error),
    )
  }

  checkWindow(winCode: any, action: any, TaskAction: any) {
    this.timerSubscription.unsubscribe()
    if (winCode['autoClosed'] !== undefined && winCode['autoClosed'] === "true") {
      this.onTaskFormActionClick(action, TaskAction);
    }
  }

  onTaskFormActionClick(action: any, TaskAction: any) {
    this.submitCount = 0;
    this.IpAddres = this.GV.UserIpAddress;
    this.ActionEvent = action.actionEvent;
    this.dispositionValueLastselected = '';
    this.dispositionValueSaving = '';


    this.ActionEventID = parseInt(action.standardActionID);
    this.workflowTasksActionID = parseInt(action.workflowTasksActionID);
    this.isclaimTaskActionType = 0;
    this.moduleService.GetDynamicProjectStatus(this.ParentID, 1).subscribe(data => {
      if (data == 2737) {//Deleted Status
        this.commonfeaturesservice.GetAllActionsMessages(4, this.workflowTasksActionID, this.ActionEventID, 1, "CDUAT", this.compnayID).subscribe((dataCDActionsMessages) => {
          this.notifyService.show(this.GetMessageByLanguage('PDTS', "Project is deleted you can not perform any actions !!!!"), 4);
        });
      }
      else {

        if (action.actionEvent == "Start") {
          this.TaskAction = TaskAction;
          this.ProjectStartConfrimopened = true;
        }
        else if (action.actionEvent == "btnReview") {
          this.ArtworkReviewgoToLink();
        }
        else if (action.actionEvent == "btnComplete") {

          this.showLoader = true;
          this.taskSubDetails.projectID = parseInt(this.ParentID);
          this.taskSubDetails.processDataId = parseInt(this.RecordID);
          this.taskSubDetails.offset = "";
          this.taskSubDetails.formDataValues = "";
          this.taskSubDetails.actionType = TaskAction;
          this.taskSubDetails.UserID = this.GV.UserId;
          this.taskSubDetails.Ip = this.IpAddres;

          this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
            this.showLoader = false;
            if (data.status == "completed") {
              this.commonfeaturesservice.GetAllActionsMessages(1, 0, 13, 999, "TPOU", 1).subscribe((dataUActionsMessages2) => {
                this.notifyService.show(dataUActionsMessages2[0].displayMessage, dataUActionsMessages2[0].messageType);
              });
            }
            else {
              //this.notifyService.show("Task updated successfully !!", 1);
              this.commonfeaturesservice.GetAllActionsMessages(1, action.workflowTasksActionID, action.standardActionID, 999, "TPCS", this.compnayID).subscribe((dataCDActionsMessages) => {
                this.notifyService.show(this.GetMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
              });
            }
            this.bindTaskManagementView();
            this.reloadParentDependentTemplate();
            // Calling Email Notifications
            if (((this.wrkFlowCurrentTaskdata.taskId == 6 && (this.wrkFlowCurrentTaskdata.workFlowId == 1 || this.wrkFlowCurrentTaskdata.workFlowId == 2))
              || (this.wrkFlowCurrentTaskdata.taskId == 26 && (this.wrkFlowCurrentTaskdata.workFlowId == 11 || this.wrkFlowCurrentTaskdata.workFlowId == 14))
              || (this.wrkFlowCurrentTaskdata.taskId == 11 || this.wrkFlowCurrentTaskdata.taskId == 52 && this.wrkFlowCurrentTaskdata.workFlowId != 1 && this.wrkFlowCurrentTaskdata.workFlowId != 2))
              || this.wrkFlowCurrentTaskdata.taskId == 7 || this.wrkFlowCurrentTaskdata.taskId == 22)
              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationGraphicArtist, 1, this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, 1, encodeURIComponent(this.offSet)).subscribe();
            if (this.wrkFlowCurrentTaskdata.taskId == 13)
              //if (this.wrkFlowCurrentTaskdata.taskId == this.filterActionEventId)
              this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoProjectcompleted, 1, this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, 1, encodeURIComponent(this.offSet)).subscribe();// Notification to Project Complete
            this.moduleService.CheckForQATaskStatus(this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId).subscribe(responseStatus => {
              if (responseStatus != null) {
                if (responseStatus == 276 || responseStatus == 277) {
                  this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationForCycleCompleted, 1, this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, 1, encodeURIComponent(this.offSet)).subscribe();
                }
              }
            })
          },
            error => {
              this.showLoader = false;
              this.notifyService.show(this.GetMessageByLanguage('STC_TDUF', "Task details updated failed !!. ") + error.statusText, 4);
            }
          );
          // Calling Email Notifications
          //if (this.wrkFlowCurrentTaskdata.taskId == 6)
          //  this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationGraphicArtist, 1, this.ParentID, 1, this.wrkFlowCurrentTaskdata.taskId, this.compnayID, 1, encodeURIComponent(this.offSet)).subscribe();
          //if (this.wrkFlowCurrentTaskdata.taskId == 22)
          //  this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoInitiator, 1, this.ParentID, 1, this.wrkFlowCurrentTaskdata.taskId, this.compnayID, 1, encodeURIComponent(this.offSet)).subscribe();// Notification to Project Complete
          //if (this.wrkFlowCurrentTaskdata.taskId == 13)
          //  this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoInitiator, 1, this.ParentID, 1, this.wrkFlowCurrentTaskdata.taskId, this.compnayID, 1, encodeURIComponent(this.offSet)).subscribe();// Notification to Project Complete
        }
        else if (action.actionEvent == "TaskOwner") {
          this.showLoader = true;
          this.bindTaskOwnerInputData();
        }
        else if (action.actionEvent == "ClaimTask") {
          this.isclaimTaskActionType = 0;
          this.ClaimTaskConfrimopened = true;
        }
        else if (action.actionEvent == "UnclaimTask") {//Unclaim Task

          this.submitted = false;
          this.isclaimTaskActionType = 2;
          this.unClaimDelegateTaskConfrimopened = true;
          this.Confirmationtitle = this.GetMessageByLanguage(action.alias, "Unclaim Task");
          this.bindTaskOwnerInputData();
        }
        else if (action.actionEvent == "DelegateTask") {
          this.submitted = false;
          this.isclaimTaskActionType = 3;
          this.unClaimDelegateTaskConfrimopened = true;
          this.Confirmationtitle = this.GetMessageByLanguage(action.alias, "Delegate Task");
          this.bindTaskOwnerInputData();
        }
        else if (action.actionEvent == "AddApprovers") {
          this.isclaimTaskActionType = 4;
          this.submitted = false;
          this.addApproversConfrimopened = true;
          this.Confirmationtitle = this.GetMessageByLanguage(action.alias, "Add Approvers");
          this.bindAddApproverInputData();
        }
        else if (action.actionEvent == "Bypass") {
          this.TaskBypassConfrimopened = true;
        }
        else if (action.actionEvent == "UnBypass") {
          this.TaskUnbypassBypassConfrimopened = true;
        }
        else if (action.actionEvent == "bypassUnbypassOpen") {
          /*alert(1);*/
          this.bypassUnbypassOpen();
        }
        else if (action.actionEvent == "BypassedTaskList") {
          this.moduleService.getbypassedTasksList(parseInt(this.RecordID)).subscribe(data => {
            this.BypassedItems = [];
            if (data != null && data != "" && data != undefined) {

              this.BypassedItems = data;
              this.showBypassedList = true;

            }

            if (this.BypassedItems.length == 0) {
              this.notifyService.show(this.GetMessageByLanguage('STC_NBPT', "There is no bypassed tasks"));
            }
          })


        }

        else if (action.actionEvent == 'showUpdatedInputs') {
          this.showLoader = true;
          //debugger
          //this.moduleService.GetCCMInputSubProcessTaskDetails(this.ParentID, this.RecordID).subscribe(result=>{
          let projectID = parseInt(this.ParentID);
          let processDataID = parseInt(this.wrkFlowCurrentCCMInputData.processDataID);
          //this.moduleService.GetCCMLanguages(projectID, processDataID).subscribe(lang => {
          //  if (lang && lang.length > 0) {
          //    this.CCMLanguages = lang;
          //    this.selectedLangValue = this.CCMLanguages[0].id;
          //    this.previousLangId = this.CCMLanguages[0].id;
          //    this.languageName = this.CCMLanguages[0].name;
          //    this.moduleService.GetCCMComponents(projectID, processDataID).subscribe(comps => {
          //      if (comps) {
          //        if (comps.length > 0)
          //          this.singleComponent = false;
          //        else
          //          this.singleComponent = true;
          //        this.ComponentsList = comps;
          //        this.selectedComponents = comps.filter(x => x.isChecked == true);
          //        var langString = lang.map(o => o.id).join();
          //        this.bindCCMInputDataWithFields(projectID, processDataID, lang[0].name);
          //      }
          //    });
          //  }
          //});
        }
        else if (action.actionEvent == "Close") {
          this.showLoader = true;
          let projectID = parseInt(this.ParentID);
          let processDataID = parseInt(this.RecordID);
          this.moduleService.GetSubProcessTaskFileds(projectID, processDataID, 0).subscribe(data => {
            if (data) {
              this.DynamicFields = data.dynamicFormFieldList;
              this.DynamicFields[0].dynamicFields.map(o => {
                if (o.key == 'RequestStatus') {
                  o.value = '2726';
                }
                if (o.key == 'ApprovedBy') {
                  o.value = localStorage.getItem("userName");
                }
              })
              this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);

              this.showLoader = false;
              this.showCompleteRqstPopUp = true;
              //this.displaypopup = "block";
            }
          });
        }
        else if (action.actionEvent == "EditTask") {

          this.moduleService.GetDynamicProjectStatus(this.ParentID, 1).subscribe(projectData => {
            if (projectData == 471) {
              this.showEditTaskPopup = false;
            }
            else {
              this.moduleService.DisplayTaskManagementView(this.ParentID, this.RecordID, this.GV.UserId).subscribe(response => {
                if (response != null) {
                  this.dataService.getEditTaskStatus(this.RecordID).subscribe(data => {
                    this.showEditTaskPopup = true;
                    this.showLoader = true;

                    this.EnableEditTask = data.enableTaskEditFields;
                    this.EnableProjectsum = data.enableProjectsum;
                    this.EnableSubproces = data.enableSubproces;
                    this.EnableSubscriber = data.enableSubscriber;
                    this.EnableSubIdentifer = data.enableSubIdentifier;
                    this.showLoader = true;

                    if ((this.GV.UserRoleID == 4 || this.GV.UserRoleID == 5) && (response.qaapTaskStatus != 276 && response.qaapTaskStatus != 277) && this.EnableSubproces == true)
                      this.EnableSubproces = true;
                    else
                      this.EnableSubproces = false;

                    if (!this.EnableProjectsum) {
                      this.moduleService.DisplayTaskManagementView(this.ParentID, this.RecordID, this.GV.UserId).subscribe(data => {
                        //debugger
                        if (data != null && data != "" && data != undefined) {
                          this.wrkFlowTaskActions = data.currenttaskActions;
                          this.wrkFlowCurrentTaskdata = data.taskView.length > 0 ? data.taskView[0] : "";;
                          if (this.wrkFlowCurrentTaskdata != "" && this.wrkFlowCurrentTaskdata.options.length > 0) {
                            this.wrkFlowCurrentTaskdata.options.forEach((item, index) => {
                              item.key = this.GetFieldNameByLanguage(item, 2);
                            })
                          }
                          this.wrkFlowCurrentProjectdata = data.projectView.length > 0 ? data.projectView[0] : "";;
                          if (this.wrkFlowCurrentProjectdata != "" && this.wrkFlowCurrentProjectdata.options.length > 0) {
                            this.wrkFlowCurrentProjectdata.options.forEach((item, index) => {
                              item.key = this.GetFieldNameByLanguage(item, 2);
                            })
                          }
                          this.wrkFlowCurrentcustomData = data.customTaskview.length > 0 ? data.customTaskview[0] : "";
                          if (this.wrkFlowCurrentcustomData != "" && this.wrkFlowCurrentcustomData.options.length > 0) {
                            this.wrkFlowCurrentcustomData.options.forEach((item, index) => {
                              item.key = this.GetFieldNameByLanguage(item, 2);
                            })

                          }
                          this.wrkFlowCurrentcustomDataEdit = data.customTaskview.length > 0 ? data.customTaskview[0] : "";
                          if (this.wrkFlowCurrentcustomDataEdit != "" && this.wrkFlowCurrentcustomDataEdit.options.length > 0) {
                            this.wrkFlowCurrentcustomDataEdit.options.forEach((item, index) => {
                              item.key = this.GetFieldNameByLanguage(item, 2);
                            })
                          }
                          if (!this.EnableSubproces) {

                            this.wrkFlowCurrentsubProcessData1 = data.subtaskView.length > 0 ? data.subtaskView[0] : "";
                            if (this.wrkFlowCurrentsubProcessData1 != "" && this.wrkFlowCurrentsubProcessData1.options.length > 0) {
                              this.wrkFlowCurrentsubProcessData1.options.forEach((item, index) => {
                                item.key = this.GetFieldNameByLanguage(item, 2);
                              })
                            }

                            this.wrkFlowReferenceFilesData = data.refrenceFilesView.length > 0 ? data.refrenceFilesView[0] : "";
                          }
                        }
                      });
                    }
                    if (this.EnableEditTask) {
                      this.dataService.getDynamicFormFields(40, this.RecordID, 0, false, action.inputFormFields, 40, false).subscribe(response => {
                        if (this.EnableSubproces)
                          this.showLoader = true;

                        this.dynTemplateHeader = response.dynTemplateHeader;

                        //console.log("edit task:", response.dynamicFormFieldList);
                        this.DynamicFieldsEditTask = response.dynamicFormFieldList;
                        this.languageService.TranslateDefaultItem(response.dynamicFormFieldList, this.languageDataDefault);

                        this.DynamicFormFieldsActions = response.displayActions;

                        this.dynamicFormEditTask = this.qcs.toFormGroup(this.DynamicFieldsEditTask);
                        this.dynamicFormBeforeEditTask = this.qcs.toFormGroup(this.DynamicFieldsEditTask);

                        if (this.EnableSubproces != true && this.EnableSubscriber != true)
                          this.showLoader = false;
                      });
                    }

                    if (this.EnableSubscriber) {
                      this.dataService.getDynamicFormFields(48, this.RecordID, 0, false, "", 48, false).subscribe(response => {
                        //console.log("Subscriber Data:", response.dynamicFormFieldList);
                        var taskName = this.wrkFlowCurrentTaskdata.taskName;
                        this.dynTemplateHeader = response.dynTemplateHeader;
                        this.DynamicSubscriberFields = response.dynamicFormFieldList;
                        this.languageService.TranslateDefaultItem(response.dynamicFormFieldList, this.languageDataDefault);
                        this.DynamicFormFieldsActions = response.displayActions;
                        this.DynamicSubscriberFields.groupName = response.dynTemplateHeader;
                        this.DynamicSubscriberFields[0].dynamicFields.forEach(function (item) {
                          if (item.controlTypeID == 4) {

                            item.selectedItems = item.value != "" ? item.value.split(",") : [];
                            item.value = item.value != "" ? JSON.parse("[" + item.value + "]") : item.value;
                            item.isChecked = item.selectedItems.length === item.options.length;
                          }

                          if (item.controlTypeID == 3) {
                            item.value = "Be advised: " + taskName + " is completed. Click the 'Go to Task' button to view";
                          }
                        });
                        this.dynamicSubscriberForm = this.qcs.toFormGroup(this.DynamicSubscriberFields);
                        //this.showPopUp = true;
                        this.showLoader = false;
                      });
                    }
                    if (this.EnableSubproces) {
                      this.moduleService.GetSubProcessTaskFileds(this.ParentID, this.RecordID, 1).subscribe(data => {
                        this.showLoader = true;

                        //console.log("data.dynamicFormFieldList", data.dynamicFormFieldList);
                        this.DynamicFields1 = data.dynamicFormFieldList;
                        this.languageService.TranslateDefaultItem(data.dynamicFormFieldList, this.languageDataDefault);
                        this.DynamicFields1.groupName = "Edit SubProcess";
                        for (var j = 0; j < data.dynamicFormFieldList.length; j++) {
                          for (var i = 0; i < data.dynamicFormFieldList[j]["dynamicFields"].length; i++) {
                            if (data.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 4) {

                              data.dynamicFormFieldList[j]["dynamicFields"][i].selectedItems = data.dynamicFormFieldList[j]["dynamicFields"][i].value != "" ? JSON.parse(data.dynamicFormFieldList[j]["dynamicFields"][i].value) : [];
                              data.dynamicFormFieldList[j]["dynamicFields"][i].value = data.dynamicFormFieldList[j]["dynamicFields"][i].value != "" ? JSON.parse("[" + data.dynamicFormFieldList[j]["dynamicFields"][i].value + "]") : "";
                              data.dynamicFormFieldList[j]["dynamicFields"][i].isChecked = data.dynamicFormFieldList[j]["dynamicFields"][i].selectedItems.length === data.dynamicFormFieldList[j]["dynamicFields"][i].options.length;
                            }
                            else {
                              data.dynamicFormFieldList[j]["dynamicFields"][i].selectedItems = data.dynamicFormFieldList[j]["dynamicFields"][i].value;
                            }

                            //data.dynamicFormFieldList[j]["dynamicFields"][i].key == 'OldMaterialDispositionStatus' && data.dynamicFormFieldList[j]["dynamicFields"][i].value == '259' || 
                            if (data.dynamicFormFieldList[j]["dynamicFields"][i].fieldId == 2411 && data.dynamicFormFieldList[j]["dynamicFields"][i].value == '2772') {
                              for (var k = 0; k < data.dynamicFormFieldList.length; k++) {
                                for (var l = 0; l < data.dynamicFormFieldList[k]["dynamicFields"].length; l++) {
                                  if (data.dynamicFormFieldList[k]["dynamicFields"][l].fieldId == 2412) {
                                    if (data.dynamicFormFieldList[k]["dynamicFields"][l].selectedItem != null
                                      || data.dynamicFormFieldList[k]["dynamicFields"][l].selectedItem != ""
                                      || data.dynamicFormFieldList[k]["dynamicFields"][l].selectedItem != undefined) {
                                      data.dynamicFormFieldList[k]["dynamicFields"][l].dependentControlDisplay = true;
                                      data.dynamicFormFieldList[k]["dynamicFields"][l].required = true;
                                    }
                                    else {
                                      data.dynamicFormFieldList[k]["dynamicFields"][l].required = false;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }

                        this.dynamicForm1 = this.qcs.toFormGroup(this.DynamicFields1);
                        this.showLoader = false;
                      });
                      if (this.EnableSubIdentifer == false) {
                        this.moduleService.Displaysubidentifier(this.ParentID, this.RecordID, this.GV.UserId, this.wrkFlowCurrentTaskdata.taskId).subscribe(data => {
                          if (data != null && data != "" && data != undefined) {

                            //this.wrkFlowTaskActions = data.currenttaskActions;
                            this.wrkFlowCurrentcustomData1 = data.customTaskview.length > 0 ? data.customTaskview[0] : "";;
                            //this.wrkFlowCurrentProjectdata = data.projectView.length > 0 ? data.projectView[0] : "";;
                          }
                        });
                      }
                      this.showLoader = false;
                    }
                  });
                }
              });
            }
          }, error => {
            this.showLoader = false;
          })

        }

        else if (action.actionEvent == "btnSuspendCycle") {
          this.showLoader = true;
          //logic start
          //3	Project Initiator
          //4	Graphic Coordinator
          //if (!(this.GV.UserRoleID == 4)) {
          //  this.notifyService.show(this.GetMessageByLanguage('STC_NASC', "You do not have access to suspend this cycle."), 4);

          //  //this.commonfeaturesservice.GetAllActionsMessages(4, action.workflowTasksActionID, action.standardActionID, 999, "YDHASC", this.compnayID).subscribe((dataCDActionsMessages) => {
          //  //  this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          //  //});
          //  //this.showLoader = false;
          //  //return;
          //}
          // else {
          this.moduleService.GetDynamicProjectStatus(this.ParentID, 1).subscribe(data => {
            if (data != 488) {
              var msg = this.GetMessageByLanguage('STC_CFSC', 'Are you sure you want to suspend this cycle?');
              var _title = this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message;
              var _No = this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message;
              var _yes = this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message;
              const dialog: DialogRef = this.dialogService.open({
                title: _title,
                content: msg,
                actions: [
                  { text: _No },
                  { text: _yes, primary: true }
                ],
                width: 350,
                height: 150,
                minWidth: 200,
                cssClass: "custom-css-class",
              });

              dialog.result.subscribe((result) => {

                if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
                  this.taskSubDetails.projectID = parseInt(this.ParentID);
                  this.taskSubDetails.processDataId = parseInt(this.RecordID);
                  this.taskSubDetails.offset = "";
                  this.taskSubDetails.formDataValues = ""
                  this.isSuspendCycleAction = true;
                  this.BypassunpassAduit = true;
                  this.invalidSubmitAttempt = false;
                  this.showLoader = true;
                  this.moduleService.GetTasksuspendResumecycleFeilds(this.taskSubDetails.projectID, this.taskSubDetails.processDataId).subscribe(data => {
                    this.DynamicFields = data.dynamicFormFieldList;
                    this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
                    this.showLoader = false;
                    this.displaypopup = "block";
                    this.openBypassUnbypasswindow = true;
                  });
                  //code for popup end
                }
              });
            }
            else
              this.notifyService.show(this.GetMessageByLanguage('STC_PSNSC', "Project was suspended.you can't suspend cycle."), 4);
          });
          //}
          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnResumeCycle") {
          this.showLoader = true;
          //logic start
          //3	Project Initiator
          //4	Graphic Coordinator
          // if (!(this.GV.UserRoleID == 4)) {
          //   //this.notifyService.show("You do not have access to suspend this resume.", 4);

          //   //this.commonfeaturesservice.GetAllActionsMessages(4, action.workflowTasksActionID, action.standardActionID, 999, "YDHARC", this.compnayID).subscribe((dataCDActionsMessages) => {
          //   //  this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          //   //});
          //   //this.showLoader = false;
          //   //return;
          // }
          // else
          //{
          this.moduleService.GetDynamicProjectStatus(this.ParentID, 1).subscribe(data => {
            if (data != 488) {
              const dialog: DialogRef = this.dialogService.open({
                title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
                content: this.GetMessageByLanguage('STC_CFRC', 'Are you sure you want to resume this cycle?'),
                actions: [
                  { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
                  { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
                ],
                width: 350,
                height: 150,
                minWidth: 200,
                //cssClass: "custom-css-class",
              });
              dialog.result.subscribe((result) => {

                if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
                  this.taskSubDetails.projectID = parseInt(this.ParentID);
                  this.taskSubDetails.processDataId = parseInt(this.RecordID);
                  this.taskSubDetails.offset = "";
                  this.taskSubDetails.formDataValues = ""
                  this.isResumeCycleAction = true;
                  this.BypassunpassAduit = true;
                  this.invalidSubmitAttempt = false;
                  this.showLoader = true;
                  this.moduleService.GetTasksuspendResumecycleFeilds(this.taskSubDetails.projectID, this.taskSubDetails.processDataId).subscribe(data => {
                    this.DynamicFields = data.dynamicFormFieldList;
                    this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
                    this.showLoader = false;
                    this.openBypassUnbypasswindow = true;
                    this.displaypopup = "block";
                  });
                  //code for popup end
                }
              });
            }
            else
              this.notifyService.show(this.GetMessageByLanguage('STC_PSNRC', "Project was suspended.you can't resume cycle."), 4);
          });

          //}
          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnCancleCycle") {
          this.showLoader = true;
          this.moduleService.GetDynamicProjectStatus(this.ParentID, 1).subscribe(data => {
            if (data != 488) {
              var msg = this.GetMessageByLanguage('STC_CCUN', 'Are you sure you want to cancel this cycle?');
              var _title = this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message;
              var _No = this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message;
              var _yes = this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message;
              const dialog: DialogRef = this.dialogService.open({
                title: _title,
                content: msg,
                actions: [
                  { text: _No },
                  { text: _yes, primary: true }
                ],
                width: 350,
                height: 150,
                minWidth: 200,
                cssClass: "custom-css-class",
              });

              dialog.result.subscribe((result) => {

                if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
                  this.taskSubDetails.projectID = parseInt(this.ParentID);
                  this.taskSubDetails.processDataId = parseInt(this.RecordID);
                  this.taskSubDetails.offset = "";
                  this.taskSubDetails.formDataValues = ""
                  this.isCancelCycleAction = true;
                  this.BypassunpassAduit = true;
                  this.invalidSubmitAttempt = false;
                  this.showLoader = true;
                  this.moduleService.GetTasksuspendResumecycleFeilds(this.taskSubDetails.projectID, this.taskSubDetails.processDataId).subscribe(data => {
                    this.DynamicFields = data.dynamicFormFieldList;
                    this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
                    this.showLoader = false;
                    this.openBypassUnbypasswindow = true;
                    this.displaypopup = "block";
                  });
                }
              });
            }
            else
              this.notifyService.show(this.GetMessageByLanguage('STC_PSNSC', "Project was suspended.you can't suspend cycle."), 4);
          });
          this.showLoader = false;
        }


        else if (action.actionEvent == "btnHideBypassTasks") {
          const dialog: DialogRef = this.dialogService.open({
            title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
            content: this.languageDataDefault.find(x => x.LanguageKey == 'SCF_HBTL').Message,
            actions: [
              { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
              { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
            ],
            width: 400,
            height: 150,
            minWidth: 200,
            cssClass: "suspend_proj",
          });
          dialog.result.subscribe((result) => {
            if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
              console.log(this.wrkFlowCurrentTaskdata.subProcessId);
              this.GV.isHideBypassTasks = 'HideSubProcessLevelBypass';
              this.GV.SubProcessID = parseInt(this.wrkFlowCurrentTaskdata.subProcessId);
              this.GV.subProcessIDList.push(this.wrkFlowCurrentTaskdata.subProcessId);
              this.reloadParentDependentTemplate();
            }
          });
        }

        else if (action.actionEvent == "btnShowBypassTasks") {
          const dialog: DialogRef = this.dialogService.open({
            title: this.languageDataDefault.find(x => x.LanguageKey == 'LGT_CONH').Message,
            content: this.languageDataDefault.find(x => x.LanguageKey == 'STC_AYSSBTL').Message,
            actions: [
              { text: this.languageDataDefault.find(x => x.LanguageKey == 'NO').Message },
              { text: this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message, primary: true }
            ],
            width: 400,
            height: 150,
            minWidth: 200,
            cssClass: "suspend_proj",
          });
          dialog.result.subscribe((result) => {
            if (result["text"] == this.languageDataDefault.find(x => x.LanguageKey == 'YES').Message) {
              this.GV.isHideBypassTasks = 'ShowSubProcessLevelBypass';
              this.GV.SubProcessID = parseInt(this.wrkFlowCurrentTaskdata.subProcessId);
              const indexToRemove = this.GV.subProcessIDList.indexOf(this.wrkFlowCurrentTaskdata.subProcessId);
              if (indexToRemove !== -1)
                this.GV.subProcessIDList.splice(indexToRemove, 1);

              this.reloadParentDependentTemplate();
              this.GV.filteredRecordsSave = [];
            }
          });
        }

        else if (action.actionEvent == "EditSubscribers") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "Download") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnAssignReviewers") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnAssignApprovers") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnSendForReview") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnSendForApproval") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }

        else if (action.actionEvent == "btnReview") {
          this.showLoader = true;

          //logic start

          //logic end

          this.showLoader = false;
        }
        else if (action.actionEvent == "btnApprove") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnReject") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnFinalize") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnRejectInfo") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnVersionTree") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnCheckOut") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnCheckIn") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnWorkflow") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnSecondaryAuthors") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnReviewApproval") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnCompleteSectionAuthoring") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnChangeSecondaryAuthors") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnChangePrimaryAuthor") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnCompareVersions") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnCompare") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnDocumentProperties") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnManageSections") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnCompleteMultipleSectionsAuthoring") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnVersionUpdate") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnDeviationManagement") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnDistribute") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnSPLView") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnSPLValidate") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnSPMView") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnSPMValidate") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else if (action.actionEvent == "btnEditContent") {
          this.showLoader = true;
          //logic start

          //logic end
          this.showLoader = false;
        }
        else {
          this.showLoader = true;
          this.moduleService.GetSubProcessTaskFileds(this.ParentID, this.RecordID, 0).subscribe(data => {
            this.DynamicFields = data.dynamicFormFieldList;
            this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
            this.showLoader = false;
            this.showDialogPopUp = true;
          });
          this.reloadParentDependentTemplate();

        }
      }
    })
  }

  public getRelatedProjectByProjectId(selectedId: number) {
    this.ParentID = selectedId;
    this.moduleService.getRelatedProjectByProjectId(this.ParentID).subscribe(data => {
      if (data) {
        this.viewRelProjects = data;

      }
    })
  }

  bypassUnbypassOpen() {
    //debugger
    this.invalidSubmitAttempt = false;
    this.showgriderror = false;

    this.showLoader = true;
    this.BypassunpassAduit = true;


    this.moduleService.GetTaskByPassFields(this.ParentID, this.RecordID).subscribe(data => {
      this.DynamicFields = data.dynamicFormFieldList;
      this.languageService.TranslateDefaultItem(data.dynamicFormFieldList, this.languageDataDefault);
      this.byPassForm = this.qcs.toFormGroup(this.DynamicFields);
      this.BypassItems = [];
      this.byPassItemsSelected = [];
      //if (this.GV.UserRoleID == 4)
      //  this.byPassForm.addControl('processId', new FormControl(this.source[0], Validators.required));
      //else if (this.GV.UserRoleID == 3)
      //  this.byPassForm.addControl('processId', new FormControl(this.source[0], Validators.required));
      //selse
      this.showBypassUnbypassPopUp = true;
      this.data = this.source;
      this.byPassForm.addControl('processId', new FormControl(this.source[0], Validators.required));

      this.byPassForm.addControl('bypassItem', new FormControl('', Validators.required));




      this.showLoader = false;

    });
  }

  UpdatemultiTaskbypass() {

    this.IpAddres = this.GV.UserIpAddress;


    let selectedItems: any = this.byPassItemsSelected.length > 0 ? String(this.byPassItemsSelected) : "";
    let processId: any = this.byPassForm.controls.processId.value.value !== undefined ? this.byPassForm.controls.processId.value.value : 0;
    let bypassItems: any = this.byPassForm.controls.bypassItem.value !== undefined ? this.byPassForm.controls.bypassItem.value : 0;

    this.templateData.DynFormFieldData = JSON.stringify(this.byPassForm.value);
    this.templateData.ProcessIds = selectedItems;
    this.templateData.TaskAction = bypassItems;
    this.templateData.Level = processId;
    this.templateData.UserID = this.GV.UserId;
    this.templateData.Ip = this.IpAddres;
    //this.moduleService.saveTaskStatusComments(this.templateData).subscribe(data => {
    //})
    let NotificationPreferences = this.byPassForm.controls["NotificationPreferences"].value;
    let NTriggerEvent: any
    let NTriggerType: any = bypassItems == "Bypass" ? 1 : 2
    if (processId == 1)
      NTriggerEvent = NTriggerType == 1 ? this._NotificationEmailTriggerEvent.NotificationtoProjectBypassed : this._NotificationEmailTriggerEvent.NotificationtoProjectUnBypassed;
    else
      NTriggerEvent = NTriggerType == 1 ? this._NotificationEmailTriggerEvent.NotificationtoSubprocessBypassed : this._NotificationEmailTriggerEvent.NotificationtoSubProcessUnBypassed;

    this.moduleService.getBypassUnbypassWorkFlowTaskList(this.ParentID, selectedItems, bypassItems, processId, this.templateData).subscribe(data => {
      this.BypassedItems = [];
      if (data != null && data != "" && data != undefined) {
        this.BypassedItems = data;
        // this.showBypassedList = true;
        //  this.showBypassUnbypassPopUp = true;
        this.notifyService.show("Tasks '" + bypassItems + "' successfully");

        this.reloadParentDependentTemplate();
        this.moduleService.saveMultiTaskBypassComments(this.templateData).subscribe(data => {
          if (NotificationPreferences != 467)
            this.amService.GetActionEmailNotification(NTriggerEvent, NTriggerType, this.ParentID, 0, selectedItems, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
        })
      }
    })
  }



  multiTaskbypass() {
    this.submitCount = 1;
    this.showgriderror = false;
    this.showradioerror = false;
    this.byPassForm.addControl('processId', new FormControl({}, Validators.required));
    this.byPassForm.addControl('bypassItem', new FormControl('', Validators.required));
    let bypassItems: any = this.byPassForm.controls.bypassItem.value !== undefined ? this.byPassForm.controls.bypassItem.value : "";
    let selectedItems: any = this.byPassItemsSelected.length > 0 ? String(this.byPassItemsSelected) : "";
    if (selectedItems.length == 0 || this.byPassForm.invalid || bypassItems.length == "") {

      if (bypassItems.length == "") {
        this.showradioerror = true;

      }
      if (selectedItems.length == 0) {
        this.showgriderror = true;
      }

      if (this.byPassForm.invalid) {
        this.invalidSubmitAttempt = true;
      }
      return;
    }


    if (bypassItems == "Bypass") {
      this.multiTaskBypassConfrimopened = true;
      this.showBypassUnbypassPopUp = false;
    }
    else {
      this.multiTaskUnBypassConfrimopened = true;
      this.showBypassUnbypassPopUp = false;
    }


  }
  ChangeBypassType(selectedId: any) {
    let rblbypassItems: any = this.byPassForm.controls.bypassItem.value !== undefined ? this.byPassForm.controls.bypassItem.value : 0;
    if (selectedId.value !== undefined && rblbypassItems !== undefined && rblbypassItems != 0) {
      //this.RecordID subprocess task ID
      this.moduleService.getBypassTasksByWorkFlow(this.ParentID, rblbypassItems, selectedId.value).subscribe(data => {
        this.BypassItems = [];
        this.byPassItemsSelected = [];
        this.selectedKeysChange();
        if (data != null && data != "" && data != undefined) {
          this.BypassItems = data;
          if (rblbypassItems == "Bypass")
            this.showBypassUnbypassPopUp = true;
          else
            this.showBypassTasks = true;
        }
      })
    }
  }

  bypassChange(actionName: string) {
    this.showradioerror = false;
    let level: any = this.byPassForm.controls.processId.value.value !== undefined ? this.byPassForm.controls.processId.value.value : 0;
    if (actionName == "Bypass") {


      this.moduleService.getBypassTasksByWorkFlow(this.ParentID, actionName, level).subscribe(data => {
        this.BypassItems = [];
        this.byPassItemsSelected = [];
        this.selectedKeysChange();
        if (data != null && data != "" && data != undefined) {
          this.BypassItems = data;
          this.showBypassUnbypassPopUp = true;

        }
      })
    }
    else {

      this.moduleService.getBypassTasksByWorkFlow(this.ParentID, actionName, level).subscribe(data => {
        this.BypassItems = [];
        this.byPassItemsSelected = [];
        this.selectedKeysChange();
        if (data != null && data != "" && data != undefined) {
          this.BypassItems = data;
          this.showBypassTasks = true;
        }
      })


    }
  }
  taskUnbypass() {
    this.TaskUnbypassBypassConfrimopened = false;
    this.taskSubDetails.projectID = parseInt(this.ParentID);
    this.taskSubDetails.processDataId = parseInt(this.RecordID);
    this.taskSubDetails.offset = "";
    this.taskSubDetails.formDataValues = ""
    this.openBypassUnbypasswindow = true;
    this.taskSubDetails.actionType = "UnBypass";
    this.BypassunpassAduit = true;
    this.showLoader = true;
    this.moduleService.GetTaskByPassFields(this.taskSubDetails.projectID, this.taskSubDetails.processDataId).subscribe(data => {
      this.isTaskBypassAction = true;
      this.DynamicFields = data.dynamicFormFieldList;
      this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
      this.showLoader = false;
      this.openBypassUnbypasswindow = true;
      this.displaypopup = "block";
    });
  }

  taskBypass() {
    this.TaskBypassConfrimopened = false;

    this.taskSubDetails.projectID = parseInt(this.ParentID);
    this.taskSubDetails.processDataId = parseInt(this.RecordID);
    this.taskSubDetails.offset = "";
    this.taskSubDetails.formDataValues = ""
    this.taskSubDetails.actionType = "Bypass";
    this.BypassunpassAduit = true;
    this.showLoader = true;
    this.moduleService.GetTaskByPassFields(this.taskSubDetails.projectID, this.taskSubDetails.processDataId).subscribe(data => {
      this.isTaskBypassAction = true;
      this.DynamicFields = data.dynamicFormFieldList;
      this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
      this.showLoader = false;
      this.displaypopup = "block";
      this.openBypassUnbypasswindow = true;
    });
  }

  closebypassDialog() {
    this.TaskBypassConfrimopened = false;
    this.TaskUnbypassBypassConfrimopened = false
    this.showBypassUnbypassPopUp = false;
    this.multiTaskBypassConfrimopened = false;
    this.multiTaskUnBypassConfrimopened = false;
    this.taskSubDetails = null;
  }
  closeMultibypassDialog() {
    this.multiTaskBypassConfrimopened = false;
    this.multiTaskUnBypassConfrimopened = false;
    this.oldMaterialDispositionPopup = false;
  }
  public CancelBypassList() {
    this.showBypassedList = false;
    this.showBypassTasks = false;
    this.showBypassUnbypassPopUp = false;
  }
  isEditActionEnable: boolean = false;
  editActionDetails: any;
  MenutaskAction: any = [];
  bindTaskManagementView() {
    this.moduleService.CheckForProjectAccess(this.RecordID, this.template.moduleID).subscribe(projectData => {
      if (projectData == true) {
        this.IsProjectAccess = projectData;
      }
    })
    this.showLoader = true;
    this.moduleService.DisplayTaskManagementView(this.ParentID, this.RecordID, this.GV.UserId).pipe(take(1)).subscribe(data => {

      try {
        // console.log("TaskData:", data);
        if (data != null && data != "" && data != undefined) {
          //remove edit and editCCM actions from menu actions
          let v = data.currenttaskActions.filter(x => x.actionEvent == 'GMUGSREdit');
          if (v.length > 0)
            this.filterActionEventId = v[0].standardActionID;
          else
            this.filterActionEventId = 0;

          //this.wrkFlowTaskActions = data.currenttaskActions.filter(x => x.standardActionID != 208);
          this.wrkFlowTaskActions = data.currenttaskActions.filter(x => x.standardActionID != this.filterActionEventId);
          this.wrkFlowTaskActions.sort(function (a, b) { return b.menuOrder - a.menuOrder });
          //if (data.currenttaskActions.filter(x => x.standardActionID == 208).length > 0) {
          this.wrkFlowCurrentTaskdata = data.taskView.length > 0 ? data.taskView[0] : "";
          if (data.currenttaskActions.filter(x => x.standardActionID == this.filterActionEventId).length > 0) {
            this.isEditActionEnable = true;
            if (this.wrkFlowCurrentTaskdata.taskId == 23) {
              if (data.qaapTaskStatus != 0 && (data.qaapTaskStatus == 276 || data.qaapTaskStatus == 277)) {
                this.QAAPTaskStatus = data.qaapTaskStatus;
                this.isEditActionEnable = false;
              }
              else {
                this.isEditActionEnable = true;
              }
            }
            //this.editActionDetails = data.currenttaskActions.filter(x => x.standardActionID == 208)[0];
            this.editActionDetails = data.currenttaskActions.filter(x => x.standardActionID == this.filterActionEventId)[0];
          }
          if (this.IsGAATESEnabled == true) {
            this.isEditActionEnable = true;
          }

          // console.log("wrkFlowCurrentTaskdata", this.wrkFlowCurrentTaskdata);
          if (this.wrkFlowCurrentTaskdata != "" && this.wrkFlowCurrentTaskdata.options.length > 0) {
            this.wrkFlowCurrentTaskdata.options.forEach((item, index) => {
              item.key = this.GetFieldNameByLanguage(item, 2);
            })
          }

          if (this.wrkFlowCurrentTaskdata != "") {
            this.ParentID = this.wrkFlowCurrentTaskdata.projectId;

            this.SelectedTaskOwnerID = this.wrkFlowCurrentTaskdata.taskOwnerId;

          }
          this.wrkFlowCurrentProjectdata = data.projectView.length > 0 ? data.projectView[0] : "";
          // console.log("this.wrkFlowCurrentProjectdata", this.wrkFlowCurrentProjectdata);
          if (this.ActionEvent == "Start") {
            this.breadcrumbs.filter(a => a.displayName != '')[0].displayName = this.wrkFlowCurrentProjectdata.projectName;
          }
          if (this.wrkFlowCurrentProjectdata != "" && this.wrkFlowCurrentProjectdata.options.length > 0) {
            this.wrkFlowCurrentProjectdata.options.forEach((item, index) => {
              item.key = this.GetFieldNameByLanguage(item, 2);
            })
          }
          this.wrkFlowCurrentsubProcessData = data.subtaskView.length > 0 ? data.subtaskView[0] : "";
          if (this.wrkFlowCurrentsubProcessData != "" && this.wrkFlowCurrentsubProcessData.options.length > 0) {
            this.wrkFlowCurrentsubProcessData.options.forEach((item, index) => {
              item.key = this.GetFieldNameByLanguage(item, 2);
            })
          }
          this.wrkFlowCurrentcustomData = data.customTaskview.length > 0 ? data.customTaskview[0] : "";
          // console.log("Cunstom Task Data:", this.wrkFlowCurrentcustomData);
          if (this.wrkFlowCurrentcustomData != "" && this.wrkFlowCurrentcustomData.options.length > 0) {
            this.wrkFlowCurrentcustomData.options.forEach((item, index) => {
              item.key = this.GetFieldNameByLanguage(item, 2);
            })

          }
          this.wrkFlowSubProcessDiagram = data.workFlowDiagramView.length > 0 ? data.workFlowDiagramView[0] : "";
          this.subProcessId = this.wrkFlowCurrentsubProcessData.subProcessId;
          this.wrkFlowSubProcessDiagrams = true;
          //Gaates annontated documents flow

          this.wrkFlowReferenceFilesData = data.refrenceFilesView.length > 0 ? data.refrenceFilesView[0] : "";
          this.ShowMultiSelect = false;
          this.ShowMultiSelect = true;
          //console.log("this.wrkFlowReferenceFilesData", this.wrkFlowReferenceFilesData);
          setTimeout(() => {
            this.commonOptions(this.wrkFlowReferenceFilesData);
          }, 3000)
          if (this.wrkFlowCurrentTaskdata.workFlowStatus == "Completed" || this.wrkFlowCurrentTaskdata.workFlowStatus == "Approved") {
            //this.wrkFlowTaskActions = data.currenttaskActions.filter(x => x.standardActionID != 208 && x.standardActionID != 57);
            this.wrkFlowTaskActions = data.currenttaskActions.filter(x => x.standardActionID != this.filterActionEventId && x.standardActionID != 57);
          }

          //this.MenutaskAction = this.wrkFlowTaskActions.filter(x => x.isMenuItem == true && x.standardActionID != 208);
          this.MenutaskAction = this.wrkFlowTaskActions.filter(x => x.isMenuItem == true && x.standardActionID != this.filterActionEventId);
          for (let index = 0; index < this.MenutaskAction.length; index++) {
            var actionID = this.MenutaskAction[index].standardActionID
            var response = this.checkUserPrivilege(actionID, 3);
            if (response != null && response == "display-none") {
              this.MenutaskAction.splice(index, 1);
            }
          }
          this.taskActionNotForMenuItems = this.wrkFlowTaskActions.filter(x => x.isMenuItem == false && x.standardActionID != this.filterActionEventId);
          this.taskActionforMenu = [{
            text: "More",
            templateName: "More",
            items: this.MenutaskAction
          }];

          if (!this.GV.subProcessIDList.includes(this.wrkFlowCurrentsubProcessData.subProcessId)) {
            const foundRecord = this.MenutaskAction.findIndex(record => record.actionEvent === 'btnShowBypassTasks');
            if (foundRecord != undefined && foundRecord !== -1)
              this.MenutaskAction.splice(foundRecord, 1);
          }
          else if (this.GV.subProcessIDList.includes(this.wrkFlowCurrentsubProcessData.subProcessId)) {
            const foundRecord = this.MenutaskAction.findIndex(record => record.actionEvent === 'btnHideBypassTasks');
            if (foundRecord != undefined && foundRecord !== -1)
              this.MenutaskAction.splice(foundRecord, 1);
          }
          if (this.wrkFlowCurrentTaskdata.length == 0) {
            this.DisplayText = "Please Select Task!...";
            this.notifyService.show("Please Select Task!...", 4);
          }
          this.showLoader = false;
          this.PageOnLoad = true;
        }
      }
      catch (e) {
        this.showLoader = false;
        this.PageOnLoad = true;
        this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + e.message, 4);
      }
    },
      error => {
        this.showLoader = false;
        this.PageOnLoad = true;
        this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
        //console.log(error);
      }
    );
  }

  EnableDisableFilter() {
    if (this.EnableFilter)
      this.EnableFilter = false;
    else
      this.EnableFilter = true;
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredRecords = filterBy(this.wrkFlowReferenceFilesData.filesList, filter);
    if (this.filteredRecords.length == 0) {
      this.gridData = this.filteredRecords;
      this.IsDisable = true;
    }
    else {
      this.gridData = this.filteredRecords;
      this.IsDisable = false;
    }
  }

  commonOptions(data) {

    var isChecked = true;
    localStorage.setItem("selectedSearchReportValues", this.IsSearchedApplied);
    this.records = [];

    this.records = data.filesList;
    if (this.wrkFlowReferenceFilesData.templateID != 39 && this.records.length > 0) {
      this.checkBoxCheckIDslength = 1;
    }
    else if (this.records.length == 0)
      this.checkBoxCheckIDslength = 0;

    var i = 0;
    this.EnableMultiSelect = true;

    if (this.EnableMultiSelect) {
      this.selectableMode = "multiple";
      this.ShowMultiSelect = true;
    } else {
      this.selectableMode = "single";
      this.ShowMultiSelect = false;
    }

    this.showLoader = false;
    data.selectedTemplateId = this.wrkFlowReferenceFilesData.templateID;
    if (this.records != undefined && this.records != null && this.records.length > 0) {
      this.checkBoxCheckIDs = [this.records[0].id];
      this.mySelection = this.checkBoxCheckIDs;
      var element = <HTMLInputElement>document.getElementById("chk" + data.selectedTemplateId + this.records[0].id);
      if (element != null) {
        if (isChecked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      }
    } else {
      this.checkBoxCheckIDs = [];
      this.mySelection = this.checkBoxCheckIDs;
    }
    var elementAll = <HTMLInputElement>document.getElementById("chkSelectALL" + data.selectedTemplateId);
    if (elementAll != null) {
      if (this.checkBoxCheckIDs.length == this.records.length) {
        elementAll.checked = true;
      } else {
        elementAll.checked = false;
      }
    }
  }

  onCheckAll(templateID) {
    var checkBoxCheckIDs = []
    var AllfilteredRecords = [];
    var filteredRecords = [];
    AllfilteredRecords = this.filteredRecords;
    this.filteredRecords = this.wrkFlowReferenceFilesData.filesList;
    filteredRecords = this.filteredRecords;
    var isChecked = false;
    var elementAll = <HTMLInputElement>document.getElementById("chkSelectALL" + templateID);
    if (elementAll != null) {
      isChecked = elementAll.checked;
      this.wrkFlowReferenceFilesData.filesList.forEach(function (value) {

        var element = <HTMLInputElement>document.getElementById("chk" + templateID + value.id);
        if (element != null) {
          if (isChecked) {
            element.checked = true;
            checkBoxCheckIDs.push(parseInt(value.id));

          } else {
            element.checked = false;
          }
        }

      });
      if (isChecked) { this.checkBoxCheckIDslength = this.filteredRecords.length }
      else { this.checkBoxCheckIDslength = 0 };
    }

    this.checkBoxCheckIDs = checkBoxCheckIDs;
  }

  onCheck(dataItem, templateID) {

    this.filteredRecords = this.wrkFlowReferenceFilesData.filesList;
    this.checkedData = dataItem;

    var element = <HTMLInputElement>document.getElementById("chk" + templateID + dataItem.id);
    if (element == null) {
    }
    else {
      var isChecked = element.checked;
      if (isChecked) {
        if (this.checkBoxCheckIDs.indexOf(dataItem.id) > -1) {
        } else {
          this.checkBoxCheckIDs.push(dataItem.id);

          this.checkBoxCheckIDslength = this.checkBoxCheckIDslength + 1;
        }
      } else {
        const index: number = this.checkBoxCheckIDs.indexOf(dataItem.id);
        if (index !== -1) {
          this.checkBoxCheckIDs.splice(index, 1);
        }
        if (this.checkBoxCheckIDslength > 0) {
          this.checkBoxCheckIDslength = this.checkBoxCheckIDslength - 1;
        }
      }
    }

    var elementAll = <HTMLInputElement>document.getElementById("chkSelectALL" + templateID);
    if (elementAll != null) {
      if (this.checkBoxCheckIDs.length == this.filteredRecords.length) {
        elementAll.checked = true;
      } else {
        elementAll.checked = false;
      }
    }
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  ArtworkReviewgoToLink() {
    //debugger
    this.showLoader = true;
    let TempId = 1;
    if (this.wrkFlowCurrentTaskdata.taskId == 7 || this.wrkFlowCurrentTaskdata.taskId == 49 || this.wrkFlowCurrentTaskdata.taskId == 18 || this.wrkFlowCurrentTaskdata.taskId == 21
      || this.wrkFlowCurrentTaskdata.taskId == 14 || this.wrkFlowCurrentTaskdata.taskId == 15 || this.wrkFlowCurrentTaskdata.taskId == 3
      || this.wrkFlowCurrentTaskdata.taskId == 20 || this.wrkFlowCurrentTaskdata.taskId == 19 || this.wrkFlowCurrentTaskdata.taskId == 4
      || this.wrkFlowCurrentTaskdata.taskId == 16 || this.wrkFlowCurrentTaskdata.taskId == 25 || this.wrkFlowCurrentTaskdata.taskId == 24) {
      //this.wrkFlowCurrentTaskdata.taskId = 7;
      TempId = 18
    }

    let selectedLanguageId = localStorage.getItem("SelectedLanguageId");
    let IsSSOEnable = JSON.parse(localStorage.getItem("ssoLogin")) != null ? JSON.parse(localStorage.getItem("ssoLogin")) : false; //this.moduleService.enableSSO;, IsSSOEnable
    if (!selectedLanguageId)
      return;
    setTimeout(() => {
      this.amService.getGetProjectArtworkComponents(TempId, this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId, this.GV.UserId, 1, 1, this.wrkFlowCurrentTaskdata.taskId, this.wrkFlowCurrentTaskdata.cycleId, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.IpAddres, selectedLanguageId, this.token, IsSSOEnable).subscribe(data => {
        // debugger;
        this.fartworkReviewURLNavigation = data.artworkReviewURLNavigation;
        //Assigning the URL to Artwork Iframe
        this.windowState = 'maximized';
        this.resizeWindow = false;
        this.showArtWorkWindow = true;

        this.artWorkReviewURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.fartworkReviewURLNavigation);

        //$('#winwindow').find;

        //var name = $('#winwindow').find('#previewIframe').val();

        //var datanew = $("#winwindow").data("kendoWindow");

        ////here you have the control over the body of the iframe document
        //var iBody = $('#winwindow').find("#previewIframe").contents().find("body");

        ////here you have the control over any element (#myContent)
        //var myContent = iBody.find("#myContent");

        //$("#rejectReasonsModal").data("kendoWindow").open();
        //$("#rejectReasonsModal").kendoWindow({
        //  width: "600px",
        //  height: "600px",
        //  modal: true,
        //  title: "Document Review/Approval",
        //  visible: true
        //}).data("kendoWindow").center();

        //let frame;
        //let frame1;
        ////let frameBody;

        //frame = document.getElementById('winwindow');

        //frame1 = document.getElementById('previewIframe');

        //frame = document.getElementById('previewIframe');
        ////frameBody = frame.document.getElementById('previewIframe');

        //frameBody = frame.contentWindow.document.documentElement.outerHTML; //will get all html within the iframe
        //alert(frameBody);
        //let printHeader = document.getElementById('print-section').innerHTML; //will get html of the div by id

        //this.frmiFramewindow.controls['preview-Iframe'].contentDocument.location.reload(true);
        /* }, 2000);*/
        console.log(this.artWorkReviewURL);
        /*"http://localhost:53035/home/index?DocPath=ProjectComponets&1=899&2=900&3=1&4=1&5=1&6=9&7=1");*/
        this.showLoader = false;
      });
    }, 100);
  }

  //Close Event for artwork review window
  artWorkReviewClose() {
    this.moduleService.GetTaskStatus(this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId, this.wrkFlowCurrentTaskdata.taskId, this.GV.UserId).subscribe(data => {
      if (this.wrkFlowCurrentTaskdata.workFlowStatusId != data)
        this.reloadParentDependentTemplate();
    });
    this.showArtWorkWindow = false;
    this.showLoader = false;

  }

  //dblClickEvent(e) {
  //  e.preventDefault();
  //  e.stopPropagation();
  //  console.log(e);

  //  return false;
  //}
  DownloadArtWorkPdfFile(Filepath: string, FileName: string) {

    this.IpAddres = this.GV.UserIpAddress;


    this.FileName = FileName;
    this.moduleService.DownloadPdfFile(Filepath, FileName, this.RecordID, this.wrkFlowCurrentTaskdata.subProcessId, this.GV.UserId, this.IpAddres).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event);
      }
    });
  }
  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;//.substring(1).slice(0,-1);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }
  openImage(filePath: string) {

    this.thumbnailFilePath1 = this.docPath + filePath;

    this.fileName = this.thumbnailFilePath1.replace(/^.*[\\\/]/, '');

    if (filePath.includes('Documents')) {
      this.openDocPath = this.documentsPath + filePath + '?' + this.date;
    } else {
      this.openDocPath = this.docPath + filePath + '?' + this.date;
    }

    this.thumbnailFileName = this.fileName;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.openDocPath);
    link.setAttribute('download', this.fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  DeleteArtWorkPdfFile() {
    this.DocDeleteConfirmation = true;
  }
  DocDelConfrim() {
    this.DocDeleteConfirmation = false;
    this.showLoader = true;
    var templData = new DynamicTemplateData();
    templData.TaskID = this.wrkFlowCurrentTaskdata.taskId;
    templData.SubprocessID = this.wrkFlowCurrentTaskdata.subProcessId;
    this.moduleService.DeleteArtWorkPdfFile(templData).subscribe(data => {
      if (data == "success") {
        this.showLoader = false;
        this.notifyService.show("File has been deleted successfully");
        this.reloadParentDependentTemplate();

      }
    });
  }
  closeDocDelDialog() {
    this.DocDeleteConfirmation = false;
  }
  DownloadAllArtWorkReferenceFile() {
    this.IpAddres = this.GV.UserIpAddress;
    if (this.checkBoxCheckIDs.length > 0) {
      this.documentRepositoryID = this.checkBoxCheckIDs.join(',')
      this.FileName = "Base_Artwork.zip";
      this.moduleService.DownloadDalorReferenceFiles(this.documentRepositoryID, this.GV.UserId, this.IpAddres).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round((100 * event.loaded) / event.total);
        else if (event.type === HttpEventType.Response) {
          this.downloadFile(event);
        }
      });
    }
  }

  fileUploadDynamicField: any;

  GetTaskFromFields(ActionType: any, TaskId: any) {
    this.invalidSubmitAttempt = false;
    this.showLoader = true;
    this.moduleService.GetSubProcessTaskFileds(this.ParentID, this.RecordID, 0).subscribe(data => {
      //console.log("Form Fields Data", data.dynamicFormFieldList);
      this.DynamicFields = data.dynamicFormFieldList;
      for (var j = 0; j < data.dynamicFormFieldList.length; j++) {
        for (var i = 0; i < data.dynamicFormFieldList[j]["dynamicFields"].length; i++) {
          if (data.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 2 && data.dynamicFormFieldList[j]["dynamicFields"][i].key == "Duplicaitondispositionrequired") {
            if (data.dynamicFormFieldList[j]["dynamicFields"][i].value == "") {
              this.notifyService.show(this.languageService.GetActionMessageByLanguage('EGCT', "Please fill subprocess meta data to complete this task!!"), 4);
              return;
            }
          }
          if (data.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 4) {

            data.dynamicFormFieldList[j]["dynamicFields"][i].selectedItems = data.dynamicFormFieldList[j]["dynamicFields"][i].value != "" ? JSON.parse(data.dynamicFormFieldList[j]["dynamicFields"][i].value) : [];
            data.dynamicFormFieldList[j]["dynamicFields"][i].value = data.dynamicFormFieldList[j]["dynamicFields"][i].value != "" ? JSON.parse("[" + data.dynamicFormFieldList[j]["dynamicFields"][i].value + "]") : "";
            data.dynamicFormFieldList[j]["dynamicFields"][i].isChecked = data.dynamicFormFieldList[j]["dynamicFields"][i].selectedItems.length === data.dynamicFormFieldList[j]["dynamicFields"][i].options.length;
          }
          else {
            data.dynamicFormFieldList[j]["dynamicFields"][i].selectedItems = data.dynamicFormFieldList[j]["dynamicFields"][i].value;
          }

          //data.dynamicFormFieldList[j]["dynamicFields"][i].key == 'OldMaterialDispositionStatus' && data.dynamicFormFieldList[j]["dynamicFields"][i].value == '259' ||
          if (data.dynamicFormFieldList[j]["dynamicFields"][i].fieldId == 2411 && data.dynamicFormFieldList[j]["dynamicFields"][i].value == '2772') {
            for (var k = 0; k < data.dynamicFormFieldList.length; k++) {
              for (var l = 0; l < data.dynamicFormFieldList[k]["dynamicFields"].length; l++) {
                if (data.dynamicFormFieldList[k]["dynamicFields"][l].fieldId == 2412) {
                  if (data.dynamicFormFieldList[k]["dynamicFields"][l].selectedItem != null
                    || data.dynamicFormFieldList[k]["dynamicFields"][l].selectedItem != ""
                    || data.dynamicFormFieldList[k]["dynamicFields"][l].selectedItem != undefined) {
                    data.dynamicFormFieldList[k]["dynamicFields"][l].dependentControlDisplay = true;
                    data.dynamicFormFieldList[k]["dynamicFields"][l].required = true;
                  }
                  else {
                    data.dynamicFormFieldList[k]["dynamicFields"][l].required = false;
                  }
                }
              }
            }
          }

        }
      }
      this.languageService.TranslateDefaultItem(data.dynamicFormFieldList, this.languageDataDefault);
      this.dynamicForm = this.qcs.toFormGroup(this.DynamicFields);
      for (var j = 0; j < data.dynamicFormFieldList.length; j++) {
        for (var i = 0; i < data.dynamicFormFieldList[j]["dynamicFields"].length; i++) {
          if (data.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 9 || data.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 24) {
            this.fileUploadDynamicField = data.dynamicFormFieldList[j]["dynamicFields"].filter(x => x.controlTypeID == 24)[0];
            this.myFiles = [];
            this.fileInfo = [];
            let uploadItems: any = [];
            this.uploadedFiles[data.dynamicFormFieldList[j]["dynamicFields"][i].key] = [];
            uploadItems = data.dynamicFormFieldList[j]["dynamicFields"][i].value;
            this.fileUploadControls[data.dynamicFormFieldList[j]["dynamicFields"][i].key] = [];
            this.fileUploadRestrictions[data.dynamicFormFieldList[j]["dynamicFields"][i].key] = [];
            // filetype restriction for multiple upload control in single form
            let restrictionObj: FileRestrictions;
            //if(data.dynamicFormFieldList[j]["dynamicFields"][i].key=='UploadArtwork'){
            restrictionObj = {
              allowedExtensions: data.dynamicFormFieldList[j]["dynamicFields"][i].validationRuleRegExp.split(','),
            };
            // }else{
            //   restrictionObj={
            //     allowedExtensions: [".doc",".docx",],
            //   };
            // }
            this.fileUploadRestrictions[data.dynamicFormFieldList[j]["dynamicFields"][i].key].push(restrictionObj);
            for (let a = 0; a < uploadItems.length; a++) {
              let obj: any = {};
              obj.name = uploadItems[a].fileName;
              obj.size = parseInt(uploadItems[a].fileSize);
              obj.fileName = uploadItems[a].fileName;
              obj.extension = uploadItems[a].fileType;
              obj.filepath = uploadItems[a].filePath;
              obj.physicalfilepath = uploadItems[a].fileLocation;
              obj.uid = uploadItems[a].id;
              this.uploadedFiles[data.dynamicFormFieldList[j]["dynamicFields"][i].key].push(uploadItems[a].fileName);
              this.myFiles.push(obj);
              //form multiple controls in single form              
              this.fileUploadControls[data.dynamicFormFieldList[j]["dynamicFields"][i].key].push(obj);
              if (data.dynamicFormFieldList[j]["dynamicFields"][i].controlTypeID == 24)
                this.fileInfo.push(obj);
            }
            this.GV.SetFileInfo(this.fileInfo)
          }
        }
      }
      this.showLoader = false;
      if (TaskId == 46)//Gaates upload docs task
        this.showGaatesUploadPopUp = true;
      else if (TaskId != 23)
        this.showDialogPopUp = true;
      else
        this.openBypassUnbypasswindow = true;
    });
  }



  onTemplateActionClick(ActionType: any, TaskId: any) {
    debugger
    this.GV.SetActionEvent(ActionType);
    this.ActionEventID = parseInt(this.editActionDetails.standardActionID);
    this.workflowTasksActionID = parseInt(this.editActionDetails.workflowTasksActionID);
    if (ActionType == "EditSubTask") {

      this.GetTaskFromFields(ActionType, TaskId);

    }
    else if (ActionType == "EditProject") {
      //Edit project code here

    }
    else if (ActionType == "Edit") {
      this.GetTaskFromFields(ActionType, TaskId);

    }


  }

  excludeValidationChar(currentValue: string) {
    var convertingUpper = currentValue.toUpperCase();
    this.FormValueSaving = this.DynamicFields
    var fieldData = this.DynamicFields
    for (let n = 0; n < fieldData[0].dynamicFields.length; n++) {

      for (var i = 0; i < this.FormValueSaving.length; i++) {

        for (var j = 0; j < this.FormValueSaving[i].dynamicFields.length; j++) {

          if (this.FormValueSaving[i].dynamicFields[j].fieldId === fieldData[0].dynamicFields[n].dependentFieldId && convertingUpper.length > 1) {

            if (this.FormValueSaving[0].dynamicFields[0].validationRuleRegExp.includes(convertingUpper)) {

              if (this.dynamicForm.controls[fieldData[0].dynamicFields[n].key].errors != null && this.dynamicForm.controls[fieldData[0].dynamicFields[n].key].errors.mustMatch) {
                this.dynamicForm.controls[fieldData[0].dynamicFields[n].key].errors.mustMatch = false
              }

            }

          }
        }
      }
    }
  }

  getClassName(name): string {
    var ClassName = "";
    ClassName = name != null ? name.replace(/\s/g, "") : ClassName;
    return ClassName;
  }

  onBackClick() {
    var templData = new templateData();
    templData.moduleID = this.template.moduleID;
    this.moduleService.passTemplateData(templData);
    this.router.navigate(['Projects']);
  }
  onKeypressEvent(event: any) {
    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }
  onGaatesKeyPress(event: any) {
    this.selectedComponents = [];
  }


  //set Task Owner 
  claimTaskStatus: boolean = false;
  dsAddApproveGroupList: any;
  dsAddApproveOwnerList: any;
  bindAddApproverInputData() {

    this.moduleService.BindTaskOwnerInputData(this.RecordID, this.GV.UserId, "", "", this.isclaimTaskActionType).subscribe(data => {
      this.dsAddApproveGroupList = data.groupList;
      this.dsAddApproveOwnerList = data.groupUsersList;
      this.addApproverGroupSelectedValue = '';
      this.addApproverUserSelectedValue = '';
      this.addApproverTaskDyForm.controls['AddApproverGroup'].setValue('');
      this.addApproverTaskDyForm.controls['AddApproverTaskOwner'].setValue('');
      this.addApproverTaskDyForm.controls['comment'].setValue('');
    },
      error => {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
      }
    )
  }
  bindTaskOwnerInputData() {
    this.moduleService.BindTaskOwnerInputData(this.RecordID, this.GV.UserId, "", "", this.isclaimTaskActionType).subscribe(data => {
      if (this.isclaimTaskActionType == 0) {
        this.dstaskOwnerGroupList = data.groupList;
        this.dstaskOwnerList = data.groupUsersList;
        setTimeout(() => {
          if (data.groupId != null && data.groupId != "" && data.groupId != undefined) {
            this.GroupDyForm.controls['Groups'].setValue(data.groupId.toString());
            this.taskOwnerGroupSelectedValue = data.groupId.toString();
          }
          else { this.GroupDyForm.controls['Groups'].setValue(""); this.taskOwnerGroupSelectedValue = "" }
          if (data.userId != null && data.userId != "" && data.userId != undefined) {
            this.GroupDyForm.controls['Users'].setValue(data.userId.toString());
            this.taskOwnerSelectedValue = data.userId.toString();
          }
          else { this.GroupDyForm.controls['Users'].setValue(""); this.taskOwnerSelectedValue = ""; }
          this.showLoader = false;
          this.showTaskOwnerPopUp = true;
          this.claimTaskStatus = false;
        }, 1000);
      }
      else {
        this.unclaimDelegateTaskDyForm.controls['selectedUser'].setValue("")
        this.unclaimDelegateTaskDyForm.controls['commenttext'].setValue("")
        this.dsTaskuserList = data.groupUsersList;
        this.showLoader = false;
      }

    },
      error => {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
      }
    )
  }
  bindTaskOwnerGroupUsers(value: any) {
    this.submitted = false;
    this.dstaskOwnerList = [];
    this.taskOwnerSelectedValue = "";
    if (value != null && value != undefined && value != "") {
      this.moduleService.GetTaskOwnerGroupUsers(2, value, this.GV.UserId, "", this.RecordID, this.wrkFlowCurrentTaskdata.subProcessId, this.wrkFlowCurrentTaskdata.taskId).subscribe(data => {
        this.dstaskOwnerList = data;
      },
        error => {
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
        })
    }
  }

  bindTaskOwnerGroupUsersEdit(event: any, item: any, value: any) {
    value = event;

    if (!value) {
      this.DynamicFieldsEditTask[0].dynamicFields.forEach((element, index) => {
        if (element.key === 'TaskOwner') {
          element.options = [];
          this.dynamicFormEditTask.controls[this.DynamicFieldsEditTask[0].dynamicFields[index].key].setValue(null);
        }
      });
    } else if (value && item.label !== 'User') {
      this.DynamicFieldsEditTask[0].dynamicFields.forEach((element, index) => {
        if (element.key === 'TaskOwner') {
          element.options = [];
          this.dynamicFormEditTask.controls[this.DynamicFieldsEditTask[0].dynamicFields[index].key].setValue(null);
        }
      });
    }

    if (item.label === 'User') return;

    if (value != null && value != undefined && value != "") {
      this.moduleService.GetTaskOwnerGroupUsers(2, value, this.GV.UserId, "", this.RecordID, this.wrkFlowCurrentTaskdata.subProcessId, this.wrkFlowCurrentTaskdata.taskId).subscribe(data => {
        this.DynamicFieldsEditTask[0].dynamicFields.forEach(element => {
          if (element.key === 'TaskOwner') {
            element.options = data;
          }
        });

      },
        error => {
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
        })
    }
  }



  bindAddApproverGroupUsers(value: any) {
    this.submitted = false;
    this.dsAddApproveOwnerList = [];
    this.addApproverTaskDyForm.controls['AddApproverTaskOwner'].setValue('');
    if (value != null && value != undefined && value != "") {
      this.moduleService.GetTaskOwnerGroupUsers(5, value, this.GV.UserId, "", this.RecordID, this.wrkFlowCurrentTaskdata.subProcessId, this.wrkFlowCurrentTaskdata.taskId).subscribe(data => {
        this.dsAddApproveOwnerList = data;
      },
        error => {
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
        })
    }
  }
  SaveTaskOwner() {
    if (this.GroupDyForm.invalid) {
      this.submitted = true;
      return;
    }
    this.taskOwnermodal = new TaskOwnermodal();
    this.taskOwnermodal.ProcessDataID = this.RecordID;
    this.taskOwnermodal.GroupId = +this.GroupDyForm.controls['Groups'].value;
    this.taskOwnermodal.UserId = +this.GroupDyForm.controls['Users'].value;
    this.taskOwnermodal.CurrentLogin_UserID = +this.GV.UserId;
    this.taskOwnermodal.Ip = this.IpAddres;
    this.showTaskOwnerPopUp = false;
    this.showLoader = true;

    this.moduleService.SaveProjectTaskOwnerGroup(this.taskOwnermodal).subscribe(data => {
      if (data == "success") {
        this.showLoader = false;
        this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TOWUS", this.compnayID).subscribe((dataCDActionsMessages) => {
          this.notifyService.show(this.GetMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
        });
        this.reloadParentDependentTemplate();
        this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoSetTaskOwner, 1, this.ParentID, 0, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
      }
      else {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('AMSUAT_', "Selected User is already assigend to the task"));
      }
    },
      error => {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_STOUF', "Set Task Owner updated failed !!. ") + error.statusText, 4);
      })
  }
  CancelTaskOwner() {
    this.showTaskOwnerPopUp = false;
  }
  CancelTaskEdtit() {
    this.ShowEditTaskPopUp = false;
  }
  closeunClaimDelegateTaskDialog() {
    this.unClaimDelegateTaskConfrimopened = false;
  }
  UnclaimDeligateTask() {
    if (this.unclaimDelegateTaskDyForm.invalid) {
      this.submitted = true;
      return false;
    }
    else this.ClaimTask();
  }
  closeClaimTaskDialog() {
    this.ClaimTaskConfrimopened = false;
  }
  taskAddApprGroupSelectedValue: any;
  taskAddApprUserSelectedValue: any;
  ClaimTask() {

    this.IpAddres = this.GV.UserIpAddress;

    this.ClaimTaskConfrimopened = false;
    this.unClaimDelegateTaskConfrimopened = false;
    this.taskOwnermodal = new TaskOwnermodal();
    this.taskOwnermodal.ProcessDataID = this.RecordID;
    this.taskOwnermodal.CurrentLogin_UserID = +this.GV.UserId;
    this.taskOwnermodal.actionType = this.isclaimTaskActionType;
    this.taskOwnermodal.Ip = this.IpAddres;
    if (this.isclaimTaskActionType != 0) {
      this.taskOwnermodal.UserId = +this.unclaimDelegateTaskDyForm.controls['selectedUser'].value;
      this.taskOwnermodal.comment = this.unclaimDelegateTaskDyForm.controls['commenttext'].value;
      this.taskOwnermodal.Ip = this.IpAddres;
    }
    else {
      this.taskOwnermodal.ClaimTaskStatus = true;
      this.taskOwnermodal.userndGroupId = 0;
      this.taskOwnermodal.comment = "";
      this.taskOwnermodal.UserId = +this.GV.UserId;
      this.taskOwnermodal.Ip = this.IpAddres;
    }
    this.showLoader = true;
    this.taskOwnermodal.Ip = this.IpAddres;
    this.moduleService.SaveProjectTaskOwnerGroup(this.taskOwnermodal).subscribe(data => {
      if (data == "success") {
        this.showLoader = false;
        if (this.isclaimTaskActionType == 0) {
          this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TKCS", this.compnayID).subscribe((dataCDActionsMessages) => {
            this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          });
          this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoClaimTask, 1, this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
        }
        else if (this.isclaimTaskActionType == 2) {
          this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TKUNCS", this.compnayID).subscribe((dataCDActionsMessages) => {
            this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          });
          this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoUnClaimTask, 2, this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
        }
        else {
          this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "TKDS", this.compnayID).subscribe((dataCDActionsMessages) => {
            this.notifyService.show(dataCDActionsMessages[0].displayMessage, dataCDActionsMessages[0].messageType);
          });
          this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoDeligateTask, 3, this.ParentID, this.wrkFlowCurrentTaskdata.subProcessId, this.RecordID, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
        }
        this.reloadParentDependentTemplate();
      }
      else {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('AMSUAT_', "Selected User is already assigend to the task"));
      }
    },
      error => {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_TDUF', "Task updated failed !!. ") + error.statusText, 4);
      })


  }

  checkUserPrivilege(actionEventID, LevelID) {
    return this.amService.GetPrivilegeAccesType(actionEventID, LevelID);
  }

  AuditLogData() {
    this.amService.AuditLogData(this.ParentID, 55, this.GV.UserId, 1).subscribe((data) => {
      this.divHisdata = true;
      var AuditDisplay = "";
      if (data.length > 0) {
        this.showDialogPopUp = false;
        this.displaypopup = "none";
        this.openBypassUnbypasswindow = false;
        this.AuditexcelBtn = true;
        this.AuditLogId = true;
        this.IsAuditExportExcel = true;
        //     AuditDisplay = AuditDisplay + "<div class='row  padding-5 border-tb-ccc'><div class='col s2'>Field name</div><div class='col s5 '>Before change</div><div class='col s5'>After change</div></div>";
        for (var i = 0; i < data.length; i++) {
          var seraizedata = JSON.parse(data[i]["changes"]);

          AuditDisplay += "<div class=''>";
          AuditDisplay += "<table class='auditclass'>";
          AuditDisplay += "<tr class='auditclass margin-top-10' style='background: rgba(245, 247, 250, 0.5) !important;'>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>User: <b class='newtext-color'>" + data[i].userId + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Event date:<b class='newtext-color'> " + this.DateTimeService.convertdate(data[i].eventDate) + "</b></td>";
          AuditDisplay = AuditDisplay + "<td style='width:30%;'>Action type:<b class='newtext-color'> " + data[i].actionType + "</b></td>";
          AuditDisplay = AuditDisplay + "</tr>"
          AuditDisplay = AuditDisplay + "<tr class='auditclass'><td><b>Field name</b></td><td><b> New Changes</b></td></tr>";
          if (seraizedata.length > 0) {
            for (var j = 0; j < seraizedata.length; j++) {
              AuditDisplay = AuditDisplay + "<tr class='auditclass'>";
              AuditDisplay = AuditDisplay + "<td>" + this.DisplayAllFields.GetFieldName(seraizedata[j].FieldName, 55) + "</div>";
              AuditDisplay = AuditDisplay + "<td style='width:33%!important;'>" + (seraizedata[j].ValueAfter == "(null)" ? "" : seraizedata[j].ValueAfter) + "</div>";
              AuditDisplay = AuditDisplay + "</tr>";
            }
          }
          else {
            AuditDisplay = AuditDisplay + "<tr class='newtext-color'><td></td><td class='center'>No updates to show!</td><td></td></tr>";
          }
          AuditDisplay = AuditDisplay + "</table><br/>";
          AuditDisplay = AuditDisplay + "</div>";
        }
        this.htmlToAdd = AuditDisplay;
      }
      else {
        this.notifyService.show(this.GetMessageByLanguage('STC_NAD', "No audit data for this record."), 4);
      }


    });
  }

  auditclose() {
    this.AuditLogId = false;
  }

  ExportAuditToPdfFile(htmlcontent: string) {
    this.ObjAuditExpPdf.htmlcontent = htmlcontent;
    this.ObjAuditExpPdf.offset = this.TZoffset;;
    this.ObjAuditExpPdf.TableEnum = '55';
    this.showLoader = true;
    this.amService.DownloadAuditPdfFile(this.ObjAuditExpPdf).subscribe((event) => {
      this.DownLoadExportPdfFile(event.filePath, event.auditFile);
      this.showLoader = false;
    },
      error => {
        this.showLoader = false;
        this.notifyService.show("Download  Failed !! " + error.statusText, 4);
      }
    );


  }

  DownLoadExportPdfFile(Filepath: string, FileName: string) {
    this.FileName = FileName;
    this.amService.GetDownloadAuditPdfFilePath(Filepath, FileName).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadExportPdfFile(event);
      }
    },
      error => {
        this.showLoader = false;
        this.notifyService.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }
  private downloadExportPdfFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }
  closeApproversConfrimTaskDialog() {
    this.addApproversConfrimopened = false;
  }
  SaveAddApproveTask() {
    this.showLoader = true;

    this.IpAddres = this.GV.UserIpAddress;

    if (this.dsAddApproveOwnerList.length == 0) {
      this.notifyService.show(this.GetMessageByLanguage('AMSUAT_', "Selected User is already assigend to the task"));
      this.showLoader = false;
      return;
    }

    if (this.addApproverTaskDyForm.invalid) {
      this.submitted = true;
      return;
    }
    this.taskOwnermodal = new TaskOwnermodal();
    this.taskOwnermodal.ProcessDataID = this.RecordID;
    this.taskOwnermodal.GroupId = +this.addApproverTaskDyForm.controls['AddApproverGroup'].value;
    this.taskOwnermodal.UserId = +this.addApproverTaskDyForm.controls['AddApproverTaskOwner'].value;
    this.taskOwnermodal.comment = this.addApproverTaskDyForm.controls['comment'].value;
    this.taskOwnermodal.CurrentLogin_UserID = +this.GV.UserId;
    this.taskOwnermodal.Ip = this.IpAddres;
    this.showTaskOwnerPopUp = false;
    this.addApproversConfrimopened = false;
    this.moduleService.SaveTaskAddApprovers(this.taskOwnermodal).subscribe(data => {
      if (JSON.parse(data).successText == "success") {
        this.showLoader = false;
        this.commonfeaturesservice.GetAllActionsMessages(1, this.workflowTasksActionID, this.ActionEventID, 999, "AAS", this.compnayID).subscribe((dataCDActionsMessages) => {
          this.notifyService.show(this.GetMessageByLanguage('AMSG_' + dataCDActionsMessages[0].aliasKey, dataCDActionsMessages[0].displayMessage), dataCDActionsMessages[0].messageType);
        });
        this.reloadParentDependentTemplate();
        this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoAddApproverTask, 1, this.ParentID, 0, JSON.parse(data).id, this.wrkFlowCurrentTaskdata.workFlowId, this.compnayID, this.wrkFlowCurrentTaskdata.cycleId, encodeURIComponent(this.offSet)).subscribe();
      }
      else {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('AMSUAT_', "Selected User is already assigend to the task"));
      }
    },
      error => {
        this.showLoader = false;
        this.notifyService.show("SaveTaskAddApprovers failed !!. " + error[0].statusText, 4);
      })

  }
  isfileuploadResizable: boolean = false;
  openDAL(dynamicField: any) {
    this.customUploadType = dynamicField.validationRule;
    this.SelectedRecord = this.RecordID;
    this.setSubProcessdisplay = true;
    this.windowState = 'maximized';
    this.isfileuploadResizable = false;
    this.showCustomArtWorkWindow = true;

  }

  GetMessageByLanguage(aliasKey, orgMsg) {
    let tData = this.languageDataDefault.find(x => x.LanguageKey == aliasKey);
    if (tData)
      return tData.Message;
    else
      return orgMsg;
  }
  public GetFieldNameByLanguage(option, workFlowTaskId) {
    let tData;
    if (option.isTableData) {
      tData = this.languageDataDefault.find(x => x.FormFieldID == option.id && x.WorkFlowTaskID == workFlowTaskId && x.Type == 2);
    } else {
      tData = this.languageDataDefault.find(x => x.FieldIdList.includes(option.id) && x.Type == 1)
    }
    if (tData)
      return tData.Message;
    else
      return option.key;
  }
  artWorkcustomuploadClose() {
    this.showCustomArtWorkWindow = false;
    this.setSubProcessdisplay = false;

  }

  public get toggleAllText() {
    if (this.isChecked) {
      this.selectAllValue = "Deselect All";
      return 'STC_DSALL';
    } else {
      this.selectAllValue = "Select All";
      return 'STC_SALL';
    }
  }
  public onClick(inputValue: any) {

    this.isChecked = !this.isChecked;
    this.selectedComponents = this.isChecked ? this.ComponentsList : [];

  }
  runAutomationTest() {

    this.IpAddres = this.GV.UserIpAddress;


    this.taskSubDetails.projectID = parseInt(this.ParentID);
    this.taskSubDetails.processDataId = parseInt(this.wrkFlowAnnotatedDocument.processDataID);
    this.taskSubDetails.offset = "";
    this.taskSubDetails.actionType = "GatesRunAutomation";
    this.taskSubDetails.Ip = this.IpAddres;
    this.taskSubDetails.UserID = this.GV.UserId;
    this.taskSubDetails.UploadFileType = this.GV.GetFileType();
    this.taskSubDetails.workFlowId = this.wrkFlowCurrentTaskdata.workFlowId;
    let formfieldsobj = {
      UploadArtwork: null,
      QRDFile: null
    };
    this.taskSubDetails.formDataValues = JSON.stringify(formfieldsobj);
    this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
      this.showLoader = false;
      this.notifyService.show("Annotation Completed !!. ", 1);
      this.bindTaskManagementView();
      this.reloadParentDependentTemplate();

    },
      error => {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_TDUF', "Task details updated failed !!. ") + error.statusText, 4);
      }
    );
  }
  annotationRequestSubmit() {
    this.bindTaskManagementView();
    this.reloadParentDependentTemplate();
  }


  closeLanguageChangeDialog() {
    this.showCCMLanguageChangeConifrm = false;
    this.selectedLangValue = this.previousLangId;
  }
  LanguageChange(event, value) {
    this.selectedLangValue = event;
    if (!this.isDisableInputEdit)
      this.showCCMLanguageChangeConifrm = true;
    else {
      this.showLoader = true;
      let langName = this.CCMLanguages.find(x => x.id == this.selectedLangValue).name;
      let projectID = parseInt(this.ParentID);
      let processDataID = parseInt(this.wrkFlowCurrentCCMInputData.processDataID);
      this.languageName = langName;
    }
  }
  saveInputsByLanguage() {


    this.IpAddres = this.GV.UserIpAddress;

    if (this.dynamicForm.invalid) {
      this.invalidSubmitAttempt = true;
      return;
    }
    else {
      this.showLoader = true;
      var currentLang = this.CCMLanguages.find(x => x.id == this.selectedLangValue).name;
      this.CCMInputAllLangFormsData[currentLang] = this.dynamicForm.value;
      this.taskSubDetails.projectID = parseInt(this.ParentID);
      this.taskSubDetails.processDataId = parseInt(this.wrkFlowCurrentCCMInputData.processDataID);
      this.taskSubDetails.offset = "";
      this.taskSubDetails.formDataValues = JSON.stringify(this.CCMInputAllLangFormsData);
      if (this.isEditActionEnable)
        this.taskSubDetails.actionType = 'EditTask';
      else
        this.taskSubDetails.actionType = 'Task Action';
      this.taskSubDetails.UserID = this.GV.UserId;
      this.taskSubDetails.Ip = this.IpAddres;
      this.moduleService.UpdateProjectTaskManagementData(this.taskSubDetails).subscribe(data => {
        this.showLoader = false;
        this.notifyService.show("Inputs updated successfully !!", 1);
        this.showLoader = false;

      },
        error => {
          this.showLoader = false;
          this.notifyService.show("Inputs update failed !!. " + error.statusText, 4);
        }
      );
    }

  }
  // to display short name if project name is too big
  getShortName(strText, isFromPopup) {
    var strTxt = "";
    if (strText != null && strText.length >= 50) {
      if (isFromPopup)
        strTxt = strText.slice(0, 120).concat('...');
      else
        strTxt = strText.slice(0, 50).concat('...');
    }
    else
      strTxt = strText;
    return strTxt;
  }
  checkallowAccessForRoles(item: any): boolean {
    let returnvalue = false;

    if (item.allowAccessForRoles == '' || item.allowAccessForRoles == undefined || item.allowAccessForRoles == null) {
      returnvalue = false;

    } else {
      var allowedRoles = item.allowAccessForRoles.split(',');

      if (allowedRoles != null && allowedRoles.length > 0 && allowedRoles.includes(this.GV.UserRoleID.toString())) {
        returnvalue = true;
      }
    }
    return returnvalue;

  }
  resetOldMaterialCode() {

    if (this.dynamicForm != undefined) {
      this.dynamicForm.controls['OldMaterialDispositionStatus'].setValue((this.dispositionValueLastselected == '' ? this.dispositionValueSaving : this.dispositionValueLastselected));
      if (this.dynamicForm1 != undefined) {
        this.dynamicForm1.controls['OldMaterialDispositionStatus'].setValue((this.dispositionValueLastselected == '' ? this.dispositionValueSaving : this.dispositionValueLastselected));
      }
    }
    else {
      this.dynamicForm1.controls['OldMaterialDispositionStatus'].setValue((this.dispositionValueLastselected == '' ? this.dispositionValueSaving : this.dispositionValueLastselected));
      if (this.dynamicForm != undefined) {
        this.dynamicForm.controls['OldMaterialDispositionStatus'].setValue((this.dispositionValueLastselected == '' ? this.dispositionValueSaving : this.dispositionValueLastselected));
      }
    }
    this.oldMaterialDispositionPopup = false;
  }

  oldDipositionStatusSuccess() {

    if (this.dynamicForm != undefined)
      this.dispositionValueLastselected = this.dynamicForm.controls['OldMaterialDispositionStatus'].value;
    else
      this.dispositionValueLastselected = this.dynamicForm1.controls['OldMaterialDispositionStatus'].value;

    this.oldMaterialDispositionPopup = false;
  }
}
