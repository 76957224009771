import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { escapeSelector } from 'jquery';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { templateData } from '../../../interfaces/ItemplateFields';
import { AdminUserManagementService } from '../../../services/adminusermanagement.service';
import { ModuleService } from '../../../services/module.service';

@Component({
  selector: 'app-dynamic-field-tabs',
  templateUrl: './dynamic-field-tabs.component.html',
  styleUrls: ['./dynamic-field-tabs.component.css']
})
export class DynamicFieldTabsComponent implements OnInit {
  displaytemplates: any;
  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;
  @Input() public componentType: any;



  selectedTab: any;
  @Input() public ID: any;

  constructor(private moduleService: ModuleService, private amService: AdminUserManagementService,private GV : GlobalVeriables) { }

  ngOnInit() {


    this.moduleService.searchTabFilters.subscribe(searchData => {
      if (searchData.templateId == 0) {

      } else {



        //alert(searchData.searchFields);
        var tempDisplayTemplates = this.displaytemplates;
        this.displaytemplates = null;
        setTimeout(() => {

          if (tempDisplayTemplates !== null && tempDisplayTemplates !== "" && tempDisplayTemplates.length > 0) {
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].searchFilters = searchData.searchFields;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].defaultSearch = searchData.defaultSearch;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].searchFilterValues = searchData.searchFieldValues;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].searchName = searchData.searchName;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].chkFields = searchData.chkFields;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].chkFieldValues = searchData.chkFieldValues;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].highlightRecordID = null;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].selectedRecordID = null;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].keyWordSearch = searchData.keyWordSearch;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].filter = searchData.filter;
            tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].filterConfigKey = searchData.filterConfigKey;
          }


          this.displaytemplates = tempDisplayTemplates;//.filter(a => a.hasParent == 0 && a.isDependent == 0 && a.displayControlsID != 0 && a.moduleID == this.moduleID);;

          this.selectedTab = tempDisplayTemplates.filter(a => a.templateID == searchData.templateId && a.displayControlsID == searchData.displayControlsID)[0].id;

        }, 100);
      }

    });

    var tempData = new templateData();
    tempData.moduleID = parseInt(this.template.moduleID);
    tempData.templateID = this.templateID;
    tempData.tabTemplateID = this.templateID;
    tempData.parentTemplateID = parseInt(this.template.parentTemplateID);
    tempData.parentRecordID = parseInt(this.template.parentRecordID);
    tempData.dependentTemplateID = parseInt(this.template.dependentTemplateID);
    tempData.dependentRecordID = parseInt(this.template.dependentRecordID);
    this.moduleService.GetTemplatesByModuleID(tempData).subscribe(data => {



      var recordID = this.template.recordID;

      var recordType = this.template.recordType;
      var collectionId = this.template.collectionId;
      var searchFilters = this.template.searchFilters;
      var searchFilterValues = this.template.searchFilterValues;
      var filterConfigKey = this.template.filterConfigKey;

      var defaultSearchCheck = this.template.defaultSearchCheck;
      var chkFields = this.template.chkFields;
      var chkFieldValues = this.template.chkFieldValues;
      var chkDisplayControl = this.template.chkDisplayControl;
      var isSplitter = this.template.isSplitter;




      data.forEach(function (part, index) {
        this[index].recordID = recordID;
        this[index].selectedRecordID = recordID;
        this[index].recordType = recordType;
        this[index].collectionId = collectionId;
        this[index].searchFilters = searchFilters;
        this[index].defaultSearch = defaultSearchCheck;
        this[index].searchFilterValues = searchFilterValues;
        this[index].filterConfigKey = filterConfigKey;
        this[index].isSplitter = isSplitter;

        if (this[index].displayControlsID == chkDisplayControl) {
          this[index].chkFields = chkFields;
          this[index].chkFieldValues = chkFieldValues;
        }





        //tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchFilters = searchData.searchFields;
        //tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchFilterValues = searchData.searchFieldValues;

      }, data);
      this.displaytemplates = data.sort((a, b) => {
        return a.tabOrder - b.tabOrder;
      });;

      if (chkDisplayControl == "") {
        this.selectedTab = this.displaytemplates[0].id;

      } else {
        this.selectedTab = this.displaytemplates.filter(a => a.displayControlsID == chkDisplayControl)[0].id;

      }


      this.onTabClick(this.selectedTab);

    });

    this.GV.ProcessDataID = 0;

    this.GV.IsFromTask = true;

    this.moduleService.loadTask.subscribe(dalFileDat => {

      this.selectedTab = 1049;

      //console.log(this.displaytemplates);

      this.GV.ProcessDataID = dalFileDat['ID'];

      this.GV.IsFromTask = true;
    });
    this.moduleService.loadProjectTasks.subscribe(dalfiedat => {

      //this.selectedTab=1049;

      // this.onTabClick(1049);

      if (this.selectedTab == 1049)

        this.reloadCurrentTab();
    });
  }

  reloadCurrentTab() {

    const selectedTab = this.selectedTab;

    // Store the currently selected index and set it to another tab index

    const selectedIndex = selectedTab ? selectedTab.index : -1;

    this.selectedTab = -1;

    // Use a timeout to allow the DOM to update before setting the selectedTab back to the original index

    setTimeout(() => {

      this.selectedTab = selectedTab;

    });

  }

  ngOnChanges(changes: SimpleChanges) {
    var tempData = new templateData();
    tempData.moduleID = parseInt(this.template.moduleID);
    tempData.templateID = this.templateID;
    tempData.tabTemplateID = this.templateID;
    tempData.parentTemplateID = parseInt(this.template.parentTemplateID);
    tempData.parentRecordID = parseInt(this.template.parentRecordID);
    tempData.dependentTemplateID = parseInt(this.template.dependentTemplateID);
    tempData.dependentRecordID = parseInt(this.template.dependentRecordID);
    this.moduleService.GetTemplatesByModuleID(tempData).subscribe(data => {

    
      var recordID = this.template.recordID;

      var recordType = this.template.recordType;
      var collectionId = this.template.collectionId;
      var searchFilters = this.template.searchFilters;
      var searchFilterValues = this.template.searchFilterValues;
      var filterConfigKey = this.template.filterConfigKey;

      var defaultSearchCheck = this.template.defaultSearchCheck;
      var chkFields = this.template.chkFields;
      var chkFieldValues = this.template.chkFieldValues;
      var chkDisplayControl = this.template.chkDisplayControl;
      var isSplitter = this.template.isSplitter;






      data.forEach(function (part, index) {
        this[index].recordID = recordID;
        this[index].selectedRecordID = recordID;
        this[index].recordType = recordType;
        this[index].collectionId = collectionId;
        this[index].searchFilters = searchFilters;
        this[index].defaultSearch = defaultSearchCheck;
        this[index].searchFilterValues = searchFilterValues;
        this[index].filterConfigKey = filterConfigKey;
        this[index].isSplitter = isSplitter;

        if (this[index].displayControlsID == chkDisplayControl) {
          this[index].chkFields = chkFields;
          this[index].chkFieldValues = chkFieldValues;
        }





        //tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchFilters = searchData.searchFields;
        //tempDisplayTemplates.filter(a => a.templateID == searchData.templateId)[0].searchFilterValues = searchData.searchFieldValues;

      }, data);
      this.displaytemplates = data.sort((a, b) => {
        return a.tabOrder - b.tabOrder;
      });;

      if (chkDisplayControl == "") {
        this.selectedTab = this.displaytemplates[0].id;

      } else {
        this.selectedTab = this.displaytemplates.filter(a => a.displayControlsID == chkDisplayControl)[0].id;

      }


      this.onTabClick(this.selectedTab);

    });
  }

  onTabClick(selectedTab) {
    this.selectedTab = selectedTab;

  }

  checkUserPrivilege(actionEventID, LevelID) {
    return this.amService.GetPrivilegeAccesType(actionEventID, LevelID);

  }


}
