import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DynamicFieldService } from '../../dynamic-fields-service/dynamic-field.service';
import { HttpClient } from '@angular/common/http';
import { ModuleService } from '../../../services/module.service';
import { GroupDescriptor, process } from '@progress/kendo-data-query';
import { IntlService } from '@progress/kendo-angular-intl';
import { DynamicFormGroupBase } from '../dynamic-field-base';
import { FormControl, FormGroup } from '@angular/forms';
import { getBaseUrl } from '../../../../main';
import { DynamicFieldControlService } from '../../dynamic-fields-service/dynamic-field-control.service';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { headerCellClass, headerRowClass, templateSelectedRecord } from '../dynamic-field-list/dynamic-field-list.component';
import { NotificationAlertsService } from '../../../services/notification.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { DialogService } from '@progress/kendo-angular-dialog';
/*import { LanguageService } from '../../../services/language.service';*/
import { LanguageService } from 'src/app/services/language.service';
import { ChartComponent, LegendLabelsContentArgs } from '@progress/kendo-angular-charts';
import { ReportsService } from '../../../services/reports.service';
import { saveAs } from "@progress/kendo-file-saver";
import { Router } from '@angular/router';
import { LegendTitle } from "@progress/kendo-angular-charts";

@Component({
  selector: 'app-dynamic-field-pie-chart',
  templateUrl: './dynamic-field-pie-chart.component.html',
  styleUrls: ['./dynamic-field-pie-chart.component.css']
})
export class DynamicFieldPieChartComponent implements OnInit {


  @Input() public templateID: any;
  @Input() public recordID: any;
  @Input() public template: any;
  showLoader: boolean = false;
  public dynamicForm: FormGroup;
  columns: any;
  SearchName: string = "";
  IsSearchedApplied: string = "";
  records: any;
  filteredRecords: any;
  public categories: any;
  public showSeries: boolean = false;
  public model = [];
  public modelres: any;// = [];
  public TChartPiedata: any;
  public TChartdata: any;
  GMTLoadDateTime: string = '';
  GMTReportCreatedTime: string = '';
  showdatetime: boolean = false;
  NoData: boolean = false;
  moduleID: any;

  EnableselectedValue: any = 0;
  loadChart: boolean = false;
  IsExportExcel: any;
  public mySelection: number[] = [0];
  public fData: any;
  loadfilesave: boolean = false;
  loadPieChartfilesave: boolean = false;
  txtFilenamevalue: string = "chart";
  txtPieChartFilenamevalue: string = "pie chart";
  TotalPrj: number = 0;
  chartannotateshow = false;
  searchfiltershow = false;
  anchornewshow = false;
  anchorpiechartshow = false;
  columnsWithAlias = [];
  chatHaederName: string = 'Projects';
  chatHeaderkey: string = 'NAV_PRJ';
  pieChatHaederName: string = 'Timelines of Project';
  pieChatHeaderkey: string = 'STC_TMLPRJ';
  totalName: string = 'Total Tasks';
  totalNameKey: string = 'STC_TTASKS';

  @ViewChild("annotateanchor") public annotateanchor: ElementRef;
  @ViewChild("annotatepopup", { read: ElementRef }) public annotatepopup: ElementRef;
  @ViewChild('anchornew') public anchornew: ElementRef;
  @ViewChild('popupnew', { read: ElementRef }) public popupnew: ElementRef;
  @ViewChild('anchorpiechart') public anchorpiechart: ElementRef;
  @ViewChild('popuppiechart', { read: ElementRef }) public popuppiechart: ElementRef;

  @ViewChild("chartPie")
  chartPie: ChartComponent;

 
  constructor(private dataService: DynamicFieldService,
    private notifyService: NotificationAlertsService,
    private commonfeaturesservice: CommonFeaturesService,
    private qcs: DynamicFieldControlService,
    private http: HttpClient,
    private moduleService: ModuleService,
    public intl: IntlService, private dialogService: DialogService, private reportservice: ReportsService,
    private GV: GlobalVeriables,
    private router: Router,
    private languageService: LanguageService) {
    this.PielabelContent = this.PielabelContent.bind(this);
  }

  ngOnInit(): void {
    this.NoData = false;
    var temp = this.template;
    this.dynamicForm = new FormGroup({
      ddlcharttypevalue: new FormControl(''),
      ddlchartgrptypevalue: new FormControl(''),
      txtFilename: new FormControl(''),
      txtPieChartFilename: new FormControl(''),
      RbChartType: new FormControl(''),
      RbChartGroupType: new FormControl('')
    });
    this.reportservice.GetUTCDate().subscribe((dateresult) => {
      this.GMTLoadDateTime = dateresult
    });

    this.loadTemplate();
  }
  loadTemplate() {
    this.showLoader = true;
    this.moduleService.GetDisplayFieldData(this.templateID, this.recordID, this.template.displayControlsID, this.template.recordType, 0, this.template.searchFilters, 'Default', 0, 0, "", this.template.chkFields,null).subscribe(data => {


      if (data.record == null && data.columns == null && data.displayProperties == null) {
        this.notifyService.show(this.languageService.GetActionMessageByLanguage('STC_SWW',"Something went wrong. Please try again "), 4);
        this.showLoader = false;

        return;
      }

      this.SearchName = this.template.searchName;

      if (this.template.searchFilters == "" || this.template.searchFilters == [] || this.template.searchFilters == undefined) {
        this.IsSearchedApplied = "";
      } else {
        //this.IsSearchedApplied = this.template.moduleID == "30" ? this.template.searchFilters : this.template.searchFilterValues;
        this.IsSearchedApplied = this.template.searchFilterValues;
      }
      localStorage.setItem("selectedSearchReportValues", this.IsSearchedApplied);
      this.records = JSON.parse(data.record);
      this.filteredRecords = this.records;
      this.columns = data.columns;
      //this.displayActions = data.displayActions;
      this.LoadData(this.filteredRecords);
      this.showLoader = false;


    });

  }

  LoadData(tChartPiedata: any = [],) {
    this.filteredRecords = tChartPiedata;
    this.modelres = [];
    this.categories = [];
    this.showdatetime = false;
    this.TChartPiedata = [];
    this.TChartdata = [];
    if (this.filteredRecords.length > 0) {
      this.columns = Object.keys(this.filteredRecords[0]);
      // this.columns.forEach(element => {
      //   let match=this.columnsWithAlias.find(x=>x.name==element);
      // });
      //this.displayActions = data.displayActions;
      if (this.templateID == 89) {
        this.chatHeaderkey = 'NAV_STATUS';
        this.chatHaederName = 'Status';
        this.pieChatHeaderkey = 'STC_SUR';
        this.pieChatHaederName = 'Status of Users';
        this.totalName = 'Total Users';
        this.totalNameKey ='STC_TUSERS'
      }
      else if (this.templateID == 92) {
        this.chatHeaderkey = 'NAV_TWG'
        this.chatHaederName = 'Task Owner Group';
        this.pieChatHeaderkey = 'STC_TWG';
        this.pieChatHaederName = 'All Active Task Report';
      }
      else {
        this.chatHeaderkey = this.chatHeaderkey;
        this.chatHaederName =  this.chatHaederName;
        this.pieChatHeaderkey =  this.pieChatHeaderkey;
        this.pieChatHaederName =  this.pieChatHaederName;
      }
      //this.chatHeaderkey = this.templateID == 89 ? 'NAV_STATUS' : this.chatHeaderkey;
      //this.chatHaederName = this.templateID == 89 ? 'Status' : this.chatHaederName;
      //this.pieChatHeaderkey = this.templateID == 89 ? 'STC_SUR' : this.pieChatHeaderkey;
      //this.pieChatHaederName = this.templateID == 89 ? 'Status of Users' : this.pieChatHaederName;
      this.TChartPiedata = tChartPiedata;
      this.TotalPrj = this.TChartPiedata.filter(x => x).reduce((sum, item) => sum + item.Yaxis, 0);
      this.showdatetime = true;
      this.loadChart = true;
      this.loadPieChartfilesave = false;
      this.loadfilesave = false;
      this.NoData = false;
      this.reportservice.GetUTCDate().subscribe((dateresult) => {
        this.GMTReportCreatedTime = dateresult
      });
    }
    else {
      this.NoData = true;
    }
    this.showLoader = false;
  }
  colorFn(e) {
    //console.log(e);
    if (e.category == 'InTime' || e.dataItem.Status == 'InTime' || e.category == 'Early' || e.dataItem.Status == 'Early' || e.dataItem.Status =='Active')
      return '#6ad49b';
   
    else if (e.category == 'Late' || e.dataItem.Status == 'Late' || e.dataItem.Status == 'Inactive')
      return '#f1416c';
    else if (e.dataItem.ColorCode != undefined && e.dataItem.ColorCode != '')
      return e.dataItem.ColorCode;
    else {
      return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
  }
  LegendNames(e) {
    if (e.category == 'InTime' || e.dataItem.Status == 'InTime' || e.category == 'Early' || e.dataItem.Status == 'Early' || e.dataItem.Status == 'Active')
      return '#6ad49b';
    else if (e.category == 'Late' || e.dataItem.Status == 'Late' || e.dataItem.Status == 'Inactive')
      return '#f1416c';
    else if (e.dataItem.ColorCode != undefined && e.dataItem.ColorCode != '')
      return e.dataItem.ColorCode;
    else {
      return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
  }

  public onTogglechartannotate(chartannotateshow?: boolean): void {
    //this.chartannotateshow = !this.chartannotateshow;
    this.chartannotateshow = chartannotateshow != undefined ? chartannotateshow : !this.chartannotateshow;
  }
  public onTogglesearchfilter(searchfiltershow?: boolean): void {
    //this.chartannotateshow = !this.chartannotateshow;
    this.searchfiltershow = searchfiltershow != undefined ? searchfiltershow : !this.searchfiltershow;
  }

  public onToggleanchorpiechart(anchorpiechartshow?: boolean): void {

    //this.chartannotateshow = !this.chartannotateshow;
    this.anchorpiechartshow = anchorpiechartshow != undefined ? anchorpiechartshow : !this.anchorpiechartshow;
    if (this.anchorpiechartshow) {
      this.exportChart('piechart');
    }

  }

  public closechartannotate(): void {
    this.chartannotateshow = false;
  }
  public closesearchfilter(): void {
    this.searchfiltershow = false;
  }


  exportChart(selecvalue: any) {
    if (selecvalue == 'piechart')
      this.loadPieChartfilesave = true;
    else
      this.loadfilesave = true;
  }
  exportfileChart(selecvalue: any) {
    if (selecvalue == 'piechart') {
      this.chartPie.exportImage().then((dataURI) => {
        saveAs(dataURI, this.txtPieChartFilenamevalue != '' ? this.txtPieChartFilenamevalue : 'piechart' + ".png");
        this.loadPieChartfilesave = false;
        this.txtPieChartFilenamevalue = "pie chart";
      });


    }
  
  }
  exportfilecancelChart(selecvalue: any) {
    if (selecvalue == 'piechart') {
      this.loadPieChartfilesave = false;
      this.txtPieChartFilenamevalue = "pie chart";
      this.anchorpiechartshow = false;
    }
    else {
      this.loadfilesave = false;
      this.txtFilenamevalue = "chart";
      this.anchornewshow = false;
    }
  }
  GetTranslatedStatus(status: string) {
    return this.languageService.GetActionMessageByLanguage('STC_' + status.toUpperCase(), status);
    //if (args.dataItem.Status == 'InTime') {
    //  text=this.languageService.GetActionMessageByLanguage('STC_INTM', args.dataItem.Status)
    //}
    //else if (args.dataItem.Status == 'Late') {
    //  text=this.languageService.GetActionMessageByLanguage('STC_LATE', args.dataItem.Status)
    //}
  }
  public PielabelContent(args: LegendLabelsContentArgs): string {
    let status= this.GetTranslatedStatus(args.dataItem.Status);
    return `${status}: ${this.intl.formatNumber(
      args.dataItem.TotalPercentage, "p2"
    )}`;
  }
  public legendTitle: LegendTitle = {
    text: "Status",
  };

  /*--Start Chart zoom js code--*/
  ZoominAction() {
    var zoomdiv = $(".div-area-zoom");
    zoomdiv.addClass("zoomdivone");
    var hide = $(".zoom_in");
    hide.addClass("znone");

    var disp = $(".zoom_out");
    disp.addClass("zdplay");
  }

  ZoomoutAction() {
    var zoomdiv = $(".div-area-zoom");
    zoomdiv.removeClass("zoomdivone");
    var hide = $(".zoom_in");
    hide.removeClass("znone");

    var disp = $(".zoom_out");
    disp.removeClass("zdplay");
  }

  ZoominAction2() {
    var zoomdiv = $(".div-area-zoom2");
    zoomdiv.addClass("zoomdivone2");
    var hide = $(".zoom_in");
    hide.addClass("znone");

    var disp = $(".zoom_out");
    disp.addClass("zdplay");
  }

  ZoomoutAction2() {
    var zoomdiv = $(".div-area-zoom2");
    zoomdiv.removeClass("zoomdivone2");
    var hide = $(".zoom_in");
    hide.removeClass("znone");

    var disp = $(".zoom_out");
    disp.removeClass("zdplay");
  }
  /*--End Chart zoom js code--*/

}
export class OpenReportType {
  type: string;
  dataItem: string;
}
