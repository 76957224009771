import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, NavigationStart, Router, Routes, RoutesRecognized } from '@angular/router';
import { LoginService } from '../services/login.service';
import { ModuleService } from '../services/module.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataByLanguage, LanguageService } from '../services/language.service';
import { NotificationAlertsService } from '../services/notification.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GlobalVeriables } from '../Global/GlobalVaribales';
import { AdminUserManagementService, NotificationEmailTriggerEvent } from '../services/adminusermanagement.service';
import { templateData } from '../interfaces/ItemplateFields';
import { DynamicFormViewComponent, templateSelectedRecord } from '../dynamic-form-view/dynamic-form-view.component';
import { CommonFeaturesService } from '../services/commonfeatures.service';
import { getBaseUrl } from '../../main';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted = false;
  validated=true;
  showLoader=false;
  Loginobj=new Login();
  showHeader:boolean=false;
  showSidebar: boolean = false;
  lblErrorMsg: string;
  showElement: boolean=false;
  curCompanyID: number = 0;
  cuserRoleID: any;
  IsLoginSuccess: boolean = false;
  masterModules: any;
  showMasterModules: boolean = false;
  selectedMasterModule: any = {};
  masterModuleID: number;
  module: any;
  defaultModule: any;
  navLinks: any[];
  modules: any;
  moduleID: any;
  forgotPasswordID: boolean = false;
  Issetpassword: boolean = false;
  odataStaticModulesData: any = {};
  routingName: string;
  offSet: string = "+05:30";
  masterModuleName: string;
  defaultHeader: string;
  masterModuleDescription: string;
  routeData: any;
  outsideurl: string;
  logo: string;
  Login_img: string;
  public languagesList = [];
  public languageData: DataByLanguage[] = [];
  selectedLanguage;
  isDataLoaded = false;
  currentYear: any;
passwordType:any="password"

  _NotificationEmailTriggerEvent: NotificationEmailTriggerEvent = new NotificationEmailTriggerEvent()
  submit = false;
  constructor(private router: Router, private formBuilder: FormBuilder, private _loginService: LoginService, private _notify: NotificationAlertsService, private GV: GlobalVeriables, private moduleService: ModuleService, private amService: AdminUserManagementService,
    private languageService: LanguageService, private notifyService: NotificationAlertsService, private commonfeaturesservice: CommonFeaturesService, private http: HttpClient  ) {     }
  loginForm = this.formBuilder.group({
    userName: ['', Validators.required],
    password: ['', Validators.required],
  })

  UserDyForm = new FormGroup(
    {
      usrEmailUserName: new FormControl('',
        [Validators.required])
    },
  );

  ngOnInit() {
    let dateObj = new Date();
    this.currentYear = dateObj.getUTCFullYear();

    this.GetAllLanguages();
    this.GetDefaultMasterModuleData();

    if (localStorage.getItem("userLogin") === "true") {
      this.showLoader = true;
      this._loginService.setHeaderShow(true);
      this.router.navigate(["/Projects"]);
    }
    else {
      if (window.location.pathname != '/Login')
          this.router.navigate(["/Login"]);
    }
  }

  Login() {

    if (this.loginForm.invalid) {
      this.submitted = true;
      return;
    }
    this.showLoader = true;
    this.Loginobj.userName = this.loginForm.controls['userName'].value;
    this.Loginobj.password = this.loginForm.controls['password'].value;
    localStorage.setItem("ssoLogin", "false");
    this._loginService.userLogin(this.Loginobj).subscribe(response => {
      this.lblErrorMsg = "";

      if (response.loginFailType == null) {
        localStorage.setItem("userLogin", "true");
        localStorage.setItem("userName", response.userName);
        localStorage.setItem("userId", response.userId);
        localStorage.setItem("userRoleID", response.roleID);
        localStorage.setItem("userRoleName", response.roleName);
        localStorage.setItem("userFirstName", response.firstName);
        localStorage.setItem("userLastName", response.lastName);
        localStorage.setItem("usercompanyID", response.companyID);
        localStorage.setItem("usertimeZone", response.timeZone);
        localStorage.setItem("OutofOfficeStatus", response.outofOfficeStatus); //to know the status is off or on
        this._loginService.isValid = true;
        localStorage.setItem("token", response.user_Token);
        localStorage.setItem("IsColumnDragged", "0");
        this.GV.setoutOfofficeState(response.outofOfficeStatus);
        this.showHeader = true;
        this._loginService.setHeaderShow(true);
        this.moduleService.reoladAppComponent(0);
        this.GV.UserName = localStorage.getItem("userName");
        this.GV.UserId = +localStorage.getItem("userId");
        this.GV.UserRole = localStorage.getItem("userRoleName");
        this.GV.UserRoleID = +localStorage.getItem("userRoleID");
        this.GV.CompanyID = +localStorage.getItem("usercompanyID");
        this.GV.TimezoneID = +localStorage.getItem("usertimeZone");
        this.curCompanyID = this.GV.CompanyID;
        this.cuserRoleID = this.GV.UserRoleID;
        this.GV.ssoLogin = JSON.parse(localStorage.getItem("ssoLogin")) != null ? JSON.parse(localStorage.getItem("ssoLogin")) : false;

        this.amService.GetUserPrivileges(this.GV.UserId, 1).subscribe(result => {
          this.GV.setUserPrivileges(result);
        })
        this.router.navigate(["/Projects"]);
      }
      else {
        this.IsLoginSuccess = false;        
        if (response.loginFailType == "Locked" && response.loginFailAttempts > 0)
          this.functionInvalidNr1(response.loginFailType, response.loginFailAttempts,response.userId);//User is locked due to incorrect password
        else if (response.loginFailType == "Locked")
          this.functionNr1("LOG_UIL");//User is locked
        else if (response.loginFailType == "Invalid" && response.loginFailAttempts >= 0)
          this.functionInvalidNr1(response.loginFailType, response.loginFailAttempts,0);//Invalid username or password
        else if (response.loginFailType == "Invalid")
          this.functionNr1("LOG_IUP");//Invalid username or password  
          else if (response.loginFailType == "NotSetPassword")
          this.functionNr1("LOG_NSP");
        else if (response.loginFailType == "Disabled")
          this.functionNr1("LOG_UIDI");//User is disabled
        else if (response.loginFailType == "Inactivated")
          this.functionNr1("LOG_UIDE");//User is deactivated
        else if (response.loginFailType == "NA")
          this.functionNr1("LOG_UNA");//User is not available
        else if (response.loginFailType == "NotValidUser")
          this.functionNr1("LOG_NVU");//User is not available in local
        else if (response.loginFailType == "AccountLocked")
          this.functionNr1("LOG_UIL");//User is locked SSO
      }
      this.showLoader = false;
    })
  }
 
  functionInvalidNr1(msg,attempts,UserID) {
    this.showElement = true;
    this.lblErrorMsg = '';
    if (msg == 'Locked')
    {
      this.lblErrorMsg = "Your account has been locked out due to multiple failed login attempts. Please contact Admin.";
      this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForUserLockOrUnlock, 1, 0, 0, UserID, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
    }
    else if (msg == 'Invalid')
      this.lblErrorMsg = "Invalid credentials. You have " + attempts +" more attempt(s) before your account gets locked out.";
    setTimeout(() => {
      this.lblErrorMsg = "";
      this.showElement = false;
    }, 5000);
  }

  GetDefaultMasterModuleData() {
    this.commonfeaturesservice.GetDefaultMasterModuleData().subscribe((dataDefaultMasterModuleData) => {
      this.masterModuleName = dataDefaultMasterModuleData[0].masterModuleName;
      this.defaultHeader = dataDefaultMasterModuleData[0].defaultHeader;
      this.masterModuleDescription = dataDefaultMasterModuleData[0].masterModuleDescription;
      if (dataDefaultMasterModuleData[0].logo != null) {
        let str_array = dataDefaultMasterModuleData[0].logo.split(',');
        this.logo = str_array[0];
        this.Login_img = str_array[1];
      }
    });
  }
 
  GetAllLanguages() {
    this.languageService.GetAllLanguages().subscribe(res => {
      this.languagesList = res;
      if (!localStorage.getItem("SelectedLanguageId")) {
        this.selectedLanguage = this.languagesList.find(x => x.isDefault == true);
        localStorage.setItem("SelectedLanguageId", this.selectedLanguage.id);
      } else {
        this.selectedLanguage = this.languagesList.find(x => x.id == parseInt(localStorage.getItem("SelectedLanguageId")));
      }
      this.GetDataByLanguageId(this.selectedLanguage.id);
    });
  }

  changeLanguage(value: any): void {
    if (this.selectedLanguage.id != value.id) {
      this.selectedLanguage = value;
      localStorage.setItem("SelectedLanguageId", this.selectedLanguage.id);
      this.GetDataByLanguageId(this.selectedLanguage.id);
      window.location.reload();
    }
  }

  GetDataByLanguageId(languageId: number) {
    this.languageService.GetDataByLanguageID(languageId).subscribe(res => {
      this.languageData = res;
      this.isDataLoaded = true;
    });
  }

  cancelUserData() {
    this.forgotPasswordID = true;
  }

  countriesClose() {
    this.forgotPasswordID = false;
    this.UserDyForm.reset();
    this.submit = false;
  }

  formreset() {
    this.loginForm.reset({
      'userName': '',
      'password': ''
    });
  }

  showPassword()
  {
    if(this.passwordType=='password')
    {
     this.passwordType='text'
    }
    else{
      this.passwordType='password'
    }
  }

  submitFWP() {
    this.formreset();
    if (this.UserDyForm.invalid) {
      this.submit = true;
      return;
    }
    this.submitted = false;
    let usernameemail = this.UserDyForm.controls['usrEmailUserName'].value;
    this._loginService.getcustomerid(usernameemail, "L").subscribe(response => {
      let userid = response;
      if (userid > 0) {
        this.amService.GetActionEmailNotification(this._NotificationEmailTriggerEvent.NotificationtoForgotLoginandESignPasswordUser, 1, 0, 0, userid, 1, 1, 1, encodeURIComponent(this.offSet)).subscribe();
        this.notifyService.show("The link has been sent, please check your email to reset your password.", 1);
        this.forgotPasswordID = false;
        this.UserDyForm.reset();
      }
      else
      {
        this.notifyService.show("User is not valid",4);
      }
    });
  }

  functionNr1(msg) {
    this.showElement = true;
    this.lblErrorMsg= this.languageData.find(x=>x.LanguageKey==msg).Message;    
    setTimeout(() => {
      this.lblErrorMsg = "";
      this.showElement = false;
    }, 3000)
  }

  SSOLogin() {
    localStorage.setItem("ssoLogin", "true");
    this.http.get(getBaseUrl() + 'api/Account/SSOlogin?requestType=Login&userID=0', {}).subscribe(result => {
      window.location.href = result.toString();
    }, error => console.error(error));
  }
}

class Login
    {
      emailID:string;
      password:string;
      userName:string;
      roles:string;
    }
