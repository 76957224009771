import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from '../../../services/module.service';
import { AduitForm, AuditDashboardForm, MasterData, MasterDataInput, MasterDataNames, PDfExpAuditSave, AuditTrailsDTOData } from '../../../services/AMFormsDataDetails';
import { AdminUserManagementService, GetAuditAllFieldName, DateConvertions } from '../../../services/adminusermanagement.service';
import { NotificationAlertsService } from '../../../services/notification.service';
import { CommonFeaturesService } from '../../../services/commonfeatures.service';
import { GlobalVeriables } from '../../../Global/GlobalVaribales';
import { FormBuilder, Validators } from '@angular/forms';
import { getBaseUrl } from '../../../../main';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { Workbook, WorkbookSheet} from "@progress/kendo-angular-excel-export";
import { saveAs } from "@progress/kendo-file-saver";
import { CompositeFilterDescriptor, filterBy} from '@progress/kendo-data-query';
import { PageChangeEvent, PagerPosition } from '@progress/kendo-angular-listview';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-masterdata-list',
  templateUrl: './masterdata-list.component.html',
  styleUrls: ['./masterdata-list.component.css'],
  providers: [GetAuditAllFieldName, NotificationAlertsService, DateConvertions]
})

export class MasterdataListComponent implements OnInit {
  OperationType: number = 1;
  MasteDataType: number = 1;
  MasteDataTypeID: number=1;
  MasteDataTypeName: any;
  MasterDataID: number = 0;
  CompanyID: number = 0;
  ResponseData: MasterData[] =[];
  MasterDataNamesDa: MasterDataNames[] = [];
  objMasterDataInput = new MasterDataInput();
  submitted = false;
  MasteDataTypeStatus: string;
  IsExportExcel: boolean = false;
  EnableSearchOption: boolean = false;
  columns: any;
  btnAddUser: boolean = true;
  btndisabledUSer: boolean = true;
  btnActive: boolean = false;
  btnActions: boolean = true;
  AuditResponseData: AuditDashboardForm[] = [];
  AuditLogId: boolean = false;
  Audituserdata: AduitForm[] = [];
  htmlToAdd: any;
  divHisdata: boolean = false;
  IsAuditExportExcel: boolean = false;
  btnEditAction: boolean = true;
  btnDeleteAction: boolean = true;
  AuditexcelBtn: boolean = false;
  public opened = false;
  classMasteDataType: string;
  savebtn: boolean = false;
  updatebtn: boolean = false;
  addScreen: boolean = false;
  titleText: string;
  excelRecords: any;
  columnNamesUser: any = [];
  gridData: any;
  IsDisable: boolean = false;
  IsExportpdf: boolean = false;
  ObjAuditExpPdf = new PDfExpAuditSave();
  showLoader: boolean = false;
  TZoffset = this.DateTimeService.setUTCTimeZone();
  message: string;
  FileName: string;
  progress: number;
  timeStamp: Date = new Date();
  uploadedFiles: any = [];
  flagImages: any = [];
  picSaving: any = [];
  temp: any = [];
  eFiles: any = [];
  @Input() public SelectedRecord: number;
  invalidSubmitAttempt: boolean = false;
  duplicateuploadedFiles: any = [];
  myFiles: any = [];
  nameSaving: any = [];
  fileFlag: any;
  flagPath: string;
  btnDisable:boolean=true;
  public buttonCount =5;
  public info = true;
  public pageSizes = [5,10,20,100,200];
  public previousNext = true;
  public position: PagerPosition = "bottom";
  classBinding:string='k-state-selected';
  ResponseDataAudit: AuditTrailsDTOData[] = [];
  ResponseData1: AuditTrailsDTOData[] = [];
  ResponseData2: AuditTrailsDTOData[] = [];
  moduleName:string='';
  pdfData = new PDFDownloadData;
  pdfDataList : PDFDownloadData[] = [];
  pdffieldNames=new PDFFieldNames;
  showmsgEditTempWindow:boolean=false;
  userName=this.GV.UserName;
  filteredRecords: any;
  filter: CompositeFilterDescriptor;
  pageSize:number=0;
  skipPage:number=0;

  constructor(private moduleService: ModuleService, private http: HttpClient, private av_router: ActivatedRoute, private router: Router, private dataservice: AdminUserManagementService
    , private commonfeaturesservice: CommonFeaturesService, private notifications: NotificationAlertsService, private DisplayAllFields: GetAuditAllFieldName, private DateTimeService: DateConvertions, private GV: GlobalVeriables,
    private formBuilder: FormBuilder , public sanitizer:DomSanitizer) {
    if (this.av_router.snapshot.params["MasteDataTypeID"])
      this.MasteDataTypeID = parseInt(this.av_router.snapshot.params["MasteDataTypeID"]);
  }

  ngOnInit() {
    this.flagPath = getBaseUrl() + "Documents/FlagImages/";
    this.addScreen = false;
    this.IsDisable = false;
    this.btnDisable = true;
    this.CompanyID = +localStorage.getItem("usercompanyID");
    this.GV.UserId = +localStorage.getItem("userId");
    this.UMAllMasteDataNames();
    this.loadMasteDataBoard(1, this.MasteDataTypeID, this.MasterDataID, this.CompanyID);
    this.MasteDataTypeStatus = "Active ";// + this.MasteDataType;
  }

  uploadSaveUrl = getBaseUrl() + 'api/adminmanagement/DynACU';
  uploadRemoveUrl = getBaseUrl() + 'api/adminmanagement/DynACUR';

  myRestrictions: FileRestrictions = {
    allowedExtensions: [".png",],
  };

  masterDataForm = this.formBuilder.group({
    txtName: ['', [Validators.required, Validators.pattern("^[ a-zA-Z0-9()_-]*$")]],
    txtDescription: ['', [Validators.pattern("^[ a-zA-Z0-9_-]*$")]],
    txtCode: ['', ],
    txtFlag: ['', ],
  })

  OpenMasterMasteData() {
    this.savebtn = true;
    this.updatebtn = false;
    this.addScreen = true;
    this.submitted=false;
    this.titleText = "Add " + this.MasteDataType;
    this.MasterDataID = 0;
    this.masterDataForm.controls['txtName'].setValue("");
    this.masterDataForm.controls['txtDescription'].setValue("");
    this.masterDataForm.controls['txtCode'].setValue("");
    this.masterDataForm.controls['txtFlag'].setValue("");
    this.myFiles='';
  }

  CancelMasteData(MasteDataTypeID) {
    this.addScreen = false;
  }

  onKeypressEvent(event: any) {
    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }

  saveMasteData(masterDataID: any, MasteDataTypeID: any) {

    if (this.masterDataForm.invalid) {
      this.submitted = true;
      return;
    }
    this.objMasterDataInput.MasterDataID = this.MasterDataID;
    this.objMasterDataInput.MasterDataName = this.masterDataForm.controls['txtName'].value;
    this.objMasterDataInput.MasterDataDescsiption = this.masterDataForm.controls['txtDescription'].value;
    if(MasteDataTypeID==8)
    {
    this.objMasterDataInput.MasterDataCode = this.masterDataForm.controls['txtCode'].value;
    this.objMasterDataInput.MasterDataFlag = this.masterDataForm.controls['txtFlag'].value[0].name;
    }
    this.objMasterDataInput.MasterDataTypeID = this.MasteDataTypeID;
    this.objMasterDataInput.CompanyID = this.CompanyID;
    this.objMasterDataInput.userID = this.GV.UserId;

    this.dataservice.osaveAllMasterData(this.objMasterDataInput).subscribe(response => {
      if (response != undefined || response == null) {

        if (response == "1") {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDCS", this.CompanyID).subscribe((dataMCActionsMessages) => {
            this.notifications.show(this.objMasterDataInput.MasterDataName + dataMCActionsMessages[0].displayMessage, dataMCActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
        else if (response == "2") {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDUS", this.CompanyID).subscribe((dataMUActionsMessages) => {
            this.notifications.show(this.objMasterDataInput.MasterDataName + dataMUActionsMessages[0].displayMessage, dataMUActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
        else if(response==null||response=='')
        {
          if(MasteDataTypeID==8)
          {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDAE", this.CompanyID).subscribe((dataMUActionsMessages) => {
            this.notifications.show(this.objMasterDataInput.MasterDataCode + " Or "+ this.objMasterDataInput.MasterDataName + dataMUActionsMessages[0].displayMessage, dataMUActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
        else{
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDAE", this.CompanyID).subscribe((dataMUActionsMessages) => {
            this.notifications.show(this.objMasterDataInput.MasterDataName + dataMUActionsMessages[0].displayMessage, dataMUActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
        }
        }
        else {
          if(MasteDataTypeID==8)
          {
            this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDAE", this.CompanyID).subscribe((dataMEActionsMessages) => {
              this.notifications.show(this.objMasterDataInput.MasterDataCode + dataMEActionsMessages[0].displayMessage, dataMEActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
            });
          }
          else
          {
          this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDAE", this.CompanyID).subscribe((dataMEActionsMessages) => {
            this.notifications.show(this.objMasterDataInput.MasterDataName + dataMEActionsMessages[0].displayMessage, dataMEActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
          });
          }
        }
      }
      this.addScreen = false;
      this.loadMasteDataBoard(1, this.MasteDataTypeID, this.MasterDataID, this.CompanyID);
    });
  }

  EditRoleData(masterDataID) {
    this.MasterDataID = parseInt(masterDataID);
    this.savebtn = false;
    this.updatebtn = true;
    this.addScreen = true;
    this.titleText = "Edit " + this.MasteDataType;

    this.dataservice.getUMManageAllMasterData(2, this.MasteDataTypeID, parseInt(masterDataID), this.CompanyID).subscribe(data => {
      this.masterDataForm.controls['txtName'].setValue(data[0].masterDataName);
      this.masterDataForm.controls['txtDescription'].setValue(data[0].masterDataDescsiption);
      this.masterDataForm.controls['txtCode'].setValue(data[0].masterDataCode);
      this.nameSaving = (data[0].masterDataFlag);
      this.myFiles = [
        { name: this.nameSaving },
      ];
    });
  }


  checkUserPrivilege(actionEventID, LevelID) {
    return this.dataservice.GetPrivilegeAccesType(actionEventID, LevelID);
  }

  public handleSelection(dataItem): void {
    this.pageSize=5;
    this.skipPage=0;
    this.filter=null
    this.masterDataForm.controls['txtName'].setValue("");
    this.masterDataForm.controls['txtDescription'].setValue("");
    this.masterDataForm.controls['txtCode'].setValue("");
    this.masterDataForm.controls['txtFlag'].setValue("");
    this.btnDisable=true;
    this.MasteDataTypeStatus='Active';
    this.masterDataForm.controls["txtCode"].clearValidators();
    this.masterDataForm.controls["txtFlag"].clearValidators();
    this.MasteDataTypeID = dataItem.dataItem.masteDataID;
    if (dataItem.dataItem.masteDataID == 1)
      this.loadMasteDataBoard(this.OperationType, 1, this.MasterDataID, this.CompanyID);
    else if (dataItem.dataItem.masteDataID == 4)
      this.loadMasteDataBoard(this.OperationType, 4, this.MasterDataID, this.CompanyID);
    else if (dataItem.dataItem.masteDataID == 5)
      this.loadMasteDataBoard(this.OperationType, 5, this.MasterDataID, this.CompanyID);
    else if (dataItem.dataItem.masteDataID == 6)
      this.loadMasteDataBoard(this.OperationType, 6, this.MasterDataID, this.CompanyID);
    else if (dataItem.dataItem.masteDataID == 8) {
      this.masterDataForm.controls["txtCode"].addValidators(Validators.required);
      this.masterDataForm.controls["txtFlag"].addValidators(Validators.required);
      this.loadMasteDataBoard(this.OperationType, 8, this.MasterDataID, this.CompanyID);
    }
    else if (dataItem.dataItem.masteDataID == 7)
      this.loadMasteDataBoard(this.OperationType, 7, this.MasterDataID, this.CompanyID);
    else if (dataItem.dataItem.masteDataID == 9)
      this.loadMasteDataBoard(this.OperationType, 9, this.MasterDataID, this.CompanyID);
    else if (dataItem.dataItem.masteDataID == 15)
      this.loadMasteDataBoard(this.OperationType, 15, this.MasterDataID, this.CompanyID);
    else if (dataItem.dataItem.masteDataID == 16)
      this.loadMasteDataBoard(this.OperationType, 16, this.MasterDataID, this.CompanyID);
    else if (dataItem.dataItem.masteDataID == 17)
      this.loadMasteDataBoard(this.OperationType, 17, this.MasterDataID, this.CompanyID);
    else if (dataItem.dataItem.masteDataID == 18)
      this.loadMasteDataBoard(this.OperationType, 18, this.MasterDataID, this.CompanyID);
  }

  UMAllMasteDataNames() {
    this.dataservice.UMAllMasteDataNamesData().subscribe(data => {
      this.MasterDataNamesDa = data;
      this.MasteDataType = data[0].masterDataType;
      this.MasteDataTypeID=data[0].masteDataID
    });
  }
  loadMasteDataBoard(OperationType: number, MasteDataType: number, MasterDataID: number, CompanyID: number) {
    this.pageSize=5;
    this.skipPage=0;
    this.filter=null
    this.dataservice.getUMManageAllMasterData(OperationType, MasteDataType, MasterDataID, CompanyID).subscribe(data => {
      this.gridData = data;
      this.ResponseData = data;
        this.IsExportExcel = true;
      this.btnEditAction = true;
      this.btnDeleteAction = true;
      this.btnAddUser = true;
      this.IsExportpdf = true;
      this.btnActive = false;
      this.btndisabledUSer = true;

      if (data.length != 0)
        this.EnableSearchOption = true;
      this.MasteDataType = data[0].masterDataType;

      if (this.gridData.length == 0)
        this.IsDisable = true;
      else
        this.IsDisable = false;
    });
  }
  
  public closeDialog() {
    this.opened = false;
  }

  public openDialog(masterDataID) {
    this.MasterDataID = 0;
    this.opened = true;
    this.MasterDataID = masterDataID;
  }

  DeleteMasterData() {
    this.dataservice.DeleteMasterData(this.MasteDataTypeID, this.MasterDataID, this.GV.UserId).subscribe((data) => {
      this.opened = false;

      this.commonfeaturesservice.GetAllActionsMessages(1, 1, 1, 0, "MDDS", this.CompanyID).subscribe((dataGAActionsMessages) => {
        this.notifications.show(this.MasteDataType + dataGAActionsMessages[0].displayMessage, dataGAActionsMessages[0].messageType);//"Are you sure you want to Unlock this User?";
      });

      this.loadMasteDataBoard(1, this.MasteDataTypeID, 0, this.CompanyID);
    });
  }

  ActiveUsers() {
    this.MasteDataTypeStatus = "Active ";
    this.btnAddUser = true;
    this.btnActive = false;
    this.btndisabledUSer = true;
    this.btnActions = true;
    this.IsExportExcel = true;
    this.btnEditAction = false;
    this.btnDeleteAction = false;
    this.btnDisable=true;
    this.loadMasteDataBoard(1, this.MasteDataTypeID, this.MasterDataID, this.CompanyID);
  }

  ExportAuditToPdfFile(htmlcontent: string) {
    this.ObjAuditExpPdf.htmlcontent = htmlcontent;
    this.ObjAuditExpPdf.offset = this.TZoffset;;
    this.ObjAuditExpPdf.TableEnum = this.MasteDataTypeID.toString();
    this.showLoader = true;
    this.dataservice.DownloadAuditPdfFile(this.ObjAuditExpPdf).subscribe((event) => {
      this.DownLoadExportPdfFile(event.filePath, event.auditFile);
      this.showLoader = false;
    },
      error => {
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );


  }

  DownLoadExportPdfFile(Filepath: string, FileName: string) {
    this.FileName = FileName;
    this.dataservice.GetDownloadAuditPdfFilePath(Filepath, FileName).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round((100 * event.loaded) / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Download success.';
        this.downloadFile(event);
        this.pdfDataList=[];
      }
    },
      error => {
        this.showLoader = false;
        this.notifications.show("Download  Failed !! " + error.statusText, 4);
      }
    );
  }
  private downloadFile(data: HttpResponse<Blob>) {
    const downloadedFile = new Blob([data.body], { type: data.body.type });
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = this.FileName;
    a.href = URL.createObjectURL(downloadedFile);
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  DisabledMasteData() {
    this.pageSize=5;
    this.skipPage=0;
    this.filter=null
    this.MasteDataTypeStatus = "Disabled ";
    this.dataservice.getUMManageAllMasterData(3, this.MasteDataTypeID, this.MasterDataID, this.CompanyID).subscribe(data => {
      this.gridData = data;
      this.ResponseData = data;
      this.btnAddUser = false;
      this.btnActive = true;
      this.btndisabledUSer = false;
      this.btnActions = true;
      this.IsExportpdf = true;
      this.btnDisable=false;
      this.IsExportExcel = true;
      this.btnEditAction = false;
      this.btnDeleteAction = false;
      if (data.length != 0) {
        this.EnableSearchOption = true;
      }
    });
  }

  public ManageAuditHistory(keyID: any, TablenumID: any, userID: any, CompanyID: any) {
    this.dataservice.AuditLogData(keyID, TablenumID, this.GV.UserId, this.CompanyID).subscribe((data) => {
      this.moduleName = this.MasteDataTypeStatus+" "+this.MasteDataType;
      this.ResponseData1 = data;

      this.divHisdata = true;
      if (this.ResponseData1.length > 0) {
        for (let i = 0; i < this.ResponseData1.length; i++) {
          this.pdfData = new PDFDownloadData;
          this.pdfData.userId=data[i].userId;
          this.pdfData.actionType = data[i].actionType;
          this.pdfData.eventDate = this.DateTimeService.convertdate(data[i].eventDate);
          let seraizedata = JSON.parse(this.ResponseData1[i]["changes"]);
           for(let i=0;i<=seraizedata.length-1;i++)
           {
             seraizedata[i].FieldName= this.DisplayAllFields.GetFieldName(seraizedata[i].FieldName, parseInt(TablenumID));
            seraizedata[i].ValueBefore=(seraizedata[i].ValueBefore == "(null)" ? "" : seraizedata[i].ValueBefore);
            seraizedata[i].ValueAfter=(seraizedata[i].ValueAfter == "(null)" ? "" : seraizedata[i].ValueAfter);  
            }
          this.pdfData.listdata=seraizedata;
          this.pdfDataList.push(this.pdfData);
        }
        this.showmsgEditTempWindow = true;
      }
      else{
        this.notifications.show("No Audit data found");
      }
    });
  }
      
  public rolesDialogClose() {
    this.pdfDataList=[];
    this.addScreen = false;
    this.showmsgEditTempWindow = false;
  }


  auditclose() {
    this.AuditLogId = false;
    if (this.btnActive) {
      this.btnEditAction = false;
      this.btnDeleteAction = false;
    }
    else {
      this.btnEditAction = true;
      this.btnDeleteAction = true;
    }
  }
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.filteredRecords = filterBy(this.ResponseData, filter);
    if (this.filteredRecords.length == 0) {
      this.gridData = this.filteredRecords;
      this.IsDisable = true;
     }
     else{
       this.gridData = this.filteredRecords;
       this.IsDisable = false;
     }

  }
  onExcelExport(MasteDataTypeID) {
    if(MasteDataTypeID==8)
      this.columnNamesUser=["Name","Country Flag","Country Code"]
    else
      this.columnNamesUser=["Name","Description"];

    this.excelRecords = this.gridData

    let rows = [];
    //Application Name
    let headerRow = new HeaderRowClass();
    var headerRowcells: HeaderCellClass[] = [];
    let headerCell = new HeaderCellClass();
    headerCell.value = "Application Name"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
     headerCell = new HeaderCellClass();
    headerCell.value = "Artwork360"
    headerCell.bold = true;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Module Name
     headerRow = new HeaderRowClass();
    var headerRowcells: HeaderCellClass[] = [];
     headerCell = new HeaderCellClass();
    headerCell.value = "Module Name"
    headerCell.bold = false;
    headerRowcells.push(headerCell);
     headerCell = new HeaderCellClass();
    headerCell.value = "Admin Settings>>Master Data>>" + this.MasteDataTypeStatus + this.MasteDataType;
    headerCell.bold = true;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    ///Downloaded by
     headerRow = new HeaderRowClass();
    var headerRowcells: HeaderCellClass[] = [];
     headerCell = new HeaderCellClass();
    headerCell.value = "Downloaded By";
    headerCell.bold = false;
    headerRowcells.push(headerCell);

     headerCell = new HeaderCellClass();
    headerCell.value = this.GV.UserName;
    headerCell.bold = true;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Downloaded Date
     headerRow = new HeaderRowClass();
     var headerRowcells: HeaderCellClass[] = [];
     headerCell = new HeaderCellClass();
    headerCell.value = "Downloaded Date";
    headerCell.bold = false;
    headerRowcells.push(headerCell);
     headerCell = new HeaderCellClass();

    let CurrentDate = new Date();
    headerCell.value = CurrentDate.toString();;
    headerCell.bold = true;
    headerRowcells.push(headerCell);
    headerRow.cells = headerRowcells;
    rows.push(headerRow);

    //Binding Columns
     headerRow = new HeaderRowClass();
     var headerRowcells: HeaderCellClass[] = [];

    this.columnNamesUser.forEach(element => {
       headerCell = new HeaderCellClass();
      headerCell.value = element
      headerCell.bold = true;
      headerRowcells.push(headerCell);
      headerCell.background = "#227447"
      headerCell.color = "#ffffff"
    });

    headerRow.cells = headerRowcells;
    rows.push(headerRow);
    //Binding Grid Data
     headerRow = new HeaderRowClass();
     var headerRowcells: HeaderCellClass[] = [];

    for (let i of this.excelRecords) {
       headerRow = new HeaderRowClass();
      let headerRowcells: HeaderCellClass[] = [];
       headerCell = new HeaderCellClass();
      headerCell.value = i.masterDataName;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""

if(MasteDataTypeID!=8)
{
   headerCell = new HeaderCellClass();
      headerCell.value = i.masterDataDescsiption;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""
      headerRow.cells = headerRowcells;
      rows.push(headerRow);
}
else {
   headerCell = new HeaderCellClass();
      headerCell.value = i.masterDataFlag;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""
   headerCell = new HeaderCellClass();
      headerCell.value = i.masterDataCode;
      headerCell.bold = false;
      headerRowcells.push(headerCell);
      headerCell.background = ""
      headerCell.color = ""
      headerRow.cells = headerRowcells;
      rows.push(headerRow);
}
    }
    let excelName = this.MasteDataTypeStatus + this.MasteDataType;
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: [
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
          ],
          // Title of the sheet
          name: excelName,
          rows: rows
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => saveAs(dataUrl, excelName + ".xlsx"));
  }
  pageChange(event: PageChangeEvent) {
    this.skipPage = event.skip;
  }
}

export class HeaderCellClass {
  value: string;
  bold: boolean;
  background: string;
  color: string;
}

export class HeaderRowClass {
  cells: any;
}

export class PDFDownloadData{
  userId : string;
  eventDate: string;
  actionType: string;
  listdata: PDFFieldNames[] = [];
}

  export class PDFFieldNames{
    Name: string;
    ValueBefore: string;
    ValueAfter: string;
  }
