import { GlobalVeriables } from '../../Global/GlobalVaribales';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DynamicFieldTableComponent } from '../../ArtworkManagement/dynamic-fields/dynamic-field-table/dynamic-field-table.component';
import { AppComponent } from '../../app.component';
import { LanguageService } from '../../services/language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from '../../services/module.service';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { CommonFeaturesService } from '../../services/commonfeatures.service';
import { NotificationAlertsService } from '../../services/notification.service';
@Component({

  selector: 'app-compare-files',
  templateUrl: './compare-files.component.html',
  styleUrls: ['./compare-files.component.css']
})
export class CompareFilesComponent implements OnInit {

  
  @Input() fileDetails: any;
  @Input() selectedDALFileIds: any;
  @Input() isFromReforDal: any;
  @Input() fileRefDetails: any;
  SelectedFiles:any [] = [];
  SelectedSourceFiles:any  [] = [];
  SelectedTargetFiles: any [] = [];
  selectedSourceFile :any;
  selectedTargetFile :any;
  @Output() hideEvent = new EventEmitter<any>();
  public languageDataDefault: any;
  srcFileID: any; srcCycleID: any; srcCycleFilePath: any; srcCycleFileName: any; srcFileGUID: any; srcFileType: any;
  targetFileID: any; subProcessFilesData: any; 
  targetCycleFilePath: any
  targetCycleFileName: any
  targetFileGUID: any;
  targetFileType: any;
  targetCycleID: any;
  compareType: any;
  textsrcFileTypes: any=  ["pdf", "doc", "docx", "rtf", "ai", "xls", "xlsx"];
  graphicsrcFileTypes: any = ["pdf", "ai"];
  texttargetFileTypes: any = ["pdf"];
  graphictargetFileTypes: any =["pdf", "ai", "png"];
  srcValidatemsg: any;
  trgtValidatemsg: any;
  isComparsionType: any;
  IscompareEnable: any;
  IssourceVal: any;
  IsTargetVal: any;
  isComparsionValue: any;
  getcompareVal: any;
  comparefilesData: any;
  UniqueId: any;
  informCCURL: any= "https://demo2.contentcompare.com/FreyrDev/InformCC/CCIntegration.aspx?paramid=";
  compareObjdata = new compareObj();
  public defaultsourceVal: { File: string; ID: number } = {
  File: 'Select Source File',
    ID: -1,
  };
  public defaultTargetVal: { File: string; ID: number } = {
    File: 'Select Source File',
    ID: -1,
  };
  istextsourcevaltrue: any;
  RefFileData = new compareFileDetails;
  istexttargetvaltrue: any;
  submitted = false;
  public selectedList: { File: string; ID: number };
  constructor(private moduleService: ModuleService, private http: HttpClient, private fb: FormBuilder, private notifications: NotificationAlertsService,
    private languageService: LanguageService, private router: ActivatedRoute, private commonfeaturesservice: CommonFeaturesService){ 

   
  }
   compareForm = this.fb.group({
     sourceDdlValue: ['', [Validators.required]],
     targetDdlValue: ['', [Validators.required]],
     isComparsion:['']

    });
  ngOnInit() {
    
    this.isComparsionValue = "Text";
    this.SelectedSourceFiles = this.SelectedFiles;
    this.SelectedTargetFiles = this.SelectedFiles;
    this.IscompareEnable = true;
    if (this.isFromReforDal == 2) {
      this.srcValidatemsg = this.GetMessageByLanguage("STC_TEXTTARGETTYPE", '');
      this.trgtValidatemsg = this.GetMessageByLanguage("STC_TEXTTARGETTYPE", '');
    }
    else {
      this.srcValidatemsg = this.GetMessageByLanguage("STC_TEXTSOURCETYPE", '');
      this.trgtValidatemsg = this.GetMessageByLanguage("STC_TEXTTARGETTYPE", '');
    }
    this.ArtworkFiles();
   
  }
 
  ArtworkFiles() {
  
    this.SelectedFiles = [];
    var objdata = [];
   
    if (this.isFromReforDal == 2) {
    var  details = (this.fileDetails.filter(x => x.displayName === 'FileName'));
      
      for (var i = 0; i < details.length; i++) {
        this.RefFileData = new compareFileDetails();
        this.RefFileData.ID = details[i].fileID;
        this.RefFileData.File = details[i].displayValue;
        this.RefFileData.fileType = "";
        this.SelectedFiles.push(this.RefFileData);
      }
      this.SelectedSourceFiles = this.SelectedFiles;
      this.SelectedTargetFiles = this.SelectedFiles;
    }
    else if (this.isFromReforDal == 1) {
      this.SelectedSourceFiles = [];
      this.SelectedTargetFiles = [];
      this.SelectedFiles = this.fileRefDetails;
      if (this.isComparsionValue == "Text" ) {
        for (var i = 0; i < this.fileRefDetails.length; i++) {
          if (this.textsrcFileTypes.filter(x => x === this.fileRefDetails[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
            this.SelectedSourceFiles.push(this.fileRefDetails[i]);
          }
        }

       
        for (var i = 0; i < this.fileRefDetails.length; i++) {
          if (this.texttargetFileTypes.filter(x => x === this.fileRefDetails[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
            this.SelectedTargetFiles.push(this.fileRefDetails[i]);
            }
           }
        }
      else if (this.isComparsionValue == "Graphic") {
        for (var i = 0; i < this.fileRefDetails.length; i++) {
          if (this.graphicsrcFileTypes.filter(x => x === this.fileRefDetails[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
            this.SelectedSourceFiles.push(this.RefFileData);
          }
        }


        for (var i = 0; i < this.fileRefDetails.length; i++) {
          if (this.graphictargetFileTypes.filter(x => x === this.fileRefDetails[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
            this.SelectedTargetFiles.push(this.RefFileData);
          }
        }
      }
      }
      
     
    
  

    
  }
  
  HideCompare() {
    this.hideEvent.emit();
  }

  CompareDocumentChangeEvent(e :any,datasource:any,divid:any) {
    var changedDivID = divid;
    this.srcFileID = (this.compareForm.controls['sourceDdlValue'].value == undefined || this.compareForm.controls['sourceDdlValue'].value == null) ? '' : this.compareForm.controls['sourceDdlValue'].value.toString(); 
    this.targetFileID = (this.compareForm.controls['targetDdlValue'].value == undefined || this.compareForm.controls['targetDdlValue'].value == null) ? '' : this.compareForm.controls['targetDdlValue'].value.toString();
    this.isComparsionType = (this.compareForm.controls['isComparsion'].value == undefined || this.compareForm.controls['isComparsion'].value == null) ? '' : this.compareForm.controls['isComparsion'].value.toString();
 
    if (this.isFromReforDal == 2) {
      var details = (this.fileDetails.filter(x => x.displayName === 'FileName'));
     
   if (changedDivID == "divSourceFiles" ) {
      var list = datasource.filter(v => v.ID == e);
      if (datasource) {
           var filteredTargetData = datasource.filter(v => v.ID != e);
           this.SelectedTargetFiles = filteredTargetData;
          }
        }
     else {
      if (changedDivID = "divTargetFiles" ) {
        if (datasource) {
          var filteredTargetData = datasource.filter(v => v.ID != e);
          this.SelectedSourceFiles = filteredTargetData;
           }
           }
         }
      
      }
    else if (this.isFromReforDal == 1) {
      
      if (this.isComparsionValue == "Text") {
        if (changedDivID == "divSourceFiles") {

          this.SelectedTargetFiles = [];
          var filteredTargetData = this.fileRefDetails.filter(x => x.ID != e);
          if (filteredTargetData) {
            for (var i = 0; i < filteredTargetData.length; i++) {
              if (this.texttargetFileTypes.filter(x => x === filteredTargetData[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
                this.SelectedTargetFiles.push(filteredTargetData[i]);
              }
            }
          }
        }
        
        if (changedDivID == "divTargetFiles") {
          this.SelectedSourceFiles = [];
            var filteredsrcData = this.fileRefDetails.filter(x => x.ID != e);
            if (filteredsrcData) {
            for (var i = 0; i < filteredsrcData.length; i++) {
              if (this.textsrcFileTypes.filter(x => x === filteredsrcData[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
                this.SelectedSourceFiles.push(filteredsrcData[i]);
              }
            }
          }
        }
      }
      else if (this.isComparsionValue == "Graphic") {
        if (changedDivID == "divSourceFiles") {

          this.SelectedTargetFiles = [];
          var filteredTargetData = this.fileRefDetails.filter(x => x.ID != e);
          if (filteredTargetData) {
            for (var i = 0; i < filteredTargetData.length; i++) {
              if (this.graphictargetFileTypes.filter(x => x === filteredTargetData[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
                this.SelectedTargetFiles.push(filteredTargetData[i]);
              }
            }
          }
        }
        if (changedDivID == "divTargetFiles") {
          this.SelectedSourceFiles = [];
          var filteredsrcData = this.fileRefDetails.filter(x => x.ID != e);
          if (filteredsrcData) {
            for (var i = 0; i < filteredsrcData.length; i++) {
              if (this.graphicsrcFileTypes.filter(x => x === filteredsrcData[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
                this.SelectedSourceFiles.push(filteredsrcData[i]);
              }
            }
          }
        }
      }
    }
    if (this.srcFileID > 0 && this.targetFileID > 0) {
      this.IscompareEnable = false;
    }
    else {
      this.IscompareEnable = true;
    }

  }

  CompareArtworkFiles() {
    var xmlContent;
    var a = document.getElementById("divSourceFiles");
 
    
    this.commonfeaturesservice.compareArtworkFiles(this.srcFileID, this.targetFileID, this.isComparsionType ).subscribe(
      (data) => {
        debugger;
        this.UniqueId = data;

        window.open(this.informCCURL + data, '_blank');
       this.CancelComparePopup();
      },
      error => {
        this.notifications.show(error.message, 4);
      }
    );
  
  }
  CancelComparePopup = function () {
    this.hideEvent.emit();
   
    this.SelectedSourceFiles = [];
    this.SelectedTargetFiles = [];
    this.IscompareEnable = true;
   
  }
  GetMessageByLanguage(aliasKey, orgMsg) {
    let tData = this.languageService.dataByLanguage.find(x => x.LanguageKey == aliasKey);
    if (tData)
      return tData.Message;
    else
      return orgMsg;
  }

  onChange(e: any, onChange: any) {
   
    this.targetFileID = 0;
    this.srcFileID = 0;
    
    this.compareForm.controls['sourceDdlValue'].setValue(null);
    this.compareForm.controls['targetDdlValue'].setValue(null);
    
    if (this.isFromReforDal == 2) {
      var details = (this.fileDetails.filter(x => x.displayName === 'FileName'));
      
      this.SelectedSourceFiles = [];
      this.SelectedTargetFiles = [];
      this.SelectedFiles = [];
      for (var i = 0; i < details.length; i++) {
        this.RefFileData = new compareFileDetails();
        this.RefFileData.ID = details[i].fileID;
        this.RefFileData.File = details[i].displayValue;
        this.RefFileData.fileType = "";
        this.SelectedFiles.push(this.RefFileData);
      }
      this.SelectedSourceFiles = this.SelectedFiles;
      this.SelectedTargetFiles = this.SelectedFiles;
    }
    else if (this.isFromReforDal == 1) {
      this.SelectedSourceFiles = [];
      this.SelectedTargetFiles = [];
      this.SelectedFiles = this.fileRefDetails;
      if (this.isComparsionValue == "Text") {
        for (var i = 0; i < this.fileRefDetails.length; i++) {
          if (this.textsrcFileTypes.filter(x => x === this.fileRefDetails[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
            this.SelectedSourceFiles.push(this.fileRefDetails[i]);
          }
        }


        for (var i = 0; i < this.fileRefDetails.length; i++) {
          if (this.texttargetFileTypes.filter(x => x === this.fileRefDetails[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
            this.SelectedTargetFiles.push(this.fileRefDetails[i]);
          }
        }
      }
      else if (this.isComparsionValue == "Graphic") {
        for (var i = 0; i < this.fileRefDetails.length; i++) {
          if (this.graphicsrcFileTypes.filter(x => x === this.fileRefDetails[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
            this.SelectedSourceFiles.push(this.fileRefDetails[i]);
          }
        }


        for (var i = 0; i < this.fileRefDetails.length; i++) {
          if (this.graphictargetFileTypes.filter(x => x === this.fileRefDetails[i].FileExtension.toLowerCase().replace(/[.]/g, '')).length > 0) {
            this.SelectedTargetFiles.push(this.fileRefDetails[i]);
          }
        }
      }
    }
    if (this.isFromReforDal == 2) {
      this.srcValidatemsg = this.GetMessageByLanguage("STC_TEXTTARGETTYPE", '');
      this.trgtValidatemsg = this.GetMessageByLanguage("STC_TEXTTARGETTYPE", '');
    }
    else {
      if (e.target.value == "Text") {
        this.srcValidatemsg = this.GetMessageByLanguage("STC_TEXTSOURCETYPE", '');
        this.trgtValidatemsg = this.GetMessageByLanguage("STC_TEXTTARGETTYPE", '');
      }
      else if (e.target.value == "Graphic") {
        this.srcValidatemsg = this.GetMessageByLanguage("STC_GRAPHICSOURCETYPE", '');
        this.trgtValidatemsg = this.GetMessageByLanguage("STC_GRAPHICTARGETTYPE", '');
      }
    }
    this.IscompareEnable = true;
  }
}

export class compareFileDetails {
  ID: string;
  File: string;
  fileType: string;
 
}

export class compareObj {
  srcFileID: number;

  srcCycleFilePath: string;
  srcCycleFileName: string;
  targetFileID: number;
  targetCycleFilePath: string;
  targetCycleFileName: string;
  projectId: string;
  subProcessId: string;
  srcFileGUID: string;
  targetFileGUID: string;
  compareType: string;
  isIncludeAnnFile: boolean ;
  srcCycleID: number;
  targetCycleID: number;
  token: string
  UserBase64info: string;
  isIncludeRefFile: boolean;
}


