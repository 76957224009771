import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GridDataResult,PageChangeEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalVeriables } from 'src/app/Global/GlobalVaribales';
import { LanguageService } from 'src/app/services/language.service';
import { ModuleService } from 'src/app/services/module.service';
import { NotificationAlertsService } from 'src/app/services/notification.service';
import { TaskOwnermodal } from '../dynamic-field-list/dynamic-field-list.component';

type NewType = Observable<GridDataResult>;

@Component({
  selector: 'app-dynamic-field-nested-grid-details',
  templateUrl: './dynamic-field-nested-grid-details.component.html',
  styleUrls: ['./dynamic-field-nested-grid-details.component.css']
})
export class DynamicFieldNestedGridDetailsComponent implements OnInit, AfterViewInit {

  /**
   * The category for which details are displayed
   */
   @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  @Input() public childGridDataSource: any;
  @Input() public MainDataSource: any;
  @Input() public allcheckboxChecked:any
  @Input() public department:any
   @Output() assingnusers = new EventEmitter<boolean>();
   @Input() public recordID: any;
   selectedTaskItems: any = [];
   taskOwnermodal = new TaskOwnermodal();
   showAssignmentTaskOwnerPopUp:boolean = false;
   frmsubmitted:boolean=false;
   showLoader:boolean = false;
   dstaskOwnerGroupList:any;
   dstaskOwnerList:any;
   public languageDataDefault : any;
   IpAddress:string;
   taskOwnerGroupSelectedValue: any;
  taskOwnerSelectedValue: any;
  assignUsersVisible: boolean = true;
  IsProjectAccess: boolean  = false;
   GroupDyForm = this.formbuilder.group({
    Groups: ['', Validators.required],
   Users: ['']
    // Users: ['', Validators.required]
  });
   constructor(private notifyService: NotificationAlertsService, private formbuilder: FormBuilder,
    private moduleService:ModuleService,private GV: GlobalVeriables, private languageService:LanguageService ) {}
 
   public ngOnInit(): void {




       this.IpAddress = this.GV.UserIpAddress;
     
   
    
    this.languageDataDefault=this.languageService.dataByLanguage;
     this.GV.UserRoleID = +localStorage.getItem("userRoleID");
     //if (this.childGridDataSource.filter(ta => ta.workFlowStatusId == 276).length == this.childGridDataSource.length || this.childGridDataSource.filter(ta => ta.workFlowStatusId == 277).length == this.childGridDataSource.length
     //  || this.childGridDataSource.filter(ta => ta.workFlowStatusId == 281))
     if ((this.childGridDataSource.filter(ta => ta.workFlowStatusId == 276).length == this.childGridDataSource.length) || (this.childGridDataSource.filter(ta => ta.workFlowStatusId == 277).length == this.childGridDataSource.length)
       || (this.childGridDataSource.filter(ta => ta.workFlowStatusId == 281).length == this.childGridDataSource.length))
     {
       this.assignUsersVisible = false;
     }
    
    if(this.childGridDataSource.filter(ta=>ta.workFlowStatusId ==274).length==0 && this.childGridDataSource.filter(ta=>ta.workFlowStatusId ==275).length==0
    && this.childGridDataSource.filter(ta=>ta.workFlowStatusId ==279).length==0)
    {
      var elementAll = <HTMLInputElement>document.getElementById("chkDeptSelectALL" + this.childGridDataSource[0].departmentID);
        if(elementAll !=null){
          elementAll.checked = false;
          elementAll.hidden = true;
        }
    }
    else{
      var checkCheckboxesIds=[];
      this.childGridDataSource.forEach(function (value) {
      if(checkCheckboxesIds.filter(val=>val.taskId==value.taskId && val.subProcessID==value.subProcessID).length==0 && (value.workFlowStatusId==274 || value.workFlowStatusId==275 || value.workFlowStatusId==279) && !value.claimTaskStatus)
      {
        checkCheckboxesIds.push(value);
      }
      else{
        var elementAll = <HTMLInputElement>document.getElementById("chkDeptSelectALL" + value.departmentID);
        if(elementAll !=null){
          elementAll.checked = false;
          //elementAll.disabled = true;
          return false;
        }
      }
        })
    }
  }
  ngAfterViewInit() {
    if (this.allcheckboxChecked) {
      if (this.department == this.childGridDataSource[0].departmentID) {
        this.childGridDataSource.forEach(function (value) {
          var element = <HTMLInputElement>document.getElementById("chkDeptradio" + value.departmentID + value.processDataID);
          if (element != null) {
            element.checked = true;
          }
        });
      }
    }
    else {
      this.childGridDataSource.forEach(function (value) {
        var element = <HTMLInputElement>document.getElementById("chkDeptradio" + value.departmentID + value.processDataID);
        if (element != null) {
          element.checked = false;
        }
      });
    }
  }
  BindAssigneUsers() {
    debugger;
    this.moduleService.CheckForProjectAccess(this.recordID, 0).subscribe(projectData => {
      if (projectData == false) {
        this.IsProjectAccess = projectData;
        this.notifyService.show(this.GetMessageByLanguage('STC_PNATU', "Project was not assigned to the user. you can't change the project assigned users."), 4);
        return;
      }
      else{
        this.moduleService.GetDynamicProjectStatus(this.recordID, 1).subscribe(data => {
          if (data != 473 && data != 2737) {
            let selectedTaskItems = [];
            this.childGridDataSource.forEach(function (value) {
              var element = <HTMLInputElement>document.getElementById("chkDeptradio" + value.departmentID + value.processDataID);
              if (element != null) {
                if (element.checked) {
                  selectedTaskItems.push(parseInt(value.processDataID));
                }
              }
            });
            if (selectedTaskItems.length == 0) {
              this.notifyService.show(this.GetMessageByLanguage('STC_SAOT', "select atleast one task"), 4);
              return;
            }
            else {
              this.selectedTaskItems = selectedTaskItems
              this.bindTaskOwnerInputData();
            }
          }
          else
            this.notifyService.show(this.GetMessageByLanguage('STC_PCNCAU', "Project was completed. you can't change the project assigned users."), 4);
        })
      }
    })
  }

  SaveTaskOwner() {


      this.IpAddress = this.GV.UserIpAddress;
   
    if (this.GroupDyForm.invalid) {
      this.frmsubmitted = true;
      return;
    }
    if(this.selectedTaskItems.length==0)
    {
      this.notifyService.show(this.GetMessageByLanguage('STC_SAOT', "select atleast one task"),4);
      return;
    }
    this.taskOwnermodal = new TaskOwnermodal();
    this.taskOwnermodal.moduleType="Assignements";
    this.taskOwnermodal.lstProcessDataIDs = this.selectedTaskItems.toString();
    this.taskOwnermodal.GroupId = +this.GroupDyForm.controls['Groups'].value;
    this.taskOwnermodal.UserId = +this.GroupDyForm.controls['Users'].value;
    this.taskOwnermodal.CurrentLogin_UserID = +this.GV.UserId;
    this.taskOwnermodal.Ip=this.IpAddress
    this.showAssignmentTaskOwnerPopUp = false;
    this.showLoader = true;
    this.moduleService.SaveProjectTaskOwnerGroup(this.taskOwnermodal).subscribe(data => {
      if (data == "success") {
        this.checkboxes.forEach((element) => {
          element.nativeElement.checked = false;
        });
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_TOUS', "Task Owner has been Updated successfully"));
        this.assingnusers.emit(true);
      }
      else{
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('AMSUAT_', "Selected User is already assigend to the task"));
      }
    },
      error => {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_TOUF',"Task Owner updated failed !!. ") + error.statusText, 4);
        //console.log(error);
      })

 
  }
  CancelTaskOwner() {
    this.showAssignmentTaskOwnerPopUp = false;
  }
  bindTaskOwnerInputData()
  {
    this.showLoader=true;
    this.moduleService.BindTaskOwnerInputData(0,this.GV.UserId,this.selectedTaskItems,"Assignements",0).subscribe(data => {
      this.dstaskOwnerGroupList=data.groupList;
      //this.languageService.TranslateDefaultItem(response.dynamicFormFieldList,this.languageDataDefault);  
      this.dstaskOwnerList=data.groupUsersList;
      setTimeout(() => { 
      if(data.groupId !=null && data.groupId !="" && data.groupId !=undefined){
      this.GroupDyForm.controls['Groups'].setValue(data.groupId.toString());
      this.taskOwnerGroupSelectedValue=data.groupId.toString();
      }
      else{ this.GroupDyForm.controls['Groups'].setValue(""); this.taskOwnerGroupSelectedValue=""}
      if(data.userId !=null && data.userId !="" && data.userId !=undefined){
      this.GroupDyForm.controls['Users'].setValue(data.userId.toString());
      this.taskOwnerSelectedValue=data.userId.toString();
      }
      else{this.GroupDyForm.controls['Users'].setValue("");this.taskOwnerSelectedValue="";}
      this.showLoader=false;
      this.showAssignmentTaskOwnerPopUp=true;
      this.showLoader=false;
    }, 1000);
      
    },
      error => {
        this.showLoader = false;
        this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
        //console.log(error);
      }
    )
  }
  bindTaskOwnerGroupUsers(value: any) {
    this.frmsubmitted = false;
    this.dstaskOwnerList = [];
    this.taskOwnerSelectedValue = "";
    if (value != null && value != undefined && value != "") {
      this.showLoader=true;
      this.moduleService.GetTaskOwnerGroupUsers(2, value, this.GV.UserId,this.selectedTaskItems).subscribe(data => {
        this.dstaskOwnerList = data;
      this.showLoader=false;

      },
        error => {
          this.showLoader = false;
          this.notifyService.show(this.GetMessageByLanguage('STC_SWW', "Something went wrong. Try again !!. ") + error.statusText, 4);
          //console.log(error);
        })
    }
  }
  onTaskCheck(DeptId:number,processDataID:number) {
        let taskExist=false;
        var checkBoxCheckIDs = []
        var checkBoxCheckTaskIds = []
        this.childGridDataSource.forEach(function (value) {
           var element = <HTMLInputElement>document.getElementById("chkDeptradio" + DeptId + value.processDataID);
              if (element != null) 
              {
                if (element.checked) 
                {
                 if(checkBoxCheckTaskIds.filter(val=>val.taskId==value.taskId && val.subProcessID==value.subProcessID).length==0)
                 {
                   checkBoxCheckIDs.push(parseInt(value.processDataID));
                   checkBoxCheckTaskIds.push(value);
                 } 
                 else
                 taskExist=true;
                 }
              }
        });
        if(taskExist)
        {
          var element = <HTMLInputElement>document.getElementById("chkDeptradio" + DeptId + processDataID);
          element.checked=false;
          this.notifyService.show("you can't assigne same user for same tasks",4);
          return false;
        }
        var elementAll = <HTMLInputElement>document.getElementById("chkDeptSelectALL" + DeptId);
        if(elementAll !=null){
        if(checkBoxCheckIDs.length==this.childGridDataSource.length)
            elementAll.checked = true;
        else
          elementAll.checked = false;
    }

    //for

    //chkDeptSelectALL - 1

    var mainDataSource = this.MainDataSource;


    mainDataSource.forEach(function (deptSource) {
      if (deptSource.departmentID == DeptId) {
       
      }
      else {
        var defelementAll = <HTMLInputElement>document.getElementById("chkDeptSelectALL" + deptSource.departmentID);
        defelementAll.checked = false;
        deptSource.taskList.forEach(function (value) {
          var element = <HTMLInputElement>document.getElementById("chkDeptradio" + deptSource.departmentID + value.processDataID);
          if (element != null)
            element.checked = false;
        });
      }
    })


    

      }
  GetMessageByLanguage(aliasKey, orgMsg){
        let tData=this.languageDataDefault.find(x=>x.LanguageKey==aliasKey);
        if(tData)
          return tData.Message;
        else
          return orgMsg;
      }
}
