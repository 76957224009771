import { Injectable } from '@angular/core';
import { GlobalVeriables } from '../Global/GlobalVaribales';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { getBaseUrl } from '../../main';
import { searchFilter, templateSelectedRecord } from '../ArtworkManagement/dynamic-fields/dynamic-field-list/dynamic-field-list.component';
import { automation, breadCrumbs, cascadingField, templateData, templateDataRecord } from '../interfaces/ItemplateFields';
import { ACMIDetails } from './AMFormsDataDetails';
import { LoaderService } from './Loader/loader.service';
import { publicIpv4 } from 'public-ip';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  SettingData = require("../../../../appsettings.json");
  enableSSO = this.SettingData["enableSSO"];
  private CHECKHASDEPENDENTS = getBaseUrl() + "api/dynamic/CheckHasDependent";
  private CHECKISDEPENDENTS = getBaseUrl() + "api/dynamic/CheckIsDependent";

  private GETTOOGLEACTIONSBYMODULE = getBaseUrl() + "api/dynamic/GetToogleActionsByModule";

  private CHECKISPARENTTEMPLATE = getBaseUrl() + "api/dynamic/CheckIsParentTemplate";
  private GETTREEDATA = getBaseUrl() + "api/dynamic/GetTreeData";
  private GETFILTERSBYTEMPLATEID = getBaseUrl() + "api/dynamic/GetFiltersByTemplateID";


  private GETDISPLAYFIELD = getBaseUrl() + "api/dynamic/GetDisplayFieldData";
  private GetProjectTasks = getBaseUrl() + "api/dynamic/GetProjectTasks";
  private GetTaskCount = getBaseUrl() + "api/dynamic/GetTaskCount";
  private DISPLAYVIEW = getBaseUrl() + "api/dynamic/DisplayView";
  private DISPLAYTABLEVIEW = getBaseUrl() + "api/dynamic/DisplayTableView";
  private TEMPLATESBYTIDFORSPLITTER = getBaseUrl() + "api/dynamic/GetTemplatesByTemplatIDForSplitter";

  private SUBTASKVIEW = getBaseUrl() + "api/dynamic/GetSubProcessWorkflowIdentifer";

  //private GetPagesData = getBaseUrl() + "api/dynamic/GetPagesData";
  private TaskDISPLAYVIEW = getBaseUrl() + "api/dynamic/GetSubProcessTaskActionList";
  private TaskDISPLAYControllVIEW = getBaseUrl() + "api/dynamic/GetSubProcessTaskFeilds";
  private ByPassTaskDisplay = getBaseUrl() + "api/dynamic/GetTaskByPassFeilds"
  private TasksuspendResumecycleFeilds = getBaseUrl() + "api/dynamic/GetTasksuspendResumecycleFeilds"
  private UpdateProjectSubTask = getBaseUrl() + "api/dynamic/UpdateProjectSubTaskData";
  private TaskByPass = getBaseUrl() + "api/dynamic/TaskByPass";
  private BypassedTaskList = getBaseUrl() + "api/dynamic/GetBypassedTaskList";
  private MandatoryStatuschangedURL = getBaseUrl() + "api/dynamic/IsMandatoryStatuschanged";
  private DeleteUploadArWorkFile = getBaseUrl() + "api/dynamic/DeleteArtWorkFile";
  private DELETEDISPLAYFIELDRECORD = getBaseUrl() + "api/dynamic/DeleteDisplayFieldRecord/";
  private DeleteORInactiveReferencefiles = getBaseUrl() + "api/dynamic/DeleteReferenceFileRecord";

  private DELETESAVEDSEARCH = getBaseUrl() + "api/dynamic/DeleteSavedSearch";
  private ConvertAITOPDF = getBaseUrl() + "api/dynamic/ConvertAITOPDFView";
  private DISPLAYVIEWSUBPROCESS = getBaseUrl() + "api/dynamic/DisplayViewSubProcess";



  private TEMPLATES = getBaseUrl() + "api/dynamic/GetTemplatesByModuleID";
  private ALLTEMPLATES = getBaseUrl() + "api/dynamic/GetAllTemplatesByModuleID";

  private TEMPLATESBYTID = getBaseUrl() + "api/dynamic/GetTemplatesByTemplatID";


  private CONTROLTEMPLATES = getBaseUrl() + "api/dynamic/GetControlTemplatesByModuleID";
  private CONTROLACTIOSBYTEMPLATE = getBaseUrl() + "api/dynamic/GetControlTemplatesByTemplateID";
  private ProjectStart = getBaseUrl() + "api/dynamic/IsProjectStart";

  private MODULES = getBaseUrl() + "api/dynamic/GetModules/";
  private MASTERMODULES = getBaseUrl() + "api/dynamic/GetMasterModules";
  private GETCLASSNAME = getBaseUrl() + "api/dynamic/GetClassName";
  private GETLEGENDS = getBaseUrl() + "api/dynamic/GetLegends";


  private GETRELATEDPROJECTS = getBaseUrl() + "api/dynamic/GetRelatedProjectByProjectId";
  private DELETERELATEDPROJECTS = getBaseUrl() + "api/dynamic/DeleteRelatedProjectByProjectId";
  private GETALLPROJECTS = getBaseUrl() + "api/dynamic/GetAllProjects";
  private SAVERELATEDPROJETS = getBaseUrl() + "api/dynamic/SaverelatedProjects";
  private SAVEMULTITASKBYPASSCOMMENTS = getBaseUrl() + "api/dynamic/SaveMultiTaskByPassComments";
  private SAVETASKBYPASSPROJETS = getBaseUrl() + "api/dynamic/SaveTaskByPassComments";
  private TaskResumeSuspendCycle = getBaseUrl() + "api/dynamic/TaskSuspendAndResumeCycleData";
  private TaskCancelCycle = getBaseUrl() + "api/dynamic/TaskCancelCycleData";
  private GETUSERSFORBYPASS = getBaseUrl() + "api/dynamic/GetUsersForBypassNotificationUsers"
  private GETBYPASSUNBYPASSWORKFLOWTASKS = getBaseUrl() + "api/dynamic/BypassUnBypassWorkFlowTasks";
  private GETBYPASSTASKSBYWORKFLOW = getBaseUrl() + "api/dynamic/GetBypassTasksByWorkflow";
  //private GetCurrentTaskStatus = getBaseUrl() + "api/artworkreview/GetTaskStatus";
  private GetCurrentTaskStatus = getBaseUrl() + "api/artworkreview/GetTaskWorkFlowStatus";
  private DownloadPdfFileUrl = getBaseUrl() + "api/dynamic/DownloadPDFFile";
  private DownloadFileUrl = getBaseUrl() + "api/Aws/DownLoad";
  private PreviewPDF = getBaseUrl() + "api/Aws/PreviewPDF";

  private DownloadAllArtWorkFilesUrl = getBaseUrl() + "api/dynamic/DownloadAllArtWorkFiles";
  private DownloadDALFilesUrl = getBaseUrl() + "api/dynamic/DownloadDALFiles";

  //private REMOVEUPLODFILES = getBaseUrl() + "api/dynamic/RemoveDocument";
  private REMOVEUPLODFILES = getBaseUrl() + 'api/dynamic/DynACUR?loginUserId=' + this.GV.UserId;
  private GetDynamicProjectStatusURL = getBaseUrl() + "api/dynamic/GetDYnamicProjectStatus";
  private GetPrjectAccess = getBaseUrl() + "api/dynamic/CheckForProjectAccess";
  private GetProjectUserStatusURL = getBaseUrl() + "api/dynamic/GetProjectUserStatus";
  private GetDynamicAuditStatusURL = getBaseUrl() + "api/dynamic/GetDYnamicAuditStatus";
  private BindTaskOwnerDataSourceURL = getBaseUrl() + "api/dynamic/BindTaskOwnerDataSource";
  private GetTaskOwnerGroupUsersURL = getBaseUrl() + "api/dynamic/GetTaskOwnerGroupUsers";
  private SaveProjectTaskOwnerGroupURL = getBaseUrl() + "api/dynamic/SaveProjectTaskOwnerGroup";
  private SaveProjectTaskOwnerGroupUsersURL = getBaseUrl() + "api/dynamic/SaveProjectTaskOwnerGroupUsers";
  private GetProjectAssigmentsURL = getBaseUrl() + "api/dynamic/GetProjectAssigments";
  private ProjectTaskOwnerGroupsUsers = getBaseUrl() + "api/dynamic/GetProjectTaskOwnerGroupsUsersData";
  private GetProjecGroupUsersURL = getBaseUrl() + "api/dynamic/GetAssignGroupsUsersDetails";

  private SaveProjectTaskAddApproversURL = getBaseUrl() + "api/dynamic/SaveProjectTaskAddApprovers";

  private GETDALFILECOLLECTIONS = getBaseUrl() + "api/dynamic/GetDALFileCollections";
  private GETEsign = getBaseUrl() + "api/Account/CheckEsign";
  private GETTEMPLATESAVEDSEARCH = getBaseUrl() + "api/dynamic/GetSavedSearch";
  private UPDATEMODIFIEDDATEFORDYNAMICFORMS = getBaseUrl() + "api/dynamic/UpdateModifiedDateforDynamicforms";

  private GETSEARCHFORMFIELDIDS = getBaseUrl() + "api/dynamic/GetSearchFormFieldIds";
  private GETCASCADINGDATA = getBaseUrl() + "api/dynamic/GetCascadingData";
  private GETIP = getBaseUrl() + "api/dynamic/GetIPAddress";
  private GETCASCADINGDATAFORFORMFIELDS = getBaseUrl() + "api/dynamic/GetCascadingDataForFormFields";
  private GETDEPENDENTFIELDS = getBaseUrl() + "api/dynamic/GetDependentFields";


  private saveorupdateAcmi = getBaseUrl() + "api/dynamic/SaveOrUpdate";
  private Getmaterialdetails = getBaseUrl() + "api/dynamic/Getmaterialdetails";
  private ADDDALFILECOLLECTION = getBaseUrl() + "api/dynamic/AddDALFileCollection";
  private REMOVEFILECOLLECTION = getBaseUrl() + "api/dynamic/RemoveFileCollection";


  private REMOVEFROMFILECOLLECTION = getBaseUrl() + "api/dynamic/RemoveFromFileCollection";
  private GetDalVersion = getBaseUrl() + "api/dynamic/GetDALVersion";
  private GETDALREFERENCES = getBaseUrl() + "api/dynamic/GetDalReference";

  private GetDalProjectReference = getBaseUrl() + "api/dynamic/GetDalProjectReference";


  private SAVESEARCHNAME = getBaseUrl() + "api/dynamic/SaveSearchName";
  private CREATESUBPROCESS = getBaseUrl() + "api/dynamic/CreateSubProcess";
  private CHECKINPROGRESSPROJECT = getBaseUrl() + "api/dynamic/checkInProgressProject";

  //private CHECKINPROGRESSPROJECT = getBaseUrl() + "api/dynamic/checkInProgressProject";

  private GETPROJECTANDSUBPROCESS = getBaseUrl() + "api/dynamic/GetProjectandSubprocess";
  private GETSOURCEDATA = getBaseUrl() + "api/dynamic/GetSourceData";
  private GETSOURCEDATAPROJECTANDUSERID = getBaseUrl() + "api/dynamic/GetSourceDataProjectandUserId";
  private UPDATESOURCEDATAPROJECTSUBPROCESSANDUSERID = getBaseUrl() + "api/dynamic/UpdateSourceDataProjectSubProcessandUserId";
  private GETAUTOMATIONAPIINPUTDATA = getBaseUrl() + "api/dynamic/GetAutomationAPIInputData";
  private GETCCMINPUTDATA = getBaseUrl() + "api/dynamic/GetCCMInputsMasterDataByComponent";
  private GETWORKFLOWDIAGRAMDATABYPROJECTID = getBaseUrl() + "api/dynamic/GetWorkFlowDiagramDataByProjectID";
  private GETCCMCOMPONENTS = getBaseUrl() + "api/dynamic/GetAutomationRequestComponents";
  private GETCCMLANGUAGES = getBaseUrl() + "api/dynamic/GetCCMLanguages";
  private GETCCMINPUTTASKDETAILS = getBaseUrl() + "api/dynamic/GetCCMInputSubProcessTaskDetails";
  private GETAUTOMATIONFILEDETAILS = getBaseUrl() + "api/dynamic/GetAutomationFileDetails";
  private UPDATEANNOTATIONSTATUS = getBaseUrl() + "api/dynamic/UpdateAnnotationRequestStatus";
  private GETCCMINPUTSUBPROCESSTASKFIELDS = getBaseUrl() + "api/dynamic/GetCCMInputSubProcessTaskFeilds";
  private CHECKCCMINPUTANDDOCUPLOAD = getBaseUrl() + "api/dynamic/CheckCCMInputAndDocUpload";
  private SENDEMAILTOFRA = getBaseUrl() + "api/dynamic/SendEmailToFRA";
  private CALCULATECOMPLETEDATE = getBaseUrl() + "api/dynamic/CalculateCompleteDate";

  private PublishFileDownload = getBaseUrl() + "api/dynamic/DownloadPublishfile";
  private GetcurrentGridfileDetails = getBaseUrl() + "api/dynamic/currentGridfileDetails";
  //private GETGAATESLANGUAGEMAPPING =getBaseUrl() + "api/dynamic/GetGaatesLanguageMapping";
  private GetPrjectTaskStatus = getBaseUrl() + "api/dynamic/CheckForQATaskStatus";

  private GetProductsvalues = getBaseUrl() + "api/dynamic/GetProductsvalues";

  constructor(private httpClient: HttpClient, private GV: GlobalVeriables, private loader: LoaderService, private datepipe: DatePipe) { }

  loadTask = new Subject();

  passLoadTask(data: any) {

    this.loadTask.next(data);

  }

  public getModules(moduleID: number) {

    return this.httpClient.get<any>(this.MODULES + moduleID);
  }
  public saveorupdate(MaterialsDetails: ACMIDetails) {
    return this.httpClient.post(this.saveorupdateAcmi, MaterialsDetails);
  }
  public getMasterModules() {

    return this.httpClient.get(this.MASTERMODULES);
  }
  public getToogleActionsByModule(moduleID: number) {

    let params = new HttpParams();
    if (Number.isNaN(moduleID)) moduleID = 1;
    params = params.set('ModuleID', moduleID.toString());

    return this.httpClient.get<any>(this.GETTOOGLEACTIONSBYMODULE, { params: params });


  }
  public GetMaterialDetails(RecordID) {
    let params = new HttpParams();
    params = params.set('ProjectID', RecordID.toString());

    return this.httpClient.get<any>(this.Getmaterialdetails, { params: params });
  }

  public GetTaskCounts(searchFilter: string, keyWordSearch: string, chkFields: string, filter: any) {

    var filterConfigKey = localStorage.getItem("filterConfigKey");
    var userId = localStorage.getItem("userId");
    if (filterConfigKey == "1") {
      userId = "0";
    }


    let params = new HttpParams();
    let headers = new Headers();



    headers.append('Access-Control-Allow-Origin', '*');



    params = params.set('searchFilters', searchFilter.toString());

    var method = "?searchFilters=&userid=" + userId.toString();
    const body = {
      'searchFilters': searchFilter.toString(),
      'chkFields': chkFields.toString(),
      'keyWordSearch': keyWordSearch.toString(),
      'filter': JSON.stringify(filter)
    };



    return this.httpClient.post<any>(this.GetTaskCount + method, body, { headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*') });







  }

  public getDalFileCollections(UserID: number) {
    let params = new HttpParams();
    params = params.set('UserID', UserID.toString());
    return this.httpClient.get<any>(this.GETDALFILECOLLECTIONS, { params: params });
  }

  public GetTemplatesByTemplateIDForSplitter(template: templateData) {


    let params = new HttpParams();
    params = params.set('moduleID', template.moduleID.toString());
    if (template.templateID != null)
      params = params.set('templateID', template.templateID.toString());
    else
      params = params.set('templateID', 0);

    params = params.set('parentTemplateID', template.parentTemplateID.toString());
    params = params.set('parentRecordID', template.parentRecordID.toString());
    params = params.set('dependentTemplateID', template.dependentTemplateID.toString());
    params = params.set('dependentRecordID', template.dependentRecordID.toString());
    params = params.set('userID', this.GV.UserId);
    if (template.tabTemplateID != null)
      params = params.set('tabTemplateID', template.tabTemplateID.toString());
    else
      params = params.set('tabTemplateID', 0);
    return this.httpClient.get<any>(this.TEMPLATESBYTIDFORSPLITTER, { params: params });
  }
  public getesignstatus(username: string, password: string, comments: string, type: string, id: number, Ip: string) {
    let params = new HttpParams();
    params = params.set('UserName', username.toString());
    params = params.set('Password', password.toString());
    params = params.set('Comments', comments.toString());
    params = params.set('Type', type.toString());
    params = params.set('ID', id.toString());
    params = params.set('Ip', this.GV.UserIpAddress.toString());

    return this.httpClient.get<any>(this.GETEsign, { params: params });
  }
  public GetSavedSearch(TemplateID: string, UserID: number): Observable<any> {

    let params = new HttpParams();
    params = params.set('TemplateID', TemplateID.toString());
    params = params.set('UserID', UserID.toString());

    return this.httpClient.get(this.GETTEMPLATESAVEDSEARCH, { params: params });
  }


  public UpdateModifiedDateForDynamicForms(ID: string): Observable<any> {

    let params = new HttpParams();
    params = params.set('ID', ID.toString());
    return this.httpClient.get(this.UPDATEMODIFIEDDATEFORDYNAMICFORMS, { params: params });
  }

  public GetFiltersByTemplateID(TemplateID: string, DisplayControlID: string): Observable<any> {

    let params = new HttpParams();
    params = params.set('templateID', parseInt(TemplateID));
    params = params.set('displayControlId', parseInt(DisplayControlID));
    return this.httpClient.get(this.GETFILTERSBYTEMPLATEID, { params: params });
  }


  public GetSearchFormFieldIds(TemplateID: string) {

    let params = new HttpParams();
    params = params.set('TemplateID', TemplateID.toString());
    return this.httpClient.get(this.GETSEARCHFORMFIELDIDS, { params: params });
  }


  public GetCascadingData(TemplateID: string, FieldId: string, SelectedValues: string) {

    let params = new HttpParams();
    params = params.set('TemplateID', TemplateID.toString());
    params = params.set('FieldId', FieldId.toString());
    params = params.set('SelectedValues', SelectedValues.toString());

    return this.httpClient.get(this.GETCASCADINGDATA, { params: params });

  }

  public GetCascadingDataForFormFields(TemplateID: string, FieldId: string, SelectedValues: string, selectedAllValues: string) {

    let params = new HttpParams();
    let headers = new Headers();

    headers.append('Access-Control-Allow-Origin', '*');
    // res.header('Access-Control-Allow-Origin', '*');



    params = params.set('TemplateID', TemplateID.toString());
    params = params.set('FieldId', FieldId.toString());
    params = params.set('SelectedValues', SelectedValues.toString());
    params = params.set('selectedAllValues', selectedAllValues.toString());
    var method = "?TemplateID=" + TemplateID.toString() + "&FieldId=" + FieldId.toString() + "&SelectedValues=" + SelectedValues.toString() + "&selectedAllValues=";
    const body = { 'selectedAllValues': selectedAllValues.toString() };

    return this.httpClient.post<any>(this.GETCASCADINGDATAFORFORMFIELDS + method, body, { headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*') });


    //return this.httpClient.get(this.GETCASCADINGDATAFORFORMFIELDS, { params: params });

  }

  public GetDependentFields(TemplateID: string, FieldId: string, SelectedValues: string) {

    let params = new HttpParams();
    params = params.set('TemplateID', TemplateID.toString());
    params = params.set('FieldId', FieldId.toString());
    params = params.set('SelectedValues', SelectedValues.toString());

    return this.httpClient.get(this.GETDEPENDENTFIELDS, { params: params });

  }

  public async getIPAddress() {
    return await publicIpv4();
  }

  public getip() {
    // return this.httpClient.get("https://ifconfig.co/ip"); 
    return this.httpClient.get(this.GETIP);
  }



  public isProjectStart(projectid: number) {
    let params = new HttpParams();
    params = params.set('ProjectID', projectid.toString());
    return this.httpClient.get<any>(this.ProjectStart, { params: params });
  }
  public AddDALFileCollection(fileCollectionName: string, fileCollectionID: string, documentRepositoryID: string, Ip: string, UserID) {

    let params = new HttpParams();
    params = params.set('fileCollectionName', fileCollectionName.toString());
    params = params.set('fileCollectionID', fileCollectionID.toString());
    params = params.set('documentRepositoryID', documentRepositoryID.toString());
    params = params.set('Ip', this.GV.UserIpAddress.toString());
    params = params.set('UserID', UserID.toString());
    return this.httpClient.get<any>(this.ADDDALFILECOLLECTION, { params: params });
  }

  public RemoveFileCollection(fileCollectionID: string, Ip: string, UserID) {

    let params = new HttpParams();
    params = params.set('fileCollectionID', fileCollectionID.toString());
    params = params.set('Ip', this.GV.UserIpAddress.toString());
    params = params.set('UserID', UserID.toString());
    return this.httpClient.get<any>(this.REMOVEFILECOLLECTION, { params: params });
  }


  public RemoveFromFileCollection(fileCollectionID: string, documentRepositoryID: string, Ip: string, UserID) {

    let params = new HttpParams();
    params = params.set('fileCollectionID', fileCollectionID.toString());
    params = params.set('documentRepositoryID', documentRepositoryID.toString());
    params = params.set('Ip', this.GV.UserIpAddress.toString());
    params = params.set('UserID', UserID.toString());
    return this.httpClient.get<any>(this.REMOVEFROMFILECOLLECTION, { params: params });
  }

  public getDalVersion(RecordID: number) {

    let params = new HttpParams();
    params = params.set('RecordID', RecordID.toString());
    return this.httpClient.get<any>(this.GetDalVersion, { params: params });
  }
  public GetDalReferences(RecordID: number) {

    let params = new HttpParams();
    params = params.set('RecordID', RecordID.toString());
    return this.httpClient.get<any>(this.GETDALREFERENCES, { params: params });
  }



  public getDalProjectReference(RecordID: number) {

    let params = new HttpParams();
    params = params.set('RecordID', RecordID.toString());
    return this.httpClient.get<any>(this.GetDalProjectReference, { params: params });
  }


  public SaveSearchName(SearchName: string, TemplateID: string, defaultSearch: boolean, SearchFilter: string, SelectedSearch: number, UserID: number, defaultsearchValues: any) {

    let params = new HttpParams();
    params = params.set('SearchName', SearchName.toString());
    params = params.set('TemplateID', TemplateID.toString());
    params = params.set('SearchFilter', SearchFilter.toString());
    params = params.set('SelectedSearch', SelectedSearch.toString());
    params = params.set('defaultSearch', defaultSearch)
    params = params.set('UserID', UserID.toString());
    params = params.set('defaultSearchValues', defaultsearchValues.toString());

    return this.httpClient.get<any>(this.SAVESEARCHNAME, { params: params });
  }

  public CreateSubProcess(ProjectID: string, fileCollectionIDs: string, files: string, UserId: number, IP: string) {

    let params = new HttpParams();
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('fileCollectionIDs', fileCollectionIDs.toString());
    params = params.set('files', files.toString());
    params = params.set('UserID', UserId.toString());
    params = params.set('IP', this.GV.UserIpAddress.toString());

    return this.httpClient.get<any>(this.CREATESUBPROCESS, { params: params });
  }



  public CheckInprogressProject(fileCollectionIDs: string) {
    let params = new HttpParams();
    params = params.set('fileCollectionIDs', fileCollectionIDs.toString());

    return this.httpClient.get<any>(this.CHECKINPROGRESSPROJECT, { params: params });
  }



  public GetProjectTaskList(projectid: number) {

    let params = new HttpParams();
    params = params.set('ProjectID', projectid.toString());
    return this.httpClient.get<any>(this.GetProjectTasks, { params: params });
  }
  public getRelatedProjectByProjectId(projectid: number) {

    let params = new HttpParams();
    params = params.set('ProjectID', projectid.toString());
    return this.httpClient.get<any>(this.GETRELATEDPROJECTS, { params: params });
  }
  public getProjectByselectedProjectId(projectid: number, type: string) {

    let params = new HttpParams();
    params = params.set('ProjectID', projectid.toString());
    params = params.set('type', type.toString());
    return this.httpClient.get<any>(this.GETRELATEDPROJECTS, { params: params });
  }

  public GetDynamicProjectStatus(projectId: number, Type: number) {

    let params = new HttpParams();
    params = params.set('ID', projectId.toString());
    params = params.set('TypeID', Type.toString());
    return this.httpClient.get<any>(this.GetDynamicProjectStatusURL, { params: params });
  }
  public CheckForProjectAccess(projectId: number, moduleId: number) {
    let params = new HttpParams();

    params = params.set('ProjectId', projectId.toString());
    params = params.set('ModuleId', moduleId.toString());

    params = params.set('ModuleId', moduleId.toString());

    params = params.set('UserId', localStorage.getItem("userId"));

    return this.httpClient.get<any>(this.GetPrjectAccess, { params: params });

  }

  public GetProjectIntitorIsorNot(projectId: number, Type: number) {

    let params = new HttpParams();
    params = params.set('ID', projectId.toString());
    params = params.set('TypeID', Type.toString());
    return this.httpClient.get<any>(this.GetProjectUserStatusURL, { params: params });
  }
  public GetDynamicAuditStatus(projectId: number, Type: number) {

    let params = new HttpParams();
    params = params.set('ID', projectId.toString());
    params = params.set('TypeID', Type.toString());
    return this.httpClient.get<any>(this.GetDynamicAuditStatusURL, { params: params });
  }
  public getAllProjetcs(projectId: number) {
    let params = new HttpParams();
    params = params.set('ProjectID', projectId.toString());
    return this.httpClient.get<any>(this.GETALLPROJECTS, { params: params });
  }
  public GetWorkFlowDiagramDataByProjectID(projectId: number, UserID: number, subProcessId: number) {
    let params = new HttpParams();
    params = params.set('ProjectID', projectId.toString());
    params = params.set('UserID', UserID.toString());
    params = params.set('subProcessID', subProcessId)
    return this.httpClient.get<any>(this.GETWORKFLOWDIAGRAMDATABYPROJECTID, { params: params });
  }

  saveRelatedProjects(dataobj: any, projectid: number, userId: any, ip: string) {
    let RelProject: any = {};
    RelProject.relatedProjects=[];
    for(let i=0;i<dataobj.length;i++)
    {
    RelProject.relatedProjects.push({
      ProjectId:(dataobj[i].projectId==undefined ? dataobj[i] : dataobj[i].projectId).toString()
    });
  }
    RelProject.projectID = (projectid);
    RelProject.UserId = userId.toString();
    RelProject.Ip = ip;
    return this.httpClient.post(this.SAVERELATEDPROJETS, RelProject);

  }

  saveTaskStatusComments(DynamicFormdataobj) {

    return this.httpClient.post(this.SAVETASKBYPASSPROJETS, DynamicFormdataobj);
  }
  updateTaskResumeSuspendCycle(DynamicFormdataobj) {

    return this.httpClient.post(this.TaskResumeSuspendCycle, DynamicFormdataobj);
  }
  updateTaskCancelCycle(DynamicFormdataobj) {

    return this.httpClient.post(this.TaskCancelCycle, DynamicFormdataobj);
  }
  saveMultiTaskBypassComments(DynamicFormdataobj) {
    return this.httpClient.post(this.SAVEMULTITASKBYPASSCOMMENTS, DynamicFormdataobj);
  }

  getUsersForNotification(projectId: number, processDataId: number, notificationId: number) {
    let params = new HttpParams();
    params = params.set('ProjId', projectId.toString());
    params = params.set('ProcessId', processDataId.toString());
    params = params.set('Notfid', notificationId.toString());
    return this.httpClient.get(this.GETUSERSFORBYPASS, { params: params });
  }

  getBypassUnbypassWorkFlowTaskList(projectId: number, processIds: string, action: string, level: number, DynamicFormdataobj: any) {
    let params = new HttpParams();
    let obj = new Object();
    params = params.set('ProjectId', projectId.toString());
    params = params.set('ProcessIds', processIds);
    params = params.set('Action', action);
    params = params.set('Level', level.toString());
    //params = params.set('DynamicFormdataobj', DynamicFormdataobj.toString());
    //obj = DynamicFormdataobj;
    params = params.set('DynamicFormdataobj', JSON.stringify(DynamicFormdataobj));
    return this.httpClient.get(this.GETBYPASSUNBYPASSWORKFLOWTASKS, { params: params });
  }

  getBypassTasksByWorkFlow(projectId: number, action: string, level: number) {
    let params = new HttpParams();
    params = params.set('ProjectId', projectId.toString());
    params = params.set('Action', action);
    params = params.set('Level', level.toString());
    return this.httpClient.get(this.GETBYPASSTASKSBYWORKFLOW, { params: params });
  }

  getbypassedTasksList(subprocessId: number) {
    let params = new HttpParams();
    params = params.set('SubProcessId', subprocessId.toString());
    return this.httpClient.get(this.BypassedTaskList, { params: params })
  }

  //saveTaskStatusComments(recordId: number, projId: string, taskid:number,comments: string, action:string) {
  //  let ByPassProject: any = {};
  //  ByPassProject.processDataId = recordId;
  //  ByPassProject.projectID = parseInt(projId);
  //  ByPassProject.TaskId = taskid;
  //  ByPassProject.Comments = comments;
  //  ByPassProject.Action = action;
  //  return this.httpClient.post(this.SAVETASKBYPASSPROJETS, ByPassProject);
  //}

  public deleteRelatedProjectByProjectId(obj: any) {
    //let params = new HttpParams();
    //params = params.set('ProjectID', projectId.toString())
    //params = params.set('RelatedProjectID', relatedProjectId.toString())
    return this.httpClient.post(this.DELETERELATEDPROJECTS, obj);
  }

  public removeUploadFiles(FileName: string) {
    let files: any = {};
    files.FileName = FileName;
    //dataObj.fileNames.push(obj);
    return this.httpClient.post(this.REMOVEUPLODFILES, files);
  }

  public GetTemplatesByModuleID(template: templateData) {


    let params = new HttpParams();
    params = params.set('moduleID', template.moduleID.toString());
    params = params.set('templateID', template.templateID.toString());
    params = params.set('parentTemplateID', template.parentTemplateID.toString());
    params = params.set('parentRecordID', template.parentRecordID.toString());
    params = params.set('dependentTemplateID', template.dependentTemplateID.toString());
    params = params.set('dependentRecordID', template.dependentRecordID.toString());
    params = params.set('tabTemplateID', template.tabTemplateID.toString());
    params = params.set('userID', this.GV.UserId);
    return this.httpClient.get<any>(this.TEMPLATES, { params: params });
  }

  public GetAllTemplatesByModuleID(template: templateData) {
    let params = new HttpParams();
    params = params.set('moduleID', template.moduleID.toString());
    params = params.set('templateID', template.templateID.toString());
    params = params.set('parentTemplateID', template.parentTemplateID.toString());
    params = params.set('parentRecordID', template.parentRecordID.toString());
    params = params.set('dependentTemplateID', template.dependentTemplateID.toString());
    params = params.set('dependentRecordID', template.dependentRecordID.toString());
    params = params.set('tabTemplateID', template.tabTemplateID.toString());
    params = params.set('UserID', this.GV.UserId)
    return this.httpClient.get<any>(this.ALLTEMPLATES, { params: params });
  }


  public GetTemplatesByTemplateID(template: templateData) {


    let params = new HttpParams();
    params = params.set('moduleID', template.moduleID.toString());
    if (template.templateID != null)
      params = params.set('templateID', template.templateID.toString());
    else
      params = params.set('templateID', 0);

    params = params.set('parentTemplateID', template.parentTemplateID.toString());
    params = params.set('parentRecordID', template.parentRecordID.toString());
    params = params.set('dependentTemplateID', template.dependentTemplateID.toString());
    params = params.set('dependentRecordID', template.dependentRecordID.toString());
    params = params.set('userID', this.GV.UserId);
    if (template.tabTemplateID != null)
      params = params.set('tabTemplateID', template.tabTemplateID.toString());
    else
      params = params.set('tabTemplateID', 0);
    return this.httpClient.get<any>(this.TEMPLATESBYTID, { params: params });
  }



  public GetControlTemplatesByModuleID(template: templateData) {
    let params = new HttpParams();
    params = params.set('moduleID', template.moduleID.toString());
    params = params.set('templateID', template.templateID.toString());
    params = params.set('parentTemplateID', template.parentTemplateID.toString());
    params = params.set('parentRecordID', template.parentRecordID.toString());
    params = params.set('dependentTemplateID', template.dependentTemplateID.toString());
    params = params.set('dependentRecordID', template.dependentRecordID.toString());



    return this.httpClient.get<any>(this.CONTROLTEMPLATES, { params: params });
  }




  public GetControlTemplatesByTemplateID(templateID: number, template: templateData, DynamicActionType: any) {


    let params = new HttpParams();
    params = params.set('moduleID', template.moduleID.toString());
    params = params.set('templateID', template.templateID.toString());
    params = params.set('parentTemplateID', template.parentTemplateID.toString());
    params = params.set('parentRecordID', template.parentRecordID.toString());
    params = params.set('dependentTemplateID', template.dependentTemplateID.toString());
    params = params.set('dependentRecordID', template.dependentRecordID.toString());
    params = params.set('DynamicActionType', DynamicActionType.toString());



    return this.httpClient.get<any>(this.CONTROLACTIOSBYTEMPLATE, { params: params });
  }
  public GetClassName(templateID: string) {




    let params = new HttpParams();
    params = params.set('TemplateID', templateID.toString());





    return this.httpClient.get<any>(this.GETCLASSNAME, { params: params });
  }


  public GetLegends(templateID: string) {




    let params = new HttpParams();
    params = params.set('TemplateID', templateID.toString());





    return this.httpClient.get<any>(this.GETLEGENDS, { params: params });
  }


  public CheckHasDependents(ModuleID: string, TempalteID: string) {
    let params = new HttpParams();
    params = params.set('ModuleID', ModuleID.toString());
    params = params.set('TempalteID', TempalteID.toString());
    params = params.set('userID', this.GV.UserId);

    return this.httpClient.get<any>(this.CHECKHASDEPENDENTS, { params: params });
  }

  public CheckIsDependents(ModuleID: string, TempalteID: string) {

    let params = new HttpParams();
    params = params.set('ModuleID', ModuleID.toString());
    params = params.set('TempalteID', TempalteID.toString());
    params = params.set('userID', this.GV.UserId);


    return this.httpClient.get<any>(this.CHECKISDEPENDENTS, { params: params });
  }


  public CheckIsParentTemplate(ModuleID: string, TempalteID: string, targetTemplateId: string) {

    let params = new HttpParams();
    params = params.set('ModuleID', ModuleID.toString());
    params = params.set('TempalteID', TempalteID.toString());
    params = params.set('TargetTemplateId', targetTemplateId.toString());

    params = params.set('userID', this.GV.UserId);


    return this.httpClient.get<any>(this.CHECKISPARENTTEMPLATE, { params: params });
  }



  public GetTreeData(ModuleID: string, TempalteID: string, UserID: number) {

    let params = new HttpParams();
    params = params.set('ModuleID', ModuleID.toString());
    params = params.set('TempalteID', TempalteID.toString());
    params = params.append('UserID', UserID.toString());


    return this.httpClient.get<any>(this.GETTREEDATA, { params: params });
  }
  public GetDisplayFieldData(templateID: number, parentRecordID: number, displayControlsID: number, recordType: number, IsExcelExportField: number, searchFilters: string, Tname: string, skip: number, take: number, keyWordSearch: string, chkFields: string, filter: any) {

    if (recordType == null || recordType == undefined || recordType == 0) {
      recordType = 1;
    }

    var filterConfigKey = localStorage.getItem("filterConfigKey");
    var userId = localStorage.getItem("userId");
    if (filterConfigKey == "1") {
      userId = "0";
    }

    if (filter == undefined) {
      filter = "";
    }

    //var currDate = new Date();

    //var addTimeZoneOffSet = currDate.getTimezoneOffset();

    //var Offset = -(addTimeZoneOffSet);
    ////negative offset for method
    //var hours = 0;
    //if (Offset < 0)
    //  hours = -(Math.floor(-(Offset) / 60));
    //else
    //  hours = Math.floor(Offset / 60);

    //if (hours > 0)
    //  hours = hours;
    //var minutes = Offset % 60;
    //if (minutes < 0)
    //  minutes = -(minutes);

    //var offset = '+' + hours + ':' + minutes

    var offset = this.getTimeZoneOffset();

    let params = new HttpParams();
    let headers = new Headers();

    headers.append('Access-Control-Allow-Origin', '*');
    // res.header('Access-Control-Allow-Origin', '*');
    params = params.set('templateID', templateID.toString());
    params = params.set('parentRecordID', parentRecordID.toString());
    params = params.set('displayControlsID', displayControlsID.toString());
    params = params.set('recordType', recordType.toString());
    params = params.set('userid', userId.toString())
    params = params.append('offset', offset);
    params = params.append('UserId', userId);
    params = params.set('searchFilters', searchFilters.toString());
    params = params.set('IsExcelExportField', IsExcelExportField.toString());
    params = params.set('Tname', Tname.toString());
    params = params.set('skip', skip.toString());
    params = params.set('take', take.toString());
    params = params.set('keyWordSearch', keyWordSearch.toString());
    params = params.set('chkFields', chkFields.toString());
    params = params.set('filter', JSON.stringify(filter));


    var method = "?templateID=" + templateID.toString() + "&parentRecordID=" + parentRecordID.toString() + "&displayControlsID=" + displayControlsID.toString() + "&recordType=" + recordType.toString() + "&offset=" + encodeURIComponent(offset).toString() + "&searchFilters=&IsExcelExportField=" + IsExcelExportField.toString() + "&Tname=" + Tname.toString() + "&skip=" + skip.toString() + "&take=" + take.toString() + "&userid=" + userId.toString() + "&keyWordSearch=" + keyWordSearch.toString() + "&chkFields=" + chkFields.toString() + "&filter=" + JSON.stringify(filter);

    const body = { 'searchFilters': searchFilters.toString() };

    return this.httpClient.post<any>(this.GETDISPLAYFIELD + method, body, { headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*') });

    //return this.httpClient.get<any>(this.GETDISPLAYFIELD + templateID);
  }
  getTimeZoneOffset(): any {
    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset();
    const timezoneOffsetFormatted = this.datepipe.transform(timezoneOffset, 'Z')
    return timezoneOffsetFormatted.slice(0, 3) + ":" + timezoneOffsetFormatted.slice(3);
  }


  public DeleteDisplayFieldRecord(id: number = 0, UserID: number = 0): Observable<any> {
    let params = new HttpParams();
    params = params.set('UserID', UserID);
    params = params.set('id', id);
    params = params.set('Ip', this.GV.UserIpAddress.toString());
    return this.httpClient.get<any>(this.DELETEDISPLAYFIELDRECORD, { params: params });
  }
  public DeleteReferencefiles(FileIds: string, ProjectId: number, UserId: number, IpAddress: string) {

    return this.httpClient.get<any>(this.DeleteORInactiveReferencefiles + "?refDocIds=" + FileIds + "&projectId=" + ProjectId + "&userId=" + UserId + "&ipAddress=" + IpAddress);
  }

  public DeleteSavedSearch(ID: number) {
    let params = new HttpParams();
    params = params.set('id', ID.toString());

    return this.httpClient.get<any>(this.DELETESAVEDSEARCH, { params: params });
  }

  public ConvertTOPDFView(FileName: string, Filepath: string) {
    return this.httpClient.get<any>(this.ConvertAITOPDF + "?documentName=" + FileName + "&PhysicalFilePath=" + Filepath);
  }

  public DisplayView(ID: number) {


    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes


    let params = new HttpParams();
    params = params.set('ID', ID.toString());
    params = params.append('offset', offset);

    return this.httpClient.get<any>(this.DISPLAYVIEW, { params: params });

    //return this.httpClient.get<any>(this.DISPLAYVIEW + ID);
  }

  public DisplayTableView(TemplateID: number, RecordID: number, RecordIDs: string, recordType: number) {
    if (recordType == null || recordType == undefined || recordType == 0) {
      recordType = 1;
    }
    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes


    let params = new HttpParams();
    params = params.set('TemplateID', TemplateID.toString());
    params = params.set('RecordID', RecordID.toString());
    params = params.set('RecordIDs', RecordIDs);
    params = params.set('RecordType', recordType.toString());
    params = params.append('offset', offset);

    return this.httpClient.get<any>(this.DISPLAYTABLEVIEW, { params: params });

    //return this.httpClient.get<any>(this.DISPLAYVIEW + ID);
  }

  //get table view data
  public DisplayViewSubProcess(ID: number) {


    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes


    let params = new HttpParams();
    params = params.set('ID', ID.toString());
    params = params.append('offset', offset);

    return this.httpClient.get<any>(this.DISPLAYVIEWSUBPROCESS, { params: params });

    //return this.httpClient.get<any>(this.DISPLAYVIEW + ID);
  }
  passDefaultSearchItem = new BehaviorSubject(true);

  passDefaultSearchValue(defaultsearchValue) {
    this.passDefaultSearchItem.next(defaultsearchValue);
  }

  private viewSearchPopUp = new BehaviorSubject<boolean>(false);
  showFilter = this.viewSearchPopUp.asObservable();
  setSearchShow(value: boolean) {
    this.viewSearchPopUp.next(value)
  }


  masterModuleID = new Subject();
  templateID = new Subject();
  //moduleID = new BehaviorSubject(0);  
  displayViewID = new Subject();

  passMasterModulueID(moduleID) {

    this.masterModuleID.next(moduleID);
  }

  //passModuleID(moduleID) {

  //  this.moduleID.next(moduleID);
  //}

  passDisplayViewID(displayViewID) {

    this.displayViewID.next(displayViewID);
  }

  templateParentID = new BehaviorSubject(0);

  passtemplateParentID(templateParentID) {


    this.templateParentID.next(templateParentID);
  }


  splitterTemplate = new Subject();

  passSplitterTemplate(data: any) {
    this.splitterTemplate.next(data);
  }


  actionTypeTemplateID = new BehaviorSubject(0);

  passActionTypeTemplateID(actionTypeTemplateID) {


    this.actionTypeTemplateID.next(actionTypeTemplateID);
  }


  parentRecordID = new BehaviorSubject(0);


  passparentRecordID(parentRecordID) {


    this.parentRecordID.next(parentRecordID);
  }

  selectedRecordID = new BehaviorSubject(0);


  passSelectedRecordID(selectedRecordID) {


    this.selectedRecordID.next(selectedRecordID);
  }


  dependencyTemplate = new BehaviorSubject(new templateSelectedRecord());

  passDependencyTemplate(data: templateSelectedRecord) {
    this.dependencyTemplate.next(data);
  }
  ReferenceFileIds = new BehaviorSubject(0);
  passReferenceFileIds(data) {
    this.ReferenceFileIds.next(data);
  }

  searchFilters = new BehaviorSubject(new searchFilter());

  passsearchFilters(data: searchFilter) {
    this.searchFilters.next(data);
  }

  searchTemplateViewFilters = new BehaviorSubject(new searchFilter());

  passTemplateViewFilters(data: searchFilter) {
    this.searchTemplateViewFilters.next(data);
  }



  searchTabFilters = new BehaviorSubject(new searchFilter());

  passsearchTabFilters(data: searchFilter) {
    this.searchTabFilters.next(data);
  }

  SelectedModuleIDForDashboard = new BehaviorSubject(0);

  passSelectedModuleIDForDashboard(data: number) {

    this.SelectedModuleIDForDashboard.next(data);

  }

  searchReportFilters = new BehaviorSubject(new searchFilter());

  passSearchReportFilters(data: searchFilter) {
    this.searchReportFilters.next(data);
  }


  getBaseUrl() {
    return getBaseUrl();
  }

  templateData = new BehaviorSubject(new templateData());

  passTemplateData(data: templateData) {
    data.initialLoad = false;
    this.templateData.next(data);
  }

  unSubTemplateData = new BehaviorSubject(new templateData());
  passunSubTemplateData(data: templateData) {
    this.unSubTemplateData.next(data)
  }

  selectedModuleID = new BehaviorSubject(0);
  passSelectedModuleID(Mid) {
    this.selectedModuleID.next(Mid);
  }

  templateRecord = new BehaviorSubject(new templateDataRecord());

  passTemplateRecord(data: templateDataRecord) {
    this.templateRecord.next(data);
  }


  templateRecordRemove = new BehaviorSubject(new templateDataRecord());

  passTemplateRecordRemove(data: templateDataRecord) {
    this.templateRecordRemove.next(data);
  }



  updateCascading = new BehaviorSubject(new cascadingField());

  passupdateCascading(data: cascadingField) {
    this.updateCascading.next(data);
  }

  dependentRecord = new BehaviorSubject(new templateDataRecord());

  passDependentRecord(data: templateDataRecord) {
    this.dependentRecord.next(data);
  }




  dependentSplitter = new Subject();

  passDependentSplitter(data: any) {
    this.dependentSplitter.next(data);
  }



  breadCrumbs = new BehaviorSubject(new Array<breadCrumbs>());

  passbreadCrumbs(data: Array<breadCrumbs>) {
    this.breadCrumbs.next(data);
  }


  breadCrumbName = new BehaviorSubject(new breadCrumbs());

  passbreadCrumbName(data: breadCrumbs) {
    this.breadCrumbName.next(data);
  }


  dalCollection = new BehaviorSubject(0);

  passdalCollection(data: any) {
    this.dalCollection.next(data);
  }

  dalFileCollection = new BehaviorSubject(0);

  passdalFileCollection(data: any) {
    this.dalFileCollection.next(data);
  }



  closeDAL = new Subject();

  passCloseDAL(data: any) {
    this.closeDAL.next(data);
  }

  AppComponent = new BehaviorSubject(0);


  reoladAppComponent(AppComponent) {


    this.AppComponent.next(AppComponent);
  }


  DisplayTaskManagementView(ProjectID: number, ProcessDataId: number, UserID: number) {
    var currDate = new Date();
    var addTimeZoneOffSet = currDate.getTimezoneOffset();
    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes
    let params = new HttpParams();
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('ProcessDataId', ProcessDataId.toString());
    params = params.append('offset', offset);
    params = params.append('UserID', UserID.toString());
    return this.httpClient.get<any>(this.TaskDISPLAYVIEW, { params: params });

  }


  Displaysubidentifier(ProjectID: number, ProcessDataId: number, UserID: number, TaskID: number) {
    var currDate = new Date();
    var addTimeZoneOffSet = currDate.getTimezoneOffset();
    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes
    let params = new HttpParams();
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('ProcessDataId', ProcessDataId.toString());
    params = params.append('offset', offset);
    params = params.append('UserID', UserID.toString());
    params = params.append('TaskID', TaskID.toString());
    return this.httpClient.get<any>(this.SUBTASKVIEW, { params: params });
  }
  UpdateProjectTaskManagementData(taskSubDetails) {
    return this.httpClient.post<any>(this.UpdateProjectSubTask, taskSubDetails);
  }

  UpdateTaskByPass(taskSubDetails) {
    return this.httpClient.post<any>(this.TaskByPass, taskSubDetails);
  }

  GetTaskByPassFields(ProjectID: number, ProcessDataId: number) {
    let params = new HttpParams();
    if (ProjectID === undefined || ProjectID === null)
      ProjectID = 0;
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('ProcessDataId', ProcessDataId.toString());
    params = params.set('UserID', this.GV.UserId);

    return this.httpClient.get<any>(this.ByPassTaskDisplay, { params: params });

  }
  GetTasksuspendResumecycleFeilds(ProjectID: number, ProcessDataId: number) {
    let params = new HttpParams();
    if (ProjectID === undefined || ProjectID === null)
      ProjectID = 0;
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('ProcessDataId', ProcessDataId.toString());
    params = params.set('UserID', this.GV.UserId);

    return this.httpClient.get<any>(this.TasksuspendResumecycleFeilds, { params: params });

  }
  GetSubProcessTaskFileds(ProjectID: number, ProcessDataId: number, Status: number) {
    var currDate = new Date();
    var addTimeZoneOffSet = currDate.getTimezoneOffset();
    var Offset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (Offset < 0)
      hours = -(Math.floor(-(Offset) / 60));
    else
      hours = Math.floor(Offset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = Offset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var offset = '+' + hours + ':' + minutes
    let params = new HttpParams();
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('ProcessDataId', ProcessDataId.toString());
    params = params.append('offset', offset);
    params = params.append('status', Status.toString());
    params = params.append('UserID', this.GV.UserId)
    return this.httpClient.get<any>(this.TaskDISPLAYControllVIEW, { params: params });
  }

  FieldMandatoryStatuschanged(objFieldStatus) {
    return this.httpClient.post(this.MandatoryStatuschangedURL, objFieldStatus, { responseType: 'text' });
  }
  GetTaskStatus(ProejectID, subProcessID, TaskID, UserID) {
    let params = new HttpParams();
    params = params.append('projectID', ProejectID);
    params = params.append('subProcessID', subProcessID);
    params = params.append('TaskID', TaskID);
    params = params.append('UserID', UserID);
    return this.httpClient.get<any>(this.GetCurrentTaskStatus, { params: params });
  }
  //DownloadPdfFile(Filepath: string, FileName: string, ProcessDataID: number, SubprocessID: number, UserID: number, Ip: string) {
  //  return this.httpClient.get(this.DownloadPdfFileUrl + "?filePath=" + Filepath + "&fileName=" + FileName + "&ProcessDataID=" + ProcessDataID + "&SubprocessID=" + SubprocessID + "&UserID=" + UserID + "&Ip=" + Ip, {
  //    reportProgress: true,
  //    observe: 'events',
  //    responseType: 'blob'
  //  });
  //}

  DownloadPdfFile(Filepath: string, FileName: string, ProcessDataID: number, SubprocessID: number, UserID: number, Ip: string) {
    let params = new HttpParams();
    //debugger;
    params = params.append('filePath', Filepath);

    params = params.append('fileName', FileName);

    params = params.append('ProcessDataID', ProcessDataID);

    params = params.append('SubprocessID', SubprocessID);

    params = params.append('UserID', UserID);

    params = params.append('Ip', Ip);

    const body = {

      'filePath': Filepath,

      'fileName': FileName,

      'ProcessDataID': ProcessDataID,

      'SubprocessID': SubprocessID,

      'UserID': Filepath,

      'Ip': FileName,
    };

    return this.httpClient.post<any>(this.DownloadPdfFileUrl + "?filePath=" + Filepath + "&fileName=" + FileName + "&ProcessDataID=" + ProcessDataID + "&SubprocessID=" + SubprocessID + "&UserID=" + UserID + "&Ip=" + Ip, body, {

      //return this.httpClient.post<any>(this.DownloadPdfFileUrl, body, {  

      reportProgress: true,

      observe: 'events',

      responseType: 'blob' as 'json'

    });

  }

  DownloadFile(Filepath: string, FileName: string)
  {
    return this.httpClient.get(this.DownloadFileUrl + "?documentName=" + FileName + "&PhysicalFilePath=" + Filepath , {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }
  PreviewPDFDoc(Filepath: string, FileName: string)
  {
    return this.httpClient.get<any>(this.PreviewPDF + "?documentName=" + FileName + "&PhysicalFilePath=" + Filepath);
  }

  public zeroPad = (num, places) => String(num).padStart(places, '0');
  public getOffset() {
    var currDate = new Date();

    var addTimeZoneOffSet = currDate.getTimezoneOffset();

    var tOffset = -(addTimeZoneOffSet);
    //negative offset for method
    var hours = 0;
    if (tOffset < 0)
      hours = -(Math.floor(-(tOffset) / 60));
    else
      hours = Math.floor(tOffset / 60);

    if (hours > 0)
      hours = hours;
    var minutes = tOffset % 60;
    if (minutes < 0)
      minutes = -(minutes);

    var resoffset = '+' + this.zeroPad(hours, 2) + ':' + this.zeroPad(minutes, 2);
    return resoffset;
  }
  DownloadALLArtworkPdfFile(ProcessDataID: number, SubprocessID: number, UserID: number, Ip: string) {
    return this.httpClient.get(this.DownloadAllArtWorkFilesUrl + "?ProcessDataID=" + ProcessDataID + "&SubprocessID=" + SubprocessID + "&UserID=" + UserID + "&Ip=" + Ip, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }


  DownloadDalorReferenceFiles(FileIds: string, UserID: number, Ip: string) {
    return this.httpClient.get(this.DownloadDALFilesUrl + "?FilesIDs=" + FileIds + "&&UserId=" + UserID + "&&Ip=" + Ip, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }
  DeleteArtWorkPdfFile(objData: any) {
    return this.httpClient.post(this.DeleteUploadArWorkFile, objData, { responseType: 'text' });
  }
  BindTaskOwnerInputData(ProcessDataId: number, userId: number, lstRecordids: string, moduleType: string, ActionTypeID: number) {
    let params = new HttpParams();
    params = params.set('ProcessDataID', ProcessDataId.toString());
    params = params.set('UserId', userId.toString());
    params = params.set('LstProcessDataIds', lstRecordids.toString());
    params = params.set('moduleType', moduleType.toString());
    params = params.set('ActionTypeID', ActionTypeID.toString());
    return this.httpClient.get<any>(this.BindTaskOwnerDataSourceURL, { params: params });
  }
  GetTaskOwnerGroupUsers(TypeID: number, DeptGroupID: number, userId: number, lstProcessDataID: string, ProcessDataID: number = 0, SubProcessId: number = 0, TaskID: number = 0) {
    let params = new HttpParams();
    params = params.set('TypeID', TypeID.toString());
    params = params.set('DeptGroupID', DeptGroupID.toString());
    params = params.set('UserId', userId.toString());
    params = params.set('ProcessDataID', ProcessDataID.toString());
    params = params.set('selProcessDataIdslist', lstProcessDataID.toString());
    params = params.set('SubProcessId', SubProcessId.toString());
    params = params.set('TaskID', TaskID.toString());
    return this.httpClient.get<any>(this.GetTaskOwnerGroupUsersURL, { params: params });
  }
  SaveProjectTaskOwnerGroup(objData: any) {
    return this.httpClient.post(this.SaveProjectTaskOwnerGroupURL, objData, { responseType: 'text' });
  }
  SaveProjectTaskOwnerGroupUsers(objData: any) {
    return this.httpClient.post(this.SaveProjectTaskOwnerGroupUsersURL, objData, { responseType: 'text' });
  }

  UpdateTask(objData: any) {
    return this.httpClient.post(this.SaveProjectTaskOwnerGroupURL, objData, { responseType: 'text' });
  }
  SaveTaskAddApprovers(objData: any) {
    return this.httpClient.post(this.SaveProjectTaskAddApproversURL, objData, { responseType: 'text' });
  }
  BindProjectAssigments(projectID: number) {
    let params = new HttpParams();
    params = params.set('ProjectID', projectID.toString());
    return this.httpClient.get<any>(this.GetProjectAssigmentsURL, { params: params });
  }

  GetProjectTaskOwnerGroupsUsers(projectID: number) {
    let params = new HttpParams();
    params = params.set('ProjectID', projectID.toString());
    return this.httpClient.get<any>(this.ProjectTaskOwnerGroupsUsers, { params: params });
  }

  BindProjectAssignGroupUsers(projectID: number) {
    let params = new HttpParams();
    params = params.set('ProjectID', projectID.toString());
    return this.httpClient.get<any>(this.GetProjecGroupUsersURL, { params: params });
  }

  public GetProjectandSubprocess(projectId: number) {
    let params = new HttpParams();
    params = params.set('ProjId', projectId.toString());
    return this.httpClient.get<any>(this.GETPROJECTANDSUBPROCESS, { params: params });
  }
  public GetSourceData(ChatType: string, SourceType: string, Params: string, UserId: number) {
    let params = new HttpParams();
    params = params.set('ChatType', ChatType);
    params = params.set('SourceType', SourceType);
    params = params.set('Params', Params.toString());
    params = params.set('UserId', UserId.toString());
    return this.httpClient.get<any>(this.GETSOURCEDATA, { params: params });
  }
  public GetSourceDataProjectAndUserId(projectId: number, UserId: number) {
    let params = new HttpParams();
    params = params.set('projectId', projectId);
    params = params.set('UserId', UserId);
    return this.httpClient.get<any>(this.GETSOURCEDATAPROJECTANDUSERID, { params: params });
  }
  UpdateSourceDataProjectSubProcessandUserId(projectId: number, subProcessId: number, userId: number, topic: string, Priority: number) {
    let params = new HttpParams();
    params = params.set("projectId", projectId);
    params = params.set("subProcessId", subProcessId);
    params = params.set("userId", userId);
    params = params.set("topic", topic);
    params = params.set("Priority", Priority);
    return this.httpClient.get(this.UPDATESOURCEDATAPROJECTSUBPROCESSANDUSERID, { params: params, responseType: 'text' });
  }







  gvresponse(stream: any) {

    return this.httpClient.post('https://verify.trial.globalvision.co/api/v1/files', stream, {
      headers: new HttpHeaders({
        'x-gv-integration': 'test',
        'x-api-key': '16fe805794c51c856a6263e6bb0ef933.ae948da7ec0c547d0f9cb6ca59cdb50ab137eed435631e10876d994e47709f4d',
        'accept': '/'
      }),
    })
  }
  CheckCCMInputAndDocUpload(ProjectID: number, ProcessDataId: number) {
    let params = new HttpParams();
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('ProcessDataId', ProcessDataId.toString());
    return this.httpClient.get(this.CHECKCCMINPUTANDDOCUPLOAD, { params: params, responseType: 'text' });
  }
  sendEmailToFRA(ProjectID: number, ProcessDataId: number) {
    let params = new HttpParams();
    params = params.set('ProjectID', ProjectID.toString());
    params = params.set('ProcessDataId', ProcessDataId.toString());
    return this.httpClient.get<any>(this.SENDEMAILTOFRA, { params: params });
  }
  CalculateCompleteDate(Priority: string, WorkflowID: number, FirstReviewDate: string, apiCall: boolean) {

    let params = new HttpParams();

    params = params.set('Priority', Priority.toString());

    params = params.set('WorkflowID', WorkflowID.toString());

    params = params.set('FirstReviewDate', FirstReviewDate.toString());

    params = params.set('apiCall', false);

    return this.httpClient.get(this.CALCULATECOMPLETEDATE, { params: params, responseType: 'text' });

  }
  Publishfiledownload(id) {

    return this.httpClient.get(this.PublishFileDownload + "?id=" + id, {

      reportProgress: true,

      observe: 'events',

      responseType: 'blob'

    });

  }

  loadProjectTasks = new Subject();
  passLoadProjectTask(data: any) {

    this.loadProjectTasks.next(data);
  }

  userSSOLogin = new BehaviorSubject(false);

  passAfterSSOLogin(responseData) {
    this.userSSOLogin.next(responseData);
  }

  public CheckForQATaskStatus(projectId: number, subProcessId: number) {
    let params = new HttpParams();

    params = params.set('ProjectId', projectId);
    params = params.set('SubprocessId', subProcessId);
    return this.httpClient.get<any>(this.GetPrjectTaskStatus, { params: params });
  }

public  GetFilesDetails(templateID: number, projectID: number, defaultRecordCount:number,skip:number) {
    let params = new HttpParams();
    params = params.set('templateID', templateID.toString());
    params = params.set('projectID', projectID.toString());
    params = params.set('defaultRecordCount', defaultRecordCount.toString());
    params = params.set('skip', skip.toString());
    return this.httpClient.get(this.GetcurrentGridfileDetails, { params: params, responseType: 'text' });
  }

  public getAllProducts(){
   
   
    return this.httpClient.get(this.GetProductsvalues);
   
  }
}

